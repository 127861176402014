import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ListItemText({
  heading, headingStyles, subtext, subtextStyles,
}) {
  return (
    <div css={styles.container}>
      {heading && <h2 css={[styles.heading, headingStyles]}>{heading}</h2>}
      {subtext && <p css={[styles.subtext, subtextStyles]}>{subtext}</p>}
    </div>
  );
}

const styles = StyleSheet.create({
  heading: {
    marginBottom: 0,
    fontSize: 18,
    lineHeight: '24px',
    ...Responsive.lessThanMd({
      fontSize: 20,
      lineHeight: '24px',
    }),
  },
  subtext: {
    marginBottom: 0,
    fontSize: 18,
    lineHeight: '24px',
    ...Responsive.lessThanMd({
      fontSize: 20,
      lineHeight: '22px',
    }),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    alignItems: 'flex-start',
  },
});

ListItemText.propTypes = {
  heading: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  headingStyles: PropTypes.object,
  subtext: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  subtextStyles: PropTypes.object,
};
