import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import DateInputGroup from '@root/core/src/components/date-input-group';
import DobDate from '@root/core/src/models/dob-date';
import Driver from '@root/auto-pricing/src/models/driver';
import ErrorMessage from '@root/core/src/components/error-message';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import licenseStroke from '@root/core/src/assets/licenseStroke.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class ProfileAddDriverBirthday extends Component {
  static propTypes = {
    cssButtonOverrides: PropTypes.object,
    driver: PropTypes.instanceOf(Driver).isRequired,
    inputStyle: PropTypes.object,
    linkStyles: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
  }

  _continueButton = React.createRef();

  static analyticsContext = 'PROFILE_ADD_DRIVER_BIRTHDAY';

  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      driver: props.driver,
      date: new DobDate(''),
    };
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(ProfileAddDriverBirthday.analyticsContext, { eventPrefix: this.context.eventPrefix });
  }

  _handleChange = (field) => (value) => {
    this.setState((prevState) => ({
      date: prevState.date.set(field, value),
    }));
  }

  _handleSubmit = () => {
    if (this._driverIsValid()) {
      AnalyticsService.trackClickEvent(ProfileAddDriverBirthday.analyticsContext, 'SUBMIT', { eventPrefix: this.context.eventPrefix });
      const driver = this.props.driver.set('dob', this.state.date);
      this.props.onDone(driver);
    }
  }

  _focusOnContinue = () => {
    this._continueButton.current.focus();
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverBirthday.analyticsContext, 'CANCEL', { eventPrefix: this.context.eventPrefix });
    this.props.onCancel();
  }

  _driverIsValid() {
    return this.state.date.isValid() && this.state.date.isOldEnough() && this.state.date.isYoungEnough();
  }

  _renderDobError() {
    if (this.state.date.isYearComplete()) {
      if (!this.state.date.isYoungEnough()) {
        return (
          <ErrorMessage message={`You must be ${DobDate.DEFAULT_MAX_AGE} years or younger to sign up!`} />
        );
      }

      if (!this.state.date.isOldEnough()) {
        return (
          <ErrorMessage message={`You must be ${DobDate.DEFAULT_MIN_AGE} years or older to sign up!`} />
        );
      }
    }

    return null;
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <ProfileHeading icon={licenseStroke}>
          When’s their birthday?
        </ProfileHeading>
        {this._renderDobError()}
        <ProfileForm onSubmit={this._handleSubmit}>
          <DateInputGroup
            focusOnMount
            date={this.state.date}
            inputStyle={this.props.inputStyle}
            onChange={this._handleChange}
            onValidYear={this._focusOnContinue}
          />
          <Button
            css={[styles.button, this.props.cssButtonOverrides]}
            disabled={!this._driverIsValid()}
            ref={this._continueButton}
          >
            Continue
          </Button>
          <NevermindLink
            linkStyles={this.props.linkStyles}
            onCancel={this._handleCancel}
          />
        </ProfileForm>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  wrapper: {
    width: '100%',
  },
});
