
// Inherit Quotes model

import Quote from '@root/quotes/src/models/quote';

export class DisplayQuote extends Quote {
  static build(params) {
    const quote = Quote.build(params);

    const displayQuote = {
      ...quote,
      title: params.title,
      description: params.description,
    };

    return Object.assign(
      new DisplayQuote(),
      displayQuote,
    );
  }
}
