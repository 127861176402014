import DeepLinkService from '@root/bind.joinroot.com/src/services/deep-link-service';
import PropTypes from '@root/vendor/prop-types';
import { DeepLink } from '@root/bind.joinroot.com/src/models/deep-link';
import { RootError } from '@root-common/root-errors';
import { createContext, useCallback, useContext, useMemo } from '@root/vendor/react';
import { useConfig } from '@root/bind.joinroot.com/src/context/config';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'deepLink';

const DeepLinkContext = createContext();
const DeepLinkFlowContext = createContext();
const SetDeepLinkContext = createContext();

const DeepLinkProvider = ({
  children,
  completeFlow: overrideCompleteFlow,
  exitFlow: overrideExitFlow,
  value = new DeepLink(),
}) => {
  const config = useConfig();
  const [deepLink, setDeepLink] = usePersistedState({
    initialValue: value,
    deserialize: (persistedItem) => new DeepLink(persistedItem),
    serialize: (storedDeepLink) => storedDeepLink.serializeForSaving(),
    storageKey: LOCAL_STORAGE_KEY,
  });

  const completeFlow = useCallback(() => {
    DeepLinkService.completeFlow(config?.successUrl);
  }, [config?.successUrl]);

  const exitFlow = useCallback(() => {
    DeepLinkService.exitFlow(config?.cancelUrl);
  }, [config?.cancelUrl]);

  const deepLinkFlow = useMemo(() => ({
    completeFlow: overrideCompleteFlow ? overrideCompleteFlow : completeFlow,
    exitFlow: overrideExitFlow ? overrideExitFlow : exitFlow,
  }), [completeFlow, exitFlow, overrideCompleteFlow, overrideExitFlow]);

  return (
    <DeepLinkContext.Provider value={deepLink}>
      <DeepLinkFlowContext.Provider value={deepLinkFlow}>
        <SetDeepLinkContext.Provider value={setDeepLink}>
          {children}
        </SetDeepLinkContext.Provider>
      </DeepLinkFlowContext.Provider>
    </DeepLinkContext.Provider>
  );
};

DeepLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
  completeFlow: PropTypes.func,
  exitFlow: PropTypes.func,
  value: PropTypes.instanceOf(DeepLink),
};

const useDeepLink = () => {
  const context = useContext(DeepLinkContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useDeepLink must be used within DeepLinkProvider',
      name: 'DeepLinkContextError',
    });
  }

  return context;
};

const useDeepLinkFlow = () => {
  const context = useContext(DeepLinkFlowContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useDeepLinkFlow must be used within DeepLinkProvider',
      name: 'DeepLinkContextError',
    });
  }

  return context;
};

const useSetDeepLink = () => {
  const context = useContext(SetDeepLinkContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useSetDeepLink must be used within DeepLinkProvider',
      name: 'DeepLinkContextError',
    });
  }

  return context;
};

export {
  DeepLinkProvider,
  useDeepLink,
  useDeepLinkFlow,
  useSetDeepLink,
};

export default useDeepLink;
