import Input from '@root/bind.joinroot.com/src/components/input';
import LicenseValidationService from '@root/core/src/services/license-number-validation-service';
import PrefillRequest from '@root/prefill/src/models/prefill-request';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import StateSelect from '@root/core/src/components/state-select';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { stylesGenerator as selectStylesGenerator } from '@root/bind.joinroot.com/src/components/select';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_EVENTS = {
  FOCUSED_ON_LICENSE_NUMBER_FIELD: 'FOCUSED_ON_LICENSE_NUMBER_FIELD',
  LICENSE_NUMBER_ERROR_DISPLAYED: 'LICENSE_NUMBER_ERROR_DISPLAYED',
  SELECTED_LICENSE_STATE: 'SELECTED_LICENSE_STATE',
};

const DriverLicenseInput = ({
  hidden,
  onUpdatePrefillRequest,
  onUpdateProfileParams,
  onValidatedLicense,
  onValidatingLicense,
  prefillRequest,
  profileParams,
  trackEvent,
  ...restProps
}) => {
  const [branding] = useBranding();
  const stateSelectOptionStyles = useMemo(() => selectStylesGenerator(branding), [branding]);
  const [licenseNumber, setLicenseNumber] = useState(prefillRequest.licenseNumber || '');
  const [licenseState, setLicenseState] = useState(prefillRequest.licenseState || prefillRequest.mailingAddress?.state || '');
  const [licenseNumberError, setLicenseNumberError] = useState(false);
  const [licenseNeedsValidation, setLicenseNeedsValidation] = useState(!!licenseNumber);
  const [prevalidatedLicenseNumber, setPrevalidatedLicenseNumber] = useState(null);
  const [prevalidatedLicenseState, setPrevalidatedLicenseState] = useState(null);

  const strings = useI18nNext('components.bindStartEntry.driverLicenseInput');

  const validateLicenseNumber = useCallback(async () => {
    if (licenseState !== prevalidatedLicenseState || licenseNumber !== prevalidatedLicenseNumber) {
      onValidatingLicense(true);

      const isValid = await LicenseValidationService.isValidForMarket({
        licenseState,
        licenseNumber,
      });
      onValidatingLicense(false);

      if (isValid) {
        const updatedParamsWithLicenseNumber = ProfileDriverService.setDriverValue(
          profileParams,
          profileParams.getPniDriver().universalDriverId, // We're only concerned about the primary driver on the start entry page
          'licenseNumber',
          licenseNumber
        );
        const updatedParamsWithLicenseState = ProfileDriverService.setDriverValue(
          updatedParamsWithLicenseNumber,
          updatedParamsWithLicenseNumber.getPniDriver().universalDriverId, // We're only concerned about the primary driver on the start entry page
          'licenseState',
          licenseState
        );
        const updatedPrefillRequest = prefillRequest.setAttributes({
          licenseNumber,
          licenseState,
        });
        onUpdateProfileParams(updatedParamsWithLicenseState);
        onUpdatePrefillRequest(updatedPrefillRequest);
      }

      onValidatedLicense(isValid);
      setLicenseNumberError(!isValid);
      setLicenseNeedsValidation(false);
      setPrevalidatedLicenseNumber(licenseNumber);
      setPrevalidatedLicenseState(licenseState);
    }
  }, [licenseNumber, licenseState, onUpdatePrefillRequest, onUpdateProfileParams, onValidatedLicense, onValidatingLicense, prefillRequest, prevalidatedLicenseNumber, prevalidatedLicenseState, profileParams]);

  useEffect(() => {
    if (licenseNeedsValidation) {
      validateLicenseNumber();
    }
  }, [validateLicenseNumber, licenseNeedsValidation]);

  const handleLicenseNumberBlur = () => {
    setLicenseNeedsValidation(true);
  };

  const handleLicenseNumberChange = (number) => {
    setLicenseNumber(number);
  };

  const handleLicenseNumberFocus = () => {
    trackEvent(ANALYTICS_EVENTS.FOCUSED_ON_LICENSE_NUMBER_FIELD);
  };

  const handleLicenseStateSelection = (newLicenseState) => {
    trackEvent(ANALYTICS_EVENTS.SELECTED_LICENSE_STATE, { licenseState: newLicenseState });
    setLicenseState(newLicenseState);
    setLicenseNeedsValidation(true);
  };

  const licenseNumberErrorMessage = useMemo(() => {
    if (licenseNumberError) {
      trackEvent(ANALYTICS_EVENTS.LICENSE_NUMBER_ERROR_DISPLAYED);
      return strings.errors.invalid;
    }
  }, [licenseNumberError, strings, trackEvent]);

  if (hidden) {
    return null;
  }

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      <StateSelect
        dropdownIndicator={branding?.chevronDown}
        dropdownIndicatorColor={branding?.primaryColor}
        onChange={handleLicenseStateSelection}
        optionStyles={stateSelectOptionStyles}
        selectedValue={licenseState}
      />
      <div css={styles.licenseNumber}>
        <Input
          errorLabel={licenseNumberErrorMessage}
          label={strings.label}
          onBlur={handleLicenseNumberBlur}
          onChange={handleLicenseNumberChange}
          onFocus={handleLicenseNumberFocus}
          value={licenseNumber}
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  licenseNumber: {
    marginTop: 10,
  },
  button: {
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading3(),
  },
});

DriverLicenseInput.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onUpdatePrefillRequest: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  onValidatedLicense: PropTypes.func,
  onValidatingLicense: PropTypes.func,
  prefillRequest: PropTypes.instanceOf(PrefillRequest).isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  trackEvent: PropTypes.func.isRequired,
};

export default DriverLicenseInput;
