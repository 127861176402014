import DeprecatedCarvanaCoBrandedLogo from '@root/bind.joinroot.com/src/components/icons/deprecated-carvana-co-branded-logo';
import RootLogo from '@root/core/src/components/root-logo';
import chevronBlue from '@root/bind.joinroot.com/src/assets/icons/chevron-blue.svg';
import chevronDownBlue from '@root/bind.joinroot.com/src/assets/icons/chevron-down-blue.svg';
import uuidv4 from '@root/vendor/uuid/v4';

export default class BrandingContextFactory {
  static defaultValues() {
    return {
      coBrandedLogoName: RootLogo.displayName,
      coreButtonVariant: 'primary',
    };
  }

  static carvanaValues() {
    return {
      chevronDown: chevronDownBlue,
      chevronRight: chevronBlue,
      coBrandedLogoName: DeprecatedCarvanaCoBrandedLogo.displayName,
      primaryColor: '#00AAD5',
      secondaryColor: '#666666',
      shouldReturnToPartner: true,
    };
  }

  static serverDrivenValues(overrides = {}) {
    return {
      borderRadius: 0.0,
      displayWelcomeScene: false,
      hideHeaderPrimaryLogo: false,
      primaryColor: '#00AAD5',
      primaryLogoUrl: uuidv4(),
      secondaryColor: '#666666',
      shouldReturnToPartner: true,
      ...overrides,
    };
  }
}
