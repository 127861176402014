import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const MOBILE_APP_EULA_URL = `${environment.websiteBaseUrl}/app-eula/`;

export function MobileAppEulaLink({ styles }) {
  return (
    <a
      css={styles}
      href={MOBILE_APP_EULA_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Mobile App EULA
    </a>
  );
}

MobileAppEulaLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
