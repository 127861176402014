import Button from '@root/core/src/components/button';
import ExplainerCard from '@root/core/src/components/explainer-card';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RightQuoteConfirmationCompColl from '@root/right-quote/src/components/right-quote-confirmation-comp-coll';
import RightQuoteDivider from '@root/right-quote/src/components/right-quote-divider';
import RightQuoteExplainerHeader from '@root/right-quote/src/components/right-quote-explainer-header';
import RightQuoteFadeTransition from '@root/right-quote/src/components/right-quote-fade-transition';
import coverage from '@root/right-quote/src/assets/coverage.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useState } from '@root/vendor/react';

export default function RightQuoteExplainerFinanceLease({
  buttonText = 'Continue', isModal, onContinue,
}) {
  const { trackClick } = useAnalytics('RIGHT_QUOTE_EXPLAINER_FINANCE_LEASE');

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleContinue = () => {
    trackClick('CONTINUE');
    if (isModal) {
      onContinue();
    } else {
      setShowConfirmation(true);
    }
  };

  return (
    <RightQuoteFadeTransition
      shouldTransition={showConfirmation}
    >
      {showConfirmation ?
        <RightQuoteConfirmationCompColl
          onComplete={onContinue}
        />
        :
        <ExplainerCard
          onModalClose={isModal ? onContinue : null}
        >
          <RightQuoteExplainerHeader
            icon={coverage}
          >
            <div>Leasing or financing?</div>
            <div>We’ve got you covered.</div>
          </RightQuoteExplainerHeader>
          <div css={styles.container}>
            <div css={styles.content}>
              <p css={styles.text}>
                In most cases, loan or lease companies require you to have a little more coverage.
              </p>
              <RightQuoteDivider
                text={'RECOMMENDATION'}
              />
            </div>
            <div css={styles.content}>
              <p css={[styles.text, styles.bottomText]}>
                Add our recommended Collision and Comprehensive coverage to your plan.
              </p>
              <Button
                onClick={handleContinue}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </ExplainerCard>
      }
    </RightQuoteFadeTransition>
  );
}

RightQuoteExplainerFinanceLease.propTypes = {
  buttonText: PropTypes.string,
  isModal: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    padding: '30px 0',
    ...Responsive.sm({
      padding: '30px 0 50px',
    }),
  },
  content: {
    padding: '0 20px',
    ...Responsive.sm({
      padding: '0 80px',
    }),
  },
  text: {
    ...Theme.paragraph1(),
  },
  bottomText: {
    marginBottom: 20,
  },
});
