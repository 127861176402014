import BackButton from '@root/bind.joinroot.com/src/components/back-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const BackToProfileButton = ({ onClick }) => {
  const { backToProfile } = useI18nNext('components.bindView');

  const { trackEvent } = useAnalytics('BIND_BACK_TO_PROFILE', false);

  const onClickButton = () => {
    trackEvent('RETURNING_FLOW_BACK_TO_PROFILE');
    onClick();
  };

  return (
    <BackButton
      onClickButton={onClickButton}
      text={backToProfile}
    />
  );
};

BackToProfileButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackToProfileButton;
