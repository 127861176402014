import Button from '@root/core/src/components/button';
import Coverage from '@root/auto-pricing/src/models/coverage';
import Link from '@root/core/src/components/link';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileModal from '@root/profile/src/components/profile-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RideshareInfoGraphic from '@root/profile/src/components/rideshare-info-graphic';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RideshareInfo({
  market,
  onCloseClick,
  onLinkClick,
}) {
  const displayLink = !!onLinkClick;
  const biDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.BODILY_INJURY,
  });
  const collDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COLLISION,
  });
  const compDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COMPREHENSIVE,
  });
  const pdDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.PROPERTY_DAMAGE,
  });

  return (
    <ProfileModal
      onCloseClick={onCloseClick}
    >
      <ProfileHeading>
        Rideshare & Delivery coverage
      </ProfileHeading>
      <div css={styles.headerSection}>
        <div css={styles.description}>
          Root requires this coverage if you drive for a rideshare or delivery (TNC) company like Uber or DoorDash. <span css={styles.descriptionBold}>Without it, we may deny your claims—even claims from personal driving.</span>
        </div>
        {displayLink &&
          <div>
            <Link
              css={styles.editLink}
              onClick={onLinkClick}
            >
              Add or remove Rideshare & Delivery coverage
            </Link>
          </div>
        }
      </div>
      <RideshareInfoGraphic />
      <div css={styles.infoListSection}>
        <div css={styles.sectionHeader}>While you’re waiting for a trip (Phase 1)</div>
        <ul css={styles.description}>
          <li>{`${biDisplayName} and ${pdDisplayName} cover others if you cause an accident.`}</li>
          <li>{`${collDisplayName} and ${compDisplayName} cover damage to your vehicle.`}</li>
        </ul>
      </div>
      <div>
        <div css={styles.sectionHeader}>Once you’ve accepted a trip (Phase 2 & 3)</div>
        <ul css={styles.description}>
          <li>{`${collDisplayName} and ${compDisplayName} cover damage to your vehicle.`}</li>
        </ul>
      </div>
      <div>
        <div css={styles.sectionHeader}>Good to know</div>
        <ul css={styles.description}>
          <li>{`Your ${collDisplayName} and ${compDisplayName} deductibles will match what you chose for your personal policy.`}</li>
          <li>{`${biDisplayName} and ${pdDisplayName} will be covered up to state minimum limits.`}</li>
          <li>All drivers and vehicles on your policy will be covered.</li>
          <li>You can always find more information about your coverages and limits in your policy docs.</li>
        </ul>
      </div>
      <div css={styles.disclaimerText}>
        * Your rideshare or delivery company may provide additional coverage. Please contact them for more information.
      </div>
      <Button
        onClick={onCloseClick}
      >
        {'Dismiss'}
      </Button>
    </ProfileModal>
  );
}

RideshareInfo.propTypes = {
  market: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func,
};

const styles = StyleSheet.create({
  headerSection: {
    paddingBottom: '36px',
  },
  description: {
    ...Theme.paragraph1(),
    color: Colors.nearBlack,
    marginBottom: '12px',
  },
  descriptionBold: {
    ...Theme.paragraph1({
      bold: true,
    }),
    color: Colors.nearBlack,
  },
  infoListSection: {
    marginTop: '36px',
  },
  sectionHeader: {
    ...Theme.paragraph1({
      bold: true,
    }),
    color: Colors.nearBlack,
    paddingBottom: '10px',
  },
  disclaimerText: {
    ...Theme.paragraph2(),
  },
  editLink: {
    ...Theme.textLink(),
  },
  modal: {
    padding: 0,
  },
});
