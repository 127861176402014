import DobDate from '@root/core/src/models/dob-date';
import dayjs from '@root/vendor/dayjs';
import uuidv4 from '@root/vendor/uuid/v4';

export const MAX_YOUNG_DRIVER_AGE = 19;

export default class ExcludedDriver {
  constructor() {
    this.id;
    this.age;
    this.dob;
    this.driversLicenseId;
    this.firstName;
    this.lastName;
    this.isLicenseEdited;
    this.licenseNumber;
    this.licenseState;
    this.universalDriverId = uuidv4();
  }

  set(key, value) {
    return Object.assign(
      new ExcludedDriver(),
      this,
      {
        [key]: value,
      },
    );
  }

  key() {
    return `${this.firstName} ${this.lastName} ${this.age} ${this.dob}`;
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  dobAge() {
    const now = dayjs();
    const dobDate = dayjs(this.dob.toStringUnobfuscate(), 'YYYY-MM-DD');
    return now.diff(dobDate, 'years');
  }

  valid() {
    return true;
  }

  static buildFromData(driverData) {
    const dob = new DobDate(driverData.dob);
    const age = driverData.age ?? dob.age();
    const id = driverData.id ?? uuidv4();
    const universalDriverId = driverData.universalDriverId ?? uuidv4();

    return Object.assign(
      new ExcludedDriver(),
      {
        ...driverData,
        age,
        dob,
        id,
        universalDriverId,
      },
    );
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new ExcludedDriver(),
      this,
      attributes,
    );
  }

  matches(excludedDriver) {
    return this.matchesName(excludedDriver) && this.matchesDob(excludedDriver);
  }

  matchesName(excludedDriver) {
    return this.firstName?.trim()?.toUpperCase() === excludedDriver.firstName?.trim()?.toUpperCase() &&
      this.lastName?.trim()?.toUpperCase() === excludedDriver.lastName?.trim()?.toUpperCase();
  }

  matchesDob(excludedDriver) {
    let dobMatch;
    if (this.dob.isObfuscated() || excludedDriver.dob.isObfuscated()) {
      const yearOfBirth = this.dob.year;
      const otherYearOfBirth = excludedDriver.dob.year;
      dobMatch = yearOfBirth === otherYearOfBirth;
    } else {
      dobMatch = this.dob.isSameDate(excludedDriver.dob);
    }

    return dobMatch;
  }

  buildLicenseParams() {
    if (this.driversLicenseId && !this.isLicenseEdited) {
      return {
        driversLicenseId: this.driversLicenseId,
      };
    }

    return {
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
    };
  }

  serializeForSubmission() {
    return {
      ...this.buildLicenseParams(),
      age: this.age,
      dob: this.dob.serializeForSubmission(),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim(),
      id: this.id,
      universalDriverId: this.universalDriverId,
    };
  }

  serializeForSaving() {
    return {
      ...this,
      dob: this.dob.serializeForSubmission(),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim(),
      id: this.id,
    };
  }
}
