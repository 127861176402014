import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function AgeLicensedSubHeading({ children }) {
  return (
    <h2 css={styles.subHeading}>
      {children}
    </h2>
  );
}

AgeLicensedSubHeading.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  subHeading: {
    ...Theme.paragraph1(),
    marginTop: 30,
    marginBottom: 20,
  },
});

