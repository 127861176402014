import Coverage from '@root/auto-pricing/src/models/coverage';
import Link from '@root/core/src/components/link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RightQuoteExplainerFinanceLease from '@root/right-quote/src/scenes/right-quote-explainer-finance-lease';
import RightQuoteExplainerNoComp from '@root/right-quote/src/scenes/right-quote-explainer-no-comp';
import coverageIcon from '@root/right-quote/src/assets/coverage.svg';
import discountIcon from '@root/right-quote/src/assets/discount-green.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { RECOMMENDATIONS } from '@root/right-quote/src/models/right-quote';

const INCLUDE_RECOMMENDED_DEDUCTIBLE = 500;

export default function RightQuoteSelectedLearnMore({
  coverage, onDone, onLearnMore, recommendation,
}) {
  const { trackClick } = useAnalytics('RIGHT_QUOTE_SELECTED_QUOTE', false);

  const handleLearnMore = (explainer) => {
    trackClick('LEARN_MORE');
    onLearnMore(explainer);
  };

  const explainerProps = {
    onContinue: onDone,
    isModal: true,
    buttonText: 'Done',
  };

  let text, icon, iconAltText, explainer = null;
  const containerStyles = [styles.container];
  let linkStyles;
  if (recommendation === RECOMMENDATIONS.INCLUDE && coverage.deductible === INCLUDE_RECOMMENDED_DEDUCTIBLE) {
    text = 'Recommended Coverage';
    icon = coverageIcon;
    iconAltText = 'coverage';

    explainer = <RightQuoteExplainerFinanceLease {...explainerProps} />;

    containerStyles.push(styles.containerInclude);
    linkStyles = styles.linkInclude;
  } else if (recommendation === RECOMMENDATIONS.WAIVE && coverage.declined) {
    text = 'Skip this one and save';
    icon = discountIcon;
    iconAltText = 'discount';

    explainer = <RightQuoteExplainerNoComp {...explainerProps} />;

    containerStyles.push(styles.containerWaive);
    linkStyles = styles.linkWaive;
  }

  if (!text) {
    return null;
  }

  return (
    <div css={containerStyles}>
      <img
        alt={iconAltText}
        css={styles.icon}
        src={icon}
      />
      <div css={styles.text}>
        {text}
        {'  '}
        <Link
          css={linkStyles}
          onClick={() => handleLearnMore(explainer)}
        >
          Learn More
        </Link>
      </div>
    </div>
  );
}

RightQuoteSelectedLearnMore.propTypes = {
  coverage: PropTypes.instanceOf(Coverage).isRequired,
  onDone: PropTypes.func.isRequired,
  onLearnMore: PropTypes.func.isRequired,
  recommendation: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  containerInclude: {
    marginTop: 30,
  },
  containerWaive: {
    marginBottom: 30,
  },
  text: {
    ...Theme.paragraph1(),
    color: Colors.black(),
    whiteSpace: 'pre-wrap',
  },
  icon: {
    marginRight: 15,
    height: 24,
    width: 24,
  },
  linkInclude: {
    ...Theme.link(),
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
    color: Colors.rootOrange(),
  },
  linkWaive: {
    ...Theme.link(),
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
    color: Colors.DEPRECATED_green(),
  },
});
