import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ProfileReviewHeading({
  children, icon, ...props
}) {
  return (
    <>
      {icon && (
        <img
          alt={'Profile Icon'}
          css={styles.icon}
          src={icon}
        />
      )}
      <h1 css={[styles.heading, props.styles]}>{children}</h1>
    </>
  );
}

ProfileReviewHeading.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  styles: PropTypes.object,
};

const styles = StyleSheet.create({
  icon: {
    width: 48,
    ...Responsive.md({
      width: 79,
    }),
  },
  heading: {
    ...Theme.heading1(),
    marginTop: 20,
    marginBottom: 30,
  },
});
