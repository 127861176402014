import Address from '@root/core/src/models/address';
import AddressFields from '@root/core/src/components/address-fields';
import AutocompleteAddressForm from '@root/core/src/components/autocomplete-address-form';
import Button from '@root/core/src/components/button';
import GoogleMapsService from '@root/core/src/services/google-maps-service';
import OutOfMarketModal from '@root/profile/src/components/garaging-address/out-of-market-modal'; // eslint-disable-line root/no-circular-dependencies
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SceneLoader from '@root/core/src/components/scene-loader';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet } from '@root/core/src/utils/styles';
import { withGoogleMapsApi } from '@root/core/src/components/with-google-maps-api';

export function UpdateGaragingAddress({
  disableFixedWidths = false,
  google,
  module,
  onDone,
  profileParams,
  vehicle,
}) {
  const { trackClick, trackEvent } = useAnalytics([module, 'PROFILE_REVIEW_UPDATE_GARAGING_ADDRESS_MODAL']);

  const googleService = useMemo(() => new GoogleMapsService(google), [google]);

  const form = useForm({
    validations: Address.validations,
  });

  const [addressFieldIsDirty, setAddressFieldIsDirty] = useState(false);
  const [autocompleteIsShowing, setAutocompleteIsShowing] = useState(true);
  const [isOutOfMarketModelVisible, setIsOutOfMarketModelVisible] = useState(false);

  const onSubmit = useCallback(async (updatedAddress) => {
    onDone(updatedAddress);
  }, [onDone]);

  const handleSubmit = () => {
    trackClick('SUBMIT');

    if (form.isValid || !addressFieldIsDirty) {
      const garagingAddress = {
        garagingAddress1: form.values.line1,
        garagingAddress2: form.values.line2,
        garagingCity: form.values.city,
        garagingState: form.values.state,
        garagingZip: form.values.zip,
      };

      if (profileParams.mailingAddress.state !== garagingAddress.garagingState) {
        trackEvent('OUT_OF_MARKET_ADDRESS', garagingAddress);
        setIsOutOfMarketModelVisible(true);
        return;
      }
      onSubmit(garagingAddress);
    }
  };

  const handleAutocompleteSubmit = useCallback(async (selectedAddress) => {
    trackClick('AUTOCOMPLETE_SUBMIT');

    if (!addressFieldIsDirty) {
      const garagingAddress = {
        garagingAddress1: selectedAddress.line1,
        garagingAddress2: selectedAddress.line2,
        garagingCity: selectedAddress.city,
        garagingState: selectedAddress.state,
        garagingZip: selectedAddress.zip,
      };

      if (profileParams.mailingAddress.state !== garagingAddress.garagingState) {
        trackEvent('OUT_OF_MARKET_ADDRESS', garagingAddress);
        setIsOutOfMarketModelVisible(true);
        return;
      }
      onDone(garagingAddress);
    }
  }, [addressFieldIsDirty, onDone, profileParams.mailingAddress.state, trackClick, trackEvent]);

  const handlePredictionSelect = () => {
    setAddressFieldIsDirty(false);
  };

  const handlePredictionNotFoundClick = () => {
    trackClick('ADDRESS_NOT_FOUND');

    setAutocompleteIsShowing(false);
  };

  const getTitle = () => {
    if (vehicle?.year && vehicle?.make && vehicle?.model) {
      return `Where do you park your ${vehicle.yearMakeAndModelOrVin()}?`;
    }

    return 'Where do you park your vehicle?';
  };

  const containerStyles = [styles.container];
  if (disableFixedWidths) { containerStyles.push(styles.disableFixedWidths); }

  return (
    <>
      <ProfileHeading styles={styles.heading}>
        {getTitle()}
      </ProfileHeading>
      {autocompleteIsShowing && (
        <AutocompleteAddressForm
          focusOnMount
          buildAddress={Address.buildFromGooglePlace}
          ctaText={'Update'}
          googleService={googleService}
          onAutocompletePredictionSelect={handlePredictionSelect}
          onPredictionNotFoundClick={handlePredictionNotFoundClick}
          onSubmit={handleAutocompleteSubmit}
        />
      )}
      {!autocompleteIsShowing && (
        <ProfileForm onSubmit={form.createSubmitHandler(handleSubmit)}>
          <AddressFields
            form={form}
          />
          <Button disabled={!form.isValid}>
            Update
          </Button>
        </ProfileForm>
      )}
      {isOutOfMarketModelVisible && (
        <OutOfMarketModal
          isModalOpen={isOutOfMarketModelVisible}
          onClose={() => setIsOutOfMarketModelVisible(false)}
        />
      )}
    </>
  );
}

export default withGoogleMapsApi(UpdateGaragingAddress, SceneLoader);

UpdateGaragingAddress.propTypes = {
  disableFixedWidths: PropTypes.bool,
  google: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  vehicle: PropTypes.object,
};

const styles = StyleSheet.create({
  container: {
    ...Responsive.md({
      width: '41.666%',
      minWidth: '815px',
    }),
    width: '100%',
  },
  disableFixedWidths: {
    ...Responsive.md({
      width: 'inherit',
      minWidth: 'inherit',
    }),
  },
  heading: {
    marginTop: 0,
    paddingRight: 24,
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    width: '100%',
  },
  submitButtonWrapper: {
    marginTop: 10,
  },
});
