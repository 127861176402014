import {
  BackendOptions, CoverageLimitTypes, CoverageSymbols, DECLINED,
} from './constants';

export function validQuotingData(quotingData) {
  if (!isBasicObject(quotingData)) {
    return false;
  }

  const rateCoverages = quotingData.rateCoverages;
  const selectedCoverages = quotingData.selectedCoverages;
  const availableVins = quotingData.availableVins;
  const vehicleCoverageSymbols = quotingData.vehicleCoverageSymbols;
  const nonVehicleCoverageSymbols = quotingData.nonVehicleCoverageSymbols;
  const options = quotingData.options;
  if (!rateCoverages || !selectedCoverages || !vehicleCoverageSymbols || !nonVehicleCoverageSymbols || !availableVins || !options) {
    return false;
  }

  return validRateCoverages(rateCoverages)
    && validSelectedCoverages(selectedCoverages)
    && validVins(availableVins)
    && validCoverageSymbols(vehicleCoverageSymbols)
    && validCoverageSymbols(nonVehicleCoverageSymbols)
    && validOptions(options);
}

export function validSelectedCoverages(coverages) {
  if (!validCoverageArray({
    coverages,
    allowedSymbols: Object.values(CoverageSymbols),
  })) {
    return false;
  }

  if (!coverages.every((coverage) => validVins(coverage.coveredVins))) { return false; }

  return new Set(coverages.map((c) => c.symbol)).size === coverages.length;
}

export function validRateCoverages(coverages) {
  if (!isBasicObject(coverages)) {
    return false;
  }

  return Object.entries(coverages).every(([symbol, symbolCoverages]) => {
    if (!Object.values(CoverageSymbols).includes(symbol)) {
      return false;
    }

    return validCoverageArray({
      coverages: symbolCoverages,
      allowedSymbols: [symbol],
    });
  });
}

function validCoverageArray(args) {
  const coverages = args.coverages;
  const allowedSymbols = args.allowedSymbols;

  if (!Array.isArray(coverages)) {
    return false;
  }

  return coverages.every((coverage) => validCoverage(coverage, allowedSymbols));
}

function validCoverage(coverage, allowedSymbols) {
  if (!isBasicObject(coverage)) {
    return false;
  }

  if (!allowedSymbols.includes(coverage.symbol)) {
    return false;
  }

  if (coverage[DECLINED]) {
    return true;
  }

  return Object.values(CoverageLimitTypes)
    .some((limitType) => typeof coverage[limitType] === 'number');
}

export function validVins(coveredVins) {
  if (!Array.isArray(coveredVins)) {
    return false;
  }

  return coveredVins.every((vin) => typeof vin === 'string');
}

export function validCoverageSymbols(symbols) {
  return Array.isArray(symbols)
    && symbols.every((symbol) => Object.values(CoverageSymbols).includes(symbol));
}

function isBasicObject(obj) {
  return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
}

const validBackendOptions = [BackendOptions.ACCEPTED, BackendOptions.DECLINED, BackendOptions.NO_USE];
const validTortOptions = [BackendOptions.FULL, BackendOptions.LIMITED, BackendOptions.NO_USE];

export function validOptions(options) {
  if (!isBasicObject(options)) {
    return false;
  }

  if (typeof options.cdwAcceptedDeclined !== 'string' || !validBackendOptions.includes(options.cdwAcceptedDeclined)) {
    return false;
  }

  if (typeof options.roadsideAcceptedDeclined !== 'string' || !validBackendOptions.includes(options.roadsideAcceptedDeclined)) {
    return false;
  }

  if (typeof options.tortSelection !== 'string' || !validTortOptions.includes(options.tortSelection)) {
    return false;
  }

  if (typeof options.physicalDamageEligible !== 'boolean') {
    return false;
  }

  return true;
}

