import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindParams from '@root/quotes/src/models/bind-params';
import CalendarFilledIcon from '@root/core/src/assets/calendar-filled.svg';
import CalendarIcon from '@root/core/src/assets/calendar-plain.svg';
import CalendarRepeatIcon from '@root/core/src/assets/calendar-repeat.svg';
import Datepicker from '@root/core/src/components/datepicker';
import IconCell from '@root/core/src/components/icon-cell';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import QuotesCheckoutForm from '@root/quotes/src/models/quote-checkout-form';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select';
import dayjs from '@root/vendor/dayjs';
import { Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function QuoteCheckoutReviewBillingDetails({
  quotesPrice,
  billingCycles,
  bindParams,
  brandedColor,
  form,
  today,
  maxDate,
  onBillingSelectCycleOpen,
  optionStyles,
  handleFieldChange,
  selectedDayStyles,
  shouldRenderNativeMobile,
  startDateAppendedText,
}) {
  const maxDateChoice = () => {
    if (!maxDate) {
      return dayjs().add(60, 'days').toDate();
    } else if (maxDate < today) {
      return today;
    }
    return maxDate;
  };
  const startDateFieldText = startDateAppendedText ? `Start date ${startDateAppendedText}` : 'Start date';

  return (
    <div css={{ marginBottom: 0 }}>
      <h1 css={[styles.header, styles.webHeader]}>Review your plan and enter your payment info.</h1>
      <p css={styles.subheading}>Payment details</p>
      <IconCell
        cssOverrides={styles.iconCellContainer}
        icon={CalendarIcon}
        primaryText={'Amount due today'}
        primaryTextStyles={styles.iconCellPrimaryTextStyle}
        secondaryText={Money.fromCents(quotesPrice).formattedDollarsAndCents()}
        secondaryTextStyles={styles.iconCellSecondaryTextStyle}
        textContainerStyles={styles.iconCellTextContainerStyles}
      />
      <IconCell
        cssOverrides={styles.iconCellContainer}
        icon={CalendarRepeatIcon}
        primaryText={'Payment frequency'}
        primaryTextStyles={styles.iconCellPrimaryTextStyle}
        secondaryText={
          <Select
            dropdownIndicatorColor={brandedColor}
            inputId={'billing-cycle'}
            inputName={'billing-cycle'}
            onChange={handleFieldChange('billingCycle')}
            onOpen={onBillingSelectCycleOpen}
            options={BillingCycle.optionsForSelect(billingCycles)}
            optionStyles={optionStyles}
            placeholder={'Payment frequency'}
            selectBoxFocusedStyles={styles.selectBoxFocusedStyles}
            selectBoxStyles={styles.selectBoxStyles}
            selectedValue={form.billingCycle}
            shouldRenderNativeMobile={shouldRenderNativeMobile}
            wrapperStyles={styles.wrapperStyles}
          />
        }
        secondaryTextStyles={styles.iconCellSecondaryTextStyle}
        textContainerStyles={styles.iconCellTextContainerStyles}
      />
      <IconCell
        cssOverrides={styles.iconCellContainer}
        icon={CalendarFilledIcon}
        primaryText={startDateFieldText}
        primaryTextStyles={styles.iconCellPrimaryTextStyle}
        secondaryText={
          <Datepicker
            brandedColor={brandedColor}
            dateLimit={maxDateChoice()}
            disablePastDays={true}
            inputId={'start-date'}
            label={'Start date'}
            onDateChange={handleFieldChange('startDate')}
            selectBoxFocusedStyles={styles.selectBoxFocusedStyles}
            selectBoxStyles={styles.selectBoxStyles}
            selectBoxWrapperStyles={styles.wrapperStyles}
            selectedDay={bindParams.startDate}
            selectedDayStyles={selectedDayStyles}
            showDropdown={true}
            today={today}
            value={form.startDate || ''}
          />
        }
        secondaryTextStyles={styles.iconCellSecondaryTextStyle}
        textContainerStyles={styles.iconCellTextContainerStyles}
      />
    </div>
  );
}

QuoteCheckoutReviewBillingDetails.propTypes = {
  billingCycles: PropTypes.array.isRequired,
  bindParams: PropTypes.instanceOf(BindParams).isRequired,
  brandedColor: PropTypes.string,
  form: PropTypes.instanceOf(QuotesCheckoutForm).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date),
  onBillingSelectCycleOpen: PropTypes.func,
  optionStyles: PropTypes.object,
  quotesPrice: PropTypes.number.isRequired,
  selectedDayStyles: PropTypes.object,
  shouldRenderNativeMobile: PropTypes.bool,
  startDateAppendedText: PropTypes.string,
  today: PropTypes.instanceOf(Date),
};

const styles = StyleSheet.create({
  iconCellContainer: {
    marginBottom: 0,
    minHeight: 65,
  },
  header: {
    ...Theme.heading1(),
    marginBottom: 24,
  },
  webHeader: {
    display: 'block',
    ...Responsive.lessThanMd({
      display: 'none',
    }),
  },
  subheading: {
    ...Theme.heading3(),
    marginBottom: 12,
  },
  iconCellPrimaryTextStyle: {
    ...Theme.paragraph2(),
  },
  iconCellSecondaryTextStyle: {
    ...Theme.input(),
  },
  selectBoxFocusedStyles: {
    ...Shadows.none(),
  },
  selectBoxStyles: {
    ...Theme.input(),
    padding: 0,
    borderStyle: 'none',
    height: '100%',
    ':focus': {
      ...Shadows.none(),
    },
    ...Responsive.endToEndTests({
      visibility: 'hidden',
    }),
  },
  wrapperStyles: {
    height: '100%',
  },
  iconCellTextContainerStyles: {
    width: '100%',
  },
});
