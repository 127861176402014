import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RightQuoteExplainerHeader({
  backgroundStyle,
  children,
  headingStyle,
  icon,
}) {
  return (
    <div css={[styles.container, backgroundStyle]}>
      <img
        css={styles.icon}
        src={icon}
      />
      <h1 css={[styles.heading, headingStyle]}>
        {children}
      </h1>
    </div>
  );
}

RightQuoteExplainerHeader.propTypes = {
  backgroundStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  headingStyle: PropTypes.object,
  icon: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    maxWidth: '100%',
    textAlign: 'center',
    backgroundColor: Colors.nearWhite(),
    borderBottom: `1px solid ${Colors.gray30()}`,
    padding: '50px 20px',
    ...Responsive.sm({
      borderRadius: '10px 10px 0 0',
      padding: '70px 100px',
    }),
  },
  heading: {
    ...Theme.heading2(),
    marginTop: 20,
  },
  icon: {
    height: 90,
    width: 90,
  },
});
