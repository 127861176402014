import ChevronRight from '@root/bind.joinroot.com/src/components/icons/chevron-right';
import ListItemBase from '@root/bind.joinroot.com/src/components/list/list-item-base';
import ListItemIcon from '@root/bind.joinroot.com/src/components/list/list-item-icon';
import ListItemText from '@root/bind.joinroot.com/src/components/list/list-item-text';
import PropTypes from '@root/vendor/prop-types';
import useBranding from '@root/bind.joinroot.com/src/context/branding';

export default function ListItem({
  editable,
  onClick,
  icon,
  iconAltText,
  heading,
  subtext,
  rightComponent,
  headingStyles,
  subtextStyles,
  isDisabled = false,
  ...props
}) {
  let rightComponentContainer;

  const [{ primaryColor }] = useBranding();

  const iconComponent = (
    <ListItemIcon
      altText={iconAltText}
      icon={icon}
    />
  );

  const textComponent = (
    <ListItemText
      heading={heading}
      headingStyles={headingStyles}
      subtext={subtext}
      subtextStyles={subtextStyles}
    />
  );

  if (rightComponent && !isDisabled) {
    rightComponentContainer = (
      <div>
        {rightComponent}
      </div>
    );
  }

  return (
    <ListItemBase
      isDisabled={isDisabled}
      onClick={onClick}
      {...props}
    >
      {iconComponent}
      {textComponent}
      {rightComponentContainer}
      {editable &&
      <div
        data-testid={'chevron-right-id'}
      >
        <ChevronRight
          color={primaryColor}
          height={'0.8rem'}
        />
      </div>
      }
    </ListItemBase>
  );
}

ListItem.propTypes = {
  editable: PropTypes.bool,
  heading: PropTypes.string,
  headingStyles: PropTypes.object,
  icon: PropTypes.node,
  iconAltText: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  rightComponent: PropTypes.node,
  subtext: PropTypes.node,
  subtextStyles: PropTypes.object,
};

