import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class ProfileModalButton extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    cssButtonOverrides: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }

  render() {
    return (
      <Button
        css={{
          ...styles.button,
          ...this.props.cssButtonOverrides,
        }}
        disabled={!!this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
});
