import Button from '@root/core/src/components/button';
import Driver from '@root/auto-pricing/src/models/driver';
import MaritalStatus from '@root/core/src/models/marital-status';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { useState } from '@root/vendor/react';
import ringsIcon from '@root/profile/src/assets/rings-icon.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ProfileAddDriverMaritalStatus({
  brandedColor, driver, linkStyles, onCancel, onDone, cssButtonOverrides, profileRulesContext,
}) {
  const [maritalStatus, setMaritalStatus] = useState('');

  const { trackClick } = useAnalytics('PROFILE_ADD_DRIVER_MARITAL_STATUS');

  const updateMaritalStatus = (e) => setMaritalStatus(e.target.value);

  const isOptionSelected = (optionValue) => maritalStatus === optionValue;

  const handleSubmit = () => {
    if (maritalStatus) {
      trackClick('SUBMIT');
      const newDriver = driver.set('maritalStatus', maritalStatus);
      onDone(newDriver);
    }
  };

  const handleCancel = () => {
    trackClick('CANCEL');
    onCancel();
  };

  return (
    <div css={styles.wrapper}>
      <ProfileHeading icon={ringsIcon}>
        What’s their marital status?
      </ProfileHeading>
      <ProfileForm onSubmit={handleSubmit}>
        <RadioInputGroup
          selectedValue={maritalStatus}
        >
          {MaritalStatus.OPTIONS(profileRulesContext.maritalStatusChoices).map((option, index) => {
            return (
              <RadioOption
                circleColor={brandedColor}
                id={`marital-status-${index}`}
                isSelected={isOptionSelected(option.value)}
                key={option.label}
                label={option.label}
                name={'marital-status-radio'}
                onChange={updateMaritalStatus}
                optionValue={option.value}
              />
            );
          })}
        </RadioInputGroup>
        <Button
          css={[styles.button, cssButtonOverrides]}
          disabled={!maritalStatus}
        >
          Continue
        </Button>
        <NevermindLink
          linkStyles={linkStyles}
          onCancel={handleCancel}
        />
      </ProfileForm>
    </div>
  );
}

ProfileAddDriverMaritalStatus.propTypes = {
  brandedColor: PropTypes.string,
  cssButtonOverrides: PropTypes.object,
  driver: PropTypes.instanceOf(Driver).isRequired,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
};

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  wrapper: {
    width: '100%',
  },
});
