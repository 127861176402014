import Button from '@root/core/src/components/button';
import IconCell from '@root/core/src/components/icon-cell';
import Link from '@root/core/src/components/link';
import PlusIconOrange from '@root/core/src/assets/plus-icon-orange';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import WhatIsGaragingAddressModal from '@root/profile/src/components/garaging-address/what-is-garaging-address-modal';
import vehicleBlack from '@root/core/src/assets/vehicle-black.svg';
import {
  BUTTON_SIZE_SMALL,
  BUTTON_VARIANT_SECONDARY,
} from '@root/core/src/components/button/styles';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function GaragingAddressSection({
  onPress, workingGaragingAddress,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOnModalClose = () => {
    setIsModalOpen(false);
  };

  const renderGaragingAddressSection = () => {
    if (workingGaragingAddress?.garagingAddress1) {
      const getPrimaryText = ({ garagingAddress1, garagingAddress2 }) => {
        if (garagingAddress2) { return `${garagingAddress1}\n${garagingAddress2}`; }
        return garagingAddress1;
      };

      return (
        <IconCell
          icon={vehicleBlack}
          onClick={onPress}
          primaryText={getPrimaryText(workingGaragingAddress)}
          primaryTextStyles={styles.primaryTextStyles}
          secondaryText={`${workingGaragingAddress.garagingCity ?? ''}, ${workingGaragingAddress.garagingState ?? ''} ${workingGaragingAddress.garagingZip ?? ''}`}
        />
      );
    }

    return (
      <Button
        iconComponent={PlusIconOrange}
        onClick={onPress}
        size={BUTTON_SIZE_SMALL}
        type={'button'}
        variant={BUTTON_VARIANT_SECONDARY}
      >
        {'Add garaging address'}
      </Button>
    );
  };

  return (
    <>
      <h1 css={[styles.heading]}>Garaging address</h1>
      {renderGaragingAddressSection()}
      <div css={styles.linkContainer}>
        <Link
          css={styles.textButton}
          onClick={() => setIsModalOpen(true)}
        >
          What’s garaging address?
        </Link>
      </div>
      {isModalOpen && (
        <WhatIsGaragingAddressModal
          isModalOpen={isModalOpen}
          onClose={handleOnModalClose}
        />
      )}
    </>
  );
}

GaragingAddressSection.propTypes = {
  onPress: PropTypes.func.isRequired,
  workingGaragingAddress: PropTypes.object,
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading3(),
    marginTop: 20,
    marginBottom: 30,
  },
  textButton: {
    color: Colors.rootOrange(),
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 20,
  },
  primaryTextStyles: {
    whiteSpace: 'pre-wrap',
  },
});
