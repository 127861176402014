import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCopy from '@root/quotes/src/components/coverage/coverage-copy';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React from '@root/vendor/react';
import RightQuoteSelectedLearnMore from '@root/right-quote/src/components/right-quote-selected-learn-more';
import VehicleSelectionCard from '@root/quotes/src/components/coverage-options/vehicle-selection-card';

export default function CoverageSectionDeductibles({
  cardHeaderBackgroundColor,
  coverageSelections,
  customQuote,
  IconComponent,
  mobileSelectedOptionStyles,
  onAcceptCoverages,
  onCloseRightQuoteModal,
  onCoverageChange,
  onCoverageSelectClose,
  onCoverageSelectOpen,
  onDeclineCoverages,
  onOpenRightQuoteModal,
  onVehicleToggle,
  profileParams,
  quotesContext,
  quotingRules,
  renderLearnMore,
  primaryBrandColor,
  dropdownIndicator,
  styleOverrides,
}) {
  const handleVehicleToggle = (vehicle, selected) => {
    const { coverages } = customQuote.getDeductibleInfo();
    onVehicleToggle(vehicle, selected, coverages);
  };

  const handleAddCoverage = () => {
    const { coverages } = customQuote.getDeductibleInfo();
    const coveredVins = profileParams.getSelectedVehiclesWithVin().map(({ vin }) => vin);

    const coveragesWithVins = coverages.map((c) => ({
      ...c,
      coveredVins,
    }));

    onAcceptCoverages(coveragesWithVins);
  };

  const renderRightQuoteBadge = (coverage) => {
    if (!customQuote.isRightQuote && !customQuote.isLowRightQuote) {
      return null;
    }

    return (
      <RightQuoteSelectedLearnMore
        coverage={coverage}
        onDone={onCloseRightQuoteModal}
        onLearnMore={onOpenRightQuoteModal}
        recommendation={quotesContext.rightQuoteRecommendation}
      />
    );
  };

  const {
    isCoverageAdded, coveredVins: selectedVins, hasSelectedVehicles,
  } = customQuote.getDeductibleInfo();
  const coll = customQuote.getCoverage(Coverage.Symbols.COLLISION);
  const comp = customQuote.getCoverage(Coverage.Symbols.COMPREHENSIVE);
  const showVehicleToggle = !quotingRules.rules.anyCollOrCompRequiresFull;

  const market = profileParams.market();
  const collisionName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COLLISION,
  });
  const comprehensiveName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COMPREHENSIVE,
  });
  const collAndCompDescription = `${collisionName} covers damage to your vehicle when you cause an accident. ${comprehensiveName} covers damage to your vehicle caused by anything other than an accident.`;
  const deductibleDescription = `Deductibles ${collisionName} and ${comprehensiveName} coverages apply to all of the vehicles on your policy.`;
  const collAndCompHeading = `${collisionName} & ${comprehensiveName}`;

  const selectionCardDescription = `Included vehicles will have ${collisionName} and ${comprehensiveName} coverages.`;
  const collAndCompSubDescription = profileParams.rideshare ? `${collisionName} & ${comprehensiveName} coverage are required if you drive for rideshare or delivery and by most lien holders.` : null;
  const coverageOptionsFullGlassAccepted = {
    [Coverage.CoverageOptions.CONVERSION]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.ENHANCED]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.FULL_GLASS]: Coverage.CoverageOptionChoice.YES,
    [Coverage.CoverageOptions.STACKED]: Coverage.CoverageOptionChoice.NO,
  };

  let onRemoveCollAndComp = null;

  const disableRemoveCollAndComp = !!profileParams.rideshare;

  if (customQuote.coverageIsDeclinable(coll) && customQuote.coverageIsDeclinable(comp) && !disableRemoveCollAndComp) {
    onRemoveCollAndComp = () => onDeclineCoverages([coll, comp]);
  }

  return (
    <CoverageGroup
      description={showVehicleToggle ? null : deductibleDescription}
      heading={'Deductibles'}
      hideDivider={showVehicleToggle}
      styleOverrides={styleOverrides}
    >
      {showVehicleToggle &&
        <VehicleSelectionCard
          additionalDescription={collAndCompSubDescription}
          description={selectionCardDescription}
          disabled={disableRemoveCollAndComp}
          isCoverageAdded={isCoverageAdded}
          onToggle={handleVehicleToggle}
          primaryBrandColor={primaryBrandColor}
          selectedVins={selectedVins}
          vehicles={profileParams.vehicles}
        />
      }
      <AddCoverageCard
        description={collAndCompDescription}
        heading={collAndCompHeading}
        IconComponent={IconComponent}
        isCoverageAdded={isCoverageAdded}
        onAddCoverage={handleAddCoverage}
        primaryBrandColor={primaryBrandColor}
        renderRightQuoteBadge={() => renderRightQuoteBadge(coll)}
      />
      <CoverageSelectionCard
        description={
          <CoverageCopy
            coverage={coll}
            market={market}
          />}
        headerBackgroundColor={cardHeaderBackgroundColor}
        heading={
          <CoverageTitle
            coverage={coll}
            market={market}
          />}
        isCoverageAdded={isCoverageAdded}
        onRemoveCoverage={onRemoveCollAndComp}
        vehiclesSelected={hasSelectedVehicles}
      >
        <QuotesSelect
          dropdownIndicator={dropdownIndicator}
          dropdownIndicatorColor={primaryBrandColor}
          inputId={'coll-coverage-select'}
          inputName={'coll-coverage-select'}
          mobileSelectedOptionStyles={mobileSelectedOptionStyles}
          onChange={(id) => onCoverageChange(id, Coverage.Symbols.COLLISION)}
          onClose={onCoverageSelectClose}
          onOpen={onCoverageSelectOpen}
          options={coverageSelections[Coverage.Symbols.COLLISION]}
          optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
          placeholder={'Select option'}
          renderRightQuoteBadge={() => renderRightQuoteBadge(coll)}
          selectedValue={customQuote.getCoverage(Coverage.Symbols.COLLISION).id}
        />
      </CoverageSelectionCard>
      <CoverageSelectionCard
        description={
          <CoverageCopy
            coverage={comp}
            market={market}
          />}
        headerBackgroundColor={cardHeaderBackgroundColor}
        heading={
          <CoverageTitle
            coverage={comp}
            market={market}
          />}
        isCoverageAdded={isCoverageAdded}
        onRemoveCoverage={onRemoveCollAndComp}
        renderLearnMore={() => renderLearnMore(coverageSelections, comp, coverageOptionsFullGlassAccepted, 'What is full glass coverage?')}
        vehiclesSelected={hasSelectedVehicles}
      >
        <QuotesSelect
          dropdownIndicator={dropdownIndicator}
          dropdownIndicatorColor={primaryBrandColor}
          inputId={'comp-coverage-select'}
          inputName={'comp-coverage-select'}
          mobileSelectedOptionStyles={mobileSelectedOptionStyles}
          onChange={(id) => onCoverageChange(id, Coverage.Symbols.COMPREHENSIVE)}
          onClose={onCoverageSelectClose}
          onOpen={onCoverageSelectOpen}
          options={coverageSelections[Coverage.Symbols.COMPREHENSIVE]}
          optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
          placeholder={'Select option'}
          renderRightQuoteBadge={() => renderRightQuoteBadge(comp)}
          selectedValue={customQuote.getCoverage(Coverage.Symbols.COMPREHENSIVE).id}
        />
      </CoverageSelectionCard>
    </CoverageGroup>
  );
}

CoverageSectionDeductibles.propTypes = {
  cardHeaderBackgroundColor: PropTypes.string,
  coverageSelections: PropTypes.object,
  customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
  dropdownIndicator: PropTypes.string,
  IconComponent: PropTypes.func,
  mobileSelectedOptionStyles: PropTypes.object,
  onAcceptCoverages: PropTypes.func.isRequired,
  onCloseRightQuoteModal: PropTypes.func.isRequired,
  onCoverageChange: PropTypes.func.isRequired,
  onCoverageSelectClose: PropTypes.func.isRequired,
  onCoverageSelectOpen: PropTypes.func.isRequired,
  onDeclineCoverages: PropTypes.func.isRequired,
  onOpenRightQuoteModal: PropTypes.func.isRequired,
  onVehicleToggle: PropTypes.func.isRequired,
  primaryBrandColor: PropTypes.string,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
  quotingRules: PropTypes.shape({
    rules: PropTypes.object.isRequired,
  }).isRequired,
  renderLearnMore: PropTypes.func,
  styleOverrides: PropTypes.object,
};
