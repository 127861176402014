import NetworkService from '@root/core/src/services/network-service';
import { isValidLicenseNumberForMarket } from '@root/core/src/api/root-server';

export default class LicenseValidationService {
  static async isValidForMarket({
    licenseState: market,
    licenseNumber,
    driversLicenseId,
  }) {
    let params = {
      licenseNumber,
      market,
    };

    if (driversLicenseId) {
      params = {
        driversLicenseId,
      };
    }

    const result = await NetworkService.safeRequest(isValidLicenseNumberForMarket, {
      ...params,
    });

    if (result.isSuccess) {
      return result.data.isValid;
    } else {
      return false;
    }
  }
}
