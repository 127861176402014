import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import Driver from '@root/auto-pricing/src/models/driver';
import Input from '@root/core/src/components/input';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import licenseStroke from '@root/core/src/assets/licenseStroke.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class ProfileAddDriverName extends Component {
  static propTypes = {
    cssButtonOverrides: PropTypes.object,
    driver: PropTypes.instanceOf(Driver).isRequired,
    inputStyle: PropTypes.object,
    linkStyles: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
  }

  static analyticsContext = 'PROFILE_ADD_DRIVER_NAME'

  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      driver: props.driver,
      firstNameError: '',
      lastNameError: '',
    };
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(ProfileAddDriverName.analyticsContext, { eventPrefix: this.context.eventPrefix });
  }

  _handleErrors() {
    this.setState({
      firstNameError: !this.state.driver.firstName,
      lastNameError: !this.state.driver.lastName,
    });
  }

  _handleFirstNameChange = (firstName) => {
    this.setState({
      driver: this.state.driver.set('firstName', firstName),
      firstNameError: !firstName,
    });
  };

  _handleLastNameChange = (lastName) => {
    this.setState({
      driver: this.state.driver.set('lastName', lastName),
      lastNameError: !lastName,
    });
  };

  _getFirstNameError() {
    if (!this.state.firstNameError) {
      return;
    }

    return 'Please enter their first name.';
  }

  _getLastNameError() {
    if (!this.state.lastNameError) {
      return;
    }

    return 'Please enter their last name.';
  }

  _handleSubmit = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverName.analyticsContext, 'CONTINUE', { eventPrefix: this.context.eventPrefix });
    this._handleErrors();

    if (this._isNameValid()) {
      this.props.onDone(this.state.driver);
    }
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverName.analyticsContext, 'CANCEL', { eventPrefix: this.context.eventPrefix });
    this.props.onCancel();
  }

  _isNameValid() {
    return !!(this.state.driver.firstName && this.state.driver.lastName);
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <ProfileHeading icon={licenseStroke}>
          What’s their name?
        </ProfileHeading>
        <ProfileForm onSubmit={this._handleSubmit}>
          <Input
            focusOnMount
            errorLabel={this._getFirstNameError()}
            inputStyle={this.props.inputStyle}
            label={'First name'}
            onChange={this._handleFirstNameChange}
            value={this.state.driver.firstName || ''}
          />
          <div css={styles.lastName}>
            <Input
              errorLabel={this._getLastNameError()}
              inputStyle={this.props.inputStyle}
              label={'Last name'}
              onChange={this._handleLastNameChange}
              value={this.state.driver.lastName || ''}
            />
          </div>
          <Button
            css={[styles.button, this.props.cssButtonOverrides]}
            disabled={!this._isNameValid()}
          >
            Continue
          </Button>
          <NevermindLink
            linkStyles={this.props.linkStyles}
            onCancel={this._handleCancel}
          />
        </ProfileForm>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  lastName: {
    marginTop: 10,
  },
  wrapper: {
    width: '100%',
  },
});
