import { Colors } from '@root/core/src/utils/styles';

export function PersonIcon() {
  return (
    <svg
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle
        cx={'12'}
        cy={'6'}
        fill={Colors.black()}
        r={'4.25'}
        stroke={Colors.black()}
        strokeWidth={'1.5'}
      />
      <path
        d={'M5 17V22H19V17C19 15.8954 18.1046 15 17 15H7C5.89543 15 5 15.8954 5 17Z'}
        fill={Colors.black()}
        stroke={Colors.black()}
        strokeWidth={'1.5'}
      />
    </svg>

  );
}
