import AgeLicensedHeading from '@root/age-licensed/src/components/age-licensed-heading';
import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React from '@root/vendor/react';

export default function AgeLicensedInexperiencedOperator({
  title,
  onChange,
  handleSubmit,
  availableOptions,
  currentAssignment,
}) {
  return (
    <div>
      <AgeLicensedHeading>
        {title}
      </AgeLicensedHeading>
      <form onSubmit={handleSubmit}>
        <RadioInputGroup
          selectedValue={currentAssignment}
        >
          {availableOptions.map((option, optionsIndex) => {
            return (
              <RadioOption
                id={`inexperienced-operator-${optionsIndex}`}
                isSelected={currentAssignment === option}
                key={`age-range-${optionsIndex}`}
                label={option}
                name={'inexperienced-operator-radio'}
                onChange={onChange}
                optionValue={option}
              />
            );
          })}
        </RadioInputGroup>
        <Button disabled={!currentAssignment}>
          Continue
        </Button>
      </form>
    </div>
  );
}

AgeLicensedInexperiencedOperator.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  currentAssignment: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

