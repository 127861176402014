import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function sendWebAppTextMessageConfiguration(phoneNumber) {
  return new NetworkRequestConfiguration({
    endpoint: 'web_app_text_message/send_authenticated_app_download_link',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      webAppTextMessage: {
        phoneNumber,
      },
    },
  });
}
