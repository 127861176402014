import React from '@root/vendor/react';
import {
  AutoscalingSvg,
  IconProps,
} from '@root/bind.joinroot.com/src/components/icons';
import { Colors } from '@root/core/src/utils/styles';

const ArrowBack = ({
  color = Colors.black(),
  height = 24,
  width = 16,
}) => {
  return (
    <AutoscalingSvg
      fill={'none'}
      height={height}
      viewBox={'0 0 24 24'}
      width={width}
    >
      <path
        clipRule={'evenodd'}
        d={'M1.08594 12L9.22673 3.85919L10.6409 5.27341L4.91436 11L23.0002 11V13L4.91436 13L10.6409 18.7266L9.22673 20.1408L1.08594 12Z'}
        fill={color}
        fillRule={'evenodd'}
      />
    </AutoscalingSvg>
  );
};

ArrowBack.propTypes = IconProps;

export default ArrowBack;
