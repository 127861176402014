import { RootError } from '@root-common/root-errors';
export default class BillingCycle {
  static MONTHLY = 'monthly';
  static FULL_TERM = 'full_term';
  static ANNUALLY = 'annually';

  static getOptions() {
    return [this.MONTHLY, this.FULL_TERM];
  }

  static getDisplayOptions() {
    return [this.MONTHLY, this.FULL_TERM];
  }

  static format(billingCycle) {
    return this._select(billingCycle, {
      [this.MONTHLY]: 'Monthly',
      [this.FULL_TERM]: '6 months',
      [this.ANNUALLY]: 'Yearly',
    });
  }

  static conversationalFormat(billingCycle) {
    return this._select(billingCycle, {
      [this.MONTHLY]: 'billed monthly',
      [this.FULL_TERM]: 'billed every 6 months',
      [this.ANNUALLY]: 'billed annually',
    });
  }

  static shortFormat(billingCycle) {
    return this._select(billingCycle, {
      [this.MONTHLY]: '/mo.',
      [this.FULL_TERM]: '/6 mo.',
      [this.ANNUALLY]: '/yr.',
    });
  }

  static optionsForSelect(billingCycles = [BillingCycle.MONTHLY, BillingCycle.FULL_TERM]) {
    return billingCycles.map((billingCycle) => ({
      label: this.format(billingCycle),
      value: billingCycle,
    }));
  }

  static _select(billingCycle, options) {
    switch (billingCycle) {
    case BillingCycle.MONTHLY:
      return options[BillingCycle.MONTHLY];
    case BillingCycle.FULL_TERM:
      return options[BillingCycle.FULL_TERM];
    case BillingCycle.ANNUALLY:
      return options[BillingCycle.ANNUALLY];
    default:
      throw new RootError({
        message: 'invalid billing cycle',
        name: 'BillingCycleError',
      });
    }
  }
}
