import { Colors } from '@root/core/src/utils/styles';

export function BlackVehicleLocationIcon() {
  return (
    <svg
      fill={'none'}
      height={'41'}
      viewBox={'0 0 29 41'}
      width={'29'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        clipRule={'evenodd'}
        d={'M3.1875 24.142V32.6895C3.1875 32.9656 3.41136 33.1895 3.6875 33.1895H5.96023C6.23637 33.1895 6.46023 32.9656 6.46023 32.6895V30.5596H21.9148V32.6895C21.9148 32.9656 22.1386 33.1895 22.4148 33.1895H24.6875C24.9636 33.1895 25.1875 32.9656 25.1875 32.6895L25.1875 24.142C25.1875 23.6968 24.9898 23.2747 24.6478 22.9897L23.6875 22.1895H24.6876C24.9637 22.1895 25.1876 21.9656 25.1876 21.6895V20.6895C25.1876 20.4133 24.9637 20.1895 24.6876 20.1895H22.4875L20.9788 17.675C20.7981 17.3738 20.4726 17.1895 20.1213 17.1895H8.25369C7.90243 17.1895 7.57692 17.3738 7.3962 17.675L5.8875 20.1895H3.6875C3.41136 20.1895 3.1875 20.4133 3.1875 20.6895V21.6895C3.1875 21.9656 3.41136 22.1895 3.6875 22.1895H4.6875L3.72722 22.9897C3.38523 23.2747 3.1875 23.6968 3.1875 24.142ZM18.6875 25.6895C18.6875 25.4133 18.9114 25.1895 19.1875 25.1895H22.6875C22.9636 25.1895 23.1875 25.4133 23.1875 25.6895V27.1895C23.1875 27.4656 22.9636 27.6895 22.6875 27.6895H19.1875C18.9114 27.6895 18.6875 27.4656 18.6875 27.1895V25.6895ZM5.6875 25.1895C5.41136 25.1895 5.1875 25.4133 5.1875 25.6895V27.1895C5.1875 27.4656 5.41136 27.6895 5.6875 27.6895H9.1875C9.46364 27.6895 9.6875 27.4656 9.6875 27.1895V25.6895C9.6875 25.4133 9.46364 25.1895 9.1875 25.1895H5.6875ZM21.6875 22.6895H6.6875L8.59897 19.2082C8.77462 18.8883 9.11058 18.6895 9.47553 18.6895H18.8995C19.2644 18.6895 19.6004 18.8883 19.776 19.2082L21.6875 22.6895Z'}
        fill={Colors.black()}
        fillRule={'evenodd'}
      />
      <path
        clipRule={'evenodd'}
        d={'M12.0626 5.05025C10.6275 6.45057 10.7701 8.53954 12.0626 10.1213L14.6611 13.2952L17.2597 10.1213C18.5522 8.53954 18.6948 6.45057 17.2597 5.05025C15.8245 3.64992 13.4977 3.64992 12.0626 5.05025ZM14.6612 8.75571C15.3775 8.75571 15.9582 8.17502 15.9582 7.4587C15.9582 6.74238 15.3775 6.16169 14.6612 6.16169C13.9448 6.16169 13.3641 6.74238 13.3641 7.4587C13.3641 8.17502 13.9448 8.75571 14.6612 8.75571Z'}
        fill={Colors.black()}
        fillRule={'evenodd'}
      />
    </svg>

  );
}
