export default class RecentlyMoved {
  static MOVED = 'moved';
  static DORMANT = 'dormant';

  static Options = [
    {
      label: 'Yes',
      value: RecentlyMoved.MOVED,
    },
    {
      label: 'No',
      value: RecentlyMoved.DORMANT,
    },
  ];

  static isRecentlyMoved(value) {
    return value === RecentlyMoved.MOVED;
  }
}
