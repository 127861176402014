import { getSupportedMarkets } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useSupportedMarketsQuery = (overrides = {}) => useQuery({
  queryFn: getSupportedMarkets,
  queryKey: ['markets', 'supported'],
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  ...overrides,
});

export default useSupportedMarketsQuery;
