import getAutoQuoteDisclaimersConfiguration from '@root/quotes/src/api/get-auto-quote-disclaimers/get-auto-quote-disclaimers-configuration';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useMemo } from '@root/vendor/react';

export default function useGetAutoQuoteDisclaimers(quoteId, invoicePeriod, callback) {
  const configuration = useMemo(() => getAutoQuoteDisclaimersConfiguration({
    quoteId,
    invoicePeriod,
  }), [quoteId, invoicePeriod]);

  return useNetworkRequest(configuration, callback);
}
