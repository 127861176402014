import Homeowner from '@root/core/src/models/homeowner';
import PrefillRequest from '@root/prefill/src/models/prefill-request';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import Select from '@root/bind.joinroot.com/src/components/select';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_EVENTS = {
  SELECTED_HOMEOWNER_STATUS: 'SELECTED_HOMEOWNER_STATUS',
};

const HomeownerSelect = ({
  hidden,
  onUpdatePrefillRequest,
  onUpdateProfileParams,
  prefillRequest,
  profileParams,
  trackEvent,
  ...restProps
}) => {
  const strings = useI18nNext('components.bindStartEntry.homeownerSelect');

  const updateHomeowner = useCallback((newHomeownerValue) => {
    trackEvent(ANALYTICS_EVENTS.SELECTED_HOMEOWNER_STATUS, { homeownerStatus: newHomeownerValue });
    let updatedProfileParams = ProfileDriverService.setDriverValue(
      profileParams,
      profileParams.getPniDriver().universalDriverId, // We're only concerned about the primary driver on the start entry page
      'homeowner',
      newHomeownerValue
    );
    updatedProfileParams = updatedProfileParams.set(
      'homeowner',
      newHomeownerValue
    );
    const updatedPrefillRequest = prefillRequest.set(
      'homeowner',
      newHomeownerValue
    );
    onUpdateProfileParams(updatedProfileParams);
    onUpdatePrefillRequest(updatedPrefillRequest);
  }, [onUpdatePrefillRequest, onUpdateProfileParams, prefillRequest, profileParams, trackEvent]);

  if (hidden) {
    return null;
  }

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      <Select
        inputId={'homeowner-select'}
        inputName={'homeowner-select'}
        onChange={updateHomeowner}
        options={Homeowner.Options}
        persistLabel={true}
        placeholder={strings.placeholder}
        selectedValue={prefillRequest.homeowner || ''}
      />
    </div>
  );
};

HomeownerSelect.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onUpdatePrefillRequest: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  prefillRequest: PropTypes.instanceOf(PrefillRequest).isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading3(),
  },
});

export default HomeownerSelect;
