import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindParams from '@root/quotes/src/models/bind-params';
import BindRideshareInfo from '@root/bind.joinroot.com/src/components/bind-quote-customization/bind-rideshare-info';
import BindRideshareRemovalConfirmation from '@root/bind.joinroot.com/src/components/bind-quote-customization/bind-rideshare-removal-confirmation';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCustomContainer from '@root/quotes/src/components/coverage/coverage-custom-container';
import CoverageDetailsCard from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ErrorReportService from '@root/core/src/services/error-report-service';
import Link from '@root/core/src/components/link';
import MobileStickyPriceFooter from '@root/bind.joinroot.com/src/components/bind-quote-customization/mobile-sticky-price-footer';
import Modal from '@root/core/src/components/modal';
import OverlayModal from '@root/core/src/components/overlay-modal';
import PlusIcon from '@root/bind.joinroot.com/src/components/icons/plus-icon';
import Profile from '@root/auto-pricing/src/models/profile';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelectedModal from '@root/quotes/src/components/quotes-selected-modal';
import QuotingRules from '@root/quotes/src/models/quoting-rules';
import React, { useCallback, useEffect, useMemo, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SceneLoader from '@root/core/src/components/scene-loader';
import Sticky from 'react-stickynode';
import ZIndex from '@root/core/src/utils/z-index';
import isEqual from '@root/vendor/lodash/isEqual';
import noop from '@root/vendor/lodash/noop';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import useDeepLink from '@root/bind.joinroot.com/src/context/deep-link';
import useMarkQuoteViewedMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-mark-quote-viewed-mutation';
import useMediaQuery from '@root/core/src/hooks/use-media-query';
import useWindowSize from '@root/core/src/hooks/use-window-size';
import uuidv4 from '@root/vendor/uuid/v4';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';
import { useInView } from 'react-intersection-observer';
import { useUnderwritingCompanyContext } from '@root/profile/src/contexts/underwriting-company-context';

export const ANALYTICS_CONTEXT = 'BIND_QUOTE_CUSTOMIZATION';
export const ANALYTICS_EVENTS = {
  ACCEPTED_COVERAGES: 'ACCEPTED_COVERAGES',
  ADD_DRIVER: 'ADD_DRIVER',
  ADD_VEHICLE: 'ADD_VEHICLE',
  CHANGED_COVERAGE: 'CHANGED_COVERAGE',
  DECLINED_COVERAGES: 'DECLINED_COVERAGES',
  EDIT_DRIVER: 'EDIT_DRIVER',
  EDIT_VEHICLE: 'EDIT_VEHICLE',
  SUBMIT: 'SUBMIT',
  TOGGLED_VEHICLE: 'TOGGLED_VEHICLE',
  UPDATED_BILLING_CYCLE: 'UPDATED_BILLING_CYCLE',
  OPEN_COVERAGE_MODAL: 'OPEN_COVERAGE_MODAL',
  OPEN_RIDESHARE_INFO_MODEL: 'OPEN_RIDESHARE_INFO_MODEL',
  CLOSE_COVERAGE_MODAL: 'CLOSE_COVERAGE_MODAL',
  CLOSE_RIDESHARE_INFO_MODEL: 'CLOSE_RIDESHARE_INFO_MODEL',
};

const CUSTOMIZATION_MODAL = {
  COVERAGE: 'COVERAGE',
  RIDESHARE_INFO: 'RIDESHARE_INFO',
  RIDESHARE_REMOVAL: 'RIDESHARE_REMOVAL',
};

function BindQuoteCustomization({
  bindParams,
  createCustomQuote,
  isCreatingCustomQuote,
  onContinue,
  onClickAddDriver,
  onClickAddVehicle,
  onClickEditDriver,
  onClickEditVehicle,
  onUpdateRideshare,
  profile,
  profileParams,
  quotesContext,
  quoteCoveragesContext,
  quoteCoveragesContextsForBackendOptions,
  quotingRules,
  scrollToRideshareCoverage,
  selectedQuote,
  updateBindParams,
  updateSelectedQuote,
}) {
  const { trackEvent, trackClick } = useAnalytics(ANALYTICS_CONTEXT);
  const deepLink = useDeepLink();

  const overviewCardContainerRef = useRef();
  const {
    ref: overviewCardRef, inView: overviewCardInView, entry,
  } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: 0.8, // Define 'in view' threshold as 80% visibility
  });

  const isMdSize = useMediaQuery(Responsive.QUERIES.md);
  const { windowHeight } = useWindowSize();
  const [overviewCardHeight, setOverviewCardHeight] = useState(0);

  const [displayedModal, setDisplayedModal] = useState(undefined);
  const [coverageModalChildren, setCoverageModalChildren] = useState(null);

  const closeModal = useCallback(() => setDisplayedModal((prevDisplayedModal) => {
    if (prevDisplayedModal === CUSTOMIZATION_MODAL.COVERAGE) {
      trackEvent(ANALYTICS_EVENTS.CLOSE_COVERAGE_MODAL);
    }
    return undefined;
  }), [setDisplayedModal, trackEvent]);

  const handleOpenRideshareInfoModal = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.OPEN_RIDESHARE_INFO_MODEL);

    setDisplayedModal(CUSTOMIZATION_MODAL.RIDESHARE_INFO);
  }, [trackClick]);

  const handleCloseRideshareInfoModal = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.CLOSE_RIDESHARE_INFO_MODEL);

    closeModal();
  }, [closeModal, trackClick]);

  const handleOpenRideshareRemovalConfirmationModal = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.OPEN_RIDESHARE_INFO_MODEL);

    setDisplayedModal(CUSTOMIZATION_MODAL.RIDESHARE_REMOVAL);
  }, [trackClick]);

  const handleCloseRideshareRemovalConfirmationModal = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.CLOSE_RIDESHARE_INFO_MODEL);

    closeModal();
  }, [closeModal, trackClick]);

  const underwritingCompanyContext = useUnderwritingCompanyContext();

  const loading = !underwritingCompanyContext || !selectedQuote?.id || !quoteCoveragesContext || !quotesContext || !quotingRules || !profileParams?.vehicles?.length || !!quotesContext.manualTortMarket && !quoteCoveragesContextsForBackendOptions?.length;

  const scrollToOverview = useCallback(() => {
    overviewCardContainerRef.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, []);

  const desktopStickySidebarEnabled = useMemo(() => isMdSize && overviewCardHeight <= windowHeight, [isMdSize, overviewCardHeight, windowHeight]);
  useEffect(
    () => setOverviewCardHeight(entry?.boundingClientRect?.height),
    [entry?.boundingClientRect?.height, setOverviewCardHeight]
  );

  const [error, setError] = useState(undefined);
  const strings = useI18nNext('pages.bindQuoteCustomization');
  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);
  const monthlyPremiumRatio = useMemo(() => 1, []); // NOTE: this can be assumed one because soft launch will only include classic markets (no equity billing)

  const {
    mutate: markQuoteAsViewed,
    isError: didMarkQuoteAsViewedFail,
    isLoading: isMarkingQuoteAsViewed,
    isSuccess: didMarkQuoteAsViewedSucceed,
  } = useMarkQuoteViewedMutation({
    onError: (markQuoteAsViewedError) => {
      ErrorReportService.reportError({
        markQuoteAsViewedError,
        caughtAt: '_markQuoteViewed',
        additionalData: {
          selectedQuoteId: selectedQuote.id,
        },
      });
    },
  });

  useEffect(() => {
    if (!didMarkQuoteAsViewedFail && !didMarkQuoteAsViewedSucceed && !isMarkingQuoteAsViewed && selectedQuote?.id) {
      markQuoteAsViewed({
        quoteId: selectedQuote.id,
      });
    }
  }, [markQuoteAsViewed, didMarkQuoteAsViewedFail, didMarkQuoteAsViewedSucceed, isMarkingQuoteAsViewed, selectedQuote?.id]);

  const rideshareRef = useRef(null);

  useEffect(() => {
    if (scrollToRideshareCoverage) {
      rideshareRef.current.scrollIntoView();
    }
  }, [scrollToRideshareCoverage]);

  const onUpdateSelectedQuote = useCallback((updatedCustomQuote, updatedCoverageSymbols) => {
    if (!updatedCustomQuote) {
      return;
    }

    let updatedBindParams = bindParams;

    if (!updatedCustomQuote.originQuoteId) {
      updatedCustomQuote = updatedCustomQuote.set('originQuoteId', updatedCustomQuote.id).set('id', uuidv4());
      updatedBindParams = bindParams.set('quoteId', updatedCustomQuote.id);
    }

    const profileVins = profileParams.vehicles.map((v) => v.getAvailableVin());
    const updatedSelectedQuote = updatedCoverageSymbols
      .reduce((currentQuote, coverageSymbol) => currentQuote.fixupCoverages(quotingRules, coverageSymbol), updatedCustomQuote)
      .updatePrices(profileVins)
      .addTaxAndFees(quotesContext);

    updateSelectedQuote(updatedSelectedQuote);
    updateBindParams(updatedBindParams);
  }, [bindParams, profileParams, quotesContext, quotingRules, updateBindParams, updateSelectedQuote]);

  /**
   * Toggling vehicles
   */

  const handleClickSubmit = useCallback(() => {
    setError(undefined);

    const wasQuoteCustomized = !!selectedQuote.originQuoteId;
    trackClick(ANALYTICS_EVENTS.SUBMIT, { wasQuoteCustomized });

    if (wasQuoteCustomized) {
      const coverageParams = selectedQuote.buildCustomCoveragesForRequest(profileParams);
      const requestParams = {
        coverages: coverageParams,
        rateId: selectedQuote.rateId,
        originQuoteId: selectedQuote.originQuoteId,
      };

      createCustomQuote(requestParams, {
        onError: setError,
        onSuccess: onContinue,
      });
    } else {
      onContinue();
    }
  }, [createCustomQuote, onContinue, selectedQuote, profileParams, trackClick]);

  const handleVehicleToggle = useCallback((vehicle, selected, coverages) => {
    const fn = selected ? 'addVinToCoverages' : 'removeVinFromCoverages';
    const vin = vehicle.getAvailableVin();
    const newSelectedQuote = selectedQuote[fn](vin, coverages);
    const coverageSymbols = coverages.map((coverage) => coverage.symbol);

    trackEvent(ANALYTICS_EVENTS.TOGGLED_VEHICLE, {
      vin,
      selected,
      coverageSymbols,
    });

    onUpdateSelectedQuote(newSelectedQuote, coverageSymbols);
  }, [onUpdateSelectedQuote, selectedQuote, trackEvent]);

  /**
   * Coverages
   */

  const handleCoverageChange = useCallback((coverageId, symbol) => {
    const coverage = quoteCoveragesContext.getCoverageByIdAndSymbol(coverageId, symbol);
    const newSelectedQuote = selectedQuote.updateCoverage(coverage);

    trackEvent(ANALYTICS_EVENTS.CHANGED_COVERAGE, { coverage: newSelectedQuote.getCoverage(symbol) });

    onUpdateSelectedQuote(newSelectedQuote, [symbol]);
  }, [quoteCoveragesContext, onUpdateSelectedQuote, selectedQuote, trackEvent]);

  const handleAcceptCoverages = useCallback((coverages) => {
    const newSelectedQuote = selectedQuote.acceptCoverages(coverages);

    const coverageSymbols = coverages.map((coverage) => coverage.symbol);
    trackEvent(ANALYTICS_EVENTS.ACCEPTED_COVERAGES, { coverages: newSelectedQuote.getCoverages(coverageSymbols) });

    onUpdateSelectedQuote(newSelectedQuote, coverageSymbols);
  }, [onUpdateSelectedQuote, selectedQuote, trackEvent]);

  const handleDeclineCoverages = useCallback((coverages) => {
    const newSelectedQuote = selectedQuote.declineCoverages(coverages);

    const coverageSymbols = coverages.map((coverage) => coverage.symbol);
    trackEvent(ANALYTICS_EVENTS.DECLINED_COVERAGES, { coverages: newSelectedQuote.getCoverages(coverageSymbols) });

    onUpdateSelectedQuote(newSelectedQuote, coverageSymbols);
  }, [onUpdateSelectedQuote, selectedQuote, trackEvent]);

  const onTortChange = useCallback((tortSelection) => updateBindParams((prevBindParams) => prevBindParams.set('tortSelection', tortSelection)), [updateBindParams]);

  const getTortPrice = useCallback((quotesCoverageContextForTortOption, billingCycle) => {
    return selectedQuote
      .updateQuoteCoveragesContext(quotesCoverageContextForTortOption)
      .updatePrices(profileParams.vehicles.map((v) => v.getAvailableVin()))
      .addTaxAndFees(quotesContext)
      .getMonthlyOrFullTermPaymentInCents(billingCycle);
  }, [profileParams, quotesContext, selectedQuote]);

  const tortPrices = useMemo(() => {
    if (loading || !quotesContext?.manualTortMarket) {
      return {
        [BillingCycle.MONTHLY]: {
          limited: 0,
          full: 0,
        },
        [BillingCycle.FULL_TERM]: {
          limited: 0,
          full: 0,
        },
      };
    }

    const limitedContext = quoteCoveragesContextsForBackendOptions.find(({ options }) => {
      return options.find(({ tortSelection }) => tortSelection === 'limited');
    }).quoteCoveragesContext;

    const fullContext = quoteCoveragesContextsForBackendOptions.find(({ options }) => {
      return options.find(({ tortSelection }) => tortSelection === 'full');
    }).quoteCoveragesContext;

    return {
      [BillingCycle.MONTHLY]: {
        limited: getTortPrice(limitedContext, BillingCycle.MONTHLY),
        full: getTortPrice(fullContext, BillingCycle.MONTHLY),
      },
      [BillingCycle.FULL_TERM]: {
        limited: getTortPrice(limitedContext, BillingCycle.FULL_TERM),
        full: getTortPrice(fullContext, BillingCycle.FULL_TERM),
      },
    };
  }, [getTortPrice, loading, quoteCoveragesContextsForBackendOptions, quotesContext?.manualTortMarket]);

  const renderCoverageModalChildren = useCallback((coverageOptions) => {
    const comprehensiveName = Coverage.getLocaleName({
      market: profileParams.market(),
      symbol: Coverage.Symbols.COMPREHENSIVE,
    });
    const uninsuredMotoristName = Coverage.getLocaleName({
      market: profileParams.market(),
      symbol: Coverage.Symbols.UNINSURED_MOTORIST,
    });
    let modalChild = null;
    if (coverageOptions[Coverage.CoverageOptions.STACKED] === Coverage.CoverageOptionChoice.YES) {
      modalChild = (
        <QuotesSelectedModal
          isOverlay={true}
          onCloseClick={closeModal}
          styles={styles.coverageModal}
        >
          <div css={styles.header}>
            <h3 css={styles.heading}>{`What is stacked ${uninsuredMotoristName} coverage?`}</h3>
            <div css={styles.smallText}>{`With stacked limits, the limit you select is multiplied by the number of vehicles on your policy and will be the most amount of money you can receive for ${uninsuredMotoristName} coverage in the case of an accident.`}</div>
            <br />
            <h3 css={styles.heading}>{`What is unstacked ${uninsuredMotoristName} coverage?`}</h3>
            <div css={styles.smallText}>{`With unstacked limits, the limit you select will be the most amount of money you can receive for ${uninsuredMotoristName} coverage in the case of an accident, regardless of the number of vehicles on your policy.`}</div>
          </div>
        </QuotesSelectedModal>
      );
    }

    if (coverageOptions[Coverage.CoverageOptions.FULL_GLASS] === Coverage.CoverageOptionChoice.YES) {
      modalChild = (
        <QuotesSelectedModal
          isOverlay={true}
          onCloseClick={closeModal}
          styles={styles.coverageModal}
        >
          <div css={styles.header}>
            <h3 css={styles.heading}>Full glass coverage</h3>
            <div css={styles.smallText}>{`When you select ${comprehensiveName} coverage with full glass, window damage will be covered at no out-of-pocket expense.`}</div>
            <br />
            <div css={styles.smallText}>{'Keep in mind that you\'ll still have to pay a deductible in case anything damages any other part of your car.'}</div>
          </div>
        </QuotesSelectedModal>
      );
    }

    return modalChild;
  }, [closeModal, profileParams, styles.coverageModal, styles.header, styles.heading, styles.smallText]);

  const openCoverageModal = useCallback((coverage) => {
    trackEvent(ANALYTICS_EVENTS.OPEN_COVERAGE_MODAL, { coverage });
    setCoverageModalChildren(renderCoverageModalChildren(coverage));
    setDisplayedModal(CUSTOMIZATION_MODAL.COVERAGE);
  }, [renderCoverageModalChildren, trackEvent]);

  const renderLearnMore = useCallback((coverageSelections, coverage, coverageOptions, learnMoreText = 'Learn more') => {
    if (coverageSelections[coverage.symbol].some((c) => isEqual(c.coverageOptions, coverageOptions))) {
      return (
        <div css={styles.learnMoreLink}>
          <Link
            css={styles.linkStyles}
            onClick={() => openCoverageModal(coverageOptions)}
          >
            {learnMoreText}
          </Link>
        </div>
      );
    }

    return null;
  }, [openCoverageModal, styles.learnMoreLink, styles.linkStyles]);

  const renderCoverageOverlayModal = () => {
    return (
      <OverlayModal
        onCancel={closeModal}
        visible={displayedModal === CUSTOMIZATION_MODAL.COVERAGE}
      >
        {coverageModalChildren}
      </OverlayModal>
    );
  };

  const passProps = useMemo(() => ({
    goToProfileReview: onUpdateRideshare,
    onLearnMoreClick: handleOpenRideshareInfoModal,
    onRemoveRideshareCoverage: handleOpenRideshareRemovalConfirmationModal,
    rideshareRef,
    skipProfileReviewFlow: true,
    onAcceptCoverages: handleAcceptCoverages,
    onCloseRightQuoteModal: noop,
    onOpenRightQuoteModal: noop,
    onCoverageChange: handleCoverageChange,
    onCoverageSelectClose: noop,
    onCoverageSelectOpen: noop,
    onDeclineCoverages: handleDeclineCoverages,
    onTortChange,
    onVehicleToggle: handleVehicleToggle,
    renderLearnMore,
    cardHeaderBackgroundColor: 'white',
    primaryBrandColor: branding?.primaryColor,
    dropdownIndicator: branding?.chevronDown,
    IconComponent: PlusIcon,
    mobileSelectedOptionStyles: styles.mobileSelectedOptionStyles,
    secondaryBrandColor: branding?.secondaryColor,
    styleOverrides: styles.bindCustomCoverage,
    quotesContext,
    tortPrices,
  }), [onUpdateRideshare, handleOpenRideshareInfoModal, handleOpenRideshareRemovalConfirmationModal, handleAcceptCoverages, handleCoverageChange, handleDeclineCoverages, onTortChange, handleVehicleToggle, branding?.primaryColor, branding?.chevronDown, branding?.secondaryColor, styles.mobileSelectedOptionStyles, renderLearnMore, styles.bindCustomCoverage, quotesContext, tortPrices]);

  const handleUpdateBillingCycle = useCallback((newBillingCycle) => {
    trackEvent(ANALYTICS_EVENTS.UPDATED_BILLING_CYCLE, { billingCycle: newBillingCycle });

    updateBindParams((prevBindParams) => {
      return prevBindParams.set('billingCycle', newBillingCycle);
    });
  }, [trackEvent, updateBindParams]);

  const handleAddDriver = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.ADD_DRIVER);

    onClickAddDriver();
  }, [onClickAddDriver, trackClick]);

  const handleEditDriver = useCallback((universalDriverId) => {
    trackClick(ANALYTICS_EVENTS.EDIT_DRIVER, { universalDriverId });

    onClickEditDriver(universalDriverId);
  }, [onClickEditDriver, trackClick]);

  const handleAddVehicle = useCallback(() => {
    trackClick(ANALYTICS_EVENTS.ADD_VEHICLE);

    onClickAddVehicle();
  }, [onClickAddVehicle, trackClick]);

  const handleEditVehicle = useCallback(({ vin, vehicleCid }) => {
    trackClick(ANALYTICS_EVENTS.EDIT_VEHICLE, { vin });

    onClickEditVehicle({
      vin,
      vehicleCid,
    });
  }, [onClickEditVehicle, trackClick]);

  if (loading) {
    return <SceneLoader />;
  }

  const coveragesOnlyHeroStyles = {
    height: '10vh',
  };

  return (
    <>
      <BindView.ExitFlowButton exitToQuote={deepLink.wasDeepLinked()} />
      <BindView
        cssOverrides={{
          content: {
            padding: '20px 20px',
            ...Responsive.xl({
              justifyContent: 'flex-start',
              width: 1080,
            }),
            position: 'relative',
          },
        }}
      >
        {renderCoverageOverlayModal()}
        <div css={deepLink.wasDeepLinked() ? [styles.hero, coveragesOnlyHeroStyles] : styles.hero}>
          <div css={[styles.heroContainer, styles.heroTextContainer]}>
            {!!deepLink.wasDeepLinked() &&
            <h3 css={styles.heroHeading2}>
              {strings.hero.coveragesOnlyHeading}
            </h3>
            }
            {!deepLink.wasDeepLinked() &&
              <>
                <h1 css={styles.heroHeading}>
                  {strings.hero.heading}
                </h1>
                <p css={styles.heroContent}>
                  {strings.hero.content}
                </p>
              </>

            }
          </div>
          <div
            css={[styles.heroContainer, styles.heroCardContainer]}
            ref={overviewCardContainerRef}
          >
            <Sticky
              bottomBoundary={'.bind-view'}
              enabled={desktopStickySidebarEnabled}
            >
              <div
                css={styles.stickySpacer}
              >
                <CoverageDetailsCard
                  billingCycle={bindParams.billingCycle}
                  containerRef={overviewCardRef}
                  disableSubmit={isCreatingCustomQuote}
                  error={error}
                  onClickAddDriver={handleAddDriver}
                  onClickAddVehicle={handleAddVehicle}
                  onClickEditDriver={handleEditDriver}
                  onClickEditVehicle={handleEditVehicle}
                  onClickSubmit={handleClickSubmit}
                  profileParams={profileParams}
                  readOnly={deepLink.wasDeepLinked()}
                  selectedQuote={selectedQuote}
                  trackClick={trackClick}
                  updateBillingCycle={handleUpdateBillingCycle}
                />
              </div>
            </Sticky>
          </div>
        </div>
        <div css={styles.coverageCustomizationContainer}>
          <CoverageCustomContainer
            bindParams={bindParams}
            customQuote={selectedQuote}
            displayRideshareCoverageComponent={!deepLink.wasDeepLinked()}
            monthlyPremiumRatio={monthlyPremiumRatio}
            passProps={passProps}
            profile={profile}
            profileParams={profileParams}
            quoteCoveragesContext={quoteCoveragesContext}
            quotingRules={quotingRules}
            tortPrices={tortPrices}
          />
        </div>
        <MobileStickyPriceFooter
          billingCycle={bindParams.billingCycle}
          onClickReview={scrollToOverview}
          selectedQuote={selectedQuote}
          visible={!overviewCardInView}
        />
        <Modal
          isShowing={displayedModal === CUSTOMIZATION_MODAL.RIDESHARE_INFO}
          name={'info-modal'}
          onCancel={handleCloseRideshareInfoModal}
          responsiveCloseButton={true}
          showCloseButton={true}
          styleOverrides={modalStyles}
        >
          <BindRideshareInfo
            market={profileParams.market()}
            onCloseClick={handleCloseRideshareInfoModal}
            primaryBrandColor={branding?.primaryColor}
          />
        </Modal>
        <Modal
          isShowing={displayedModal === CUSTOMIZATION_MODAL.RIDESHARE_REMOVAL}
          name={'confirmation-modal'}
          onCancel={handleCloseRideshareRemovalConfirmationModal}
          responsiveCloseButton={true}
          showCloseButton={true}
        >
          <BindRideshareRemovalConfirmation
            onCancel={handleCloseRideshareRemovalConfirmationModal}
            onUpdateRideshare={onUpdateRideshare}
          />
        </Modal>
      </BindView>
    </>
  );
}

const modalStyles = StyleSheet.create({
  outerContent: {
    width: '150vw',
  },
  innerContent: {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    maxWidth: 750,
    ...Responsive.sm({
      padding: '40px 40px',
    }),
    ...Responsive.lessThanSm({
      paddingBottom: 90, /* For the mobile Safari bar */
    }),
  },
});

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  coverageModal: {
    zIndex: ZIndex.MODAL,
    ...Responsive.md({
      maxWidth: '465px',
    }),
    padding: '45px 65px',
  },
  hero: {
    display: 'flex',
    flexDirection: 'row',
    height: '40vh',
    width: '100%',
    ...Responsive.lessThanMd({
      flexDirection: 'column',
      height: 'auto',
    }),
    zIndex: 2,
  },
  heroContainer: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',

    ...Responsive.lessThanMd({
      width: '100%',
    }),
  },
  heroContent: {
    ...Theme.paragraph1(),
  },
  heroHeading: {
    ...Theme.heading1(),
    ...Responsive.md({
      fontSize: 66,
    }),
  },
  heroHeading2: {
    ...Theme.heading2(),
    ...Responsive.md({
      fontSize: 48,
    }),
  },
  heroCardContainer: {
    flex: 4,
  },
  heroTextContainer: {
    flex: 7,
    alignItems: 'flex-start',
    justifyContent: 'center',
    ...Responsive.md({
      paddingRight: '2rem',
    }),
  },
  coverageCustomizationContainer: {
    display: 'flex',
    flex: 7,
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 70,
    paddingTop: '1.5em',
    width: '100%',
    ...Responsive.sm({
      marginBottom: 0,
    }),
  },
  learnMoreLink: {
    marginTop: 10,
  },
  linkStyles: {
    ':hover': {
      color: primaryColor,
    },
  },
  mobileSelectedOptionStyles: {
    color: Colors.white(),
    background: primaryColor,
  },
  smallText: {
    ...Theme.paragraph2(),
  },
  stickySpacer: {
    marginBottom: 15,
    marginTop: 15,
  },
  bindCustomCoverage: {
    ...Responsive.lessThanLg({
      maxWidth: 450,
    }),
    ...Responsive.lessThanMd({
      maxWidth: '100%',
    }),
  },
});

BindQuoteCustomization.propTypes = {
  bindParams: PropTypes.instanceOf(BindParams),
  createCustomQuote: PropTypes.func,
  isCreatingCustomQuote: PropTypes.bool.isRequired,
  onClickAddDriver: PropTypes.func.isRequired,
  onClickAddVehicle: PropTypes.func.isRequired,
  onClickEditDriver: PropTypes.func.isRequired,
  onClickEditVehicle: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
  onUpdateRideshare: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Profile),
  profileParams: PropTypes.instanceOf(ProfileParams),
  quoteCoveragesContext: PropTypes.instanceOf(QuoteCoveragesContext),
  quoteCoveragesContextsForBackendOptions: PropTypes.array,
  quotesContext: PropTypes.instanceOf(QuotesContext),
  quotingRules: PropTypes.instanceOf(QuotingRules),
  scrollToRideshareCoverage: PropTypes.bool,
  selectedQuote: PropTypes.instanceOf(CustomQuote).isRequired,
  updateBindParams: PropTypes.func,
  updateSelectedQuote: PropTypes.func,
};

export default BindQuoteCustomization;
