import { getQuote } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useQuoteQuery = ({ ratingRequestId }, config = {}) => useQuery({
  enabled: !!ratingRequestId,
  queryFn: async () => await getQuote({
    ratingRequestId,
  }),
  queryKey: ['user', 'quote', ratingRequestId],
  ...config,
});

export default useQuoteQuery;
