import isObject from '@root/vendor/lodash/isObject';

export default class BraintreeResult {
  static success(nonce) {
    return new BraintreeResult({
      nonce,
    });
  }

  static error(error = true) {
    return new BraintreeResult({
      error,
    });
  }

  constructor({ nonce, error } = {}) {
    this.nonce = nonce;
    this.error = error;
  }

  isSuccess() {
    return !this.isError();
  }

  isError() {
    return !!this.error;
  }

  /**
   * Create a "flatter" details object with important info from the error for logging.
   * Flatten the details to just the details' top level attributes that are not objects.
   * If an array is a top level object, it will be included in the results with only primitive data.
   * @returns {Object} - The key attributes of the error and top level attributes of the details object. Object always returned.
   */
  flattenedDetails() {
    if (!this.error) {
      return {};
    }
    if (!this.error.details) {
      return { braintreeDetails: 'none' };
    }

    const details = this.error.details;
    const skinnyDetails = {
      braintreeCode: this.error.code,
      braintreeType: this.error.type,
    };

    // Excluding objects from the processing is important because we do not know the exact
    // structure of a BraintreeError.  When the details are recursively iterated over for logging,
    // a 'Maximum call stack size exceeded' error occurs because of circular references.  By flattening just the
    // top level items, we should still get valuable data, but not have memory problems.
    for (const property in details) {
      if (!isObject(details[property])) {
        skinnyDetails[property] = details[property];
      } else if (Array.isArray(details[property])) {
        const arrayToCopy = details[property];
        const newArray = [];
        for (const item of arrayToCopy) {
          if (!isObject(item)) {
            newArray.push(item);
          }
        }
        if (newArray.length > 0) {
          skinnyDetails[property] = newArray;
        }
      }
    }
    return skinnyDetails;
  }
}
