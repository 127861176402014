import AuthService from '@root/core/src/services/auth-service';
import StorageService from '@root/core/src/services/storage-service';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import environment from '@root/core/src/utils/environment';
import useAssociateTouchAttribution from '@root/attribution/src/hooks/use-associate-touch-attribution';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import useLocalization from '@root/bind.joinroot.com/src/context/localization';
import { useMutation } from '@root/vendor/react-query';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';
import { validateAuthPrefill } from '@root/bind.joinroot.com/src/services/api';

const useValidateAuthPrefillMutation = ({
  onSuccess,
  ...restOptions
}) => {
  const [associateTouchAttribution] = useAssociateTouchAttribution();
  const [, setBranding] = useBranding();
  const [, setLocalization] = useLocalization();
  const rootId = useRootId();
  return useMutation({
    mutationFn: validateAuthPrefill,
    mutationKey: ['user', 'authPrefill'],
    onSuccess: (responseData) => {
      if (responseData?.accessToken) {
        StorageService.clear();

        AuthService.setAccessToken(responseData.accessToken);
        UserSessionService.initializeSessionActivity();
        associateTouchAttribution({
          rootId,
        });
      }

      if (responseData?.bindProfile) {
        AuthService.setCurrentUserContext(responseData.bindProfile);
      }

      if (environment.bindAppServerDrivenBrandingEnabled && responseData?.brandingConfiguration) {
        setBranding(responseData.brandingConfiguration);
      }

      if (environment.bindAppServerDrivenLocalizationEnabled && responseData?.localization) {
        setLocalization(responseData.localization);
      }

      onSuccess?.(responseData);
    },
    retry: false,
    ...restOptions,
  });
};

export default useValidateAuthPrefillMutation;
