import amex from '@root/core/src/assets/card-types/amex.svg';
import applePay from '@root/core/src/assets/card-types/apple-pay.svg';
import cvv from '@root/core/src/assets/card-types/credit-card-cvv.svg';
import dinersClub from '@root/core/src/assets/card-types/diners-club.svg';
import discover from '@root/core/src/assets/card-types/discover.svg';
import elo from '@root/core/src/assets/card-types/elo.svg';
import hiper from '@root/core/src/assets/card-types/hiper.svg';
import hipercard from '@root/core/src/assets/card-types/hipercard.svg';
import jcb from '@root/core/src/assets/card-types/jcb.svg';
import maestro from '@root/core/src/assets/card-types/maestro.svg';
import mastercard from '@root/core/src/assets/card-types/mastercard.svg';
import mir from '@root/core/src/assets/card-types/mir.svg';
import unionpay from '@root/core/src/assets/card-types/unionpay.svg';
import unknown from '@root/core/src/assets/card-types/unknown.svg';
import visa from '@root/core/src/assets/card-types/visa.svg';
import { toKebabCase } from '@root/core/src/utils/strings';

export default class PaymentMethodIconService {
  static paymentTypeIcons = {
    'american-express': amex,
    'diners-club': dinersClub,
    'apple-pay': applePay,
    'master-card': mastercard,
    visa,
    discover,
    jcb,
    unionpay,
    maestro,
    elo,
    hiper,
    hipercard,
    mir,
    cvv,
  };

  static getPaymentMethodIcon(cardType) {
    if (!cardType) { return unknown; }
    cardType = cardType.startsWith('apple-pay') ? 'apple-pay' : cardType;
    cardType = toKebabCase(cardType);
    return this.paymentTypeIcons[cardType] || unknown;
  }
}
