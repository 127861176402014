import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/core/src/components/root-logo';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { StyleSheet } from '@root/core/src/utils/styles';

export const BRANDED_LOGO_ALT_TEXT = 'Auto Insurance powered by Root';

const styles = StyleSheet.create({
  logo: {
    alignItems: 'center',
    flex: '50%',
    justifyContent: 'center',
    marginLeft: 10,
    ...Responsive.lessThanMd({
      justifyContent: 'flex-start',
      marginLeft: 0,
      width: '100%',
    }),
  },
});

const CoBrandedLogo = ({
  className, cssOverrides, forceDefaultLogo,
} = { forceDefaultLogo: false }) => {
  const [branding] = useBranding();

  return (
    <div
      className={className}
      css={[styles.logo, cssOverrides]}
    >
      {
        !forceDefaultLogo && branding?.primaryLogoUrl ?
          <img
            alt={BRANDED_LOGO_ALT_TEXT}
            src={branding.primaryLogoUrl}
          /> :
          <RootLogo />
      }
    </div>
  );
};

CoBrandedLogo.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  cssOverrides: PropTypes.object,
  forceDefaultLogo: PropTypes.bool,
};

export default CoBrandedLogo;
