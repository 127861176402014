import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import TriageCheckmark from '@root/triage/src/components/triage-checkmark';
import TriageContent from '@root/triage/src/components/triage-content';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function TriageSendTextSuccess({
  inline,
}) {
  const styles = getStyles(inline);

  const successContent = () => {
    return (
      <div css={styles.confirmationContainer}>
        <TriageCheckmark />
        <TriageContent>
          You should receive a text message with a link to the app shortly.
        </TriageContent>
      </div>
    );
  };

  return successContent();
}

TriageSendTextSuccess.propTypes = {
  inline: PropTypes.bool,
};

const getStyles = (inline) =>
  inline ?
    StyleSheet.create({
      confirmationContainer: {
        marginTop: 30,
        textAlign: 'center',
      },
      animationContainer: {
        marginRight: -130,
        marginTop: -150,
        marginLeft: -150,
        width: 600,
        ...Responsive.lessThanLg({
          marginRight: -80,
          marginTop: -90,
          marginLeft: -100,
        }),
      },
      heading: {
        ...Theme.displayHeading3(),
      },
      subtext: {
        ...Theme.paragraph1(),
        marginTop: 12,
        marginBottom: 0,
        width: 320,
      },
      phoneNumberInputContainer: {
        marginTop: 20,
      },
      divider: {
        borderBottom: '1px solid #DADADA',
        marginTop: 20,
      },
    }) :
    StyleSheet.create({
      confirmationContainer: {
        textAlign: 'center',
        marginTop: 45,
      },
      phoneNumberInputContainer: {
        marginTop: 45,
        marginBottom: 25,
      },
      animationContainer: {
        marginTop: -100,
        marginBottom: -90,
      },
    });
