import { ACTION_IDS, PAGES } from '@root/bind.joinroot.com/src/pages/constants';

const DEEP_LINK_ACTION_IDS = {
  [ACTION_IDS.ADD_DRIVER]: PAGES.ADD_DRIVER,
  [ACTION_IDS.ADD_VEHICLE]: PAGES.ADD_VEHICLE,
  [ACTION_IDS.CUSTOMIZE_COVERAGES]: PAGES.CUSTOMIZE_COVERAGES,
  [ACTION_IDS.EDIT_DRIVER]: PAGES.EDIT_DRIVER,
  [ACTION_IDS.EDIT_VEHICLE]: PAGES.EDIT_VEHICLE,
  [ACTION_IDS.REMOVE_DRIVER]: PAGES.EDIT_DRIVER,
  [ACTION_IDS.REMOVE_VEHICLE]: PAGES.EDIT_VEHICLE,
};

export class DeepLink {
  constructor({ actionId, actionTarget } = {}) {
    this.actionId = actionId;
    this.actionTarget = actionTarget;
  }

  wasDeepLinked() {
    return !!this.navigateTo();
  }

  navigateTo() {
    return DEEP_LINK_ACTION_IDS[this.actionId];
  }

  serializeForSaving() {
    return { ...this };
  }
}
