import Button from '@root/bind.joinroot.com/src/components/button';
import Link from '@root/core/src/components/link';
import ProfileReviewHeading from '@root/profile-review/src/components/profile-review-heading';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export default function BindRideshareRemovalConfirmation({
  onUpdateRideshare,
  onCancel,
}) {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageRideshare.removalConfirmation');

  const { trackClick } = useAnalytics('RIDESHARE_REMOVAL_CONFIRMATION');
  const [useBrandingState] = useBranding();
  const styles = useMemo(() => stylesGenerator(useBrandingState), [useBrandingState]);

  const handleRemoveCoverageClick = () => {
    trackClick('REMOVE_COVERAGE');
    onUpdateRideshare();
  };

  const handleNevermindClick = () => {
    trackClick('NEVERMIND');
    onCancel();
  };

  return (
    <>
      <ProfileReviewHeading
        styles={styles.modalHeading}
      >
        {strings.confirmationMessage}
      </ProfileReviewHeading>
      <p css={styles.confirmationContent}>
        {strings.description}
      </p>
      <Button
        onClick={handleRemoveCoverageClick}
      >
        {strings.confirmationButton}
      </Button>
      <div css={styles.nevermindLinkContainer}>
        <Link
          css={styles.nevermindLink}
          onClick={handleNevermindClick}
        >
          {strings.nevermind}
        </Link>
      </div>
    </>
  );
}

BindRideshareRemovalConfirmation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdateRideshare: PropTypes.func.isRequired,
};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  modalHeading: {
    marginBottom: 20,
  },
  confirmationContent: {
    ...Theme.paragraph1(),
    marginBottom: 20,
  },
  nevermindLink: {
    marginTop: 20,
    ':hover': {
      color: primaryColor,
    },
  },
  nevermindLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});
