import { useLocation } from '@root/vendor/react-router';

// Small helper for extracting query params from URL location
// Ex: visiting 'account?name=modus-create'
//    const queryParams = useQueryParams();
//    const name = queryParams.get('name') // 'modus-create'

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQueryParams;
