import DobDate from '@root/core/src/models/dob-date';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import ShortDateInputGroup from '@root/core/src/components/short-date-input-group';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class ProfileReviewPurchaseDate extends Component {
  static propTypes = {
    date: PropTypes.instanceOf(DobDate).isRequired,
    errorLabel: PropTypes.string,
    inputStyle: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <h3 css={styles.heading}>
          Vehicle purchase or lease date
        </h3>
        <p css={styles.content}>
          You may be eligible for a better rate depending on how long you’ve owned or leased this vehicle. The closest month is OK.
        </p>
        <ShortDateInputGroup
          date={this.props.date}
          errorLabel={this.props.errorLabel}
          inputStyle={this.props.inputStyle}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading3(),
  },
  content: {
    ...Theme.paragraph2(),
  },
  wrapper: {
    marginTop: 30,
  },
});
