import React from '@root/vendor/react';
import {
  AutoscalingSvg,
  IconProps,
} from '@root/bind.joinroot.com/src/components/icons';

const PhoneIcon = ({
  color = '#00AAD5',
  height = 24,
  width = 24,
  iconStyles,
}) => {
  return (
    <AutoscalingSvg
      css={iconStyles}
      fill={'none'}
      height={height}
      viewBox={'0 0 13 13'}
      width={width}
    >
      <path
        clipRule={'evenodd'}
        d={'M11.9001 9.80494C12.1476 9.98166 12.2086 10.3383 11.9933 10.5536L10.4247 12.1217C10.4247 12.1217 7.71026 12.3594 4.17457 8.82652C0.640493 5.292 0.878348 2.57845 0.878348 2.57845L2.44691 1.0104C2.66226 0.79512 3.0094 0.846531 3.1878 1.09395L4.52814 2.96082C4.70653 3.20823 4.65671 3.57133 4.44136 3.78661L3.29869 4.9289C3.86761 5.72256 4.56671 6.58049 5.49242 7.5075C6.41974 8.4329 7.27634 9.13177 8.07187 9.70051L9.21454 8.55821C9.42989 8.34293 9.81078 8.3108 10.0583 8.48913L11.9001 9.80494Z'}
        fill={color}
        fillRule={'evenodd'}
      />
    </AutoscalingSvg>
  );
};

PhoneIcon.propTypes = IconProps;

export default PhoneIcon;
