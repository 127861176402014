import PropTypes from '@root/vendor/prop-types';
import React, { Component, Fragment } from '@root/vendor/react';

export default class ArrayToText extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
  }

  render() {
    const { children } = this.props;
    const lastIndex = children.length - 1;

    return (
      children.map((el, index) => {
        if (index === lastIndex) {
          return el;
        }
        return <Fragment key={index}>{el}<br /></Fragment>;
      })
    );
  }
}
