import BindProfileParams from '@root/bind.joinroot.com//src/models/bind-profile-params';
import PropTypes from '@root/vendor/prop-types';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'bind_profile_params';

function useBindProfileParams({
  bindProfile,
}) {
  const [profileParams, setProfileParams] = usePersistedState({
    deserialize: (persistedItem) => BindProfileParams.buildFromData(persistedItem),
    initialValue: () => BindProfileParams.buildFromBindProfile(bindProfile),
    serialize: (bindProfileParams) => bindProfileParams.serializeForSaving(),
    storageKey: LOCAL_STORAGE_KEY,
  });

  return [profileParams, setProfileParams];
}

useBindProfileParams.propTypes = {
  bindProfile: PropTypes.object.isRequired,
};

export default useBindProfileParams;
