import React from '@root/vendor/react';
import {
  AutoscalingSvg,
  IconProps,
} from '@root/bind.joinroot.com/src/components/icons';
import { Colors } from '@root/core/src/utils/styles';

const ChevronUp = ({
  color = Colors.black(),
  height = 18,
  width = 18,
  ...restProps
}) => {
  return (
    <AutoscalingSvg
      fill={'none'}
      height={height}
      viewBox={'0 0 48 48'}
      width={width}
      {...restProps}
    >
      <path
        d={'M 24,13.3 3.3,34 4.7,35.4 24,16.1 43.3,35.4 44.7,34 Z'}
        fill={color}
      />
    </AutoscalingSvg>
  );
};

ChevronUp.propTypes = IconProps;

export default ChevronUp;
