import DateInput from '@root/core/src/components/date-input';
import InputError from '@root/core/src/components/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import noop from '@root/vendor/lodash/noop';
import useInputFocusOnMount from '@root/core/src/hooks/use-input-focus-on-mount';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { TextTypes } from '@root/core/src/components/input';
import { obfuscate } from '@root/core/src/utils/strings';

const DAYS = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16',
  '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
];

const MONTHS = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
];

function onlyPossibleDatePart(datePart, allParts) {
  return allParts.filter((part) => part.startsWith(datePart.toString())).length === 1;
}

export default function DateInputGroup({
  date, disabled = false, errorLabel, isObfuscated, onBlur = () => noop, onChange, onFocus = noop, onValidYear = noop, inputStyle, focusOnMount = false,
}) {
  const [obfuscatedValues, setObfuscatedValues] = useState({});
  const [prevDate, setPrevDate] = useState(date);
  const [touched, setTouched] = useState({
    month: false,
    day: false,
    year: false,
  });

  const dayInput = useRef();
  const yearInput = useRef();

  const inputRef = useInputFocusOnMount(focusOnMount);

  useEffect(() => {
    if (date.isSameDate(prevDate)) { return; }

    const finishedEntryHandlers = {
      month: () => date.isValidMonth() && onlyPossibleDatePart(date.month, MONTHS) && dayInput.current.focus(),
      day: () => date.isValidDay() && onlyPossibleDatePart(date.day, DAYS) && yearInput.current.focus(),
      year: () => date.isValidYear() && onValidYear(),
    };

    ['month', 'day', 'year'].find((datePartName) => date[datePartName] !== prevDate[datePartName] && finishedEntryHandlers[datePartName]());
    setPrevDate(date);
  }, [date, onValidYear, prevDate]);

  const isDatePartObfuscated = (datePartName) => {
    return Array.isArray(isObfuscated)
      ? isObfuscated?.indexOf(datePartName) >= 0
      : !!isObfuscated;
  };

  const getDatePartDisplayValue = (datePartName) => {
    if (!date[datePartName]) {
      return '';
    }

    if (isDatePartObfuscated(datePartName)) {
      if (obfuscatedValues[datePartName] !== undefined) {
        return obfuscatedValues[datePartName];
      }
      return obfuscate(date[datePartName], {
        numVisible: 0,
        minLength: 2,
      });
    }

    return date[datePartName];
  };

  const setObfuscatedValueIfObfuscated = ({
    datePartName, value, isOnlyUpdateIfUndefined = false,
  }) => {
    if (isDatePartObfuscated(datePartName)) {
      if (isOnlyUpdateIfUndefined && obfuscatedValues[datePartName] !== undefined) { return; }

      setObfuscatedValues({
        ...obfuscatedValues,
        [datePartName]: value,
      });
    }
  };

  const handleFocus = (datePartName) => () => {
    setObfuscatedValueIfObfuscated({
      datePartName,
      value: '',
      isOnlyUpdateIfUndefined: true,
    });
    onFocus?.(datePartName);
  };

  const handleChange = (datePartName) => (value) => {
    setObfuscatedValueIfObfuscated({
      datePartName,
      value,
    });
    onChange(datePartName)(value);
  };

  const handleBlur = (datePartName) => (value) => {
    setTouched({
      ...touched,
      [datePartName]: true,
    });
    setObfuscatedValueIfObfuscated({
      datePartName,
      value: value || undefined,
    });
    onBlur && onBlur(datePartName)(value);
  };

  const getIsUseNumberKeyboard = (datePartName) => !isDatePartObfuscated(datePartName) || obfuscatedValues[datePartName] !== undefined;

  return (
    <>
      <div css={styles.dateInputGroupWrapper}>
        <DateInput
          disabled={disabled}
          inputStyle={[styles.leftInput, inputStyle]}
          inputType={TextTypes.TEL}
          isError={!!errorLabel || !!(date.month && !date.isValidMonth()) && touched.month}
          isUseNumberKeyboard={getIsUseNumberKeyboard('month')}
          label={'MM'}
          maxLength={'2'}
          onBlur={handleBlur('month')}
          onChange={handleChange('month')}
          onFocus={handleFocus('month')}
          ref={inputRef}
          value={getDatePartDisplayValue('month')}
        />
        <DateInput
          disabled={disabled}
          inputStyle={[styles.centerInput, inputStyle]}
          inputType={TextTypes.TEL}
          isError={!!errorLabel || !!(date.day && !date.isValidDay()) && touched.day}
          isUseNumberKeyboard={getIsUseNumberKeyboard('day')}
          label={'DD'}
          maxLength={'2'}
          onBlur={handleBlur('day')}
          onChange={handleChange('day')}
          onFocus={handleFocus('day')}
          ref={dayInput}
          value={getDatePartDisplayValue('day')}
        />
        <DateInput
          disabled={disabled}
          inputStyle={[styles.rightInput, inputStyle]}
          inputType={TextTypes.TEL}
          isError={!!errorLabel || !!(date.year && !date.isValidYear()) && touched.year}
          isUseNumberKeyboard={getIsUseNumberKeyboard('year')}
          label={'YYYY'}
          maxLength={'4'}
          onBlur={handleBlur('year')}
          onChange={handleChange('year')}
          onFocus={handleFocus('year')}
          ref={yearInput}
          value={getDatePartDisplayValue('year')}
        />
      </div>
      {errorLabel && <InputError message={errorLabel} />}
    </>
  );
}

DateInputGroup.propTypes = {
  date: PropTypes.shape({
    isSameDate: PropTypes.func.isRequired,
    isValidYear: PropTypes.func.isRequired,
    isValidDay: PropTypes.func.isRequired,
    isValidMonth: PropTypes.func.isRequired,
    month: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,

  }),
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string,
  focusOnMount: PropTypes.bool,
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isObfuscated: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onValidYear: PropTypes.func,
};

const styles = StyleSheet.create({
  dateInputGroupWrapper: {
    display: 'flex',
  },
  leftInput: {
    ...Theme.roundedCorners(),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ':disabled': {
      borderRight: `1px solid ${Colors.gray40()}`,
    },
  },
  centerInput: {
    borderRadius: 0, // needed for iOS Safari
  },
  rightInput: {
    ...Theme.roundedCorners(),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    ':disabled': {
      borderLeft: `1px solid ${Colors.gray40()}`,
    },
  },
  errorLabel: {
    color: Colors.error(),
    paddingTop: 10,
  },
});
