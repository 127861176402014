import { createPrefillRequest } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useCreatePrefillRequestMutation = (options = {}) => {
  return useMutation({
    mutationFn: createPrefillRequest,
    mutationKey: ['prefill', 'create'],
    ...options,
  });
};

export default useCreatePrefillRequestMutation;
