export default class AutoQuoteDisclaimer {
  static build({
    id, acknowledgedAt, affirmedAt, url, name,
  } = {}) {
    return Object.assign(
      new AutoQuoteDisclaimer(),
      {
        acknowledgedAt,
        affirmedAt,
        id,
        name,
        url,
      },
    );
  }

  constructor() {
    this.acknowledgedAt = null;
    this.affirmedAt = null;
    this.id = null;
    this.name = null;
    this.url = null;
  }

  set(key, value) {
    return Object.assign(
      new AutoQuoteDisclaimer(),
      this,
      {
        [key]: value,
      },
    );
  }

  isAcknowledged() {
    return !!this.acknowledgedAt;
  }

  isAffirmed() {
    return !!this.affirmedAt;
  }
}
