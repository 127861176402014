import { getQuoteCoveragesForAllBackendOptions } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useQuoteCoveragesForAllBackendOptionsQuery = ({ manualTortMarket, quoteId }, overrides = {}) => useQuery({
  enabled: !!quoteId && !!manualTortMarket,
  queryFn: async () => await getQuoteCoveragesForAllBackendOptions({
    quoteId,
  }),
  queryKey: ['user', 'quote', quoteId, 'coverages'],
  ...overrides,
});

export default useQuoteCoveragesForAllBackendOptionsQuery;
