import Driver from '@root/auto-pricing/src/models/driver';
import Market from '@root/core/src/models/market';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import ToggleField from '@root/profile/src/components/edit-driver/toggle-field';
import {
  showAccidentPreventionCourse,
  showActiveDutyMilitary,
  showCollegeGraduateDiscount,
  showGoodStudentDiscount,
  showNationalGuardMember,
  showRefresherPreventionCourse,
} from '@root/profile/src/utils/driver-flag-rules';

export default class ProfileDriverStateSpecificRequirements extends Component {
  static propTypes = {
    circleSelectedStyles: PropTypes.object,
    driver: PropTypes.instanceOf(Driver).isRequired,
    market: PropTypes.oneOf(Object.keys(Market.MAPPING)),
    onChange: PropTypes.func.isRequired,
    profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
    toggleSelectedStyles: PropTypes.object,
  }

  _renderAccidentPreventionCourseField(driver) {
    if (!showAccidentPreventionCourse(this.props.profileRulesContext, this.props.driver)) {
      return null;
    }

    const accidentPreventionCourseText = this.props.market === 'NV' ? 'Defensive driving course' : 'Accident prevention course';

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.accidentPreventionCourse}
        onChange={this.props.onChange('accidentPreventionCourse')}
        testID={'accidentPreventionCourse'}
        text={accidentPreventionCourseText}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  _renderActiveDutyMilitaryField(driver) {
    if (!showActiveDutyMilitary(this.props.profileRulesContext, this.props.driver)) {
      return null;
    }

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.activeDutyMilitary}
        onChange={this.props.onChange('activeDutyMilitary')}
        testID={'activeDutyMilitary'}
        text={'Active duty military'}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  _renderCollegeGraduateDiscountField(driver) {
    if (!showCollegeGraduateDiscount(this.props.profileRulesContext, this.props.driver)) {
      return null;
    }

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.collegeGraduate}
        onChange={this.props.onChange('collegeGraduate')}
        secondaryText={'Have you graduated from college with a B average or better? (Proof may be required)'}
        testID={'collegeGraduate'}
        text={'College graduate discount'}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  _renderGoodStudentDiscountField(driver) {
    if (!showGoodStudentDiscount(this.props.profileRulesContext, this.props.driver)) {
      return null;
    }

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.goodStudent}
        onChange={this.props.onChange('goodStudent')}
        secondaryText={'Are you a full-time student with a B average or better? (Proof may be required)'}
        testID={'goodStudent'}
        text={'Student discount'}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  _renderNationalGuardMemberField(driver) {
    if (!showNationalGuardMember(this.props.profileRulesContext, this.props.driver)) {
      return null;
    }

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.nationalGuardMember}
        onChange={this.props.onChange('nationalGuardMember')}
        testID={'nationalGuardMember'}
        text={'National guard member'}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  _renderRefresherPreventionCourseField(driver) {
    if (!showRefresherPreventionCourse(this.props.profileRulesContext)) {
      return null;
    }

    return (
      <ToggleField
        circleSelectedStyles={this.props.circleSelectedStyles}
        isSelected={!!driver.refresherPreventionCourse}
        onChange={this.props.onChange('refresherPreventionCourse')}
        testID={'refresherPreventionCourse'}
        text={'Refresher prevention course'}
        toggleSelectedStyles={this.props.toggleSelectedStyles}
      />
    );
  }

  render() {
    return (
      <div>
        {this._renderAccidentPreventionCourseField(this.props.driver)}
        {this._renderActiveDutyMilitaryField(this.props.driver)}
        {this._renderCollegeGraduateDiscountField(this.props.driver)}
        {this._renderGoodStudentDiscountField(this.props.driver)}
        {this._renderNationalGuardMemberField(this.props.driver)}
        {this._renderRefresherPreventionCourseField(this.props.driver)}
      </div>
    );
  }
}
