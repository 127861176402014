import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { AutoscalingSvg, SvgProps } from '@root/bind.joinroot.com/src/components/icons';
import { CARD_HEIGHT, CARD_WIDTH } from '@root/bind.joinroot.com/src/components/insurance-card';
import { Colors, Shadows } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const Front = ({
  firstName,
  height = CARD_HEIGHT,
  lastName,
  width = CARD_WIDTH,
  ...restProps
}) => {
  const strings = useI18nNext('components.insuranceCard.front');
  return (
    <AutoscalingSvg
      css={{
        backgroundColor: Colors.white(),
        ...Shadows.sidesAndBottom(),
        borderRadius: 10,
        border: `1px solid ${Colors.gray40()}`,
      }}
      data-testid={'insurance-card--front'}
      height={height}
      viewBox={'0 0 366 205'}
      width={width}
      {...restProps}
    >
      <mask
        height={'663'}
        id={'mask0'}
        mask-type={'alpha'}
        maskUnits={'userSpaceOnUse'}
        width={'711'}
        x={'-53'}
        y={'-364'}
      >
        <path
          clipRule={'evenodd'}
          d={'M156.084 -363.508C40.2557 -363.508 -52.1492 -266.758 -52.1492 -149.283C-52.1492 -31.807 40.2557 64.9432 156.084 64.9432C187.9 64.9432 217.949 57.6434 244.801 44.6194C242.475 57.4304 241.26 70.6285 241.26 84.0992C241.26 201.575 333.666 298.325 449.496 298.325C565.326 298.325 657.733 201.575 657.733 84.0992C657.733 -33.377 565.326 -130.127 449.496 -130.127C417.679 -130.127 387.629 -122.827 360.777 -109.802C363.103 -122.613 364.318 -135.812 364.318 -149.283C364.318 -266.758 271.913 -363.508 156.084 -363.508ZM2.03954 -149.283C2.03954 -238.507 71.8322 -309.32 156.084 -309.32C240.336 -309.32 310.129 -238.507 310.129 -149.283C310.129 -60.0586 240.336 10.7545 156.084 10.7545C71.8322 10.7545 2.03954 -60.0586 2.03954 -149.283ZM295.448 84.0992C295.448 -5.12442 365.242 -75.938 449.496 -75.938C533.751 -75.938 603.544 -5.12442 603.544 84.0992C603.544 173.323 533.751 244.136 449.496 244.136C365.242 244.136 295.448 173.323 295.448 84.0992Z'}
          fill={Colors.white()}
          fillOpacity={'0.3'}
          fillRule={'evenodd'}
          transform={'scale(0.65),translate(100,50)'}
        />
      </mask>

      <g
        clipPath={'url(#rootLogoRClip)'}
        transform={'scale(0.45),translate(30, 30)'}
        x={'350'}
        y={'30'}
      >
        <path
          d={'M61.0476 0H18.9524C8.4852 0 0 8.4852 0 18.9524V61.048C0 71.5148 8.4852 80 18.9524 80H61.048C71.5152 80 80.0004 71.5148 80.0004 61.0476V18.9524C80 8.4852 71.5148 0 61.0476 0Z'}
          fill={Colors.rootOrange()}
        />
        <path
          d={'M49.5023 45.2137L50.3063 44.8105C55.4139 42.2425 58.3435 37.7261 58.3435 32.4209C58.3435 28.4069 57.0543 25.2469 54.5123 23.0313C51.4275 20.3405 46.4543 18.9773 39.7299 18.9773C34.1623 18.9773 27.1339 19.9213 24.2959 20.3181V60.6525H35.3975V46.9497H40.1323L47.9887 60.6525H60.2963L49.5023 45.2137ZM39.4723 40.2637H35.3975V27.0837L35.8235 27.0117C36.7543 26.8533 38.1395 26.7461 39.2719 26.7461C44.0979 26.7461 46.7567 29.1445 46.7567 33.4977C46.7583 37.3553 43.6255 40.2637 39.4723 40.2637Z'}
          fill={Colors.white()}
        />
      </g>
      <g mask={'url(#mask0)'}>
        <g filter={'url(#filter0_f)'}>
          <path
            d={'M247.892 -43.6546H430.066L224.002 272.201H41.8289L247.892 -43.6546Z'}
            fill={'url(#paint0_linear)'}
          />
        </g>
        <g filter={'url(#filter1_f)'}>
          <path
            d={'M508.548 -32.3728H592.904L392.355 271.634H308L508.548 -32.3728Z'}
            fill={'url(#paint1_linear)'}
          />
        </g>
      </g>
      <g
        fill={'none'}
        fillRule={'evenodd'}
        fontFamily={'Helvetica'}
        fontSize={'20'}
        fontWeight={'bold'}
        stroke={'none'}
        strokeWidth={'1'}
      >
        <g fill={Colors.gray60()}>
          <text>
            <tspan
              x={'63'}
              y={'38'}
            >
              {strings.card}
            </tspan>
          </text>
        </g>
      </g>
      <g
        fill={'none'}
        fillRule={'evenodd'}
        fontFamily={'Helvetica'}
        fontSize={'30'}
        fontWeight={'bold'}
        stroke={'none'}
        strokeWidth={'1'}
      >
        <g fill={Colors.nearBlack()}>
          <text>
            <tspan
              x={'20'}
              y={'110'}
            >
              {firstName}
            </tspan>
            <tspan
              x={'20'}
              y={'145'}
            >
              {lastName}
            </tspan>
          </text>
        </g>
      </g>

      <defs>
        <clipPath id={'rootLogoRClip'}>
          <rect
            fill={Colors.white()}
            height={'80'}
            transform={'matrix(1 0 0 -1 0 80)'}
            width={'80'}
          />
        </clipPath>
        <filter
          colorInterpolationFilters={'sRGB'}
          filterUnits={'userSpaceOnUse'}
          height={'548.463'}
          id={'filter0_f'}
          width={'620.845'}
          x={'-74.4752'}
          y={'-159.959'}
        >
          <feFlood
            floodOpacity={'0'}
            result={'BackgroundImageFix'}
          />
          <feBlend
            in={'SourceGraphic'}
            in2={'BackgroundImageFix'}
            mode={'normal'}
            result={'shape'}
          />
          <feGaussianBlur
            result={'foregroundBlur'}
            stdDeviation={'58.1521'}
          />
        </filter>
        <filter
          colorInterpolationFilters={'sRGB'}
          filterUnits={'userSpaceOnUse'}
          height={'536.615'}
          id={'filter1_f'}
          width={'517.512'}
          x={'191.695'}
          y={'-148.677'}
        >
          <feFlood
            floodOpacity={'0'}
            result={'BackgroundImageFix'}
          />
          <feBlend
            in={'SourceGraphic'}
            in2={'BackgroundImageFix'}
            mode={'normal'}
            result={'shape'}
          />
          <feGaussianBlur
            result={'foregroundBlur'}
            stdDeviation={'58.1521'}
          />
        </filter>
        <linearGradient
          gradientUnits={'userSpaceOnUse'}
          id={'paint0_linear'}
          x1={'311.971'}
          x2={'245.195'}
          y1={'-43.6546'}
          y2={'286.033'}
        >
          <stop
            offset={'0.28125'}
            stopColor={'#A4F9FF'}
          />
          <stop
            offset={'0.53125'}
            stopColor={'#97FF95'}
          />
          <stop
            offset={'0.875'}
            stopColor={'#FFEF5C'}
          />
        </linearGradient>
        <linearGradient
          gradientUnits={'userSpaceOnUse'}
          id={'paint1_linear'}
          x1={'533.856'}
          x2={'463.387'}
          y1={'-32.3728'}
          y2={'287.291'}
        >
          <stop stopColor={'#FFCB8E'} />
          <stop
            offset={'1'}
            stopColor={'#C05AFF'}
          />
        </linearGradient>
      </defs>
    </AutoscalingSvg>
  );
};

export const FrontPropTypes = {
  ...SvgProps,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

Front.propTypes = FrontPropTypes;

export default Front;
