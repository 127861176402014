import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindRoutes from '@root/bind.joinroot.com/src/components/routers/bind-routes';
import BindWelcomeEntry from '@root/bind.joinroot.com/src/pages/bind-welcome-entry';
import BridgeLoadingEntry from '@root/bind.joinroot.com/src/pages/bridge-loading-entry';
import MissingAuthEntry from '@root/bind.joinroot.com/src/pages/missing-auth-entry';
import ProfileProtectedRoute from '@root/bind.joinroot.com/src/components/routers/profile-protected-route';
import React, { useCallback, useState } from '@root/vendor/react';
import { BrandingProvider } from '@root/bind.joinroot.com/src/context/branding';
import { DeepLinkProvider } from '@root/bind.joinroot.com/src/context/deep-link';
import { FunnelStages } from '@root/core/src/models/funnel-stages';
import { LocalizationProvider } from '@root/bind.joinroot.com/src/context/localization';
import { ProfileProvider } from '@root/bind.joinroot.com/src/context/profile';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from '@root/core/src/components/route';

const DEFAULT_BILLING_CYCLE = BillingCycle.MONTHLY;

const PATHS = {
  BIND: '/bind',
  BRIDGE: '/bridge',
  MISSING_AUTH: '/',
  WELCOME: '/welcome',
};

const AppRouter = () => {
  const [initialRatingRequestId, setInitialRatingRequestId] = useState();

  const history = useHistory();

  const navigateToBind = useCallback(() => {
    history.replace(PATHS.BIND);
  }, [history]);

  const navigateToWelcome = useCallback(() => {
    history.replace(PATHS.WELCOME);
  }, [history]);

  const handleUnauthenticated = useCallback(() => {
    history.replace(PATHS.MISSING_AUTH);
  }, [history]);

  return (
    <BrandingProvider>
      <LocalizationProvider>
        <DeepLinkProvider>
          <ProfileProvider>
            <Switch>
              <ProfileProtectedRoute
                path={PATHS.BIND}
                redirectUnauthorizedToPath={PATHS.BRIDGE}
              >
                <BindRoutes
                  defaultBillingCycle={DEFAULT_BILLING_CYCLE}
                  funnelStage={FunnelStages.EMBEDDED}
                  initialRatingRequestId={initialRatingRequestId}
                />
              </ProfileProtectedRoute>
              <Route
                funnelStage={FunnelStages.EMBEDDED}
                path={PATHS.WELCOME}
              >
                <BindWelcomeEntry
                  onWelcomed={navigateToBind}
                />
              </Route>
              <Route
                funnelStage={FunnelStages.EMBEDDED}
                path={PATHS.BRIDGE}
              >
                <BridgeLoadingEntry
                  navigateToBind={navigateToBind}
                  navigateToWelcome={navigateToWelcome}
                  onLoadingError={handleUnauthenticated}
                  setRatingRequestId={setInitialRatingRequestId}
                />
              </Route>
              <Route
                funnelStage={FunnelStages.EMBEDDED}
                path={PATHS.MISSING_AUTH}
              >
                <MissingAuthEntry />
              </Route>
              <Route
                funnelStage={FunnelStages.EMBEDDED}
              >
                <Redirect to={PATHS.MISSING_AUTH} />
              </Route>
            </Switch>
          </ProfileProvider>
        </DeepLinkProvider>
      </LocalizationProvider>
    </BrandingProvider>
  );
};

AppRouter.propTypes = {};

export default AppRouter;
