import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import CoBrandedLogo from '@root/bind.joinroot.com/src/components/bind-view/header/co-branded-logo';
import LoadingCarousel from '@root/bind.joinroot.com/src/components/carousel/loading-carousel';
import PollerAnimation from '@root/core/src/components/poller-animation';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import UnderwritingDecision from '@root/quotes/src/models/underwriting-decision';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import usePrefillRequestQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-prefill-request-query';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useDeepLinkFlow } from '@root/bind.joinroot.com/src/context/deep-link';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const styles = StyleSheet.create({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    ...Responsive.md({
      maxWidth: Responsive.BREAKPOINTS.sm,
    }),
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 50,

    ...Responsive.lessThanMd({
      paddingBottom: 10,
    }),

    img: {
      height: '50px',
      maxWidth: '250px',
      objectFit: 'contain',
    },
    svg: {
      height: '50px',
      maxWidth: '250px',
      objectFit: 'contain',
    },
  },
  containerShared: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',

    ...Responsive.lessThanMd({
      alignItems: 'center',
    }),
  },
  pollerContainer: {
    alignItems: 'center',
    ' > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    ...Responsive.lessThanMd({
      height: '50vh',
    }),
  },
  infoContainer: {
    ...Responsive.lessThanMd({
      height: '20vh',
      padding: '0 1rem',
      paddingBottom: '2rem',
    }),
  },
  carouselContainer: {
    alignItems: 'flex-start',
    ...Responsive.lessThanMd({
      alignItems: 'flex-start',
      height: '30vh',
      padding: '0 1rem',
    }),
  },
  h3: {
    ...Theme.heading3(),
  },
});

const bindViewCssOverrides = {
  content: styles.content,
};

const BindLoadingEntry = ({
  createQuote,
  didCreateQuote,
  isCreatingQuote,
  isQuoteCurrent,
  onQuoteReady,
  onQuoteUnderwritingStatusReady,
  quotesContext,
  readyToCustomizeQuote,
  shouldCallSuccessUrl,
  shouldWaitForPrefill,
}) => {
  useAnalytics('BIND_LOADING_ENTRY');
  const { completeFlow } = useDeepLinkFlow();

  const strings = useI18nNext('pages.bindLoading');

  const [enablePrefillRequestQuery, setEnablePrefillRequestQuery] = useState(shouldWaitForPrefill);
  const [shouldRecreateQuote, setShouldRecreateQuote] = useState(!shouldWaitForPrefill && !isQuoteCurrent);
  /**
   * Setup custom quote creation & our queries for prefill status
   */

  usePrefillRequestQuery({
    enabled: enablePrefillRequestQuery,
    onSuccess: (prefillData) => {
      if (prefillData) {
        setShouldRecreateQuote(true);
        setEnablePrefillRequestQuery(false);
      }
    },
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (shouldRecreateQuote && !isCreatingQuote && !didCreateQuote) {
      createQuote({
        onSuccess: () => {
          if (shouldCallSuccessUrl) {
            completeFlow();
          }
          setShouldRecreateQuote(false);
        },
      });
    }
  }, [completeFlow, createQuote, didCreateQuote, isCreatingQuote, setShouldRecreateQuote, shouldRecreateQuote, shouldCallSuccessUrl]);

  useEffect(() => {
    if (quotesContext?.underwritingStatus !== UnderwritingDecision.Status.APPROVED) {
      onQuoteUnderwritingStatusReady(quotesContext?.underwritingStatus);
    } else if (readyToCustomizeQuote) {
      onQuoteReady();
    }
  }, [onQuoteUnderwritingStatusReady, quotesContext, readyToCustomizeQuote, onQuoteReady]);

  const loadingAnimation = shouldCallSuccessUrl ? null : (
    <BindView
      fullWhite
      cssOverrides={bindViewCssOverrides}
    >
      <div css={[styles.containerShared, styles.pollerContainer]}>
        <PollerAnimation />
      </div>
      <div css={[styles.containerShared, styles.infoContainer]}>
        <CoBrandedLogo cssOverrides={styles.logo} />
        <h3
          css={styles.h3}
        >
          {strings.personalizing}
        </h3>
      </div>
      <div css={[styles.containerShared, styles.carouselContainer]}>
        <LoadingCarousel
          items={Object.entries(strings.loadingDetails).map(([, v]) => v)}
        />
      </div>
    </BindView>
  );

  return loadingAnimation;
};

BindLoadingEntry.propTypes = {
  createQuote: PropTypes.func.isRequired,
  didCreateQuote: PropTypes.bool.isRequired,
  isCreatingQuote: PropTypes.bool.isRequired,
  isQuoteCurrent: PropTypes.bool.isRequired,
  onQuoteReady: PropTypes.func.isRequired,
  onQuoteUnderwritingStatusReady: PropTypes.func.isRequired,
  quotesContext: PropTypes.object,
  readyToCustomizeQuote: PropTypes.bool.isRequired,
  shouldCallSuccessUrl: PropTypes.bool.isRequired,
  shouldWaitForPrefill: PropTypes.bool.isRequired,
};

export default BindLoadingEntry;
