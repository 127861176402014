export const CARD_HEIGHT = 205;
export const CARD_WIDTH = 366;
export const CARD_SCALE_XY = CARD_WIDTH / CARD_HEIGHT;
export const CARD_SCALE_YX = CARD_HEIGHT / CARD_WIDTH;

import Back, { BackPropTypes } from '@root/bind.joinroot.com/src/components/insurance-card/back';
import Front, { FrontPropTypes } from '@root/bind.joinroot.com/src/components/insurance-card/front';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';
import { motion, useAnimation } from '@root/vendor/framer-motion';

const styles = StyleSheet.create({
  card: {
    position: 'absolute',
  },
  cardContainer: {
    position: 'relative',
  },
});

const InsuranceCard = ({
  cssOverrides = {},
  firstName,
  flipped,
  flipDuration = 0.5,
  height = CARD_HEIGHT,
  lastName,
  partnerName,
  width = CARD_WIDTH,
}) => {
  const backAnimationControls = useAnimation();
  const frontAnimationControls = useAnimation();
  const transition = useMemo(() => ({ duration: flipDuration }), [flipDuration]);

  useEffect(() => {
    backAnimationControls.start({
      opacity: flipped ? 1 : 0,
      rotateX: flipped ? 0 : -180,
      transition,
    });
    frontAnimationControls.start({
      opacity: flipped ? 0 : 1,
      rotateX: flipped ? 180 : 0,
      transition,
    });
  }, [backAnimationControls, flipped, frontAnimationControls, transition]);

  const backInitialAnimation = useMemo(() => ({
    opacity: flipped ? 100 : 0,
    rotateX: flipped ? 0 : -180,
  }), [flipped]);

  const frontInitialAnimation = useMemo(() => ({
    opacity: flipped ? 0 : 100,
    rotateX: flipped ? 180 : 0,
  }), [flipped]);

  return (
    <div
      css={[
        cssOverrides,
        styles.cardContainer,
        {
          height,
          width,
        },
      ]}
      data-testid={'insurance-card'}
    >
      <motion.div
        animate={backAnimationControls}
        css={styles.card}
        initial={backInitialAnimation}
      >
        <Back
          animationDelay={flipDuration * 100 + 3000}
          height={height}
          partnerName={partnerName}
          width={width}
        />
      </motion.div>
      <motion.div
        animate={frontAnimationControls}
        css={styles.card}
        initial={frontInitialAnimation}
      >
        <Front
          firstName={firstName}
          height={height}
          lastName={lastName}
          width={width}
        />
      </motion.div>
    </div>
  );
};

InsuranceCard.propTypes = {
  ...BackPropTypes,
  ...FrontPropTypes,
  cssOverrides: PropTypes.object,
  flipDuration: PropTypes.number,
  flipped: PropTypes.bool,
};

export default InsuranceCard;
