import BaseSelect from '@root/core/src/components/select';
import React, { useMemo } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const Select = (props) => {
  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  return (
    <BaseSelect
      dropdownIndicator={branding?.chevronDown}
      dropdownIndicatorColor={branding?.primaryColor}
      optionStyles={styles}
      shouldRenderNativeMobile={false}
      {...props}
    />
  );
};

Select.propTypes = BaseSelect.propTypes;

export const stylesGenerator = ({ primaryColor }, restStyles = {}) => StyleSheet.create({
  ':hover, :hover > span': {
    background: primaryColor,
    color: Colors.white(),
  },
  ...restStyles,
});

export default Select;
