import Button from '@root/core/src/components/button';
import Modal from '@root/core/src/components/modal';
import Money from '@root/core/src/models/money';
import ProgressBar from '@root/core/src/components/progress-bar';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import { BUTTON_VARIANT_PRIMARY } from '@root/core/src/components/button/styles';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function FirstPaymentExplainerModal({
  pricesInDollars,
  totalInDollars,
  hasUncollectedPremium = false,
  isShowing = false,
  dismiss,
  showFirstPaymentLowerCopy,
  shouldShowAsterisks,
  showPaymentBreakdown = () => {},
  styleOverrides = {},
}) {
  const _handleDismiss = () => {
    dismiss();
  };

  const _fillPercentageFromRatio = () => {
    const variant = pricesInDollars[0] / (totalInDollars / pricesInDollars.length);
    return 100 / pricesInDollars.length * variant;
  };

  const _generateMonthList = () => {
    const monthLabels = shouldShowAsterisks
      ? ['Month 1', 'Month 2', 'Month 3*', 'Month 4*', 'Month 5*', 'Month 6*']
      : ['Month 1', 'Month 2', 'Month 3', 'Month 4', 'Month 5', 'Month 6'];

    const paymentBreakdownLink = hasUncollectedPremium ? (
      <>
        {' ('}
        <a
          css={[styles.thinText, styles.textLink]}
          href={'#'}
          onClick={showPaymentBreakdown}
        >
          view breakdown
        </a>
        {')'}
      </>
    ) : null;

    return pricesInDollars.map((price, i) => {
      return (
        <div
          css={[styles.row, styles.horizontalDivider]}
          key={`month-row-${i}`}
        >
          <div css={styles.thinText}>{monthLabels[i]}{i === 0 ? paymentBreakdownLink : null}</div>
          <div css={[styles.priceLabel, styles.center]}>{Money.fromDollars(price).formattedDollarsAndCents()}</div>
        </div>
      );
    });
  };

  const csLink = (
    <a
      css={[styles.csLink, styleOverrides.csLink]}
      href={`${environment.websiteBaseUrl}/contact/`}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Customer Support
    </a>
  );

  const titleCopy = () => {
    if (showFirstPaymentLowerCopy) {
      return 'Why is my first payment lower?';
    } else {
      return 'Why is my first payment different?';
    }
  };

  const bodyCopy = () => {
    if (showFirstPaymentLowerCopy) {
      return 'We want to make your first month with Root as easy as possible. That’s why we start off with a lower first payment. We want to give your budget time to best position your finances.';
    } else if (hasUncollectedPremium) {
      return 'We collect more up front as a down payment towards your premium, applicable fees, and any unpaid premium from your previous policy.';
    } else {
      return 'Your first month payment could be higher/lower than subsequent payments due to down payment requirements and applicable fees.';
    }
  };

  return (
    <Modal
      isShowing={isShowing}
      name={'payment-breakdown-modal'}
      onCancel={_handleDismiss}
      responsiveCloseButton={false}
      showCloseButton={true}
      styleOverrides={styles}
    >
      <div>
        <h1 css={styles.heading1}>{titleCopy()}</h1>
        <p css={styles.body}>{bodyCopy()}</p>
        <div css={styles.breakdownContainer}>
          <h4 css={styles.heading4}>Your monthly payment breakdown</h4>
          <ProgressBar
            bottomText={`${Money.fromDollars(pricesInDollars[0]).formattedDollarsAndCents()} due today`}
            fillPercent={_fillPercentageFromRatio()}
            rightLabel={Money.fromDollars(totalInDollars).formattedDollarsAndCents()}
            styleOverrides={styleOverrides}
          />
          <div css={styles.list}>
            {_generateMonthList()}
            <div css={styles.row}>
              <div css={styles.totalLabel}>
                {shouldShowAsterisks ? 'Total*' : 'Total'}
              </div>
              <div css={[styles.totalPrice, styles.center]}>{Money.fromDollars(totalInDollars).formattedDollarsAndCents()}</div>
            </div>
          </div>
          {
            shouldShowAsterisks &&
              <p css={styles.thinText}>*We’ll insure you for up to 60 days if you purchase a quote from Root. We haven’t included your driving behavior in this quote. You’ll get a new price after you’ve driven enough.</p>
          }
        </div>
        <h4 css={styles.heading4}>Still have questions?</h4>
        <p css={styles.body}>You can reach out to our {csLink} team.
        </p>
        <Button
          css={[styles.button, styleOverrides.modalCloseButton]}
          onClick={_handleDismiss}
          variant={BUTTON_VARIANT_PRIMARY}
        >
          Dismiss
        </Button>
      </div>
    </Modal>
  );
}

FirstPaymentExplainerModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  hasUncollectedPremium: PropTypes.bool,
  isShowing: PropTypes.bool,
  pricesInDollars: PropTypes.array.isRequired,
  shouldShowAsterisks: PropTypes.bool.isRequired,
  showFirstPaymentLowerCopy: PropTypes.bool.isRequired,
  showPaymentBreakdown: PropTypes.func,
  styleOverrides: PropTypes.shape({
    modalBarChartBarFill: PropTypes.object,
    modalBarChartBottomLabel: PropTypes.object,
    modalCloseButton: PropTypes.object,
  }),
  totalInDollars: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  outerContent: {
    paddingRight: 8,
    ...Responsive.lessThanSm({
      '& > button': {
        marginRight: 16,
      },
    }),
    ...Responsive.sm({
      maxHeight: '90vh',
      borderRadius: 10,
      padding: 20,
      overflow: 'auto',
    }),
  },
  innerContent: {
    paddingRight: 18,
    ...Responsive.sm({
      maxWidth: 540,
      padding: 40,
      overflow: 'visible',
    }),
  },
  breakdownContainer: {
    margin: '42px 0 30px',
  },
  list: {
    position: 'relative',
  },
  heading1: {
    ...Theme.cardHeading(),
    marginBottom: 20,
  },
  heading4: {
    ...Theme.heading4(),
    fontWeight: 600,
  },
  body: {
    ...Theme.cardContent(),
  },
  center: {
    position: 'absolute',
    marginLeft: '50%',
  },
  priceLabel: {
    ...Theme.cardContent({
      black: true,
    }),
  },
  thinText: {
    ...Theme.paragraph2(),
    fontSize: 16,
  },
  totalLabel: {
    ...Theme.heading4(),
    color: Colors.gray50(),
  },
  totalPrice: {
    ...Theme.heading4(),
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 14,
    paddingBottom: 14,
  },
  horizontalDivider: {
    borderBottom: `1px solid ${Colors.gray10()}`,
  },
  csLink: {
    ...Theme.link(),
  },
  button: {
    marginTop: 40,
  },
  textLink: {
    textDecoration: 'underline',
  },
});
