import CoverageNonLiabilityLinkModal from '@root/quotes/src/components/modals/coverage-non-liability-link-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

const PipLimitsExplanationLinkModal = ({
  primaryBrandColor,
  secondaryBrandColor,
}) => (
  <CoverageNonLiabilityLinkModal
    linkText={'View your PIP limits'}
    modalName={'PIP Limits Explanation Modal'}
    primaryBrandColor={primaryBrandColor}
    secondaryBrandColor={secondaryBrandColor}
  >
    <h1 css={styles.header}>Here’s what Root may cover under PIP:</h1>
    <div css={styles.paragraphContainer}>
      <div>Limit:</div>
      <div>$10,000 per insured</div>
    </div>
    <div css={styles.paragraphContainer}>
      <span>Medical benefits:</span>
      <span>80% of qualifying expenses</span>
    </div>
    <div css={styles.paragraphContainer}>
      <span>Disability benefits:</span>
      <span>60% of loss of earnings per insured</span>
    </div>
    <div css={styles.paragraphContainer}>
      <span>Death benefits:</span>
      <span>$5000 per insured</span>
    </div>
  </CoverageNonLiabilityLinkModal>
);

PipLimitsExplanationLinkModal.propTypes = {
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
};

export default PipLimitsExplanationLinkModal;

const styles = StyleSheet.create({
  header: {
    lineHeight: 1.1,
    marginBottom: 24,
  },
  paragraphContainer: {
    color: '#767676',
    display: 'grid',
    fontSize: 18,
    gridTemplateColumns: '40% 60%',
    marginBottom: 12,
  },
});
