import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AppRouter from '@root/bind.joinroot.com/src/components/routers/app-router';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { ConfigProvider } from '@root/bind.joinroot.com/src/context/config';
import { QueryClient, QueryClientProvider } from '@root/vendor/react-query';
import { Suspense } from '@root/vendor/react';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

if (environment.dataDogEnabled) {
  datadogRum.init({
    applicationId: environment.dataDogApplicationId,
    clientToken: environment.dataDogClientToken,
    site: 'datadoghq.com',
    service: 'bind.joinroot.com',
    env: environment.environment,
    version: environment.releaseSha,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /https?:\/\/(.+\.)?joinroot.com(:\d+)?/],
    trackSessionAcrossSubdomains: true,
  });

  datadogRum.startSessionReplayRecording();
}

function App({ routerComponent: Router, history }) {
  useAnalytics('APP_ENTRY');

  return (
    <Router history={history}>
      <Suspense fallback={<SceneLoader hideHeader={true} />}>
        <AppRouter />
      </Suspense>
    </Router>
  );
}

App.propTypes = {
  history: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

function AppEntry({ history, routerComponent }) {
  const queryClient = new QueryClient();
  return (
    <ErrorBoundary
      errorTextSuffix={'Please come back and try again later.'}
      showHeader={false}
      showTimestamp={true}
    >
      <AnalyticsProvider eventPrefix={'ROOT_EMBEDDED'}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <App
              history={history}
              routerComponent={routerComponent}
            />
          </ConfigProvider>
        </QueryClientProvider>
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  history: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

const ROOT_BIND_APP_DOMAIN = 'bind.joinroot.com';
export { ROOT_BIND_APP_DOMAIN };

export default AppEntry;
