import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { Colors, StyleSheet, Theme, createEmotionClassName } from '@root/core/src/utils/styles';

export default class CoverageGroup extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    heading: PropTypes.string.isRequired,
    hideDivider: PropTypes.bool,
    styleOverrides: PropTypes.object,
  }

  render() {
    let description = null;

    if (this.props.description) {
      description = <p css={styles.description}>{this.props.description}</p>;
    }

    return (
      <div className={createEmotionClassName(this.props.styleOverrides)}>
        <div css={styles.headingContainer}>
          <h1 css={styles.heading}>{this.props.heading}</h1>
          {description}
          {!this.props.hideDivider && <div css={styles.divider} />}
        </div>
        {this.props.children}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading1(),
    marginBottom: 15,
  },
  headingContainer: {
    paddingBottom: 5,
    marginTop: 80,
  },
  description: {
    ...Theme.paragraph1(),
    maxWidth: 550,
    marginBottom: 15,
  },
  divider: {
    backgroundColor: Colors.gray30(),
    height: 1,
    maxWidth: 550,
    marginBottom: 30,
  },
});
