import PrefillRequest from '@root/prefill/src/models/prefill-request';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import Select from '@root/bind.joinroot.com/src/components/select';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_EVENTS = {
  SELECTED_MARITAL_STATUS: 'SELECTED_MARITAL_STATUS',
};

const MaritalStatusSelect = ({
  hidden,
  maritalStatusChoices,
  onUpdatePrefillRequest,
  onUpdateProfileParams,
  prefillRequest,
  profileParams,
  trackEvent,
  ...restProps
}) => {
  const strings = useI18nNext('components.bindStartEntry.maritalStatusSelect');

  const updateMaritalStatus = useCallback((newMaritalStatus) => {
    trackEvent(ANALYTICS_EVENTS.SELECTED_MARITAL_STATUS, { maritalStatus: newMaritalStatus });
    const updatedProfileParams = ProfileDriverService.setDriverValue(
      profileParams,
      profileParams.getPniDriver().universalDriverId, // We're only concerned about the primary driver on the start entry page
      'maritalStatus',
      newMaritalStatus
    );
    const updatedPrefillRequest = prefillRequest.set(
      'maritalStatus',
      newMaritalStatus
    );
    onUpdateProfileParams(updatedProfileParams);
    onUpdatePrefillRequest(updatedPrefillRequest);
  }, [onUpdatePrefillRequest, onUpdateProfileParams, prefillRequest, profileParams, trackEvent]);

  if (hidden) {
    return null;
  }

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      <Select
        inputId={'marital-status-select'}
        inputName={'marital-status-select'}
        onChange={updateMaritalStatus}
        options={maritalStatusChoices.map(([value, label]) => ({
          label,
          value,
        }))}
        placeholder={strings.placeholder}
        selectedValue={prefillRequest.maritalStatus || ''}
      />
    </div>
  );
};

MaritalStatusSelect.propTypes = {
  hidden: PropTypes.bool.isRequired,
  maritalStatusChoices: PropTypes.array,
  onUpdatePrefillRequest: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  prefillRequest: PropTypes.instanceOf(PrefillRequest).isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading3(),
  },
});

export default MaritalStatusSelect;
