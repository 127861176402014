import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const FCRA_DISCLAIMER_URL = `${environment.websiteBaseUrl}/fcra/`;

export function FcraDisclaimerLink({ styles }) {
  return (
    <a
      css={styles}
      href={FCRA_DISCLAIMER_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      FCRA Disclaimer
    </a>
  );
}

FcraDisclaimerLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
