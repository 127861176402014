import PropTypes from '@root/vendor/prop-types';
import useDeepLink from '@root/bind.joinroot.com/src/context/deep-link';
import { RootError } from '@root-common/root-errors';
import { createContext, useContext } from '@root/vendor/react';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'profile';

const ProfileContext = createContext();
const SetProfileContext = createContext();

const ProfileProvider = ({ children, value: overrideValue = null }) => {
  const [profile, setProfile] = usePersistedState({
    initialValue: overrideValue,
    shouldSkipLoadingFromLocalStorage: !!overrideValue,
    storageKey: LOCAL_STORAGE_KEY,
  });
  const deepLink = useDeepLink();

  // Attach the user_id and account_id to the rum session if present
  if (profile) {
    const {
      accountId,
      userId,
      externalId,
      quoteId,
      embeddedClientId,
      email,
      mailingAddress,
    } = profile ?? {};
    datadogRum.setUser({
      id: userId,
      externalId,
      email,
      accountId,
      quoteId,
      embeddedClientId,
      deepLinked: deepLink.wasDeepLinked(),
      market: mailingAddress?.state,
    });
  }

  return (
    <ProfileContext.Provider value={profile}>
      <SetProfileContext.Provider value={setProfile}>
        {children}
      </SetProfileContext.Provider>
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

const useProfile = () => {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useProfile must be used within ProfileProvider',
      name: 'ProfileContextError',
    });
  }

  return context;
};

const ProfileConsumer = ProfileContext.Consumer;

const useSetProfile = () => {
  const context = useContext(SetProfileContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useSetProfile must be used within ProfileProvider',
      name: 'ProfileContextError',
    });
  }

  return context;
};

export {
  ProfileConsumer,
  ProfileProvider,
  useProfile,
  useSetProfile,
};

export default useProfile;
