import ArrayToText from '@root/core/src/components/array-to-text';
import PropTypes from '@root/vendor/prop-types';
import QuotesMobileSelectMenu from '@root/quotes/src/components/form-elements/quotes-mobile-select-menu';
import React, { Component } from '@root/vendor/react';
import chevronDown from '@root/core/src/assets/chevron-down.svg';
import noop from '@root/vendor/lodash/noop';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class QuotesMobileSelect extends Component {
  static propTypes = {
    dropdownIndicator: PropTypes.string,
    inputId: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    mobileSelectedOptionStyles: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
      value: PropTypes.string.isRequired,
    })).isRequired,
    placeholder: PropTypes.string.isRequired,
    selectBoxStyles: PropTypes.object,
    selectedValue: PropTypes.string.isRequired,
  }

  static defaultProps = {
    onClose: noop,
    onOpen: noop,
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  _getOptionByValue(value) {
    return this.props.options.find((option) => option.value.toUpperCase() === value.toUpperCase());
  }

  _getSelectBoxStyles() {
    const brandedDropdownIndicator = this.props.dropdownIndicator ? {
      backgroundImage: `url(${this.props.dropdownIndicator})`,
    } : {};
    const selectBoxStyles = [styles.selectBox, this.props.selectBoxStyles, brandedDropdownIndicator];
    if (this.state.isOpen) {
      selectBoxStyles.push(styles.selectBoxFocused);
    }

    return selectBoxStyles;
  }

  _getLabelId() {
    return this.props.inputId + '-label';
  }

  _getSelectBoxTextStyles() {
    if (this.props.selectedValue === '') {
      return styles.selectBoxTextPlaceholder;
    }

    return styles.selectBoxText;
  }

  _getLabelStyles() {
    const labelStyles = [styles.labelFull];
    if (this.props.selectedValue !== '') {
      labelStyles.push(styles.labelSmall);
    }

    return labelStyles;
  }

  _handleSelectBoxClick = () => {
    document.body.style.overflowY = 'hidden';

    this.setState({
      isOpen: true,
    });

    this.props.onOpen();
  }

  _handleOptionClick = (option) => {
    this.props.onChange(option.value);
  }

  _closeMenu = () => {
    document.body.style.overflowY = 'auto';

    this.setState({
      isOpen: false,
    });

    this.props.onClose();
  }

  render() {
    return (
      <>
        <div
          css={styles.wrapper}
          onClick={this._handleSelectBoxClick}
        >
          <input
            aria-labelledby={this._getLabelId()}
            css={this._getSelectBoxStyles()}
            id={this.props.inputId}
            name={this.props.inputName}
            onChange={this.props.onChange}
            type={'hidden'}
            value={this.props.selectedValue}
          />
          <div css={this._getSelectBoxStyles(this.state.isOpen)}>
            <div css={this._getSelectBoxTextStyles()}>
              <ArrayToText>{this._getOptionByValue(this.props.selectedValue)?.label.split(' / ') || []}</ArrayToText>
              <label
                css={this._getLabelStyles()}
                htmlFor={this.props.inputId}
                id={this._getLabelId()}
              >
                {this.props.placeholder}
              </label>
            </div>
          </div>
        </div>
        <QuotesMobileSelectMenu
          isOpen={this.state.isOpen}
          mobileSelectedOptionStyles={this.props.mobileSelectedOptionStyles}
          onClose={this._closeMenu}
          onOptionClick={this._handleOptionClick}
          options={this.props.options}
          selectedValue={this.props.selectedValue}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...Theme.paragraph1(),
    fontSize: 17,
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  selectBox: {
    ...Theme.paragraph1(),
    fontSize: 17,
    caretColor: 'transparent',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '12px',
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray30()}`,
    color: Colors.gray50(),
    cursor: 'pointer',
    background: Colors.white(),
    backgroundImage: `url(${chevronDown})`,
    backgroundOrigin: 'content-box',
    backgroundPosition: '100% 50%',
    backgroundRepeat: 'no-repeat',
    ':focus': {
      borderColor: Colors.DEPRECATED_seafoam(),
      outline: 'none',
    },
  },
  selectBoxText: {
    padding: '24px 12px 12px 15px',
  },
  selectBoxTextPlaceholder: {
    padding: '18px 12px 18px 15px',
  },
  selectBoxFocused: {
    borderColor: Colors.DEPRECATED_seafoam(),
  },
  labelFull: {
    margin: 0,
    pointerEvents: 'none',
  },
  labelSmall: {
    position: 'absolute',
    top: 0,
    left: '15px',
    transform: 'translateY(7px)',
    fontSize: 13,
    lineHeight: '15px',
    alignItems: 'flex-start',
    color: Colors.gray50(),
  },
});
