import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getAutoQuoteDisclaimersConfiguration({ quoteId, invoicePeriod }) {
  return new NetworkRequestConfiguration({
    endpoint: `quotes/${quoteId}/auto_quote_disclaimers`,
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      invoicePeriod,
    },
  });
}
