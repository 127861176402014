import Coverage from '@root/auto-pricing/src/models/coverage';

export function toQuotingData({ coverages, quoteCoveragesContext }, other = {}) {
  return {
    selectedCoverages: coverages,
    rateCoverages: quoteCoveragesContext.coverages,
    availableVins: toAvailableVins(quoteCoveragesContext.coverages),
    vehicleCoverageSymbols: quoteCoveragesContext.vehicleCoverageSymbols,
    nonVehicleCoverageSymbols: quoteCoveragesContext.nonVehicleCoverageSymbols,
    options: {
      roadsideAcceptedDeclined: 'no_use',
      cdwAcceptedDeclined: 'no_use',
      physicalDamageEligible: true,
      tortSelection: 'no_use',
    },
    ...other,
  };
}

export function fromQuotingData(quotesData) {
  return {
    selectedCoverages: quotesData.selectedCoverages.map((coverage) => Coverage.build(coverage)),
    rateCoverages: quotesData.rateCoverages,
  };
}

function toAvailableVins(rateCoverages) {
  const availableVins = new Set();

  Object.values(rateCoverages).forEach((coverageArray) => {
    coverageArray.filter((c) => c.vin).forEach((c) => availableVins.add(c.vin));
  });
  return [...availableVins];
}
