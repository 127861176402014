import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import noop from '@root/vendor/lodash/noop';
import { BraintreeHostedFieldRegistry } from '@root/payments/src/services/braintree';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class BraintreeHostedField extends Component {
  static propTypes = {
    /** Either nothing or a string value
     *      This field is designed to be used with the overrideError prop
     *    -  If overrideError is true, then the value of this field will display as an error message
     *    -  If overrideError is false, then the value of this field will not display as an error message
     */
    errorMessage: PropTypes.string,
    fieldRegistry: PropTypes.instanceOf(BraintreeHostedFieldRegistry).isRequired,
    iconSlot: PropTypes.node,
    isFormError: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    maskInput: PropTypes.bool,
    /** @type {func} called with 3 parameters: type, isValid and isEmpty **/
    onBlur: PropTypes.func,
    /** @type {func} called with 1 parameter: isValid **/
    onCardTypeChange: PropTypes.func,
    /** @type {func} called with 1 parameter: type **/
    onEmpty: PropTypes.func,
    /** @type {func} called with 1 parameter: type **/
    onFocus: PropTypes.func, // Function will be called with type
    /** @type {func} called with 2 parameters: type and isValid **/
    onNotEmpty: PropTypes.func,
    onValidityChange: PropTypes.func,
    /**
     * @type {bool} of either nothing, true or false
     *      - do not pass a value if it should let the internal tracking logic
     *        determine if it is an error (this option is to maintain passivity)
     *      - true if the field must be stylized for an error and also display the error message
     *      - false if the field is not to be stylized for an error and not display the error message
     */
    overrideError: PropTypes.bool,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([
      'number', 'expirationDate', 'expirationMonth', 'expirationYear', 'cvv', 'postalCode', 'cardholderName',
    ]).isRequired,
    wrapperStyles: PropTypes.object,
  }

  static defaultProps = {
    errorMessage: null,
    maskInput: false,
    onBlur: noop,
    onEmpty: noop,
    onFocus: noop,
    onNotEmpty: noop,
    onValidityChange: noop,
    overrideError: null,
  }

  state = {
    isFocus: false,
    isError: false,
    isEmpty: true,
  }

  constructor(props) {
    super(props);
    const {
      fieldRegistry, type, placeholder, maskInput,
    } = props;
    fieldRegistry.register({
      type,
      placeholder,
      maskInput,
      selector: `#${this._getFieldId()}`,
      handlers: {
        blur: this._handleOnBlur,
        empty: this._handleOnEmpty,
        focus: this._handleOnFocus,
        notEmpty: this._handleOnNotEmpty,
        validityChange: this._handleOnValidityChange,
        cardTypeChange: this._handleCardTypeChange,
      },
    });
  }

  _handleOnFocus = () => {
    this.setState({
      isFocus: true,
    });
    this.props.onFocus(this.props.type);
  }

  _handleOnBlur = (braintreeField) => {
    this.setState({
      isFocus: false,
    });
    this.props.onBlur(this.props.type, braintreeField.isValid, braintreeField.isEmpty);
  }

  _handleOnEmpty = () => {
    this.setState({
      isEmpty: true,
    });
    this.props.onEmpty(this.props.type);
  }

  _handleOnNotEmpty = (braintreeField) => {
    this.setState({
      isEmpty: false,
    });
    this.props.onNotEmpty(this.props.type, braintreeField.isValid);
  }

  _handleOnValidityChange = (braintreeField) => {
    const isValid = braintreeField.isValid;
    this.setState({
      isError: !isValid,
    });
    // The signature for onValidityChange is different from others to remain passive with existing code.
    this.props.onValidityChange(isValid);
  }

  _handleCardTypeChange = (_braintreeField, braintreeEvent) => {
    const cards = braintreeEvent.cards;
    if (cards.length === 1) {
      const card = cards[0];
      this.props.onCardTypeChange({
        cvvLabel: card.code.name,
        cardType: card.type,
      });
    } else {
      this.props.onCardTypeChange({
        cvvLabel: undefined,
        cardType: undefined,
      });
    }
  }

  _getFieldId = () => `braintree-field-${this.props.type}`
  _getLabelId = () => `${this._getFieldId()}-label`.replace(/ /g, '-')

  _isFieldError() {
    if (this.props.overrideError === null) {
      return !!(this.state.isError || this.props.isFormError);
    }
    return this.props.overrideError;
  }

  _getWrapperStyles() {
    const wrapperStyles = [styles.wrapper];
    if (this.props.wrapperStyles) {
      wrapperStyles.push(this.props.wrapperStyles);
    }
    if (this.state.isFocus) {
      wrapperStyles.push(styles.focusedWrapper);
    }
    if (this._isFieldError()) {
      wrapperStyles.push(styles.errorWrapper);
    }
    return wrapperStyles;
  }

  _getInputWrapperStyles() {
    const inputWrapperStyles = [styles.braintreeInputWrapper];
    if (this.props.iconSlot) {
      inputWrapperStyles.push(styles.iconPadding);
    }
    return inputWrapperStyles;
  }

  _getLabelStyles() {
    const labelStyles = [styles.labelFull];
    if (this.state.isFocus || !this.state.isEmpty) {
      labelStyles.push(styles.labelSmall);
    }
    if (this._isFieldError()) {
      labelStyles.push(styles.errorLabel);
    }
    return labelStyles;
  }

  _renderIconSlot() {
    if (!this.props.iconSlot) { return null; }
    return (
      <div css={styles.icon}>{this.props.iconSlot}</div>
    );
  }

  render() {
    return (
      <div css={styles.row}>
        <div css={this._getWrapperStyles()}>
          <div
            aria-labelledby={this._getLabelId()}
            css={this._getInputWrapperStyles()}
            id={this._getFieldId()}
          />
          <label
            css={this._getLabelStyles()}
            htmlFor={this._getFieldId()}
            id={this._getLabelId()}
          >
            {this.props.label}
          </label>
          {this._renderIconSlot()}
        </div>
        {this.props.overrideError && (
          <div css={styles.errorMessage}>
            {this.props.errorMessage}
          </div>
        )}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...Theme.paragraph1(),
    width: '100%',
    height: '60px',
    position: 'relative',
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray30()}`,
    paddingLeft: '15px',
  },
  focusedWrapper: {
    borderColor: Colors.black(),
  },
  errorWrapper: {
    borderColor: Colors.error(),
  },
  braintreeInputWrapper: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    color: Colors.gray50(),
    background: Colors.white(),
    borderRadius: 4,
  },
  labelFull: {
    pointerEvents: 'none',
    paddingLeft: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'all 150ms ease-out',
  },
  labelSmall: {
    top: 0,
    transform: 'translateY(7px)',
    fontSize: 13,
    lineHeight: '15px',
    alignItems: 'flex-start',
  },
  errorMessage: {
    display: 'flex',
    color: Colors.error(),
    marginBottom: 10,
    marginTop: 5,
  },
  errorLabel: {
    color: Colors.error(),
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 0,
    paddingRight: 15,
  },
  iconPadding: {
    paddingRight: 85,
  },
  row: {
    width: '100%',
  },
});
