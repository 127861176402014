import DriverVehicleAssignmentRouter from '@root/driver-vehicle-assignment/src/components/driver-vehicle-assignment-router';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { withRouter } from '@root/core/src/components/route';

function DriverVehicleAssignmentEntry({
  brandedColor,
  cssButtonOverrides,
  onVehicleUsageChange,
  onDriverVehicleAssignmentChange,
  profileParams,
  location,
  module,
}) {
  return (
    <DriverVehicleAssignmentRouter
      location={location}
      module={module}
      passProps={{
        brandedColor,
        cssButtonOverrides,
        onVehicleUsageChange,
        onDriverVehicleAssignmentChange,
        profileParams,
      }}
    />
  );
}

DriverVehicleAssignmentEntry.propTypes = {
  brandedColor: PropTypes.string,
  cssButtonOverrides: PropTypes.object,
  location: PropTypes.object.isRequired,
  module: PropTypes.string.isRequired,
  onDriverVehicleAssignmentChange: PropTypes.func,
  onVehicleUsageChange: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
};

export default withRouter(DriverVehicleAssignmentEntry);
