import CheckFilled from '@root/bind.joinroot.com/src/components/icons/check-filled';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const styles = StyleSheet.create({
  checkFilled: {
    minWidth: 30,
    paddingRight: 10,
  },
  listCardItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginTop: 5,
    marginBottom: 5,
  },
  incompleteItem: {
    width: 20,
    height: 20,
    backgroundColor: Colors.white(),
    border: 1,
    borderColor: Colors.gray50(),
    borderStyle: 'solid',
    borderRadius: '100%',
    marginRight: 10,
  },
});

const CheckFilledListItem = ({
  complete,
  text,
}) => {
  const icon = complete
    ? (
      <CheckFilled
        css={styles.checkFilled}
        data-testid={'complete-item'}
        fillColor={Colors.green()}
        strokeColor={Colors.white()}
      />
    ) : (
      <div
        css={styles.incompleteItem}
        data-testid={'incomplete-item'}
      />
    );

  return (
    <div css={styles.listCardItem}>
      {icon}
      <p css={styles.text}>{text}</p>
    </div>
  );
};

CheckFilledListItem.propTypes = {
  complete: PropTypes.bool,
  text: PropTypes.string,
};

export default CheckFilledListItem;
