import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Button from '@root/bind.joinroot.com/src/components/button';
import CarImage from '@root/bind.joinroot.com/src/components/bind-underwriting-declined/car-image';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme, createEmotionClassName } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_SCOPE = 'BIND_UNDERWRITING_DECLINED';
export const ANALYTICS_EVENT = {
  CLICK_CONTINUE: 'CONTINUE_TO_INSURANCE_OPTIONS',
};

const styles = StyleSheet.create({
  carCircle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    minHeight: '175px',
    height: '100%',
    width: '100%',
  },
  carImage: {
    display: 'flex',
    flex: 1,
    marginTop: '-2rem',
    zIndex: 2,
  },
  carImageContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    padding: 20,
  },
  content: {
    height: '100vh',
    justifyContent: 'center',
    ...Responsive.sm({
      width: 450,
    }),
    padding: '2rem',
  },
  heading: {
    ...Theme.heading1(),
    zIndex: 2,
  },
  info: {
    ...Theme.paragraph1(),
    zIndex: 2,
  },
});

const bindViewCssOverrides = {
  content: styles.content,
};

const carImageClassNames = {
  circle: createEmotionClassName(styles.carCircle),
  container: createEmotionClassName(styles.carImageContainer),
  image: createEmotionClassName(styles.carImage),
};

const BindUnderwritingDeclined = ({
  onClickContinue,
}) => {
  const { trackClick } = useAnalytics(ANALYTICS_SCOPE);
  const strings = useI18nNext('pages.bindUnderwritingDeclined');

  const handleClickContinue = useCallback(() => {
    trackClick(ANALYTICS_EVENT.CLICK_CONTINUE);
    onClickContinue?.();
  }, [onClickContinue, trackClick]);

  return (
    <BindView
      fullWhite
      cssOverrides={bindViewCssOverrides}
    >
      <CarImage
        classNames={carImageClassNames}
      />
      <h2
        css={styles.heading}
      >
        {strings.heading}
      </h2>
      <p
        css={styles.info}
      >
        {strings.info}
      </p>
      <Button
        css={styles.button}
        onClick={handleClickContinue}
      >
        {strings.continueButton}
      </Button>
    </BindView>
  );
};

BindUnderwritingDeclined.propTypes = {
  onClickContinue: PropTypes.func.isRequired,
};

export default BindUnderwritingDeclined;
