import isEmpty from '@root/vendor/lodash/isEmpty';
import pick from '@root/vendor/lodash/pick';

export default class VehicleCreationParams {
  static DEFAULTS = {
    bodyStyle: {},
    make: {},
    model: {},
    shortVin: undefined,
    vin: undefined,
    year: undefined,
    antiTheftEquipment: null,
    vinEtching: null,
    purchaseDate: undefined,
    garagingAddress1: undefined,
    garagingAddress2: undefined,
    garagingCity: undefined,
    garagingState: undefined,
    garagingZip: undefined,
  };

  static serialize = (value) => pick(value, Object.keys(VehicleCreationParams.DEFAULTS));

  constructor(value = VehicleCreationParams.DEFAULTS) {
    const props = VehicleCreationParams.serialize({
      ...VehicleCreationParams.DEFAULTS,
      ...value,
    });
    Object.assign(this, props);
  }

  set(key, value) {
    if (!Object.keys(VehicleCreationParams.DEFAULTS).includes(key)) {
      return this;
    }

    return new VehicleCreationParams(Object.assign(this, {
      [key]: value,
    }));
  }

  isValid() {
    return !!this.vin || !!this.year && !(isEmpty(this.make) || isEmpty(this.model) || isEmpty(this.bodyStyle));
  }

  serialize() {
    return VehicleCreationParams.serialize(this);
  }
}
