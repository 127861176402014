import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function affirmAutoQuoteDisclaimersConfiguration({
  disclaimerIds, invoicePeriod, quoteId, isAgentBind = false,
}) {
  const bodyParameters = {
    invoicePeriod,
    disclaimerIds,
  };
  if (isAgentBind) {
    bodyParameters['isAgentBind'] = true;
  }
  return new NetworkRequestConfiguration({
    endpoint: `quotes/${quoteId}/auto_quote_disclaimers/affirm`,
    method: NetworkRequestConfiguration.Methods.PUT,
    bodyParameters,
    successCodes: [204],
  });
}
