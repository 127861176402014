import CSSTransition from '@root/vendor/react-transition-group/CSSTransition';
import PropTypes from '@root/vendor/prop-types';
import confirmationAnimation from '@root/right-quote/src/assets/confirmation-animation.json';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useLottieAnimation from '@root/core/src/hooks/use-lottie-animation';
import { AnimationStyleSheet, Colors, Gradients, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useEffect, useState } from '@root/vendor/react';

export default function RightQuoteConfirmationCompColl({ onComplete, transitionTimeout = 4000 }) {
  useAnalytics('RIGHT_QUOTE_CONFIRMATION_COMP_COLL');

  const [showText, setShowText] = useState(true);
  const [animationContainer, player] = useLottieAnimation(confirmationAnimation);

  useEffect(() => {
    if (player) {
      const timerId = setInterval(() => {
        setShowText(false);
      }, transitionTimeout);

      player.addEventListener('complete', () => onComplete());

      return () => { clearInterval(timerId); };
    }
  }, [onComplete, player, transitionTimeout]);

  return (
    <div css={styles.container} >
      <div css={styles.content} >
        <div
          css={styles.animationContainer}
          ref={animationContainer}
        />
        <CSSTransition
          classNames={animationStyles}
          enter={false}
          in={showText}
          onExited={() => player.play()}
          timeout={300}
        >
          <h1 css={styles.text}>
            Adding Collision and Comprehensive coverage to your insurance plan.
          </h1>
        </CSSTransition>
      </div>
    </div>
  );
}

RightQuoteConfirmationCompColl.propTypes = {
  onComplete: PropTypes.func.isRequired,
  transitionTimeout: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    background: Gradients.byDegrees(Colors.DEPRECATED_rootOrangeGradient(), 145),
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 320,
  },
  text: {
    ...Theme.heading4(),
    color: Colors.white(),
    position: 'relative',
    height: 0,
    top: -245,
  },
  animationContainer: {
    height: 400,
  },
});

const animationStyles = AnimationStyleSheet.createClassNames({
  exitActive: {
    transition: 'all 300ms ease-in',
    opacity: '0',
  },
  exitDone: {
    opacity: '0',
  },
});
