import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BillingCycleSelect from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/billing-cycle-select';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Button from '@root/bind.joinroot.com/src/components/button';
import CoveredDriversList from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/covered-drivers-list';
import CoveredVehiclesList from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/covered-vehicles-list';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import FirstPaymentExplainer from '@root/payments/src/components/first-payment-explainer';
import FullTermMonthlySavingsText from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/full-term-monthly-savings-text';
import FullTermTotalSavingsText from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/full-term-total-savings-text';
import MailingAddressListItem from '@root/bind.joinroot.com/src/components/bind-quote-customization/coverage-details-card/mailing-address-list-item';
import Money from '@root/core/src/models/money';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuotesPrice from '@root/quotes/src/components/quotes-price';
import React, { useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { RootError } from '@root-common/root-errors';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  card: {
    backgroundColor: Colors.white(),
    ...Responsive.md({
      padding: 30,
      ...Shadows.cardShadow(),
    }),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
    top: 62,
    boxShadow: 'none',
    padding: 10,
    marginBottom: '-20rem',
    width: '100% !important',
    zIndex: 9,

    ...Responsive.lessThanMd({
      marginBottom: '-5rem',
      paddingBottom: '5rem',
      padding: 20,
      marginLeft: 0,
      ...Shadows.cardShadow(),
    }),
  },
  divider: {
    width: 40,
    height: 3,
    marginTop: 6,
    marginBottom: 20,
    backgroundColor: primaryColor,
  },
  error: {
    ...Theme.caption(),
    backgroundColor: Colors.errorBackground(),
    border: `1px solid ${Colors.error()}`,
    borderRadius: 6,
    color: Colors.error(),
    padding: '0.5rem',
  },
  periodStyle: {
    ...Theme.heading2({
      color: Colors.gray50(),
    }),
  },
  sectionTitle: {
    ...Theme.paragraph1(),
  },
  overviewBorder: {
    marginTop: 25,
    marginBottom: 25,
    borderTop: `1px solid ${Colors.DEPRECATED_blackShadow()}`,
    width: '100%',
  },
  modalOpenButton: {
    color: primaryColor,
  },
  modalCloseButton: {
    backgroundColor: primaryColor,
  },
  modalBarChartBarFill: {
    backgroundColor: primaryColor,
  },
  modalBarChartBottomLabel: {
    color: primaryColor,
  },
  csLink: {
    color: primaryColor,
    ':hover': { color: Colors.nearBlack() },
  },
});

const CoverageDetailsCard = ({
  billingCycle,
  containerRef,
  disableSubmit,
  error,
  onClickAddDriver,
  onClickAddVehicle,
  onClickEditDriver,
  onClickEditVehicle,
  onClickSubmit,
  profileParams,
  readOnly,
  selectedQuote,
  trackClick,
  updateBillingCycle,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard');

  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  const orderedDrivers = useMemo(() => {
    if (profileParams) {
      return profileParams.getOrderedDrivers();
    }
    return [];
  }, [profileParams]);

  const coveredVehicles = useMemo(() => profileParams?.getAllSelectedVehicles() ?? [], [profileParams]);

  const mailingAddress = useMemo(() => profileParams && profileParams.mailingAddress ? profileParams.mailingAddress : undefined, [profileParams]);

  const price = useMemo(() => {
    return Money.fromCents(selectedQuote.getMonthlyOrFullTermPaymentInCents(billingCycle));
  }, [billingCycle, selectedQuote]);

  const localError = useMemo(() => {
    if (coveredVehicles.length === 0) {
      // eslint-disable-next-line root/error-reporting-usage
      return new RootError({
        message: strings.localErrors.missingCoveredVehicle,
        name: 'MissingCoveredVehicleError',
      });
    }
    return undefined;
  }, [coveredVehicles.length, strings.localErrors.missingCoveredVehicle]);

  const savingsTotalIfPaidFullTerm = useMemo(() => selectedQuote.getFullTermSavingsCents(), [selectedQuote]);
  const savingsPerMonthIfPaidFullTerm = useMemo(() => Math.floor(savingsTotalIfPaidFullTerm / 6), [savingsTotalIfPaidFullTerm]);

  const shouldRenderTotalSavings = billingCycle === BillingCycle.FULL_TERM && savingsTotalIfPaidFullTerm > 0;
  const shouldRenderMonthlySavings = billingCycle === BillingCycle.MONTHLY && savingsPerMonthIfPaidFullTerm > 0;

  const shouldRenderDownPaymentUI = billingCycle === BillingCycle.MONTHLY && selectedQuote.hasDifferingFirstPayment();

  return (
    <div
      css={styles.card}
      ref={containerRef}
    >
      <QuotesPrice
        billingCycle={billingCycle}
        price={price}
        size={QuotesPrice.Size.CUSTOM_LARGE}
      />

      {!readOnly &&
        <>
          {shouldRenderTotalSavings &&
            <FullTermTotalSavingsText
              savingsTotalIfPaidFullTerm={savingsTotalIfPaidFullTerm}
              totalPriceIfPaidMonthly={selectedQuote.getTotalPriceIfPaidMonthly()}
            />
          }
          {shouldRenderMonthlySavings && (
            <FullTermMonthlySavingsText
              savingsPerMonthIfPaidFullTerm={savingsPerMonthIfPaidFullTerm}
              trackClick={trackClick}
              updateBillingCycle={updateBillingCycle}
            />
          )}
          {shouldRenderDownPaymentUI && (
            <>
              <div css={styles.divider} />
              <FirstPaymentExplainer
                analyticsPrefix={'analyticsContext'}
                postBindTestDriveRequired={false}
                prices={selectedQuote.getMonthlyPricesInCents()}
                styleOverrides={styles}
              />
            </>
          )}
          <BillingCycleSelect
            billingCycle={billingCycle}
            updateBillingCycle={updateBillingCycle}
          />
          <div css={styles.overviewBorder} />
          <CoveredVehiclesList
            onClickAddVehicle={onClickAddVehicle}
            onClickEditVehicle={onClickEditVehicle}
            originalVehicleVin={profileParams.originalVehicleVin}
            vehicles={coveredVehicles}
          />
          <CoveredDriversList
            drivers={orderedDrivers}
            onClickAddDriver={onClickAddDriver}
            onClickEditDriver={onClickEditDriver}
          />
          <MailingAddressListItem mailingAddress={mailingAddress} />
        </>
      }
      {error && (
        <p css={styles.error}>
          {error.message}
        </p>
      )}
      {localError && (
        <p css={styles.error}>
          {localError.message}
        </p>
      )}
      <Button
        disabled={disableSubmit || localError && localError.length > 0}
        onClick={onClickSubmit}
      >
        {readOnly ? strings.readOnlySubmit : strings.submit}
      </Button>
    </div>
  );
};

CoverageDetailsCard.propTypes = {
  ...BindView.Card.propTypes,
  billingCycle: BillingCycleSelect.propTypes.billingCycle,
  children: PropTypes.node,
  containerRef: PropTypes.func,
  disableSubmit: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  onClickAddDriver: PropTypes.func.isRequired,
  onClickAddVehicle: PropTypes.func.isRequired,
  onClickEditDriver: PropTypes.func.isRequired,
  onClickEditVehicle: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams),
  readOnly: PropTypes.bool,
  selectedQuote: PropTypes.instanceOf(CustomQuote),
  trackClick: PropTypes.func.isRequired,
  updateBillingCycle: PropTypes.func.isRequired,
};

export default CoverageDetailsCard;
