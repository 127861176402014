import ListItem from '@root/bind.joinroot.com/src/components/list/list-item';
import ListItemBase from '@root/bind.joinroot.com/src/components/list/list-item-base';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function List({
  children, header, className,
}) {
  return (
    <div
      className={className}
      css={styles.listContainer}
    >
      {header && <h3 css={styles.header}>{header}</h3>}
      <ul css={styles.list}>
        {React.Children.map(children, (item) => item)}
      </ul>
    </div>
  );
}

const styles = StyleSheet.create({
  listContainer: {
    paddingBottom: '12px',
  },
  list: {
    padding: 0,
    margin: 0,
    li: {
      listStyle: 'none',
    },
  },
  header: {
    ...Theme.paragraph1({
      bold: true,
    }),
    color: Colors.nearBlack(),
  },
});

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.string,
};

export { ListItemBase, ListItem };
