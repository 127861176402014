import {
  CoverageSymbols, CoverageTypes, FixupTypes, QuotingRulesKeys,
} from './constants';
import { getFilteringValidate, getFixupDefinitions } from './quoting-rules';
import { sortCoverages } from './coverage-sort';
import { validQuotingData } from './validate';

export function filterCoverages({
  quotingRules,
  quotingData,
}) {
  quotingData = {
    ...quotingData,
  };

  const errors = [];
  if (!validQuotingData(quotingData)) {
    errors.push(new Error('Quoting data is invalid check quoting data adaptor for issues.'));
  }

  const fixups = getFixupDefinitions(quotingRules);

  fixups.forEach((fixup) => {
    const fixupType = fixup.fixupType;

    try {
      if (fixupType === FixupTypes.FIXUP_DEPENDENT) {
        quotingData = filterDependentCoverages({
          ...fixup,
          filter: getFilteringValidate(fixup),
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_BOTH) {
        quotingData = filterBothCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_ANY_COLL_COMP_REQUIRES_FULL) {
        quotingData = fixupAnyCollCompRequiresFullCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_UMPD_WITH_COLL) {
        quotingData = filterUmpdWithCollCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_PHYSICAL_DAMAGE_TO_DECLINED) {
        quotingData = filterPhysicalDamageToDeclined(quotingData);
      }
    } catch (err) {
      errors.push(err);
    }
  });

  return [
    {
      ...quotingData,
      rateCoverages: sortCoverages(quotingData.rateCoverages),
    },
    errors,
  ];
}

function filterDependentCoverages({
  quotingData,
  parentSymbols,
  dependentSymbol,
  name,
  filter,
}) {
  const sortedCoverages = sortCoverages(quotingData.rateCoverages);
  const parentCoverages = parentSymbols
    .map((sym) => getSelectedCoverage(quotingData.selectedCoverages, sym))
    .filter((coverage) => coverage);

  if (!sortedCoverages[dependentSymbol]) {
    throw makeError(name, `Missing coverages for ${dependentSymbol}.`);
  }

  parentSymbols.forEach((parentSymbol) => {
    if (!sortedCoverages[parentSymbol]) {
      throw makeError(name, `Missing coverages for ${parentSymbol}.`);
    }
  });

  let validCoverages = sortedCoverages[dependentSymbol];

  if (parentCoverages.length === parentSymbols.length) {
    validCoverages = sortedCoverages[dependentSymbol]
      .filter((c) => filter(...parentCoverages, c, {
        ...quotingData.options,
        sortedCoverages,
      }));
  }

  return mergeRateCoverages(quotingData, validCoverages, dependentSymbol);
}

function filterBothCoverages({
  coverages,
  ...rest
}) {
  const [symbol1, symbol2] = coverages;

  if (!symbol1 || !symbol2) {
    return {
      coverages,
      ...rest,
    };
  }

  const filter = getFilteringValidate(rest);
  const updatedQuotingData = filterDependentCoverages({
    ...rest,
    dependentSymbol: symbol1,
    parentSymbols: [symbol2],
    filter,
  });

  return filterDependentCoverages({
    ...rest,
    quotingData: updatedQuotingData,
    dependentSymbol: symbol2,
    parentSymbols: [symbol1],
    filter,
  });
}

function fixupAnyCollCompRequiresFullCoverages(params) {
  return filterBothCoverages(params);
}

function filterUmpdWithCollCoverages({
  quotingData,
  ...rest
}) {
  if (quotingData.nonVehicleCoverageSymbols.includes(CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE)) {
    return filterDependentCoverages({
      ...rest,
      quotingData,
      filter: getFilteringValidate(rest, QuotingRulesKeys.VALIDATE),
    });
  } else {
    return fixupUmpdWithCollPerVehicle({
      ...rest,
      quotingData,
      filter: getFilteringValidate(rest, QuotingRulesKeys.ALTERNATE_VALIDATE),
    });
  }
}

function fixupUmpdWithCollPerVehicle({
  quotingData,
  filter,
  name,
}) {
  const umpdSymbol = CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE;
  const collSymbol = CoverageSymbols.COLLISION;

  const collCoverage = getSelectedCoverage(quotingData.selectedCoverages, collSymbol);
  const sortedCoverages = sortCoverages(quotingData.rateCoverages);

  if (!sortedCoverages[umpdSymbol]) {
    throw makeError(name, `Missing coverages for ${umpdSymbol}.`);
  }

  if (!sortedCoverages[collSymbol]) {
    throw makeError(name, `Missing coverages for ${collSymbol}.`);
  }

  const validCoverages = sortedCoverages[umpdSymbol]
    .filter((coverage) => filter(collCoverage, quotingData.availableVins, coverage));

  return mergeRateCoverages(quotingData, validCoverages, umpdSymbol);
}

function filterPhysicalDamageToDeclined(quotingData) {
  if (quotingData.options.physicalDamageEligible) {
    return quotingData;
  }

  return CoverageTypes.PHYSICAL_DAMAGE.reduce((updatedQuotingData, symbol) => {
    if (!updatedQuotingData.rateCoverages[symbol]) {
      return updatedQuotingData;
    }

    const declinedCoverages = quotingData.rateCoverages[symbol].filter((c) => c.declined);

    return mergeRateCoverages(updatedQuotingData, declinedCoverages, symbol);
  }, Object.assign({}, quotingData));
}

function makeError(name, message) {
  return new Error(`Quoting rule ${name} cannot be executed. ${message}`);
}

function getSelectedCoverage(selectedCoverages, symbol) {
  return selectedCoverages.find((c) => c.symbol === symbol);
}

function mergeRateCoverages(quotingData, coverages, symbol) {
  return {
    ...quotingData,
    rateCoverages: Object.entries(quotingData.rateCoverages).reduce((acc, [currentSymbol, currentCoverages]) => {
      if (currentSymbol === symbol) {
        acc[symbol] = coverages;
      } else {
        acc[currentSymbol] = currentCoverages;
      }

      return acc;
    }, {}),
  };
}
