import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileIcon from '@root/profile/src/components/profile-icon';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { Component, Fragment } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import SubHeading from '@root/profile/src/components/sub-heading';
import VehicleDriverMap from '@root/auto-pricing/src/models/vehicle-driver-map';
import car from '@root/core/src/assets/car.svg';

export default class VehicleDriverAssignment extends Component {
  static propTypes = {
    brandedColor: PropTypes.string,
    cssButtonOverrides: PropTypes.object,
    onDriverVehicleAssignmentChange: PropTypes.func,
    onVehicleUsageChange: PropTypes.func.isRequired,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  };

  static defaultProps = {
    onDriverVehicleAssignmentChange: () => {},
  };

  static analyticsContext = 'PROFILE_VEHICLE_DRIVER_ASSIGNMENT';

  constructor(props) {
    super(props);

    const vehicleDriverMap = ProfileDriverVehicleAssignmentService.buildVehicleDriverMap(
      this.props.profileParams
    );
    const firstMapping = this.props.profileParams.driverVehicleAssignments.length === 0;
    const hasUnassignedVehicles = true;

    this.state = {
      vehicleDriverMap,
      firstMapping,
      hasUnassignedVehicles,
    };
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(VehicleDriverAssignment.analyticsContext, {
      currentAssignments: this.props.profileParams.driverVehicleAssignments.length,
      assignmentStatus: ProfileDriverVehicleAssignmentService.areValidAssignments(this.props.profileParams),
    });
    this.setState({
      hasUnassignedVehicles: this._areVehiclesUnassigned(),
    });
  }

  _drivers = () => this.props.profileParams.getSelectedDrivers()

  _vehicles = () => this.props.profileParams.unassignedVehicles()

  _areVehiclesUnassigned() {
    return !this.state.vehicleDriverMap.allVehiclesAssigned();
  }

  _isCurrentAssociations = (universalDriverId, vehicleCid) => {
    return this.state.vehicleDriverMap.associations[vehicleCid]?.universalDriverId === universalDriverId;
  }

  _handleSubmit = () => {
    if (this._areVehiclesUnassigned()) {
      return;
    }

    const profileParams = ProfileDriverVehicleAssignmentService.mergeVehicleDriverMap({
      profileParams: this.props.profileParams,
      vehicleDriverMap: this.state.vehicleDriverMap,
    });

    AnalyticsService.trackClickEvent(VehicleDriverAssignment.analyticsContext, 'CONTINUE', {
      previousAssignments: this.props.profileParams.driverVehicleAssignments.length,
      currentAssignments: profileParams.driverVehicleAssignments.length,
      assignmentStatus: ProfileDriverVehicleAssignmentService.areValidAssignments(profileParams),
    });

    this.props.onVehicleUsageChange(profileParams);
    this.props.onDriverVehicleAssignmentChange(profileParams);
  };

  _handleSelect = (vehicleCid) => (event) => {
    const selectedDriver = this._drivers()
      .find((d) => d.universalDriverId === event.target.value);

    const vehicleDriverMap = this.state.vehicleDriverMap.toggleVehicleDriverAssociation(
      vehicleCid,
      selectedDriver,
      this.state.firstMapping,
    );

    const trackEvents = {
      [VehicleDriverMap.CHANGE.ASSOCIATION]: 'ASSOCIATE_VEHICLE_DRIVER',
      [VehicleDriverMap.CHANGE.REASSIGN]: 'REASSIGN_VEHICLE_DRIVER',
      [VehicleDriverMap.CHANGE.DISSOCIATION]: 'DISSOCIATE_VEHICLE_DRIVER',
    };
    const trackEvent = trackEvents[vehicleDriverMap.change(this.state.vehicleDriverMap)];
    AnalyticsService.trackClickEvent(VehicleDriverAssignment.analyticsContext, trackEvent);

    this.setState({
      vehicleDriverMap,
    });
  }

  _renderVehicleRadioInputGroups() {
    return this._vehicles().map((vehicle, index) => (
      <Fragment key={vehicle.key()}>
        <SubHeading>{vehicle.yearMakeAndModelOrVin()}</SubHeading>
        <RadioInputGroup>
          {this._renderDriverRadioOptions(vehicle, index)}
        </RadioInputGroup>
      </Fragment>
    ));
  }

  _renderDriverRadioOptions(vehicle, index) {
    return this._drivers().map((driver) => (
      <Fragment key={driver.universalDriverId}>
        <RadioOption
          circleColor={this.props.brandedColor}
          id={`driver-${driver.universalDriverId}-vehicleIndex-${index}`}
          isSelected={this._isCurrentAssociations(driver.universalDriverId, vehicle.cid)}
          label={driver.firstName}
          name={'vehicle-driver-assignment-radio'}
          onClick={this._handleSelect(vehicle.cid)}
          optionValue={driver.universalDriverId}
        />
      </Fragment>
    ));
  }

  _generateHeading() {
    return this.state.hasUnassignedVehicles ? 'Please select the main driver of each vehicle.' : 'You\'ve already provided what we need. Please continue.';
  }

  render() {
    if (this.state.isSubmitting) {
      return <SceneLoader />;
    }

    return (
      <>
        <ProfileIcon src={car} />
        <ProfileForm onSubmit={this._handleSubmit}>
          <ProfileHeading>
            {this._generateHeading()}
          </ProfileHeading>
          {this._renderVehicleRadioInputGroups()}
          <Button
            css={this.props.cssButtonOverrides}
            disabled={this._areVehiclesUnassigned()}
          >
            Continue
          </Button>
        </ProfileForm>
      </>
    );
  }
}

