export const RECOMMENDATIONS = {
  INCLUDE: 'include',
  WAIVE: 'waive',
  NONE: 'none',
  INCOMPLETE: 'incomplete',
  INVALID: 'invalid',
};

const VEHICLE_PAID_OFF_YEAR_THRESHOLD = 16;

export function guessVehiclesPaidOff(vehicles) {
  const currentYear = new Date().getFullYear();
  return vehicles.every((vehicle) => {
    return !vehicle.selected || vehicle.year && currentYear - vehicle.year > VEHICLE_PAID_OFF_YEAR_THRESHOLD;
  });
}

export default class RightQuote {
  static build({
    rightQuoteEnabled,
    vehiclesPaidOff,
    financingOrLeasing = {
      answered: false,
      value: null,
    },
    compAndColl = {
      answered: false,
      value: null,
    },
  } = {}) {
    const buildParams = {
      rightQuoteEnabled,
      vehiclesPaidOff,
      financingOrLeasing,
      compAndColl,
    };

    return Object.assign(
      new RightQuote(),
      buildParams,
    );
  }

  constructor() {
    this.rightQuoteEnabled;
    this.vehiclesPaidOff = false;
    this.financingOrLeasing = {
      answered: false,
      value: null,
    };
    this.compAndColl = {
      answered: false,
      value: null,
    };
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new RightQuote(),
      this,
      attributes,
    );
  }

  getCompCollRecommendation() {
    if (this.financingOrLeasing.answered) {
      if (this.financingOrLeasing.value) {
        return RECOMMENDATIONS.INCLUDE;
      }
      if (this.vehiclesPaidOff) {
        return RECOMMENDATIONS.WAIVE;
      }
    }
    if (this.compAndColl.answered) {
      if (this.compAndColl.value) {
        return RECOMMENDATIONS.INCLUDE;
      }
      return RECOMMENDATIONS.NONE;
    }

    return RECOMMENDATIONS.INCOMPLETE;
  }
}
