import BaseButton from '@root/core/src/components/button';
import React, { useMemo } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Shadows, StyleSheet } from '@root/core/src/utils/styles';

const Button = ({
  cssOverrides = {},
  ...restProps
}) => {
  const [branding] = useBranding();

  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  return (
    <BaseButton
      css={[
        styles.button,
        cssOverrides,
      ]}
      {...restProps}
    />
  );
};

Button.propTypes = BaseButton.propTypes;

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  button: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
});

export default Button;
