import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import DobDate from '@root/core/src/models/dob-date';
import { isNotOlderThan, isOldEnough, isRequired, isValid, isYoungEnough } from '@root/core/src/utils/validators';

const VALIDATORS = {
  ageWhenLicensed: [
    () => isRequired('ageWhenLicensed', AgeWhenLicensed.ERROR_NOT_VALID),
    (currentDate) => isOldEnough('ageWhenLicensed', AgeWhenLicensed.ERROR_TOO_YOUNG, currentDate),
    (currentDate) => isYoungEnough('ageWhenLicensed', AgeWhenLicensed.ERROR_TOO_OLD, currentDate),
    () => isNotOlderThan('ageWhenLicensed', 'dob', AgeWhenLicensed.ERROR_OLDER_THAN_CURRENT_AGE),
    () => isValid('ageWhenLicensed', AgeWhenLicensed.ERROR_NOT_VALID),
  ],
  dob: [
    (currentDate) => isOldEnough('dob', `You must be ${DobDate.DEFAULT_MIN_AGE} years or older to sign up!`, currentDate),
    (currentDate) => isYoungEnough('dob', `You must be ${DobDate.DEFAULT_MAX_AGE} years or younger to sign up!`, currentDate),
    () => isValid('dob', 'Enter the driver’s birthday.'),
  ],
  firstName: [() => isRequired('firstName', 'Enter the driver’s first name.')],
  lastName: [() => isRequired('lastName', 'Enter the driver’s last name.')],
  licenseNumber: [() => isRequired('licenseNumber', 'Enter the driver’s license number.')],
};

export default function driverFieldValidator(driver, field, fieldRequired = true, currentDate = new Date()) {
  const validators = VALIDATORS[field];

  if (!validators || !fieldRequired) {
    return null;
  }

  const errors = {};
  validators.forEach((validator) => validator(currentDate)(driver, errors));
  return errors[field] || null;
}
