import UnderwritingCompanyContext from '@root/auto-pricing/src/models/underwriting-company-context';
import getUnderwritingCompanyContextConfiguration from '@root/profile/src/api/underwriting-company-context-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useProfile from '@root/bind.joinroot.com/src/context/profile';
import { useEffect, useRef } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'underwriting_company_context';

export function usePersistedUnderwritingCompanyContext() {
  const [underwritingCompanyContext, setUnderwritingCompanyContext] = usePersistedState({
    deserialize: (storedObject) => new UnderwritingCompanyContext(storedObject),
    initialValue: null,
    storageKey: LOCAL_STORAGE_KEY,
  });

  const shouldFetchContextRef = useRef(!underwritingCompanyContext);
  const [fetchContext] = useImperativeNetworkRequest(getUnderwritingCompanyContextConfiguration);

  const bindProfile = useProfile();

  useEffect(() => {
    if (shouldFetchContextRef.current && bindProfile?.mailingAddress?.state) {
      const fetch = async () => {
        const result = await fetchContext({ market: bindProfile.mailingAddress.state });

        setUnderwritingCompanyContext(new UnderwritingCompanyContext(result.data.underwritingCompanyContext));
        shouldFetchContextRef.current = false;
      };

      fetch();
    }
  }, [bindProfile?.mailingAddress?.state, fetchContext, setUnderwritingCompanyContext]);

  return underwritingCompanyContext;
}
