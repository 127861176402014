import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class QuotesPrice extends Component {
  static Size = {
    SMALL: 'sm',
    MEDIUM: 'md',
    LARGE: 'lg',
    CUSTOM_LARGE: 'customLg',
  }

  static propTypes = {
    billingCycle: PropTypes.oneOf(BillingCycle.getOptions()),
    centStyle: PropTypes.object,
    color: PropTypes.string,
    dollarStyle: PropTypes.object,
    hideShortFormat: PropTypes.bool,
    periodStyle: PropTypes.object,
    price: PropTypes.instanceOf(Money),
    refProp: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    size: PropTypes.oneOf(Object.values(QuotesPrice.Size)),
  }

  static defaultProps = {
    size: QuotesPrice.Size.LARGE,
  }

  render() {
    const {
      dollarStyle, centStyle, periodStyle, price, billingCycle, size, color, refProp, hideShortFormat = false,
    } = this.props;

    const style = {
      color,
    };

    const _shortFormat = () => {
      if (hideShortFormat) {
        return null;
      }
      return BillingCycle.shortFormat(billingCycle);
    };

    return (
      <h1
        css={[styles.price, styles[`${size}Price`], dollarStyle]}
        ref={refProp}
        style={style}
      >
        {price.formattedDollars()}
        <span css={[styles.cents, styles[`${size}Cents`], centStyle]}>
          {price.formattedCents()}
        </span>
        <span
          css={[styles.period, styles[`${size}Period`], periodStyle]}
          style={style}
        >
          {_shortFormat()}
        </span>
      </h1>
    );
  }
}

const styles = StyleSheet.create({
  price: {
    ...Theme.heading1(),
    margin: 0,
  },
  customLgPrice: {
    fontSize: 66,
    ...Responsive.md({
      fontSize: 66,
    }),
  },
  mdPrice: {
    fontSize: 32,
  },
  smPrice: {
    fontSize: 22,
  },
  cents: {
    fontSize: 22,
  },
  customLgCents: {
    fontSize: 32,
    ...Responsive.md({
      fontSize: 32,
    }),
  },
  mdCents: {
    fontSize: 15,
  },
  smCents: {
    fontSize: 12,
  },
  period: {
    ...Theme.paragraph2(),
  },
  customLgPeriod: {
    fontSize: 18,
  },
  mdPeriod: {
    fontSize: 13,
  },
  smPeriod: {
    fontSize: 11,
  },
});
