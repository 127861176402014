import FieldCell from '@root/profile/src/components/edit-driver/field-cell';
import FieldGroup from '@root/profile/src/components/edit-driver/field-group';
import FieldHeading from '@root/profile/src/components/edit-driver/field-heading';
import Input from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { sanitize } from '@root/core/src/utils/strings';

export default function NameFields({
  errors, firstName, inputStyle, lastName, onBlur, onChange,
}) {
  const handleNameChange = (field) => (value) => {
    onChange(field)(sanitize(value));
  };

  return (
    <FieldGroup>
      <FieldHeading>
        Name
      </FieldHeading>
      <FieldCell>
        <Input
          errorLabel={errors.firstName}
          inputStyle={inputStyle}
          label={'First name'}
          onBlur={onBlur('firstName')}
          onChange={handleNameChange('firstName')}
          value={firstName || ''}
        />
      </FieldCell>
      <FieldCell>
        <Input
          errorLabel={errors.lastName}
          inputStyle={inputStyle}
          label={'Last name'}
          onBlur={onBlur('lastName')}
          onChange={handleNameChange('lastName')}
          value={lastName || ''}
        />
      </FieldCell>
    </FieldGroup>
  );
}

NameFields.propTypes = {
  errors: PropTypes.object,
  firstName: PropTypes.string,
  inputStyle: PropTypes.object,
  lastName: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
