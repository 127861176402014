import ProfileIcon from '@root/profile/src/components/profile-icon';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ProfileHeading({
  children, icon, iconLabel, ...props
}) {
  return (
    <>
      {icon && <ProfileIcon src={icon} />}
      {icon && iconLabel && <p css={[styles.iconLabel, props.styles]}>{iconLabel}</p>}
      <h1 css={[styles.heading, props.styles]}>{children}</h1>
    </>
  );
}

ProfileHeading.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  iconLabel: PropTypes.string,
  styles: PropTypes.object,
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading1(),
    marginTop: 20,
    marginBottom: 30,
  },
  iconLabel: {
    ...Theme.paragraph2(),
    color: Colors.black(),
  },
});
