import AddDriverAgeWhenLicensedComponent from '@root/age-licensed/src/components/age-licensed-age-when-licensed';
import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Driver from '@root/auto-pricing/src/models/driver';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import calendar from '@root/core/src/assets/calendar-colored.svg';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isNotOlderThan, isOldEnough, isRequired, isValid, isYoungEnough } from '@root/core/src/utils/validators';

const VALIDATORS = [
  isRequired('ageWhenLicensed', AgeWhenLicensed.ERROR_NOT_VALID),
  isValid('ageWhenLicensed', AgeWhenLicensed.ERROR_NOT_VALID),
  isOldEnough('ageWhenLicensed', AgeWhenLicensed.ERROR_TOO_YOUNG),
  isYoungEnough('ageWhenLicensed', AgeWhenLicensed.ERROR_TOO_OLD),
  isNotOlderThan('ageWhenLicensed', 'dob', AgeWhenLicensed.ERROR_OLDER_THAN_CURRENT_AGE),
];

export default class ProfileAddDriverAgeWhenLicensed extends Component {
  static propTypes = {
    cssButtonOverrides: PropTypes.object,
    driver: PropTypes.instanceOf(Driver).isRequired,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      ageWhenLicensed: undefined,
      dob: props.driver.dob,
      error: undefined,
    };
  }

  static analyticsContext = 'PROFILE_ADD_DRIVER_AGE_LICENSED';

  static contextType = AnalyticsContext;

  componentDidMount() {
    if (!this.props.profileRulesContext.ageWhenLicensed) {
      AnalyticsService.trackEvent(ProfileAddDriverAgeWhenLicensed.analyticsContext, 'NOT_REQUIRED', { eventPrefix: this.context.eventPrefix });
      return this.props.onDone(this.props.driver);
    }

    AnalyticsService.trackViewEvent(ProfileAddDriverAgeWhenLicensed.analyticsContext, { eventPrefix: this.context.eventPrefix });
  }

  _handleChangeAgeWhenLicensed = (value) => {
    const ageWhenLicensed = new AgeWhenLicensed(this._sanitizeAgeWhenLicensed(value));
    this.setState({
      ageWhenLicensed,
    }, () => {
      this._validateAgeWhenLicensed();
    });
  }

  _sanitizeAgeWhenLicensed = (value) => {
    if (!!value && isNaN(value)) {
      const checkedValue = value.replace(/[^\d]/g, '');

      if (!isNaN(checkedValue)) {
        return checkedValue;
      }
    }

    return value;
  }

  _validateAgeWhenLicensed = () => {
    const errors = {};
    VALIDATORS.every((validator) => {
      validator(this.state, errors);
      return !errors.ageWhenLicensed;
    });

    this.setState({
      error: errors.ageWhenLicensed,
    });

    return !errors.ageWhenLicensed;
  }

  _driver() {
    return this.props.driver;
  }

  _handleSubmit = () => {
    if (this.state.ageWhenLicensed) {
      AnalyticsService.trackClickEvent(ProfileAddDriverAgeWhenLicensed.analyticsContext, 'SUBMIT', { eventPrefix: this.context.eventPrefix });
      const driver = this.props.driver.set('ageWhenLicensed', this.state.ageWhenLicensed);
      this.props.onDone(driver);
    }
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverAgeWhenLicensed.analyticsContext, 'CANCEL', { eventPrefix: this.context.eventPrefix });
    this.props.onCancel();
  }

  _renderTitle() {
    return `How old was ${this._driver().firstName} when they were first licensed?`;
  }

  render() {
    return (
      <div>
        <div css={styles.wrapper}>
          <ProfileHeading icon={calendar} />
          <AddDriverAgeWhenLicensedComponent
            ageWhenLicensed={this.state.ageWhenLicensed}
            cssButtonOverrides={this.props.cssButtonOverrides}
            error={this.state.error}
            onChange={this._handleChangeAgeWhenLicensed}
            onSubmit={this._handleSubmit}
            title={this._renderTitle()}
          />
        </div>
        <div>
          <NevermindLink onCancel={this._handleCancel} />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    marginBottom: 30,
  },
});
