import isBoolean from '@root/vendor/lodash/isBoolean';
import isEmpty from '@root/vendor/lodash/isEmpty';

export default class ProfileRulesContext {
  constructor(props) {
    this.accidentPreventionCourse = props.accidentPreventionCourse;
    this.accidentPreventionCourseMinimumAge = props.accidentPreventionCourseMinimumAge;
    this.activeDutyMilitary = props.activeDutyMilitary;
    this.ageWhenLicensed = props.ageWhenLicensed;
    this.antiTheftEquipment = props.antiTheftEquipment;
    this.allowTestDriveOptIn = props.allowTestDriveOptIn;
    this.askPriorInsuranceQuestions = props.askPriorInsuranceQuestions;
    this.collegeGraduate = props.collegeGraduate;
    this.driverVehicleAssignments = props.driverVehicleAssignments;
    this.driverVehicleUsage = props.driverVehicleUsage;
    this.durationWithoutInsuranceChoices = props.durationWithoutInsuranceChoices;
    this.earlyOnboardingGaragingAddressConfirmation = props.earlyOnboardingGaragingAddressConfirmation;
    this.garagingZipRequiredMarket = props.garagingZipRequiredMarket;
    this.garagingAddressWebEnabled = props.garagingAddressWebEnabled;
    this.genderChoices = props.genderChoices;
    this.goodStudent = props.goodStudent;
    this.inexperiencedOperator = props.inexperiencedOperator;
    this.maritalStatusChoices = props.maritalStatusChoices;
    this.nationalGuardMember = props.nationalGuardMember;
    this.previousInsurerDurationInsuredChoices = props.previousInsurerDurationInsuredChoices;
    this.purchaseDate = props.purchaseDate;
    this.ratingMunicipality = props.ratingMunicipality;
    this.refresherPreventionCourse = props.refresherPreventionCourse;
    this.takeAllComersMarket = props.takeAllComersMarket;
    this.useGender = props.useGender;
    this.userSpecifiedYearsLicensedSelectionChoices = props.userSpecifiedYearsLicensedSelectionChoices;
    this.testDriveDisabledMarkets = props.testDriveDisabledMarkets;
    this.vehicleClassCode = props.vehicleClassCode;
    this.vehicleUsage = props.vehicleUsage;
    this.vehicleUsageChoices = props.vehicleUsageChoices;
    this.vinEtching = props.vinEtching;
  }

  isValid() {
    return [
      isBoolean(this.accidentPreventionCourse),
      isBoolean(this.activeDutyMilitary),
      isBoolean(this.ageWhenLicensed),
      isBoolean(this.antiTheftEquipment),
      isBoolean(this.allowTestDriveOptIn),
      isBoolean(this.askPriorInsuranceQuestions),
      isBoolean(this.collegeGraduate),
      isBoolean(this.driverVehicleAssignments),
      isBoolean(this.driverVehicleUsage),
      Array.isArray(this.durationWithoutInsuranceChoices),
      isBoolean(this.earlyOnboardingGaragingAddressConfirmation),
      Array.isArray(this.genderChoices),
      isBoolean(this.garagingZipRequiredMarket),
      isBoolean(this.garagingAddressWebEnabled),
      !isEmpty(this.genderChoices),
      isBoolean(this.goodStudent),
      isBoolean(this.inexperiencedOperator),
      Array.isArray(this.maritalStatusChoices),
      !isEmpty(this.maritalStatusChoices),
      isBoolean(this.nationalGuardMember),
      Array.isArray(this.previousInsurerDurationInsuredChoices),
      isBoolean(this.purchaseDate),
      isBoolean(this.ratingMunicipality),
      isBoolean(this.refresherPreventionCourse),
      isBoolean(this.takeAllComersMarket),
      isBoolean(this.useGender),
      Array.isArray(this.userSpecifiedYearsLicensedSelectionChoices),
      Array.isArray(this.testDriveDisabledMarkets),
      isBoolean(this.vehicleClassCode),
      isBoolean(this.vehicleUsage),
      Array.isArray(this.vehicleUsageChoices),
      isBoolean(this.vinEtching),
    ].every((requirement) => requirement);
  }
}
