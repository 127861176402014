import Coverage from '@root/auto-pricing/src/models/coverage';
import PropTypes from '@root/vendor/prop-types';
import { Component } from '@root/vendor/react';

export default class CoverageTitle extends Component {
  static propTypes = {
    coverage: PropTypes.instanceOf(Coverage).isRequired,
    market: PropTypes.string.isRequired,
  };

  render() {
    return Coverage.getLocaleName({
      market: this.props.market,
      symbol: this.props.coverage.symbol,
    });
  }
}
