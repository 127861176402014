import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Theme } from '@root/core/src/utils/styles';

export default function BodySmallText({ children, className }) {
  return (
    <p
      className={className}
      css={Theme.paragraph2()}
    >
      {children}
    </p>
  );
}

BodySmallText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
