import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Link from '@root/core/src/components/link';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export default function FullTermMonthlySavingsText({
  savingsPerMonthIfPaidFullTerm,
  trackClick,
  updateBillingCycle,
}) {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard.fullTermMonthlySavingsText');

  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  const handleUpdateBillingCycleToFullTerm = () => {
    trackClick('SWITCH_TO_FULL_TERM_LINK');
    updateBillingCycle(BillingCycle.FULL_TERM);
  };

  return (
    <div css={styles.container}>
      <span>{strings.saveBeforePrice}</span>
      <span css={styles.savingsPrice}>{Money.fromCents(savingsPerMonthIfPaidFullTerm).formattedDollarsAndCents()}</span>
      <span>{strings.saveAfterPrice}</span>
      <Link
        cssOverrides={styles.switchLink}
        onClick={handleUpdateBillingCycleToFullTerm}
      >
        {strings.switchToFullTermLink}
      </Link>
    </div>
  );
}

FullTermMonthlySavingsText.propTypes = {
  savingsPerMonthIfPaidFullTerm: PropTypes.number.isRequired,
  trackClick: PropTypes.func.isRequired,
  updateBillingCycle: PropTypes.func.isRequired,
};

const stylesGenerator = ({ primaryColor, secondaryColor }) => StyleSheet.create({
  container: {
    lineHeight: '120%',
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  savingsPrice: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginLeft: '0.25rem',
  },
  switchLink: {
    color: primaryColor,
    fontSize: '1.125rem',
    marginLeft: '0.25rem',
    textAlign: 'left',
    textDecoration: 'underline',
    ':hover': {
      color: secondaryColor,
    },
  },
});
