import Button from '@root/bind.joinroot.com/src/components/button';
import List, { ListItem } from '@root/bind.joinroot.com/src/components/list';
import PlusIcon from '@root/bind.joinroot.com/src/components/icons/plus-icon';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Vehicle from '@root/core/src/models/vehicle';
import { BlackVehicleIcon } from '@root/profile/src/components/icons/black-vehicle-icon';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const CoveredVehiclesList = ({
  onClickAddVehicle,
  onClickEditVehicle,
  originalVehicleVin,
  vehicles,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard.coveredVehiclesList');
  const partnerName = useI18nNext('partner') || '';

  if (!vehicles) {
    return null;
  }

  return (
    <>
      <List header={strings.title}>
        {vehicles.map((vehicle) => {
          let editable = true;

          if (partnerName.toLowerCase().includes('carvana')) {
            editable = originalVehicleVin !== vehicle.vin;
          }

          const onClick = editable ? () => editable && onClickEditVehicle({
            vin: vehicle.vin,
            vehicleCid: vehicle.cid,
          }) : undefined;

          return (
            <ListItem
              editable={editable}
              heading={vehicle.yearMakeAndModelOrVin()}
              icon={<BlackVehicleIcon />}
              key={vehicle.vin}
              onClick={onClick}
            />
          );
        })}
      </List>
      <Button
        cssOverrides={styles.addItemButton}
        iconComponent={PlusIcon}
        onClick={onClickAddVehicle}
      >
        {strings.addItem}
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  addItemButton: {
    border: `2px solid ${Colors.gray40()}`,
    backgroundColor: 'transparent',
    color: Colors.black(),
    marginTop: -10,
    marginBottom: 15,
  },
});

CoveredVehiclesList.propTypes = {
  onClickAddVehicle: PropTypes.func.isRequired,
  onClickEditVehicle: PropTypes.func.isRequired,
  originalVehicleVin: PropTypes.string.isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.instanceOf(Vehicle)),
};

export default CoveredVehiclesList;
