import React from '@root/vendor/react';
import {
  AutoscalingSvg,
  IconProps,
} from '@root/bind.joinroot.com/src/components/icons';
import { Colors } from '@root/core/src/utils/styles';

const ChevronRight = ({
  color = Colors.black(),
  height = 17,
  width = 10,
}) => {
  return (
    <AutoscalingSvg
      fill={'none'}
      height={height}
      viewBox={'0 0 10 17'}
      width={width}
    >
      <path
        d={'M9.82947 8.62057C9.831 8.88637 9.72665 9.14185 9.53947 9.33057L2.03947 16.8306L0.579468 15.4106L7.41947 8.62057L0.579468 1.83057L1.98947 0.420567L9.57947 7.91057C9.75209 8.10564 9.84179 8.36037 9.82947 8.62057Z'}
        fill={color}
      />
    </AutoscalingSvg>
  );
};

ChevronRight.propTypes = IconProps;

export default ChevronRight;
