import Button from '@root/core/src/components/button';
import Link from '@root/core/src/components/link';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function CoverageNonLiabilityLinkModal({
  children,
  ctaText = 'Got it',
  linkText,
  modalName,
  primaryBrandColor,
  secondaryBrandColor,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const brandedStyles = useMemo(() => stylesGenerator({ primaryBrandColor }), [primaryBrandColor]);
  const linkCssOverrides = useMemo(() => ({
    ...Theme.linkRootOrange(),
    ...primaryBrandColor ? { color: primaryBrandColor } : {},
    ...secondaryBrandColor ? {
      ':hover': {
        color: secondaryBrandColor,
      },
    } : {},
  }), [primaryBrandColor, secondaryBrandColor]);

  return (
    <>
      <Link
        cssOverrides={linkCssOverrides}
        onClick={() => setModalVisible(true)}
      >
        {linkText}
      </Link>
      <Modal
        isShowing={modalVisible}
        name={modalName}
        onCancel={() => setModalVisible(false)}
        showCloseButton={Responsive.matches(Responsive.QUERIES.lessThanMd)}
        styleOverrides={{ innerContent: styles.innerContainerOverride }}
      >
        <div css={styles.container}>
          {children}
          <Button
            css={brandedStyles.button}
            onClick={() => setModalVisible(false)}
          >
            {ctaText}
          </Button>
        </div>
      </Modal>
    </>
  );
}

CoverageNonLiabilityLinkModal.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
};

const stylesGenerator = ({ primaryBrandColor }) => StyleSheet.create({
  button: {
    backgroundColor: primaryBrandColor,
    ...Shadows.onFocusStateShadow({}, primaryBrandColor),
  },
});

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 12,
  },
  innerContainerOverride: {
    ...Responsive.sm({
      maxWidth: 446,
      padding: 40,
      paddingLeft: 36,
      paddingRight: 12,
    }),
  },
});
