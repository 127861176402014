import BackButton from '@root/bind.joinroot.com/src/components/back-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { useDeepLinkFlow } from '@root/bind.joinroot.com/src/context/deep-link';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export default function ExitFlowButton({ exitToQuote = false }) {
  const [{ primaryColor, shouldReturnToPartner }] = useBranding();
  const { backToQuote, backTo } = useI18nNext('components.bindView');
  const { exitFlow } = useDeepLinkFlow();

  const { trackEvent } = useAnalytics('BIND_BACK_TO_ORDER', false);

  const onClickButton = useCallback(() => {
    trackEvent('EXITING_FLOW_BACK_TO_PARTNER');
    exitFlow();
  }, [exitFlow, trackEvent]);
  if (shouldReturnToPartner) {
    return (
      <BackButton
        color={exitToQuote ? primaryColor : undefined}
        onClickButton={onClickButton}
        text={exitToQuote ? backToQuote : backTo}
      />
    );
  }
  return null;
}

ExitFlowButton.propTypes = {
  exitToQuote: PropTypes.bool,
};
