import Button from '@root/core/src/components/button';
import ConfirmationDialog from '@root/profile-review/src/components/confirmation-dialog';
import DobDate from '@root/core/src/models/dob-date';
import Input from '@root/core/src/components/input';
import Link from '@root/core/src/components/link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileReviewAntiTheftEquipment from '@root/profile-review/src/components/profile-review-anti-theft-equipment';
import ProfileReviewPurchaseDate from '@root/profile-review/src/components/profile-review-purchase-date';
import ProfileReviewVinEtching from '@root/profile-review/src/components/profile-review-vin-etching';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import ProfileVehicleService from '@root/auto-pricing/src/services/profile-vehicle-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Vehicle from '@root/core/src/models/vehicle';
import noop from '@root/vendor/lodash/noop';
import { BlackVehicleIcon } from '@root/profile/src/components/icons/black-vehicle-icon';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_EVENTS = {
  REMOVE_VEHICLE_CANCEL: 'REMOVE_VEHICLE_CANCEL',
  REMOVE_VEHICLE_CONFIRM: 'REMOVE_VEHICLE_CONFIRM',
  REMOVE_VEHICLE: 'REMOVE_VEHICLE',
  SUBMIT: 'SUBMIT',
};

function getInitialPurchaseDate(v, { purchaseDate }) {
  if (purchaseDate) {
    const vehiclePurchaseDate = v?.purchaseDate;

    if (vehiclePurchaseDate && vehiclePurchaseDate instanceof DobDate) {
      return vehiclePurchaseDate;
    }

    return new DobDate('');
  }
  return undefined;
}

export default function EditVehicleManager({
  circleSelectedStyles,
  cssButtonOverrides,
  inputStyle,
  linkStyles,
  onProfileChange,
  profileParams,
  profileRulesContext,
  toggleSelectedStyles,
  trackClick,
  vehicle,
}) {
  const [dateError, setDateError] = useState('');
  const [isConfirmationShowing, setIsConfirmationShowing] = useState(false);
  const [purchaseDate, setPurchaseDate] = useState(getInitialPurchaseDate(vehicle, profileRulesContext));
  const [vinEtching, setVinEtching] = useState(vehicle?.vinEtching);
  const [antiTheftEquipment, setAntiTheftEquipment] = useState(vehicle?.antiTheftEquipment);
  const handleDateChange = useCallback((field) => (value) => {
    const updatedDate = purchaseDate.set(field, value);
    setPurchaseDate(updatedDate);
  }, [purchaseDate]);

  function handleRemoveVehicle() {
    trackClick(ANALYTICS_EVENTS.REMOVE_VEHICLE);
    if (!isConfirmationShowing) {
      setIsConfirmationShowing(true);
    }
  }

  function handleRemoveConfirmation() {
    const { vin, cid } = vehicle;

    trackClick(ANALYTICS_EVENTS.REMOVE_VEHICLE_CONFIRM, { vin });
    setIsConfirmationShowing(false);
    onProfileChange(ProfileVehicleService.removeVehicle(profileParams, cid));
  }

  function handleRemoveCancel() {
    trackClick(ANALYTICS_EVENTS.REMOVE_VEHICLE_CANCEL);
    setIsConfirmationShowing(false);
  }

  function updateVehicle() {
    return ProfileVehicleService.setVehicleValues(profileParams, vehicle.cid, {
      antiTheftEquipment,
      purchaseDate,
      vinEtching,
    });
  }

  function isDateValid() {
    let dateToValidate;
    if (purchaseDate) {
      const { year, month } = purchaseDate;
      dateToValidate = new DobDate(`${year}-${month}-01`);
    } else {
      dateToValidate = purchaseDate;
    }
    if (profileRulesContext.purchaseDate && dateToValidate.isValid() || !profileRulesContext.purchaseDate) {
      setDateError('');
      return true;
    } else {
      setDateError('Please enter the vehicle’s purchase date.');
      return false;
    }
  }

  function handleSubmit() {
    if (isDateValid()) {
      trackClick(ANALYTICS_EVENTS.SUBMIT);
      onProfileChange(updateVehicle());
    }
  }

  function handleVinEtchingChange() {
    setVinEtching(!vinEtching);
  }

  function toggleAntiTheftEquipment() {
    setAntiTheftEquipment(!antiTheftEquipment);
  }

  function renderHeading() {
    return (
      <>
        <BlackVehicleIcon
          height={'32'}
          width={'44'}
        />
        <ProfileHeading >
          {vehicle.yearMakeAndModelOrVin()}
        </ProfileHeading>
      </>
    );
  }

  function renderVehicleSummary() {
    if (vehicle.vin) {
      return (
        <Input
          disabled={true}
          inputStyle={inputStyle}
          label={'VIN'}
          onChange={noop}
          value={vehicle.vin}
        />
      );
    }

    if (vehicle.shortVin) {
      return (
        <>
          <Input
            disabled={true}
            label={'Year'}
            onChange={noop}
            value={vehicle.year}
            wrapperStyle={styles.vinSkipInput}
          />
          <Input
            disabled={true}
            label={'Make'}
            onChange={noop}
            value={vehicle.make}
            wrapperStyle={styles.vinSkipInput}
          />
          <Input
            disabled={true}
            label={'Model'}
            onChange={noop}
            value={vehicle.model}
            wrapperStyle={styles.vinSkipInput}
          />
          <Input
            disabled={true}
            label={'Trim'}
            onChange={noop}
            value={vehicle.trim}
          />
        </>
      );
    }
    return null;
  }

  function renderConfirmationDialog() {
    if (isConfirmationShowing) {
      return (
        <ConfirmationDialog
          buttonStyles={cssButtonOverrides}
          buttonText={'Remove vehicle'}
          content={'Please confirm that you want to remove this vehicle. All of its info will be deleted from your account.'}
          heading={'Remove vehicle?'}
          linkStyles={linkStyles}
          onCancel={handleRemoveCancel}
          onConfirm={handleRemoveConfirmation}
        />
      );
    }
    return null;
  }

  function renderPurchaseDate() {
    if (profileRulesContext.purchaseDate) {
      return (
        <ProfileReviewPurchaseDate
          date={purchaseDate}
          errorLabel={dateError}
          inputStyle={inputStyle}
          onChange={handleDateChange}
        />
      );
    }
    return null;
  }

  function renderVinEtching() {
    if (profileRulesContext.vinEtching) {
      return (
        <ProfileReviewVinEtching
          circleSelectedStyles={circleSelectedStyles}
          onClick={handleVinEtchingChange}
          toggleSelectedStyles={toggleSelectedStyles}
          vinEtching={vinEtching}
        />
      );
    }
  }

  function renderAntiTheftEquipment() {
    if (profileRulesContext.antiTheftEquipment) {
      return (
        <ProfileReviewAntiTheftEquipment
          antiTheftEquipment={antiTheftEquipment}
          circleSelectedStyles={circleSelectedStyles}
          onClick={toggleAntiTheftEquipment}
          toggleSelectedStyles={toggleSelectedStyles}
        />
      );
    }
  }

  function renderSaveButton() {
    if (profileRulesContext.purchaseDate || profileRulesContext.vinEtching || profileRulesContext.antiTheftEquipment) {
      return (
        <Button
          css={[styles.button, cssButtonOverrides]}
        >
          Save changes
        </Button>
      );
    }
  }

  return (
    <>
      {renderConfirmationDialog()}
      {renderHeading()}
      <ProfileForm onSubmit={handleSubmit}>
        {renderVehicleSummary()}
        {renderVinEtching()}
        {renderAntiTheftEquipment()}
        {renderPurchaseDate()}
        {renderSaveButton()}
        {profileParams?.getAllSelectedVehicles().length > 1 &&
          <div css={styles.linkContainer}>
            <Link
              css={[styles.removeVehicleLink, linkStyles]}
              onClick={handleRemoveVehicle}
            >
              Remove vehicle
            </Link>
          </div>
        }
      </ProfileForm>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
  },
  container: {
    width: '100%',
    margin: '0 20px',
    ...Responsive.md({
      width: '41.666%',
      minWidth: '830px',
    }),
  },
  disableFixedWidths: {
    ...Responsive.md({
      width: 'inherit',
      minWidth: 'inherit',
    }),
  },
  removeVehicleLink: {
    color: Colors.red(),
    ':hover': {
      color: Colors.red(),
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 40,
  },
  vinSkipInput: {
    marginBottom: 3,
  },
});
EditVehicleManager.propTypes = {
  circleSelectedStyles: PropTypes.object,
  cssButtonOverrides: PropTypes.object,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  onProfileChange: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
  toggleSelectedStyles: PropTypes.object,
  trackClick: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  vehicle: PropTypes.instanceOf(Vehicle).isRequired,
};
