import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Separator({ children, className }) {
  const renderWithChildren = () => {
    return (
      <>
        <hr css={styles.lineWithChildren} />
        <span css={styles.content}>{children}</span>
        <hr css={styles.lineWithChildren} />
      </>);
  };
  const renderWithoutChildren = () => {
    return <hr css={styles.lineWithoutChildren} />;
  };

  return (
    <div
      className={className}
      css={styles.wrapper}
    >
      {children ? renderWithChildren(children) : renderWithoutChildren()}
    </div>
  );
}

Separator.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  wrapper: {
    margin: '10px auto',
    display: 'flex',
    justifyContext: 'center',
    alignItems: 'center',
  },
  content: {
    flex: '1 1 auto',
    textAlign: 'center',
    margin: '0 20px',
    ...Theme.paragraph2(),
    color: Colors.black(),
  },
  lineWithChildren: {
    flex: '1 1 50%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid',
    borderBottomColor: Colors.gray30(),
  },
  lineWithoutChildren: {
    flex: '1 1 100%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid',
    borderBottomColor: Colors.gray30(),
  },
});
