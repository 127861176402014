import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { Colors, LegacyStyleSheet as StyleSheet, css } from '@root/core/src/utils/styles';

export class QuotesTransparentButton extends Component {
  static propTypes = {
    buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    styles: PropTypes.object,
    type: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    type: 'button',
  }

  render() {
    return (
      <button
        className={css(styles.button, this.props.styles)}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        ref={this.props.buttonRef}
        type={this.props.type}
      >
        {this.props.children}
      </button>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <QuotesTransparentButton
    {...props}
    buttonRef={ref}
  />
));

export const styles = StyleSheet.create({
  button: {
    width: 'auto',
    height: '30px',
    color: Colors.white(),
    background: 'transparent',
    border: `1px solid ${Colors.white()}`,
    borderRadius: '16px',
    padding: '0 15px',
    ':disabled': {
      color: Colors.gray40(),
      border: `1px solid ${Colors.gray40()}`,
    },
    ':not(:disabled)': {
      cursor: 'pointer',
    },
  },
});
