import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import MediaAlphaResell from '@root/core/src/components/media-alpha-resell';
import MediaAlphaScriptService from '@root/core/src/services/media-alpha-script-service';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_SCOPE = 'BIND_UNDERWRITING_DECLINED_RESELL';

const styles = StyleSheet.create({
  content: {
    height: '100vh',
    justifyContent: 'center',
    padding: '2rem',
  },
});

const bindViewCssOverrides = {
  content: styles.content,
};

const BindUnderwritingDeclinedResell = ({ profileParams }) => {
  const strings = useI18nNext('pages.bindUnderwritingDeclinedResell');
  const placementId = useMemo(() => MediaAlphaScriptService.getDNQPlacementId(isMobileBrowser()), []);
  const request = useMemo(() => MediaAlphaScriptService.buildRequestFromProfile(
    profileParams.serializeForSubmission()
  ), [profileParams]);

  return (
    <BindView
      fullWhite
      cssOverrides={bindViewCssOverrides}
    >
      <MediaAlphaResell
        analyticsContext={ANALYTICS_SCOPE}
        explainerText={strings.info}
        heading={strings.heading}
        placementId={placementId}
        request={request}
      />
    </BindView>
  );
};

BindUnderwritingDeclinedResell.propTypes = {
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
};

export default BindUnderwritingDeclinedResell;
