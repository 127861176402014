import PropTypes from '@root/vendor/prop-types';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import UnderwritingPendingLayout from '@root/quotes/src/components/underwriting-pending-layout';

export default function BindUnderwritingPending({
  quotesContext,
}) {
  const analyticsContext = 'BIND_UNDERWRITING_PENDING';

  return (
    <UnderwritingPendingLayout
      analyticsContext={analyticsContext}
      quotesContext={quotesContext}
    />
  );
}

BindUnderwritingPending.propTypes = {
  quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
};
