import Disclaimer from '@root/profile/src/components/disclaimer';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ProfileDisclaimer({ text }) {
  return (
    <Disclaimer
      css={styles.disclaimerWrapper}
      text={text}
    />
  );
}

ProfileDisclaimer.propTypes = {
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  disclaimerWrapper: {
    marginTop: 45,
  },
});
