import { createPaymentAuthorization } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useCreatePaymentAuthorizationMutation = (options = {}) => {
  return useMutation({
    mutationFn: createPaymentAuthorization,
    mutationKey: ['paymentAuthorization', 'create'],
    ...options,
  });
};

export default useCreatePaymentAuthorizationMutation;
