import PropTypes from '@root/vendor/prop-types';

export default function Container({
  as: RenderComponent = 'div', children, className = '',
}) {
  return (
    <RenderComponent className={`container ${className}`.trim()}>
      {children}
    </RenderComponent>
  );
}

Container.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
};
