import { createCustomQuote } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useCreateCustomQuoteMutation = (options = {}) => {
  return useMutation({
    mutationFn: createCustomQuote,
    mutationKey: ['quote', 'createCustom'],
    ...options,
  });
};

export default useCreateCustomQuoteMutation;
