import BaseButton from '@root/bind.joinroot.com/src/components/button';
import PhoneIcon from '@root/bind.joinroot.com/src/components/icons/phone-icon';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { ANALYTICS_EVENT, ANALYTICS_SCOPE } from '@root/bind.joinroot.com/src/components/speak-to-agent';
import { BUTTON_VARIANT_PRIMARY, BUTTON_VARIANT_STYLES } from '@root/core/src/components/button/styles/variant';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const generateStyles = ({ primaryColor }) => StyleSheet.create({
  button: {
    border: `1px solid ${primaryColor}`,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
});

const styles = StyleSheet.create({
  button: {
    ...BUTTON_VARIANT_STYLES[BUTTON_VARIANT_PRIMARY],
    ...Theme.paragraph2(),
    backgroundColor: 'transparent',
    border: 0,
    color: Colors.black(),
    lineHeight: '100%',
    marginTop: 0,
    minHeight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
    padding: '0.5rem',
    width: 'auto',
    '> svg': {
      height: '0.8rem',
    },
  },
});

const Button = ({ onClickSpeakToAgent }) => {
  const { trackEvent } = useAnalytics(ANALYTICS_SCOPE, false);
  const buttonText = useI18nNext('components.speakToAgent.button');

  const [branding] = useBranding();
  const generatedStyles = useMemo(() => generateStyles(branding), [branding]);
  const partnerName = useI18nNext('partner') ?? '';
  const isCarvana = partnerName.toLowerCase().includes('carvana');

  const handleClick = useCallback(() => {
    trackEvent(ANALYTICS_EVENT.OPEN_MODAL);
    onClickSpeakToAgent();
  }, [onClickSpeakToAgent, trackEvent]);

  const BrandedPhoneIcon = useMemo(() => (props) => {
    const {
      primaryColor,
    } = branding;
    return (
      <PhoneIcon
        color={primaryColor}
        {...props}
      />
    );
  }, [branding]);

  if (!isCarvana && !branding.partnerAgentSupportPhoneNumber) {
    return null;
  }

  return (
    <BaseButton
      cssOverrides={[
        styles.button,
        generatedStyles.button,
      ]}
      iconComponent={BrandedPhoneIcon}
      onClick={handleClick}
    >
      {buttonText}
    </BaseButton>
  );
};

Button.propTypes = {
  onClickSpeakToAgent: PropTypes.func.isRequired,
};

export default Button;
