export default class MaritalStatus {
  static MAPPING = {
    Single: 'Single',
    Married: 'Married',
    Widowed: 'Widowed',
  }

  static SINGLE = MaritalStatus.MAPPING['Single'];
  static MARRIED = MaritalStatus.MAPPING['Married'];

  static OPTIONS = (maritalStatusChoices) => {
    return maritalStatusChoices.map(([value, label]) => ({
      value,
      label,
    }));
  }
}
