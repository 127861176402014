import pick from '@root/vendor/lodash/pick';

export default class BraintreeHostedFieldRegistry {
  static FieldEvents = [
    'blur',
    'focus',
    'empty',
    'notEmpty',
    'cardTypeChange',
    'validityChange',
    'inputSubmitRequest',
  ];

  fields = {}
  fieldEventHandlers = {}

  register({
    type, selector, placeholder, maskInput = false, handlers = {},
  }) {
    this.fields[type] = {
      selector,
      placeholder,
      maskInput,
    };
    this.fieldEventHandlers[type] = pick(handlers, BraintreeHostedFieldRegistry.FieldEvents);
  }

  registerEventHandlers(hostedFieldsClient) {
    BraintreeHostedFieldRegistry.FieldEvents.forEach((eventName) => {
      hostedFieldsClient.on(eventName, this._braintreeEventHandler(eventName));
    });
  }

  _braintreeEventHandler(eventName) {
    return (braintreeEvent) => {
      const emittedBy = braintreeEvent.emittedBy;
      const eventHandler = this.fieldEventHandlers[emittedBy]?.[eventName];
      if (eventHandler) {
        eventHandler(braintreeEvent.fields[emittedBy], braintreeEvent);
      }
    };
  }
}
