import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ListItemIcon({ icon, altText = '' }) {
  if (typeof icon === 'string') {
    return (
      <img
        alt={altText}
        css={styles.icon}
        src={icon}
      />
    );
  } else if (icon && typeof icon === 'object') {
    return (
      <div
        css={styles.svgContainer}
        data-testid={'list-item-icon-object'}
      >
        {icon}
      </div>
    );
  }

  return null;
}

const styles = StyleSheet.create({
  icon: {
    marginRight: 15,
    ...Responsive.sm({
      marginRight: 25,
    }),
    height: 24,
    width: 24,
  },
  svgContainer: {
    marginRight: 15,
    ...Responsive.sm({
      marginRight: 25,
    }),
    '> svg': {
      height: 24,
      width: 24,
    },
  },
});

ListItemIcon.propTypes = {
  altText: PropTypes.string,
  icon: PropTypes.node,
};
