import QuotesContext from '@root/quotes/src/models/quotes-context';
import useQuoteQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-quote-query';
import { useCallback } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEYS = {
  LAST_FETCHED_QUOTES_CONTEXT_RATING_REQUEST_ID: 'last_fetched_quotes_context_rating_request_id',
  QUOTES_CONTEXT: 'quotes_context',
};

const POLLING_INTERVAL = 1000;

export function usePersistedQuotesContext({ ratingRequestId }) {
  const [quotesContext, setQuotesContext] = usePersistedState({
    deserialize: (persistedItem) => QuotesContext.build(persistedItem),
    storageKey: LOCAL_STORAGE_KEYS.QUOTES_CONTEXT,
  });

  const [lastFetchedQuoteContextRatingRequestId, setLastFetchedQuoteContextRatingRequestId] = usePersistedState({
    storageKey: LOCAL_STORAGE_KEYS.LAST_FETCHED_QUOTES_CONTEXT_RATING_REQUEST_ID,
  });

  const handleFetchedQuotesContext = useCallback((fetchedQuoteContextData) => {
    if (fetchedQuoteContextData?.quotesContext) {
      setLastFetchedQuoteContextRatingRequestId(ratingRequestId);

      setQuotesContext(
        QuotesContext.build(fetchedQuoteContextData.quotesContext)
      );
    }
  }, [ratingRequestId, setLastFetchedQuoteContextRatingRequestId, setQuotesContext]);

  const isQuotesContextCurrent = !!quotesContext && lastFetchedQuoteContextRatingRequestId === ratingRequestId;

  useQuoteQuery({ ratingRequestId }, {
    enabled: !!ratingRequestId && !isQuotesContextCurrent,
    onSuccess: handleFetchedQuotesContext,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    isQuotesContextCurrent,
    quotesContext,
    setQuotesContext,
  };
}
