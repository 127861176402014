import PropTypes from '@root/vendor/prop-types';
import { RootError } from '@root-common/root-errors';
import { createContext, useContext } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'config';

const ConfigContext = createContext();
const SetConfigContext = createContext();

const ConfigProvider = ({ children, value: overrideValue = null }) => {
  const [config, setConfig] = usePersistedState({
    initialValue: overrideValue,
    shouldSkipLoadingFromLocalStorage: !!overrideValue,
    storageKey: LOCAL_STORAGE_KEY,
  });

  return (
    <ConfigContext.Provider value={config}>
      <SetConfigContext.Provider value={setConfig}>
        {children}
      </SetConfigContext.Provider>
    </ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

const useConfig = () => {
  const context = useContext(ConfigContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useConfig must be used within ConfigProvider',
      name: 'ConfigProviderError',
    });
  }

  return context;
};

const ConfigConsumer = ({ children }) => {
  const config = useConfig();
  return children(config);
};

ConfigConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

const useSetConfig = () => {
  const context = useContext(SetConfigContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useConfig must be used within ConfigProvider',
      name: 'ConfigProviderError',
    });
  }

  return context;
};

export {
  ConfigConsumer,
  ConfigProvider,
  useConfig,
  useSetConfig,
};
