import { ComparisonOrder, CoverageLimitTypes, CoverageLimitTypesBySymbolForSort, CoverageOptionChoice, CoverageSymbols, DefaultCoverageOptions } from './constants';

export function sortCoverageBySymbol(symbol, coverages) {
  const limitType = getLimitTypesForSymbolForSort(symbol, coverages);
  const comparisonOrder = limitType === CoverageLimitTypes.DEDUCTIBLE
    ? ComparisonOrder.ASCENDING
    : ComparisonOrder.DESCENDING;

  const sortFn = compareCoverages(limitType, comparisonOrder);
  return coverages.sort(sortFn);
}

export function sortCoverages(coverages) {
  const sortedCoverages = {};

  Object.entries(coverages).forEach(([symbol, symbolCoverages]) => {
    sortedCoverages[symbol] = sortCoverageBySymbol(symbol, symbolCoverages);
  });

  return sortedCoverages;
}

export function compareCoverages(limitType, sortOrder) {
  return (c1, c2) => {
    const firstCoverage = sortOrder === ComparisonOrder.ASCENDING ? c1 : c2;
    const secondCoverage = sortOrder === ComparisonOrder.ASCENDING ? c2 : c1;

    const c1SelectedCoverageOptions = Object.keys(selectedCoverageOptions(c1.coverageOptions || DefaultCoverageOptions, CoverageOptionChoice.YES));
    const c2SelectedCoverageOptions = Object.keys(selectedCoverageOptions(c2.coverageOptions || DefaultCoverageOptions, CoverageOptionChoice.YES));

    const coverageOptionTotalSelectedDifference = c1SelectedCoverageOptions.length - c2SelectedCoverageOptions.length;

    let coverageOptionOrder = 0;

    if (coverageOptionTotalSelectedDifference === 0) {
      coverageOptionOrder = c1SelectedCoverageOptions.sort().join().localeCompare(c2SelectedCoverageOptions.sort().join());
    }

    return coverageOptionTotalSelectedDifference || coverageOptionOrder || firstCoverage[limitType] - secondCoverage[limitType];
  };
}

function selectedCoverageOptions(coverageOptions, matchValue) {
  return Object.fromEntries(Object.entries(coverageOptions).
    filter(([, value]) => value === matchValue));
}

function getLimitTypesForSymbolForSort(symbol, coverages) {
  if (symbol === CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE && coverages.some((c) => c.deductible)) {
    return CoverageLimitTypes.DEDUCTIBLE;
  }

  return CoverageLimitTypesBySymbolForSort[symbol];
}
