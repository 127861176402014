export default class QuoteStrings {
  static TIERS = {
    CUSTOM: 'custom',
    HIGH: 'high',
    LOW: 'low',
    RECOMMENDED: 'recommended',
    STATE_MINIMUM: 'state_minimum',
    RIGHT_QUOTE: 'right_quote',
    LOW_RIGHT_QUOTE: 'low_right_quote',
    RATE_CALL_1_MATCH: 'rate_call_1_match',
    EMBEDDED: 'embedded',
    EMBEDDED_PRIOR_COVERAGE: 'embedded_prior_coverage',
  };

  static TITLE_BY_TIER = {
    [QuoteStrings.TIERS.LOW]: 'Good',
    [QuoteStrings.TIERS.STATE_MINIMUM]: 'Good',
    [QuoteStrings.TIERS.RECOMMENDED]: 'Great',
    [QuoteStrings.TIERS.HIGH]: 'Fantastic',
    [QuoteStrings.TIERS.CUSTOM]: 'Custom',
    [QuoteStrings.TIERS.RATE_CALL_1_MATCH]: 'Great',
    [QuoteStrings.TIERS.EMBEDDED]: 'Great',
    [QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE]: 'Great',
  };

  static DESCRIPTION_BY_TIER = {
    [QuoteStrings.TIERS.LOW]: 'Fulfills state minimum requirements.',
    [QuoteStrings.TIERS.STATE_MINIMUM]: 'Fulfills state minimum requirements.',
    [QuoteStrings.TIERS.RECOMMENDED]: 'A solid amount of coverage.',
    [QuoteStrings.TIERS.HIGH]: 'Higher levels of coverage.',
    [QuoteStrings.TIERS.CUSTOM]: 'You customized your quote.',
    [QuoteStrings.TIERS.RATE_CALL_1_MATCH]: 'A solid amount of coverage.',
    [QuoteStrings.TIERS.EMBEDDED]: 'A solid amount of coverage.',
    [QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE]: 'A solid amount of coverage based on your existing policy.',
  };

  static GENERIC_QUOTE_TITLE = 'Here’s your plan.';
  static GENERIC_QUOTE_DESCRIPTION = 'Here’s a plan that’s built for you.';

  static TITLE_BY_MARKET_BY_TIER = {
    CA: {
      [QuoteStrings.TIERS.LOW]: 'Minimum Coverage',
      [QuoteStrings.TIERS.STATE_MINIMUM]: 'Minimum Coverage',
      [QuoteStrings.TIERS.RECOMMENDED]: 'Medium Coverage',
      [QuoteStrings.TIERS.HIGH]: 'Higher Coverage',
      [QuoteStrings.TIERS.CUSTOM]: QuoteStrings.TITLE_BY_TIER[QuoteStrings.TIERS.CUSTOM],
      [QuoteStrings.TIERS.RATE_CALL_1_MATCH]: QuoteStrings.TITLE_BY_TIER[QuoteStrings.TIERS.RATE_CALL_1_MATCH],
      [QuoteStrings.TIERS.EMBEDDED]: QuoteStrings.TITLE_BY_TIER[QuoteStrings.TIERS.EMBEDDED],
      [QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE]: QuoteStrings.TITLE_BY_TIER[QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE],
    },
  };

  static DESCRIPTION_BY_MARKET_BY_TIER = {
    CA: {
      [QuoteStrings.TIERS.LOW]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.LOW],
      [QuoteStrings.TIERS.STATE_MINIMUM]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.STATE_MINIMUM],
      [QuoteStrings.TIERS.RECOMMENDED]: 'A medium amount of coverage.',
      [QuoteStrings.TIERS.HIGH]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.HIGH],
      [QuoteStrings.TIERS.CUSTOM]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.CUSTOM],
      [QuoteStrings.TIERS.RATE_CALL_1_MATCH]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.RATE_CALL_1_MATCH],
      [QuoteStrings.TIERS.EMBEDDED]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.EMBEDDED],
      [QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE]: QuoteStrings.DESCRIPTION_BY_TIER[QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE],
    },
  };

  static NON_PAY_CANCEL_REPAYMENT = {
    CHARGE_NAME: 'Past due payment',
    CHARGE_DESCRIPTION: 'A one time payment to cover uncollected premium from your previous policy with Root.',
  };
}
