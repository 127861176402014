import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ErrorMessage({ message }) {
  return (
    <div css={styles.container}>
      <p
        aria-label={message}
        css={styles.message}
        role={'alert'}
      >
        {message}
      </p>
    </div>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.errorBackground(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },

  message: {
    ...Theme.paragraph1(),
    color: Colors.error(),
    margin: 0,
  },
});
