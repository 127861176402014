import BindPrefillRequest from '@root/bind.joinroot.com/src/models/bind-prefill-request';
import PropTypes from '@root/vendor/prop-types';
import useCreatePrefillRequestMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-create-prefill-request-mutation';
import { useCallback, useMemo } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'prefill_request';

export const buildPrefillData = (profile) => {
  const pni = profile.drivers?.find((driver) => driver.pni);

  return {
    ...profile,
    dob: pni?.dob,
    firstName: pni?.firstName,
    lastName: pni?.lastName,
    licenseNumber: pni?.licenseNumber,
    licenseState: pni?.licenseState,
    maritalStatus: pni?.maritalStatus,
    universalDriverId: pni?.universalDriverId,
  };
};

export default function usePrefillRequest({ onSuccess, profile }) {
  const initialPrefillRequest = useMemo(() => BindPrefillRequest.buildFromData(buildPrefillData(profile)), [profile]);
  const [currentPrefillRequest, setCurrentPrefillRequest] = usePersistedState({
    deserialize: (persistedItem) => BindPrefillRequest.buildFromData(persistedItem),
    initialValue: initialPrefillRequest,
    storageKey: LOCAL_STORAGE_KEY,
  });

  const {
    mutate: createPrefillMutation,
    isLoading: isCreatingPrefill,
  } = useCreatePrefillRequestMutation({
    onSuccess,
  });

  const createPrefill = useCallback(() => {
    const { mailingAddress, ...prefillData } = currentPrefillRequest.serializeForSubmission();

    createPrefillMutation({
      ...prefillData,
      ...mailingAddress,
    });
  }, [createPrefillMutation, currentPrefillRequest]);

  return {
    createPrefill,
    currentPrefillRequest,
    initialPrefillRequest,
    isCreatingPrefill,
    setCurrentPrefillRequest,
  };
}

usePrefillRequest.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

