import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import closeModal from '@root/core/src/assets/closeModal.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Layout } from '@root/brand/src/utils/layout';

export default function ExplainerCard({ children, onModalClose }) {
  const cardStyles = onModalClose ? [styles.card, styles.modalCard] : [styles.card];

  return (
    <div css={styles.container}>
      <div css={cardStyles}>
        {onModalClose && (
          <div css={styles.modal}>
            <button
              aria-label={'close'}
              css={[
                styles.closeButton,
              ]}
              onClick={onModalClose}
            >
              <img src={closeModal} />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

ExplainerCard.propTypes = {
  children: PropTypes.node.isRequired,
  onModalClose: PropTypes.func,
};

const styles = StyleSheet.create({
  container: {
    flex: '1 0 auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
    ...Responsive.sm({
      alignItems: 'center',
      display: 'flex',
      height: `calc(100vh - ${Layout.navbarHeight()}px)`,
    }),
  },
  card: {
    background: Colors.white(),
    textAlign: 'center',
    maxWidth: '100%',
    ...Responsive.sm({
      margin: 'auto',
      borderRadius: 10,
      border: `1px solid ${Colors.gray30()}`,
      ...Shadows.cardShadow(),
    }),
  },
  modalCard: {
    ...Responsive.sm({
      border: 'none',
    }),
  },
  modal: {
    position: 'relative',
  },
  closeButton: {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    height: 24,
    padding: 0,
    position: 'absolute',
    right: 20,
    top: 20,
    width: 24,
  },
});
