import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function AgeLicensedFieldCell({ children }) {
  return (
    <div css={styles.fieldCell}>
      {children}
    </div>
  );
}

AgeLicensedFieldCell.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  fieldCell: {
    marginTop: 10,
  },
});
