import PropTypes from '@root/vendor/prop-types';
import checkmarkStroke from '@root/core/src/assets/checkmark-stroke.svg';

export default function TriageCheckmark({ className }) {
  return (
    <img
      alt={'Circle with checkmark icon'}
      className={className}
      src={checkmarkStroke}
    />
  );
}

TriageCheckmark.propTypes = {
  className: PropTypes.string,
};
