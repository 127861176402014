import BasePhoneNumberInput from '@root/core/src/components/phone-number-input';
import PrefillRequest from '@root/prefill/src/models/prefill-request';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const ANALYTICS_EVENTS = {
  FOCUSED_ON_PHONE_NUMBER_FIELD: 'FOCUSED_ON_PHONE_NUMBER_FIELD',
};

export default function PhoneNumberInput({
  hidden, onUpdatePrefillRequest, prefillRequest, trackEvent, ...restProps
}) {
  const strings = useI18nNext('components.bindStartEntry.phoneNumberInput');

  const handleChange = useCallback((number) => {
    const updatedPrefillRequest = prefillRequest.set(
      'phoneNumber',
      number
    );
    onUpdatePrefillRequest(updatedPrefillRequest);
  }, [onUpdatePrefillRequest, prefillRequest]);

  const handleFocus = useCallback(() => {
    trackEvent(ANALYTICS_EVENTS.FOCUSED_ON_PHONE_NUMBER_FIELD);
  }, [trackEvent]);

  if (hidden) {
    return null;
  }

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      <BasePhoneNumberInput
        autoFocus={false}
        label={strings.label}
        onChange={handleChange}
        onFocus={handleFocus}
        value={prefillRequest.phoneNumber || ''}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading3(),
  },
});

PhoneNumberInput.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onUpdatePrefillRequest: PropTypes.func.isRequired,
  prefillRequest: PropTypes.instanceOf(PrefillRequest).isRequired,
  trackEvent: PropTypes.func.isRequired,
};
