import Button from '@root/core/src/components/button';
import KeyCode from '@root/core/src/utils/keycode';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileModal from '@root/profile/src/components/profile-modal';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { opacityKeyframes } from '@root/brand/src/utils/animation';

export default class ConfirmationDialog extends Component {
  static propTypes = {
    buttonStyles: PropTypes.object,
    buttonText: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    linkStyles: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.portalNode = document.createElement('div');
    this.portalNode.dataset.testid = 'confirmation-dialog';
  }

  componentDidMount() {
    document.body.appendChild(this.portalNode);
    document.addEventListener('keydown', this._handleKeydown, false);
  }

  componentWillUnmount() {
    this.portalNode.parentNode && this.portalNode.parentNode.removeChild(this.portalNode);
    document.removeEventListener('keydown', this._handleKeydown, false);
  }

  _handleKeydown = (event) => {
    if (event.keyCode === KeyCode.KEYCODES.ESC) {
      this.props.onCancel();
    }
  }

  render() {
    return ReactDOM.createPortal(
      <div css={styles.container}>
        <ProfileModal
          isOverlay={true}
          styles={styles.modalStyles}
        >
          <h3 css={styles.heading}>
            {this.props.heading}
          </h3>
          <p css={styles.content}>
            {this.props.content}
          </p>
          <Button
            css={[styles.button, this.props.buttonStyles]}
            onClick={this.props.onConfirm}
          >
            {this.props.buttonText}
          </Button>
          <NevermindLink
            linkStyles={this.props.linkStyles}
            onCancel={this.props.onCancel}
          />
        </ProfileModal>
      </div>,
      this.portalNode,
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginBottom: 30,
  },
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: Colors.DEPRECATED_overlayShadow(),
    width: '100%',
    height: '100vh',
    padding: '0 24px',
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
    animationName: opacityKeyframes,
    animationDuration: '300ms',
  },
  heading: {
    ...Theme.heading3(),
    marginBottom: 15,
  },
  content: {
    ...Theme.paragraph1(),
  },
  modalStyles: {
    width: '100%',
    zIndex: ZIndex.MODAL,
    ...Responsive.md({
      maxWidth: '465px',
    }),
    padding: '45px 65px',
  },
});
