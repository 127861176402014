import DateInputGroup from '@root/core/src/components/date-input-group';
import DobDate from '@root/core/src/models/dob-date';
import FieldCell from '@root/profile/src/components/edit-driver/field-cell';
import FieldGroup from '@root/profile/src/components/edit-driver/field-group';
import FieldHeading from '@root/profile/src/components/edit-driver/field-heading';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function DobFields({
  dob, errors, inputStyle, isObfuscated, onBlur, onChange,
}) {
  const handleBlur = (field) => (value) => onBlur('dob')(dob.set(field, value));
  const handleChange = (field) => (value) => onChange('dob')(dob.set(field, value));

  return (
    <FieldGroup>
      <FieldHeading>
        Birthday
      </FieldHeading>
      <FieldCell>
        <DateInputGroup
          date={dob}
          errorLabel={errors.dob}
          inputStyle={inputStyle}
          isObfuscated={isObfuscated}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </FieldCell>
    </FieldGroup>
  );
}

DobFields.propTypes = {
  dob: PropTypes.instanceOf(DobDate).isRequired,
  errors: PropTypes.object.isRequired,
  inputStyle: PropTypes.object,
  isObfuscated: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
