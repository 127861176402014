import BackToProfileButton from '@root/bind.joinroot.com/src/components/bind-view/back-to-profile-button';
import Card from '@root/bind.joinroot.com/src/components/bind-view/card';
import ExitFlowButton from '@root/bind.joinroot.com/src/components/bind-view/exit-flow-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, createEmotionClassName } from '@root/core/src/utils/styles';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F5F9FC',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '&:before': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: Colors.white(),
      content: '""',
      height: '40vh',
      width: '100vw',
    },
  },
  containerFullWhite: {
    backgroundColor: Colors.white(),
    '&:before': {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

const BindView = ({
  children,
  cssOverrides = {
    container: {},
    content: {},
  },
  fullWhite,
  ...restProps
}) => {
  return (
    <div
      className={`bind-view ${
        createEmotionClassName(
          styles.container,
          fullWhite && styles.containerFullWhite,
          cssOverrides?.container,
        )
      }`}
      {...restProps}
    >
      <div
        css={[
          styles.content,
          cssOverrides?.content,
        ]}
      >
        {children}
      </div>
    </div>
  );
};

BindView.propTypes = {
  BackToButtonComponent: PropTypes.node,
  children: PropTypes.node.isRequired,
  cssOverrides: PropTypes.shape({
    container: PropTypes.object,
    content: PropTypes.object,
  }),
  fullWhite: PropTypes.bool,
};

BindView.Card = Card;
BindView.ExitFlowButton = ExitFlowButton;
BindView.BackToProfileButton = BackToProfileButton;

export default BindView;
