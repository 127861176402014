import { getQuotingRules } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useQuotingRulesQuery = ({ market }, overrides = {}) => useQuery({
  enabled: !!market,
  queryFn: async () => await getQuotingRules({
    market,
  }),
  queryKey: ['market', market, 'quotingRules'],
  ...overrides,
});

export default useQuotingRulesQuery;
