import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export default function FullTermTotalSavingsText({ savingsTotalIfPaidFullTerm, totalPriceIfPaidMonthly }) {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard.fullTermTotalSavingsText');

  return (
    <div css={styles.container}>
      <p css={styles.paragraph}>
        <span css={styles.price}>{Money.fromCents(totalPriceIfPaidMonthly).formattedDollarsAndCents()}</span>
        <span>{strings.alternativePay}</span>
      </p>
      <p css={styles.paragraph}>
        <span>{strings.saveBeforePrice}</span>
        <span css={[styles.price, styles.green]}>{Money.fromCents(savingsTotalIfPaidFullTerm).formattedDollarsAndCents()}</span>
        <span>{strings.saveAfterPrice}</span>
      </p>
    </div>
  );
}

FullTermTotalSavingsText.propTypes = {
  savingsTotalIfPaidFullTerm: PropTypes.number.isRequired,
  totalPriceIfPaidMonthly: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  container: {
    marginBottom: '0.75rem',
  },
  paragraph: {
    marginBottom: '0.25rem',
    lineHeight: '120%',
    fontSize: '1rem',
    '& > span + span': {
      marginLeft: '0.25rem',
    },
  },
  price: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  green: {
    color: Colors.green(),
  },
});
