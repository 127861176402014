import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';

export default class ProfileRulesContextFactory {
  static createModel(overrides = {}) {
    return new ProfileRulesContext({
      ...this._defaultValues(),
      ...overrides,
    });
  }

  static createModelWithDriverDiscountsEnabled(overrides = {}) {
    return this.createModel({
      ...this.driverDiscountsEnabled(),
      ...overrides,
    });
  }

  static createModelWithDriverDiscountsDisabled(overrides = {}) {
    return this.createModel({
      ...this.driverDiscountsDisabled(),
      ...overrides,
    });
  }

  static createModelWithVehicleDiscountsEnabled(overrides = {}) {
    return this.createModel({
      ...this.vehicleDiscountsEnabled(),
      ...overrides,
    });
  }

  static createModelWithVehicleDiscountsDisabled(overrides = {}) {
    return this.createModel({
      ...this.vehicleDiscountsDisabled(),
      ...overrides,
    });
  }

  static createModelForMarketWithCivilUnion(overrides = {}) {
    return this.createModel({
      maritalStatusChoices: this.maritalStatusChoicesWithCivilUnion(),
      ...overrides,
    });
  }

  static createModelForMarketWithXGender(overrides = {}) {
    return this.createModel({
      genderChoices: this.genderChoicesWithXGender(),
      ...overrides,
    });
  }

  static createModelForMarketWithPurchaseDate(overrides = {}) {
    return this.createModel({
      purchaseDate: true,
      ...overrides,
    });
  }

  static createModelForMarketWithVinEtching(overrides = {}) {
    return this.createModel({
      vinEtching: true,
      ...overrides,
    });
  }

  static createModelForMarketWithAntiTheftEquipment(overrides = {}) {
    return this.createModel({
      antiTheftEquipment: true,
      ...overrides,
    });
  }

  static createWithAccidentPreventionCourse(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      accidentPreventionCourse: true,
      accidentPreventionCourseMinimumAge: 60,
      ...overrides,
    });
  }

  static createWithActiveDutyMilitary(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      activeDutyMilitary: true,
      ...overrides,
    });
  }

  static createWithCollegeGraduate(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      collegeGraduate: true,
      ...overrides,
    });
  }

  static createWithGoodStudent(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      goodStudent: true,
      ...overrides,
    });
  }

  static createWithNationalGuardMember(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      nationalGuardMember: true,
      ...overrides,
    });
  }

  static createWithRefresherPreventionCourse(overrides = {}) {
    return this.createModelWithDriverDiscountsDisabled({
      refresherPreventionCourse: true,
      ...overrides,
    });
  }

  static createModelWithVehicleUsageChoices(overrides = {}) {
    return new ProfileRulesContext({
      ...this._defaultValues(),
      vehicleUsageChoices: this.vehicleUsageChoices(),
      ...overrides,
    });
  }

  static createResponse(overrides = {}) {
    return {
      profileRulesContext: {
        ...this._defaultValues(),
        ...overrides,
      },
    };
  }

  static createResponseForMarketWithCivilUnion(overrides = {}) {
    return this.createResponse({
      maritalStatusChoices: this.maritalStatusChoicesWithCivilUnion(),
      ...overrides,
    });
  }

  static createResponseForMarketWithXGender(overrides = {}) {
    return this.createResponse({
      genderChoices: this.genderChoicesWithXGender(),
      ...overrides,
    });
  }

  static createResponseWithVehicleDiscountsEnabled(overrides = {}) {
    return this.createResponse({
      ...this.vehicleDiscountsEnabled(),
      ...overrides,
    });
  }

  static genderChoices() {
    return [
      ['M', 'Male'],
      ['F', 'Female'],
    ];
  }

  static vehicleUsageChoices() {
    return [
      ['commute', 'Commute to work or school'],
      ['occasional', 'Occasional driving'],
      ['business', 'Business use'],
    ];
  }

  static vehicleUsageChoicesNorthCarolina() {
    return [
      ['work', 'Commute to work or school'],
      ['pleasure', 'Occasional driving'],
      ['business', 'Business use'],
      ['rideshare', 'Rideshare'],
      ['farm', 'Farming'],
    ];
  }

  static genderChoicesWithXGender() {
    return [
      ['M', 'Male'],
      ['F', 'Female'],
      ['X', 'Not Specified'],
    ];
  }

  static maritalStatusChoices() {
    return [
      ['Single', 'Single'],
      ['Married', 'Married'],
      ['Widowed', 'Widowed'],
    ];
  }

  static maritalStatusChoicesWithCivilUnion() {
    return [
      ['Single', 'Single'],
      ['Married', 'Married / Civil Union'],
      ['Widowed', 'Widowed'],
    ];
  }

  static durationWithoutInsuranceChoices() {
    return [
      'Less than a month',
      '1-2 months',
      '3 months or more',
    ];
  }

  static previousInsurerDurationInsuredChoices() {
    return [
      'Less than a year',
      '1 to 2 years',
      '2 to 3 years',
      '3 years or more',
    ];
  }

  static userSpecifiedYearsLicensedSelectionChoices() {
    return [
      'Less than 1 year',
      'Between 1 and 2 years',
      'Between 2 and 3 years',
      'More than 3 years',
    ];
  }

  static driverDiscountsEnabled() {
    return {
      accidentPreventionCourse: true,
      activeDutyMilitary: true,
      collegeGraduate: true,
      goodStudent: true,
      nationalGuardMember: true,
      driverVehicleUsage: true,
      refresherPreventionCourse: true,
      vehicleUsage: true,
    };
  }

  static driverDiscountsDisabled() {
    return {
      accidentPreventionCourse: false,
      accidentPreventionCourseMinimumAge: null,
      activeDutyMilitary: false,
      collegeGraduate: false,
      goodStudent: false,
      nationalGuardMember: false,
      driverVehicleUsage: false,
      refresherPreventionCourse: false,
      vehicleUsage: false,
    };
  }

  static vehicleDiscountsEnabled() {
    return {
      antiTheftEquipment: true,
      purchaseDate: true,
      vinEtching: true,
    };
  }

  static vehicleDiscountsDisabled() {
    return {
      antiTheftEquipment: false,
      purchaseDate: false,
      vinEtching: false,
    };
  }

  static _defaultValues() {
    return {
      accidentPreventionCourse: true,
      activeDutyMilitary: false,
      ageWhenLicensed: false,
      antiTheftEquipment: false,
      allowTestDriveOptIn: true,
      askPriorInsuranceQuestions: false,
      collegeGraduate: false,
      driverVehicleAssignments: false,
      durationWithoutInsuranceChoices: this.durationWithoutInsuranceChoices(),
      earlyOnboardingGaragingAddressConfirmation: false,
      garagingZipRequiredMarket: false,
      garagingAddressWebEnabled: true,
      genderChoices: this.genderChoices(),
      goodStudent: false,
      inexperiencedOperator: false,
      maritalStatusChoices: this.maritalStatusChoices(),
      nationalGuardMember: false,
      driverVehicleUsage: false,
      previousInsurerDurationInsuredChoices: this.previousInsurerDurationInsuredChoices(),
      purchaseDate: false,
      ratingMunicipality: false,
      refresherPreventionCourse: false,
      takeAllComersMarket: false,
      testDriveDisabledMarkets: ['CA'],
      useGender: true,
      userSpecifiedYearsLicensedSelectionChoices: this.userSpecifiedYearsLicensedSelectionChoices(),
      vehicleClassCode: false,
      vehicleUsage: false,
      vehicleUsageChoices: [],
      vinEtching: false,
    };
  }
}
