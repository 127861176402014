import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RightQuoteDivider({ text }) {
  let middle = <div css={styles.circle} />;
  if (text) {
    middle = <div css={styles.text}>{text}</div>;
  }

  return (
    <div
      css={styles.container}
      role={'separator'}
    >
      <div css={styles.divider} />
      {middle}
      <div css={styles.divider} />
    </div>
  );
}

RightQuoteDivider.propTypes = {
  text: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0px',
  },
  divider: {
    backgroundColor: Colors.gray30(),
    height: 1,
    width: '100%',
  },
  text: {
    ...Theme.captionBold(),
    color: Colors.nearBlack(),
    margin: '0 20px',
    whiteSpace: 'nowrap',
  },
  circle: {
    backgroundColor: Colors.gray30(),
    height: 9,
    minWidth: 9,
    borderRadius: 50,
    margin: '0 10px',
  },
});
