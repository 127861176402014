import BraintreeClient from 'braintree-web/client';
import BraintreeHostedFieldRegistry from '@root/payments/src/services/braintree/braintree-hosted-field-registry';
import BraintreeHostedFields from 'braintree-web/hosted-fields';
import BraintreeResult from '@root/payments/src/services/braintree/braintree-result';
export { BraintreeHostedFieldRegistry };

export default class BraintreeService {
  constructor() {
    this.fieldRegistry = new BraintreeHostedFieldRegistry();
    this.client = false;
    this.hostedFields = false;
  }

  async authorize(authorization) {
    try {
      this.client = await BraintreeClient.create({
        authorization,
      });
      return BraintreeResult.success();
    } catch (err) {
      return BraintreeResult.error(err);
    }
  }

  async createHostedFields(styles = {}) {
    try {
      this.hostedFields = await BraintreeHostedFields.create({
        styles,
        client: this.client,
        fields: this.fieldRegistry.fields,
      });
      this.fieldRegistry.registerEventHandlers(this.hostedFields);
      return BraintreeResult.success();
    } catch (err) {
      return BraintreeResult.error(err);
    }
  }

  async tokenize() {
    try {
      const payload = await this.hostedFields.tokenize();
      return BraintreeResult.success(payload.nonce);
    } catch (err) {
      return BraintreeResult.error(err);
    }
  }

  async teardown() {
    try {
      await this.hostedFields.teardown();
      return BraintreeResult.success();
    } catch (err) {
      return BraintreeResult.error(err);
    }
  }
}
