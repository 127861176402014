import ListCardItem from '@root/core/src/components/list-card-item';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RentalCoverageDisclaimer({ market }) {
  if (market !== 'CA') { return null; }

  return (
    <ListCardItem>
      <div css={styles.wrapper}>
        Any vehicles for which Rental is selected will have the same coverage limits.
      </div>
    </ListCardItem>
  );
}

RentalCoverageDisclaimer.propTypes = {
  market: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    ...Theme.paragraph2(),
    display: 'flex',
  },
});
