import PropTypes from '@root/vendor/prop-types';
import QuotesMobileSelectOption from '@root/quotes/src/components/form-elements/quotes-mobile-select-option';
import QuotesTransparentButton from '@root/quotes/src/components/quotes-transparent-button';
import React, { Component } from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import ZIndex from '@root/core/src/utils/z-index';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { opacityKeyframes } from '@root/brand/src/utils/animation';

export default class QuotesMobileSelectMenu extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    mobileSelectedOptionStyles: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
      value: PropTypes.string.isRequired,
    })).isRequired,
    selectedValue: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.portalNode = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.portalNode);
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';
    this.portalNode.parentNode && this.portalNode.parentNode.removeChild(this.portalNode);
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return ReactDOM.createPortal(
      <div
        css={styles.overlay}
        data-testid={'mobile-select-menu'}
        onClick={this.props.onClose}
      >
        <div css={styles.menuWrapper}>
          <div css={styles.buttonWrapper}>
            <QuotesTransparentButton>Close</QuotesTransparentButton>
          </div>
          <div css={styles.menu}>
            {this.props.options.map((option) => (
              <QuotesMobileSelectOption
                key={option.value}
                mobileSelectedOptionStyles={this.props.mobileSelectedOptionStyles}
                onClick={this.props.onOptionClick}
                option={option}
                selectedValue={this.props.selectedValue}
              />
            ))}
          </div>
        </div>
      </div>,
      this.portalNode,
    );
  }
}

const styles = StyleSheet.create({
  menu: {
    ...Theme.paragraph1(),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...Theme.roundedCorners(),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    border: `1px solid ${Colors.gray30()}`,
    color: Colors.gray50(),
    background: Colors.white(),
    cursor: 'pointer',
    zIndex: ZIndex.MOBILE_DROPDOWN_MENU,
    boxShadow: `0 5px 20px 3px ${Colors.DEPRECATED_blackShadow()}`,
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'scroll',
    animationName: opacityKeyframes,
    animationDuration: '300ms',
    '::-webkit-scrollbar': {
      width: 0,
    },
  },
  menuWrapper: {
    margin: '0 10px',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    background: Colors.DEPRECATED_overlayShadowDark(),
    width: '100%',
    height: 'calc(100vh - 70px)',
    zIndex: ZIndex.DROPDOWN_OVERLAY_BACKGROUND,
    animationName: opacityKeyframes,
    animationDuration: '300ms',
  },
});
