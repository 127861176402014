import GaragingAddressSection from '@root/profile/src/components/garaging-address/garaging-address-section';
import IconTextRow from '@root/core/src/components/icon-text-row';
import Input, { TextTypes } from '@root/core/src/components/input';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileModalButton from '@root/profile/src/components/profile-modal-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import UpdateGaragingAddress from '@root/profile-review/src/components/update-garaging-address'; // eslint-disable-line root/no-circular-dependencies
import Vehicle from '@root/core/src/models/vehicle';
import VehicleCreationParams from '@root/auto-pricing/src/models/vehicle-creation-params';
import Vin from '@root/core/src/models/vin';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import vinDashboard from '@root/core/src/assets/vinDashboard.svg';
import vinJamb from '@root/core/src/assets/vinJamb.svg';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ProfileAddVehicleVin(props) {
  const { trackClick } = useAnalytics(`${props.module}_PROFILE_ADD_VEHICLE_VIN`);

  const [vin, setVin] = useState('');
  const [error, setError] = useState('');
  const [showGaragingAddressModal, setShowGaragingAddressModal] = useState(false);
  const [workingGaragingAddress, setWorkingGaragingAddress] = useState();

  function _handleChangeInput(value) {
    setVin(value.toUpperCase());
  }

  function _handleCancel() {
    trackClick('CANCEL');
    props.onCancel();
  }

  function _handleSubmit() {
    trackClick('SUBMIT');
    const { isValid, error: errorMessage } = Vin.validate(vin, props.vehicles);

    if (isValid) {
      props.onDone(props.vehicleCreationParams.set('vin', vin));
    } else {
      setError(errorMessage);
    }
  }

  const handleGaragingAddressPressed = () => {
    trackClick('GARAGING_ADDRESS');

    setShowGaragingAddressModal(true);
  };

  const handleGaragingAddressModalOnDone = (address) => {
    setShowGaragingAddressModal(false);
    setWorkingGaragingAddress(address);
  };

  const _renderGaragingAddress = () => {
    if (props.garagingAddressEnabled) {
      if (workingGaragingAddress?.garagingAddress1) {
        props.vehicleCreationParams.set('garagingAddress1', workingGaragingAddress.garagingAddress1);
        props.vehicleCreationParams.set('garagingAddress2', workingGaragingAddress.garagingAddress2);
        props.vehicleCreationParams.set('garagingCity', workingGaragingAddress.garagingCity);
        props.vehicleCreationParams.set('garagingState', workingGaragingAddress.garagingState);
        props.vehicleCreationParams.set('garagingZip', workingGaragingAddress.garagingZip);
      } else if (!props.vehicleCreationParams.garagingAddress1) {
        props.vehicleCreationParams.set('garagingAddress1', props.profileParams.mailingAddress.line1);
        props.vehicleCreationParams.set('garagingAddress2', props.profileParams.mailingAddress.line2);
        props.vehicleCreationParams.set('garagingCity', props.profileParams.mailingAddress.city);
        props.vehicleCreationParams.set('garagingState', props.profileParams.mailingAddress.state);
        props.vehicleCreationParams.set('garagingZip', props.profileParams.mailingAddress.zip);
      }

      const garagingAddress = {
        garagingAddress1: props.vehicleCreationParams.garagingAddress1,
        garagingAddress2: props.vehicleCreationParams.garagingAddress2,
        garagingCity: props.vehicleCreationParams.garagingCity,
        garagingState: props.vehicleCreationParams.garagingState,
        garagingZip: props.vehicleCreationParams.garagingZip,
      };

      return (
        <GaragingAddressSection
          onPress={() => handleGaragingAddressPressed()}
          workingGaragingAddress={garagingAddress}
        />
      );
    }
  };

  return (
    <>
      {!showGaragingAddressModal && (
        <div>
          <ProfileHeading>
            What’s the VIN?
          </ProfileHeading>
          <ProfileForm onSubmit={_handleSubmit}>
            <Input
              focusOnMount
              errorLabel={error}
              inputStyle={props.inputStyle}
              inputType={TextTypes.TEXT}
              label={'VIN'}
              onChange={_handleChangeInput}
              value={vin}
            />
            <p css={styles.content}>
              Don’t have the Vehicle Identification Number (VIN) handy? Here’s where to find it.
            </p>
            <IconTextRow
              heading={'Driver side dashboard'}
              icon={vinDashboard}
              subtext={'Just look through the windshield.'}
            />
            <IconTextRow
              heading={'Driver side door jamb'}
              icon={vinJamb}
              subtext={'Look for a sticker with lots of numbers and letters.'}
            />
            {_renderGaragingAddress()}
            <ProfileModalButton
              cssButtonOverrides={props.cssButtonOverrides}
              disabled={vin.length !== Vin.LENGTH}
            >
              {'Add vehicle'}
            </ProfileModalButton>
            <NevermindLink
              linkStyles={props.linkStyles}
              onCancel={_handleCancel}
            />
          </ProfileForm>
        </div>
      )}
      {showGaragingAddressModal &&
        <UpdateGaragingAddress
          module={`${props.module}`}
          onDone={handleGaragingAddressModalOnDone}
          profileParams={props.profileParams}
          workingGaragingAddress={workingGaragingAddress}
        />
      }
    </>
  );
}

ProfileAddVehicleVin.propTypes = {
  cssButtonOverrides: PropTypes.object,
  garagingAddressEnabled: PropTypes.bool,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  module: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  profileParams: PropTypes.object,
  vehicleCreationParams: PropTypes.instanceOf(VehicleCreationParams).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.instanceOf(Vehicle)).isRequired,
};

const styles = StyleSheet.create({
  content: {
    marginTop: 30,
    ...Theme.paragraph1(),
  },
});
