import { getTerritoryContext } from '@root/profile-review/src/api/root-server';
import { useQuery } from '@root/vendor/react-query';

const useTerritories = ({ market }, overrides = {}) => useQuery({
  enabled: !!market,
  queryFn: async () => await getTerritoryContext(market),
  queryKey: ['market', market, 'territories'],
  ...overrides,
});

export default useTerritories;
