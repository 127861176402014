import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import DriverVehicleMap from '@root/auto-pricing/src/models/driver-vehicle-map';
import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileIcon from '@root/profile/src/components/profile-icon';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import RadioInputGroup from '@root/core/src/components/radio-input-group';
import RadioOption from '@root/core/src/components/radio-option';
import React, { Component, Fragment } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import SubHeading from '@root/profile/src/components/sub-heading';
import car from '@root/core/src/assets/car.svg';

export default class DriverVehicleAssignment extends Component {
  static propTypes = {
    brandedColor: PropTypes.string,
    cssButtonOverrides: PropTypes.object,
    onDriverVehicleAssignmentChange: PropTypes.func,
    onVehicleUsageChange: PropTypes.func.isRequired,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  };

  static defaultProps = {
    onDriverVehicleAssignmentChange: () => { },
  };

  static analyticsContext = 'PROFILE_DRIVER_VEHICLE_ASSIGNMENT';

  constructor(props) {
    super(props);

    const driverVehicleMap = ProfileDriverVehicleAssignmentService.buildDriverVehicleMap(
      this.props.profileParams
    );
    const firstMapping = this.props.profileParams.driverVehicleAssignments.length === 0;
    const hasUnassignedDrivers = true;

    this.state = {
      driverVehicleMap,
      firstMapping,
      hasUnassignedDrivers,
    };
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(DriverVehicleAssignment.analyticsContext, {
      currentAssignments: this.props.profileParams.driverVehicleAssignments.length,
      assignmentStatus: ProfileDriverVehicleAssignmentService.areValidAssignments(this.props.profileParams),
    });
    this.setState({
      hasUnassignedDrivers: this._areDriversUnassigned(),
    });
  }

  _drivers = () => this.props.profileParams.unassignedDrivers()

  _vehicles = () => this.props.profileParams.getAllSelectedVehicles()

  _originalUniversalDriverId() {
    return this.props.profileParams.originalUniversalDriverId;
  }

  _hasOnlyOneDriver() {
    return this.props.profileParams.getSelectedDrivers().length === 1;
  }

  _areDriversUnassigned() {
    return !this.state.driverVehicleMap.allDriversAssigned();
  }

  _isCurrentAssociation = (universalDriverId, vehicleCid) => {
    return this.state.driverVehicleMap.associations[universalDriverId]?.cid === vehicleCid;
  }

  _handleSubmit = () => {
    if (this._areDriversUnassigned()) {
      return;
    }

    let profileParams = ProfileDriverVehicleAssignmentService.mergeDriverVehicleMap({
      profileParams: this.props.profileParams,
      driverVehicleMap: this.state.driverVehicleMap,
    });

    if (this._hasOnlyOneDriver()) {
      let vehicleDriverMap = ProfileDriverVehicleAssignmentService.buildVehicleDriverMap(
        profileParams
      );

      const driver = this.props.profileParams.getSelectedDrivers()[0];
      profileParams.unassignedVehicles().forEach((vehicle) => {
        vehicleDriverMap = vehicleDriverMap.associateVehicleToDriver(vehicle.cid, driver, false);
      });

      profileParams = ProfileDriverVehicleAssignmentService.mergeVehicleDriverMap({
        profileParams,
        vehicleDriverMap,
      });
    }

    AnalyticsService.trackClickEvent(DriverVehicleAssignment.analyticsContext, 'CONTINUE', {
      previousAssignments: this.props.profileParams.driverVehicleAssignments.length,
      currentAssignments: profileParams.driverVehicleAssignments.length,
      assignmentStatus: ProfileDriverVehicleAssignmentService.areValidAssignments(profileParams),
    });
    this.props.onVehicleUsageChange(profileParams);
    this.props.onDriverVehicleAssignmentChange(profileParams);
  };

  _handleSelect = (universalDriverId) => (event) => {
    const selectedVehicle = this._vehicles().find((v) => v.cid === event.target.value);

    const driverVehicleMap = this.state.driverVehicleMap.toggleDriverVehicleAssociation(
      universalDriverId,
      selectedVehicle,
      true,
    );

    const trackEvents = {
      [DriverVehicleMap.CHANGE.ASSOCIATION]: 'ASSOCIATE_DRIVER_VEHICLE',
      [DriverVehicleMap.CHANGE.REASSIGN]: 'REASSIGN_DRIVER_VEHICLE',
      [DriverVehicleMap.CHANGE.DISSOCIATION]: 'DISSOCIATE_DRIVER_VEHICLE',
    };
    const trackEvent = trackEvents[driverVehicleMap.change(this.state.driverVehicleMap)];
    AnalyticsService.trackClickEvent(DriverVehicleAssignment.analyticsContext, trackEvent);

    this.setState({
      driverVehicleMap,
    });
  }

  _renderDriverRadioInputGroups() {
    return this._drivers().map((driver, index) => (
      <Fragment key={driver.key()}>
        <SubHeading>{driver.universalDriverId === this._originalUniversalDriverId() ? 'You' : driver.firstName}</SubHeading>
        <RadioInputGroup>
          {this._renderVehicleRadioOptions(driver, index)}
        </RadioInputGroup>
      </Fragment>
    ));
  }

  _renderVehicleRadioOptions(driver, index) {
    return this._vehicles().map((vehicle) => (
      <Fragment key={vehicle.cid}>
        <RadioOption
          circleColor={this.props.brandedColor}
          id={`vehicle-${vehicle.cid}-driverIndex-${index}`}
          isSelected={this._isCurrentAssociation(driver.universalDriverId, vehicle.cid)}
          label={vehicle.yearMakeAndModelOrVin()}
          name={'driver-vehicle-assignment-radio'}
          onClick={this._handleSelect(driver.universalDriverId)}
          optionValue={vehicle.cid}
        />
      </Fragment>
    ));
  }

  _generateHeading() {
    return this.state.hasUnassignedDrivers ? 'Please select the main vehicle each person drives.' : 'You\'ve already provided what we need. Please continue.';
  }

  render() {
    if (this.state.isSubmitting) {
      return <SceneLoader />;
    }

    return (
      <>
        <ProfileIcon src={car} />
        <ProfileForm onSubmit={this._handleSubmit}>
          <ProfileHeading>
            {this._generateHeading()}
          </ProfileHeading>
          {this._renderDriverRadioInputGroups(this._drivers())}
          <Button
            css={this.props.cssButtonOverrides}
            disabled={this._areDriversUnassigned()}
          >
            Continue
          </Button>
        </ProfileForm>
      </>
    );
  }
}
