import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import useQuoteCoveragesForAllBackendOptionsQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-quote-coverages-for-all-backend-options-query';
import { useCallback } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEYS = {
  LAST_FETCHED_QUOTE_COVERAGES_FOR_ALL_BACKEND_OPTIONS_RATING_REQUEST_ID: 'last_fetched_quote_coverages_for_all_backend_options_rating_request_id',
  QUOTE_COVERAGES_CONTEXTS_FOR_BACKEND_OPTIONS: 'quote_coverages_contexts_for_backend_options',
};

const POLLING_INTERVAL = 1000;

export function usePersistedQuoteCoveragesContextsForBackendOptions({
  manualTortMarket,
  quoteId,
  ratingRequestId,
}) {
  const [quoteCoveragesContextsForBackendOptions, setQuoteCoveragesContextsForBackendOptions] = usePersistedState({
    deserialize: (persistedArray) => persistedArray?.map((context) => ({
      ...context,
      quoteCoveragesContext: QuoteCoveragesContext.build(context.quoteCoveragesContext),
    })),
    storageKey: LOCAL_STORAGE_KEYS.QUOTE_COVERAGES_CONTEXTS_FOR_BACKEND_OPTIONS,
  });

  const [lastFetchedQuoteCoveragesContextsForBackendOptionsRatingRequestId, setLastFetchedQuoteCoveragesContextsForBackendOptionsRatingRequestId] = usePersistedState({
    storageKey: LOCAL_STORAGE_KEYS.LAST_FETCHED_QUOTE_COVERAGES_FOR_ALL_BACKEND_OPTIONS_RATING_REQUEST_ID,
  });

  const handleFetchedQuoteCoveragesForAllBackendOptions = useCallback(({ quoteCoveragesContexts }) => {
    if (quoteCoveragesContexts) {
      setLastFetchedQuoteCoveragesContextsForBackendOptionsRatingRequestId(ratingRequestId);

      const builtQuoteCoveragesContextsForBackendOptions = quoteCoveragesContexts.map((context) => ({
        ...context,
        quoteCoveragesContext: QuoteCoveragesContext.build(context.quoteCoveragesContext),
      }));

      setQuoteCoveragesContextsForBackendOptions(builtQuoteCoveragesContextsForBackendOptions);
    }
  }, [ratingRequestId, setLastFetchedQuoteCoveragesContextsForBackendOptionsRatingRequestId, setQuoteCoveragesContextsForBackendOptions]);

  const isQuoteCoveragesContextsForBackendOptionsCurrent =
    !manualTortMarket ||
    !!quoteCoveragesContextsForBackendOptions && lastFetchedQuoteCoveragesContextsForBackendOptionsRatingRequestId === ratingRequestId;

  const { refetch: refetchQuotesCoveragesForAllBackendOptions } = useQuoteCoveragesForAllBackendOptionsQuery({
    manualTortMarket,
    quoteId,
  }, {
    enabled: !!quoteId && !!ratingRequestId && !isQuoteCoveragesContextsForBackendOptionsCurrent,
    onSuccess: handleFetchedQuoteCoveragesForAllBackendOptions,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    isQuoteCoveragesContextsForBackendOptionsCurrent,
    quoteCoveragesContextsForBackendOptions,
    refetchQuotesCoveragesForAllBackendOptions,
    setQuoteCoveragesContextsForBackendOptions,
  };
}
