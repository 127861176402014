import Responsive from '@root/core/src/utils/responsive';
import pollerCar from '@root/core/src/assets/poller-car.png';
import pollerCar2x from '@root/core/src/assets/poller-car@2x.png';
import roadAnimation from '@root/core/src/assets/animations/road.json';
import useLottieAnimation from '@root/core/src/hooks/use-lottie-animation';
import { StyleSheet } from '@root/core/src/utils/styles';
import { memo } from '@root/vendor/react';

export function PollerAnimation() {
  const [animationContainer] = useLottieAnimation(roadAnimation, {
    autoplay: true,
    loop: true,
  });

  return (
    <div
      css={styles.animation}
      ref={animationContainer}
    >
      <img
        css={styles.image}
        src={pollerCar}
        srcSet={`${pollerCar}, ${pollerCar2x}`}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  animation: {
    width: '120%',
    ...Responsive.md({
      width: '100%',
      maxWidth: Responsive.BREAKPOINTS.md,
    }),
  },
  image: {
    position: 'absolute',
    width: '120%',
    ...Responsive.md({
      width: '100%',
      maxWidth: Responsive.BREAKPOINTS.md,
    }),
  },
});

export default memo(PollerAnimation);
