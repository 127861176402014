import AttributionQueryParamService from '@root/attribution/src/services/attribution-query-param-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useMemo } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useQueryParams from '@root/bind.joinroot.com/src/hooks/use-query-params';
import useValidateAuthPrefillMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-validate-auth-prefill-mutation';
import { DeepLink } from '@root/bind.joinroot.com/src/models/deep-link';
import { Partners } from '@root/bind.joinroot.com/src/models/partners';
import { useSetConfig } from '@root/bind.joinroot.com/src/context/config';
import { useSetDeepLink } from '@root/bind.joinroot.com/src/context/deep-link';
import { useSetProfile } from '@root/bind.joinroot.com/src/context/profile';

export const QUERY_PARAM_KEYS = {
  AUTH: 'auth',
};

const BridgeLoadingEntry = ({
  navigateToBind, navigateToWelcome, onLoadingError, setRatingRequestId,
}) => {
  useAnalytics('BRIDGE_LOADING_ENTRY');

  const queryParams = useQueryParams();
  const auth = useMemo(() => queryParams.get(QUERY_PARAM_KEYS.AUTH), [queryParams]);
  const setConfig = useSetConfig();
  const setProfile = useSetProfile();
  const setDeepLink = useSetDeepLink();

  const {
    mutate: validateAuth,
  } = useValidateAuthPrefillMutation({
    onError: onLoadingError,
    onSuccess: (responseData) => {
      if (responseData?.accessToken && responseData?.bindProfile) {
        // Access token and current user context is set by hook's default onSuccess
        // method so we're just validating their presence. See source for more info
        const { cancelUrl, successUrl } = responseData.bindProfile;
        setConfig({
          cancelUrl: cancelUrl ?? queryParams.get('cancelUrl'),
          partner: AttributionQueryParamService.read()?.pid ?? Partners.CARVANA,
          // this is the only way we figure out a relation to carvana elsewhere, but it's not currently wired up to work on bind.joinroot.com.
          // change to grabbing partner out of a context/from query params if we decide to use those routes
          successUrl: successUrl ?? queryParams.get('successUrl'),
        });
        setDeepLink(new DeepLink({
          actionId: queryParams.get('action_id')?.toUpperCase(),
          actionTarget: queryParams.get('action_target'),
        }));
        setProfile(responseData.bindProfile);
        setRatingRequestId(
          queryParams.get('rating_request_id')
        );
        if (responseData?.brandingConfiguration?.displayWelcomeScene) {
          navigateToWelcome();
        } else {
          navigateToBind();
        }
      }
    },
  });

  useEffect(() => {
    if (auth) {
      validateAuth({ auth });
    } else {
      onLoadingError(); // If a user doesn't have an auth param then they shouldn't be here, redirect
    }
  }, [auth, onLoadingError, validateAuth]);

  return (
    <SceneLoader
      hideHeader={true}
    />
  );
};

BridgeLoadingEntry.propTypes = {
  navigateToBind: PropTypes.func.isRequired,
  navigateToWelcome: PropTypes.func.isRequired,
  onLoadingError: PropTypes.func.isRequired,
  setRatingRequestId: PropTypes.func.isRequired,
};

export default BridgeLoadingEntry;
