import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Header from '@root/bind.joinroot.com/src/components/bind-view/header';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SplashLoader from '@root/bind.joinroot.com/src/components/bind-welcome-entry/splash-loader';
import useAnalytics from '@root/core/src/hooks/use-analytics';

import CoBrandedLogo from '@root/bind.joinroot.com/src/components/bind-view/header/co-branded-logo';
import { StyleSheet, Theme, createEmotionClassName } from '@root/core/src/utils/styles';
import { keyframes } from '@root/vendor/@emotion/core';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const fadeInFromTop = keyframes({
  '0%': {
    transform: 'translate3d(0px, -2em, 0px)',
    opacity: 0,
    pointerEvents: 'none',
  },
  '100%': {
    transform: 'translate3d(0px, 0px, 0px)',
    opacity: 1,
    pointerEvents: 'auto',
  },
});

export const fadeInFromBottom = keyframes({
  '0%': {
    transform: 'translate3d(0px, 2em, 0px)',
    opacity: 0,
    pointerEvents: 'none',
  },
  '100%': {
    transform: 'translate3d(0px, 0px, 0px)',
    opacity: 1,
    pointerEvents: 'auto',
  },
});

const styles = StyleSheet.create({
  animationContainer: {
    width: '100%',
    position: 'relative',
  },
  logo: {
    display: 'flex',
    flex: 0,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,

    ...Responsive.lessThanMd({
      paddingBottom: 10,
    }),

    img: {
      maxHeight: '6.0em',
    },
  },
  containerShared: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    marginTop: -60,

    'img, svg': {
      display: 'flex',
      flexGrow: 1,
      maxHeight: '25vh',
      objectFit: 'contain',
      width: 'auto',

      ...Responsive.lessThanMd({
        maxHeight: '50vh',
      }),
    },

    img: {
      maxWidth: '35vw',

      ...Responsive.lessThanMd({
        maxWidth: '60vw',
      }),
    },

    svg: {
      maxWidth: '30vw',

      ...Responsive.lessThanMd({
        maxWidth: '50vw',
      }),
    },

    ...Responsive.lessThanMd({
      justifyContent: 'flex-start',
      marginTop: -70,
    }),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flex: 0,
    justifyContent: 'center',
    textAlign: 'left',
    width: '100%',
    ...Responsive.md({
      maxWidth: Responsive.BREAKPOINTS.sm,
    }),
  },
  message: {
    width: '100%',

    h3: {
      width: '100%',
      ...Theme.heading3(),
    },
  },
});

const animationStyles = ({ screenTimeout }) => StyleSheet.create({
  logo: {
    animation: `${screenTimeout / 5}ms ${fadeInFromTop}`,
  },
  message: {
    animation: `${screenTimeout / 4}ms ${fadeInFromBottom}`,
  },
});

export const TransitionDuration = {
  display: 3500,
};

const bindViewCssOverrides = {
  content: styles.content,
};

const BindWelcomeEntry = ({
  displayMs,
  onWelcomed,
  skipAnimation,
}) => {
  useAnalytics('BIND_WELCOME_ENTRY');
  const strings = useI18nNext('pages.bindWelcome');

  const [animating, setAnimating] = useState(!skipAnimation);
  const handleAnimationComplete = useCallback(() => setAnimating(false), []);

  const screenDisplayTimeout = useMemo(() => displayMs ?? TransitionDuration.display, [displayMs]);
  const animatedStyles = useMemo(() => animationStyles({ screenTimeout: screenDisplayTimeout }), [screenDisplayTimeout]);
  useEffect(() => {
    const welcomeTimeout = setTimeout(onWelcomed, screenDisplayTimeout);

    return () => clearTimeout(welcomeTimeout);
  }, [onWelcomed, screenDisplayTimeout]);

  return (
    <>
      <Header />
      <BindView
        fullWhite
        cssOverrides={bindViewCssOverrides}
      >
        <div css={styles.containerShared}>
          {
            animating ?
              <SplashLoader
                onComplete={handleAnimationComplete}
              /> :
              <>
                <CoBrandedLogo
                  className={
                    createEmotionClassName([
                      styles.logo,
                      animatedStyles.logo,
                    ])
                  }
                />
                <div css={styles.message}>
                  <h3
                    css={animatedStyles?.message}
                  >
                    {unescape(strings?.message)}

                  </h3>
                </div>
              </>
          }
        </div>
      </BindView>
    </>
  );
};

BindWelcomeEntry.propTypes = {
  displayMs: PropTypes.number,
  onWelcomed: PropTypes.func.isRequired,
  skipAnimation: PropTypes.bool,
};

export default BindWelcomeEntry;
