import AutoQuoteDisclaimerFactory from '@root/quotes/test/support/factories/auto-quote-disclaimer-factory';
import ServerUtils from '@root/core/src/api/server-utils';
import { RootError } from '@root-common/root-errors';
import { getSupportedMarkets as baseGetSupportedMarkets } from '@root/prefill/src/api/root-server';

export { createPolicy } from '@root/quotes/src/api/root-server';

const ENDPOINTS = {
  // CREATE_POLICY: '/web_api/policies',
  CREATE_PREFILL_REQUEST: '/bind_api/web/prefills',
  CREATE_CUSTOM_QUOTE: '/bind_api/web/custom_quote',
  CREATE_PAYMENT_AUTHORIZATION: '/bind_api/web/payment_authorization',
  CREATE_QUOTE: '/bind_api/web/quotes',
  // AFFIRM_DISCLAIMERS: ({ userId, quoteId }) => `/bind_api/users/${userId}/disclaimers/${quoteId}/affirm`,
  // GET_DISCLAIMERS: ({ userId, quoteId }) => `/bind_api/users/${userId}/disclaimers/${quoteId}`,
  // GET_MARKET_CLASSIC_ONLY_CHECK: ({ market }) => `/bind_api/markets/${market.toUpperCase()}/classic_only`,
  GET_MARKET_RULES: '/web_api/contexts/profile_rules_context/',
  GET_PREFILL_REQUEST: '/bind_api/web/prefills',
  GET_QUOTE: ({ ratingRequestId }) => `/bind_api/web/quotes/${ratingRequestId}`,
  GET_QUOTE_COVERAGES: '/bind_api/web/quote_coverages',
  GET_QUOTE_COVERAGES_FOR_ALL_BACKEND_OPTIONS: '/bind_api/web/quote_coverages/for_all_backend_options',
  GET_QUOTING_RULES: '/bind_api/web/quoting_rules',
  MARK_QUOTE_VIEWED: '/bind_api/web/quotes/mark_viewed',
  RERATE_WITH_BACKEND_OPTIONS: '/bind_api/web/quotes/rerate_with_backend_options',
  VALIDATE_ACCESS_TOKEN: '/bind_api/web/validate_token',
  VALIDATE_AUTH_PREFILL: '/bind_api/web/validate',
};

/* Resolve value with simulated traffic time */
const fakeApiResponseTime = 500;
const fakeApiResolve = (value, timeout = fakeApiResponseTime) => new Promise((resolve) => setTimeout(() => resolve(value), timeout));

export const createCustomQuote = async (customQuoteParams) => {
  const { data } = await ServerUtils.authenticatedPost(
    ENDPOINTS.CREATE_CUSTOM_QUOTE,
    {
      body: customQuoteParams,
      expectedResponse: 201,
      expectedErrorResponses: [422],
    },
  );
  if (data.error?.details?.originalMessage) {
    // This API endpoint has a special error response structure
    // See https://github.com/Root-App/root-monorepo/blob/main/server/systems/quoting/bind_api/app/controllers/bind_api/web/quotes_controller.rb#L54 for details
    throw data.error.details.originalMessage;
  }
  return data.quote;
};

export const createPaymentAuthorization = async () => {
  const { data } = await ServerUtils.authenticatedPost(
    ENDPOINTS.CREATE_PAYMENT_AUTHORIZATION,
    {
      expectedResponse: 201,
      expectedErrorResponses: [422],
    }
  );
  if (data.error) {
    throw data.error;
  }
  return data.authorizationToken;
};

export const createPrefillRequest = async (prefillRequestParams = {}) => {
  const { data } = await ServerUtils.authenticatedPost(
    ENDPOINTS.CREATE_PREFILL_REQUEST,
    {
      body: prefillRequestParams,
      expectedResponse: 201,
      expectedErrorResponses: [422],
    }
  );
  if (data.error) {
    throw data.error;
  }
  return data;
};

export const createQuote = async (quoteParams = {}) => {
  const { data } = await ServerUtils.authenticatedPost(
    ENDPOINTS.CREATE_QUOTE,
    {
      body: quoteParams,
      expectedResponse: 201,
      expectedErrorResponses: [422],
    }
  );
  if (data.error) {
    throw data.error;
  }

  return data;
};

export const rerateWithBackendOptions = async ({ rateId, backendOptions } = {}) => {
  const { data } = await ServerUtils.authenticatedPost(
    ENDPOINTS.RERATE_WITH_BACKEND_OPTIONS,
    {
      body: {
        backendOptions,
        rateId,
      },
      expectedResponse: 201,
      expectedErrorResponses: [422],
    }
  );

  if (data.error) {
    throw data.error;
  }

  return data;
};

export const getSupportedMarkets = async () => {
  const data = await baseGetSupportedMarkets();
  if (data.error) {
    throw data.error;
  }
  return data.supportedMarkets;
};

export const getDisclaimers = async ({
  userId, quoteId, // eslint-disable-line no-unused-vars
}) => {
  /*
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_DISCLAIMERS({ userId, quoteId }),
    {
      expectedResponse: 200,
    }
  );
  */

  const data = await fakeApiResolve({
    autoQuoteDisclaimers: [
      AutoQuoteDisclaimerFactory.createWithGeneratedDocument({
        id: 'disclaimer1',
        url: 'https://github.com/Root-App/root-server/files/2563401/e.delivery.pdf',
        name: 'E-delivery consent',
      }),
      AutoQuoteDisclaimerFactory.createWithGeneratedDocument({
        id: 'disclaimer2',
        url: 'https://github.com/Root-App/root-server/files/2563398/uim.selected.pdf',
        name: 'Underinsured motorist coverage',
      }),
    ],
  }, 3000);
  return data;
};

export const getPrefillRequest = async () => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_PREFILL_REQUEST,
    {
      expectedResponse: [200, 201, 204, 400],
    }
  );

  if (data.error) {
    throw data.error;
  }

  return data.prefill;
};

export const affirmDisclaimers = async ({ userId, quoteId }) => { // eslint-disable-line no-unused-vars
/*
const { data } = await ServerUtils.authenticatedPost(
  ENDPOINTS.AFFIRM_DISCLAIMERS({ userId, quoteId }),
  {
    expectedResponse: 200,
  }
);
*/
  const data = await fakeApiResolve({ isSuccess: true });
  return data;
};

export const getIsClassicMarket = async ({ market }) => { // eslint-disable-line no-unused-vars
  /*
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_MARKET_CLASSIC_ONLY_CHECK({ market }),
    {
      expectedResponse: 200,
    }
  );
  */
  const data = await fakeApiResolve({});
  return data;
};

export const getMarketRules = async ({ market }) => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_MARKET_RULES,
    {
      query: {
        market,
      },
    }
  );
  if (data.error) {
    throw data.error;
  }
  return data.profileRulesContext;
};

export const getQuote = async ({ ratingRequestId }) => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_QUOTE({ ratingRequestId }),
    {
      expectedResponse: [200, 204, 400],
    }
  );

  if (data.error) {
    throw data.error;
  }
  return data;
};

export const markQuoteViewed = async ({ quoteId }) => {
  const { data } = ServerUtils.authenticatedPatch(
    ENDPOINTS.MARK_QUOTE_VIEWED,
    {
      body: {
        quoteId,
      },
    }
  );

  if (data?.error) {
    throw data.error;
  }

  return data;
};

export const getQuoteCoverages = async ({ ratingRequestId }) => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_QUOTE_COVERAGES,
    {
      query: {
        ratingRequestId,
      },
      expectedResponse: [200, 204, 400],
    }
  );
  if (data.error) {
    throw data.error;
  }
  return data;
};

export const getQuoteCoveragesForAllBackendOptions = async ({ quoteId }) => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_QUOTE_COVERAGES_FOR_ALL_BACKEND_OPTIONS,
    {
      query: {
        quoteId,
      },
      expectedResponse: [200, 204, 400],
    }
  );

  if (data.error) {
    throw data.error;
  }
  return data;
};

export const getQuotingRules = async ({ market }) => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.GET_QUOTING_RULES,
    {
      query: {
        market,
      },
    }
  );
  if (data.error) {
    throw data.error;
  }
  return data.quotingRulesContext;
};

export const validateAccessToken = async () => {
  const { data } = await ServerUtils.authenticatedGet(
    ENDPOINTS.VALIDATE_ACCESS_TOKEN,
    {
      expectedResponse: [200],
      expectedErrorResponse: [401],
    }
  );
  if (!data.valid) {
    // Throw an error if the result is invalid so we can hook into `onError` calls
    throw new RootError({
      message: 'Existing access token is invalid',
      name: 'ApiError',
    });
  }
  return data.valid;
};

export const validateAuthPrefill = async ({ auth }) => {
  const { data } = await ServerUtils.unauthenticatedPost(
    ENDPOINTS.VALIDATE_AUTH_PREFILL,
    {
      body: {
        auth,
      },
      expectedResponse: [200, 201],
      expectedErrorResponses: [401],
    }
  );

  if (data.error) {
    throw new RootError({
      message: data.error,
      name: 'ApiError',
      fingerprint: ['ApiErrorMessage'],
    });
  }

  return data;
};
