import { useCallback, useEffect, useRef, useState } from '@root/vendor/react';

const addQueryListener = (query, callback) => {
  try {
    query.addEventListener('change', callback);
    return () => query.removeEventListener('change', callback);
  } catch (e) {
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
};

const getInitialQueryMatch = (query) => {
  if (window && window.matchMedia) {
    return window.matchMedia(query).matches;
  }
  return false;
};

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(getInitialQueryMatch(query));
  const queryRef = useRef();

  const updateMatch = useCallback((e) => setMatches(e.matches), [setMatches]);

  useEffect(() => {
    if (window.matchMedia) {
      queryRef.current = window.matchMedia(query);
      setMatches(queryRef.current.matches);
      return addQueryListener(queryRef.current, updateMatch);
    }
  }, [query, updateMatch]);

  return matches;
};

export default useMediaQuery;
