import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ListCardItem({ children, className }) {
  return (
    <li
      className={className}
      css={styles.item}
    >{children}
    </li>
  );
}

ListCardItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  item: {
    ...Theme.paragraph1(),
    padding: 30,
  },
});
