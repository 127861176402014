import CoverageNonLiabilityLinkModal from '@root/quotes/src/components/modals/coverage-non-liability-link-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

const PipLostWagesExplanationLinkModal = ({
  primaryBrandColor,
  secondaryBrandColor,
}) => (
  <CoverageNonLiabilityLinkModal
    linkText={'What you need to know first'}
    modalName={'PIP Lost Wages Explanation Modal'}
    primaryBrandColor={primaryBrandColor}
    secondaryBrandColor={secondaryBrandColor}
  >
    <p css={styles.paragraph}>The named insured may elect a deductible and to exclude coverage for loss of gross income and loss of earning capacity (”lost wages”). These elections apply to the named insured alone, or to the named insured and all dependent resident relatives. A premium reduction will result from these elections. The named insured is hereby advised not to elect the lost wage exclusion if the named insured or dependent resident relatives are employed, since lost wages will not be payable in the event of an accident.</p>
  </CoverageNonLiabilityLinkModal>
);

PipLostWagesExplanationLinkModal.propTypes = {
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
};

export default PipLostWagesExplanationLinkModal;

const styles = StyleSheet.create({
  paragraph: {
    color: '#767676',
    fontSize: 18,
  },
});
