import CoverageNonLiabilityLinkModal from '@root/quotes/src/components/modals/coverage-non-liability-link-modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

const PipMnLostWagesExplanationLinkModal = ({
  primaryBrandColor,
  secondaryBrandColor,
}) => (
  <CoverageNonLiabilityLinkModal
    linkText={'Learn more'}
    modalName={'PIP MN Lost Wages Explanation Modal'}
    primaryBrandColor={primaryBrandColor}
    secondaryBrandColor={secondaryBrandColor}
  >
    <h2 css={[styles.title, styles.spacing]}>Should I accept economic loss coverage?</h2>
    <p css={[styles.paragraph, styles.spacing]}>Economic loss coverage pays for non-medical losses such as lost wages, replacement services (like childcare or home maintenance), and funeral costs.</p>
    <p css={styles.listHeading}>Per Minnesota regulations, you can opt out of this coverage if:</p>
    <ul>
      <li css={styles.paragraph}>You are 65 years or older, or</li>
      <li css={styles.paragraph}>You are between the ages of 60-64 and are retired and receiving a pension.</li>
    </ul>
  </CoverageNonLiabilityLinkModal>
);

PipMnLostWagesExplanationLinkModal.propTypes = {
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
};

export default PipMnLostWagesExplanationLinkModal;

const styles = StyleSheet.create({
  title: {
    ...Theme.heading2(),
  },
  listHeading: {
    ...Theme.paragraph1({ bold: true }),
    marginBottom: 5,
  },
  paragraph: {
    ...Theme.paragraph1(),
  },
  spacing: {
    marginBottom: 24,
  },
});
