import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function AgeLicensedHeading({ children }) {
  return (
    <h1 css={[styles.heading]}>
      {children}
    </h1>
  );
}

AgeLicensedHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading1(),
    marginTop: 20,
    marginBottom: 30,
  },
});
