import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
const checkAnimation = require('@root/bind.joinroot.com/src/components/insurance-card/check-animation.json');
import useLottieAnimation from '@root/core/src/hooks/use-lottie-animation';
import { AutoscalingSvg, SvgProps } from '@root/bind.joinroot.com/src/components/icons';
import { CARD_HEIGHT, CARD_WIDTH } from '@root/bind.joinroot.com/src/components/insurance-card';
import { Colors, Shadows } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const Back = ({
  animationDelay = 0,
  height = CARD_HEIGHT,
  partnerName,
  width = CARD_WIDTH,
  ...restProps
}) => {
  const strings = useI18nNext('components.insuranceCard.back');
  const [animationContainer, player] = useLottieAnimation(checkAnimation, { loop: false });

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      player?.play();
    }, animationDelay);

    return () => {
      clearTimeout(animationTimer);
      player?.stop();
    };
  }, [animationDelay, player]);

  return (
    <AutoscalingSvg
      css={{
        backgroundColor: Colors.white(),
        ...Shadows.sidesAndBottom(),
        borderRadius: 10,
        border: `1px solid ${Colors.gray40()}`,
      }}
      data-testid={'insurance-card--back'}
      height={height}
      viewBox={'0 0 366 205'}
      width={width}
      {...restProps}
    >
      <mask
        height={'663'}
        id={'mask0'}
        mask-type={'alpha'}
        maskUnits={'userSpaceOnUse'}
        width={'711'}
        x={'-53'}
        y={'-364'}
      >
        <path
          clipRule={'evenodd'}
          d={'M156.084 -363.508C40.2557 -363.508 -52.1492 -266.758 -52.1492 -149.283C-52.1492 -31.807 40.2557 64.9432 156.084 64.9432C187.9 64.9432 217.949 57.6434 244.801 44.6194C242.475 57.4304 241.26 70.6285 241.26 84.0992C241.26 201.575 333.666 298.325 449.496 298.325C565.326 298.325 657.733 201.575 657.733 84.0992C657.733 -33.377 565.326 -130.127 449.496 -130.127C417.679 -130.127 387.629 -122.827 360.777 -109.802C363.103 -122.613 364.318 -135.812 364.318 -149.283C364.318 -266.758 271.913 -363.508 156.084 -363.508ZM2.03954 -149.283C2.03954 -238.507 71.8322 -309.32 156.084 -309.32C240.336 -309.32 310.129 -238.507 310.129 -149.283C310.129 -60.0586 240.336 10.7545 156.084 10.7545C71.8322 10.7545 2.03954 -60.0586 2.03954 -149.283ZM295.448 84.0992C295.448 -5.12442 365.242 -75.938 449.496 -75.938C533.751 -75.938 603.544 -5.12442 603.544 84.0992C603.544 173.323 533.751 244.136 449.496 244.136C365.242 244.136 295.448 173.323 295.448 84.0992Z'}
          fill={Colors.white()}
          fillOpacity={'0.3'}
          fillRule={'evenodd'}
          transform={'scale(0.65),translate(100,50)'}
        />
      </mask>

      <g mask={'url(#mask0)'}>
        <g filter={'url(#filter0_f)'}>
          <path
            d={'M247.892 -43.6546H430.066L224.002 272.201H41.8289L247.892 -43.6546Z'}
            fill={'url(#paint0_linear)'}
          />
        </g>
        <g filter={'url(#filter1_f)'}>
          <path
            d={'M508.548 -32.3728H592.904L392.355 271.634H308L508.548 -32.3728Z'}
            fill={'url(#paint1_linear)'}
          />
        </g>
      </g>
      <g
        ref={animationContainer}
        transform={'scale(0.7),translate(20,-150)'}
      />
      <g
        fill={'none'}
        fillRule={'evenodd'}
        fontFamily={'Helvetica'}
        fontSize={'24'}
        fontWeight={'bold'}
        stroke={'none'}
        strokeWidth={'1'}
      >
        <g fill={Colors.nearBlack()}>
          <text
            dominantBaseline={'middle'}
            textAnchor={'middle'}
          >
            <tspan
              x={'50%'}
              y={'140'}
            >
              {strings.action}
            </tspan>
            <tspan
              x={'50%'}
              y={'170'}
            >
              {strings.partnerNamePrefix}
              {partnerName}
            </tspan>
          </text>
        </g>
      </g>

      <defs>
        <filter
          colorInterpolationFilters={'sRGB'}
          filterUnits={'userSpaceOnUse'}
          height={'548.463'}
          id={'filter0_f'}
          width={'620.845'}
          x={'-74.4752'}
          y={'-159.959'}
        >
          <feFlood
            floodOpacity={'0'}
            result={'BackgroundImageFix'}
          />
          <feBlend
            in={'SourceGraphic'}
            in2={'BackgroundImageFix'}
            mode={'normal'}
            result={'shape'}
          />
          <feGaussianBlur
            result={'foregroundBlur'}
            stdDeviation={'58.1521'}
          />
        </filter>
        <filter
          colorInterpolationFilters={'sRGB'}
          filterUnits={'userSpaceOnUse'}
          height={'536.615'}
          id={'filter1_f'}
          width={'517.512'}
          x={'191.695'}
          y={'-148.677'}
        >
          <feFlood
            floodOpacity={'0'}
            result={'BackgroundImageFix'}
          />
          <feBlend
            in={'SourceGraphic'}
            in2={'BackgroundImageFix'}
            mode={'normal'}
            result={'shape'}
          />
          <feGaussianBlur
            result={'foregroundBlur'}
            stdDeviation={'58.1521'}
          />
        </filter>
        <linearGradient
          gradientUnits={'userSpaceOnUse'}
          id={'paint0_linear'}
          x1={'311.971'}
          x2={'245.195'}
          y1={'-43.6546'}
          y2={'286.033'}
        >
          <stop
            offset={'0.28125'}
            stopColor={'#A4F9FF'}
          />
          <stop
            offset={'0.53125'}
            stopColor={'#97FF95'}
          />
          <stop
            offset={'0.875'}
            stopColor={'#FFEF5C'}
          />
        </linearGradient>
        <linearGradient
          gradientUnits={'userSpaceOnUse'}
          id={'paint1_linear'}
          x1={'533.856'}
          x2={'463.387'}
          y1={'-32.3728'}
          y2={'287.291'}
        >
          <stop stopColor={'#FFCB8E'} />
          <stop
            offset={'1'}
            stopColor={'#C05AFF'}
          />
        </linearGradient>
      </defs>
    </AutoscalingSvg>
  );
};

export const BackPropTypes = {
  ...SvgProps,
  partnerName: PropTypes.string.isRequired,
};

Back.propTypes = BackPropTypes;

export default Back;
