import BillingCycle from '@root/quotes/src/models/billing-cycle';
import BindAddDriver from '@root/bind.joinroot.com/src/pages/bind-add-driver';
import BindAddVehicle from '@root/bind.joinroot.com/src/pages/bind-add-vehicle';
import BindCheckout from '@root/bind.joinroot.com/src/pages/bind-checkout';
import BindCoveredEntry from '@root/bind.joinroot.com/src/pages/bind-covered-entry';
import BindDisclaimers from '@root/bind.joinroot.com/src/pages/bind-disclaimers';
import BindDriverVehicleAssignment from '@root/bind.joinroot.com/src/pages/bind-driver-vehicle-assignment';
import BindEditDriver from '@root/bind.joinroot.com/src/pages/bind-edit-driver';
import BindEditVehicle from '@root/bind.joinroot.com/src/pages/bind-edit-vehicle';
import BindLoadingEntry from '@root/bind.joinroot.com/src/pages/bind-loading-entry';
import BindQuoteCustomization from '@root/bind.joinroot.com/src/pages/bind-quote-customization';
import BindStartEntry from '@root/bind.joinroot.com/src/pages/bind-start-entry';
import BindUnderwritingDeclined from '@root/bind.joinroot.com/src/pages/bind-underwriting-declined';
import BindUnderwritingDeclinedResell from '@root/bind.joinroot.com/src/pages/bind-underwriting-declined-resell';
import BindUnderwritingPending from '@root/bind.joinroot.com/src/pages/bind-underwriting-pending';
import Button from '@root/bind.joinroot.com/src/components/speak-to-agent/button';
import DriverVehicleAssignmentFlowService from '@root/driver-vehicle-assignment/src/services/driver-vehicle-assignment-flow-service';
import Header from '@root/bind.joinroot.com/src/components/bind-view/header';
import Modal from '@root/bind.joinroot.com/src/components/speak-to-agent/modal';
import Profile from '@root/auto-pricing/src/models/profile';
import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import Progress from '@root/bind.joinroot.com/src/components/bind-view/header/progress';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo, useState } from '@root/vendor/react';
import UnderwritingDecision from '@root/quotes/src/models/underwriting-decision';
import dayjs from '@root/vendor/dayjs';
import environment from '@root/core/src/utils/environment';
import useBindProfileParams from '@root/bind.joinroot.com/src/hooks/use-bind-profile-params';
import useDeepLink from '@root/bind.joinroot.com/src/context/deep-link';
import useProfile from '@root/bind.joinroot.com/src/context/profile';
import useQuote from '@root/bind.joinroot.com/src/hooks/api/use-quote';
import useSupportedMarketsMarketsQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-supported-markets-query';
import { PAGES, RELATIVE_PATHS } from '@root/bind.joinroot.com/src/pages/constants';
import { PROGRESS_ITEMS } from '@root/bind.joinroot.com/src/components/bind-view/header/progress';
import { Redirect, Route, Switch, useHistory } from '@root/core/src/components/route';
import { UnderwritingCompanyProvider } from '@root/profile/src/contexts/underwriting-company-context';
import { useConfig } from '@root/bind.joinroot.com/src/context/config';
import { useDeepLinkFlow } from '@root/bind.joinroot.com/src/context/deep-link';
import { usePersistedProfileRules } from '@root/bind.joinroot.com/src/hooks/use-persisted-profile-rules';
import { usePersistedUnderwritingCompanyContext } from '@root/bind.joinroot.com/src/hooks/use-persisted-underwriting-company-context';
import { useRouteMatch } from '@root/vendor/react-router-dom';

// Small helper for building absolute paths/urls from our relative paths key/value object
const prependBaseToKeyValues = (baseString = '', prependToObj = {}) => Object.fromEntries(
  Object.keys(prependToObj).map((prependKey) => [
    prependKey,
    baseString + prependToObj[prependKey],
  ])
);

const BindRoutes = ({
  defaultBillingCycle,
  funnelStage,
  initialRatingRequestId,
}) => {
  /**
   * NAVIGATION AND SETUP
   */
  const history = useHistory();

  /**
   * Scroll to top of page when history changes
   */
  useEffect(() => {
    const removeListener = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      removeListener();
    };
  }, [history]);

  const { path: routerPath, url: routerUrl } = useRouteMatch();

  const PATHS = useMemo(() => prependBaseToKeyValues(routerPath, RELATIVE_PATHS), [routerPath]);
  const URLS = useMemo(() => prependBaseToKeyValues(routerUrl, RELATIVE_PATHS), [routerUrl]);

  const navigateToPageAndDisableBack = useCallback((pageName) => {
    if (URLS[pageName]) {
      window.addEventListener('popstate', () => {
        history.go(1);
      });
      history.replace(URLS[pageName]);
    }
  }, [history, URLS]);

  const navigateToPage = useCallback((pageName, state = {}) => {
    if (URLS[pageName]) {
      history.push(URLS[pageName], state);
    }
  }, [history, URLS]);

  const deepLink = useDeepLink();

  const [progressState, setProgressState] = useState(PROGRESS_ITEMS.FINALIZE_QUOTE);
  const [displayProgress, setDisplayProgress] = useState(!deepLink.wasDeepLinked());

  // Track if we're displaying the speak to agent modal & provide a helper to toggle its display
  const [showSpeakToAgent, setShowSpeakToAgent] = useState(false);
  const toggleSpeakToAgent = useCallback(() => setShowSpeakToAgent((shown) => !shown), [setShowSpeakToAgent]);

  const navigateToLoadingPage = useCallback((routeState = { shouldWaitForPrefill: false }) => {
    setProgressState(PROGRESS_ITEMS.FINALIZE_QUOTE);
    navigateToPage(PAGES.LOADING, routeState);
  }, [navigateToPage, setProgressState]);
  const navigateToCoverageDetailsPage = useCallback(() => {
    setProgressState(PROGRESS_ITEMS.CUSTOMIZE_COVERAGES);
    navigateToPage(PAGES.CUSTOMIZE_COVERAGES);
  }, [navigateToPage, setProgressState]);
  const navigateToAddVehiclePage = useCallback(() => {
    setProgressState(PROGRESS_ITEMS.CUSTOMIZE_COVERAGES);
    navigateToPage(PAGES.ADD_VEHICLE);
  }, [navigateToPage]);
  const navigateToAddDriverPage = useCallback(() => {
    setProgressState(PROGRESS_ITEMS.CUSTOMIZE_COVERAGES);
    navigateToPage(PAGES.ADD_DRIVER);
  }, [navigateToPage]);
  const navigateToEditDriverPage = useCallback((universalDriverId) => {
    setProgressState(PROGRESS_ITEMS.CUSTOMIZE_COVERAGES);
    navigateToPage(PAGES.EDIT_DRIVER, { universalDriverId });
  }, [navigateToPage, setProgressState]);
  const navigateToEditVehiclePage = useCallback(({ vin, vehicleCid }) => {
    setProgressState(PROGRESS_ITEMS.CUSTOMIZE_COVERAGES);
    navigateToPage(PAGES.EDIT_VEHICLE, {
      vin,
      vehicleCid,
    });
  }, [navigateToPage, setProgressState]);
  const navigateToDisclaimersPage = useCallback(() => {
    setProgressState(PROGRESS_ITEMS.CHECKOUT);
    navigateToPage(PAGES.DISCLAIMERS);
  }, [navigateToPage, setProgressState]);
  const navigateToCheckoutPage = useCallback(() => {
    setProgressState(PROGRESS_ITEMS.CHECKOUT);
    navigateToPage(PAGES.CHECKOUT);
  }, [navigateToPage, setProgressState]);
  const navigateToCoveredPage = useCallback(() => {
    setDisplayProgress(false);
    navigateToPageAndDisableBack(PAGES.COVERED);
  }, [navigateToPageAndDisableBack, setDisplayProgress]);
  const navigateToUnderwritingPage = useCallback((underwritingStatus) => {
    setDisplayProgress(false);
    if (underwritingStatus === UnderwritingDecision.Status.DECLINED) {
      navigateToPage(PAGES.UNDERWRITING_DECLINED);
    } else if (underwritingStatus === UnderwritingDecision.Status.PENDING) {
      navigateToPage(PAGES.UNDERWRITING_PENDING);
    }
  }, [navigateToPage, setDisplayProgress]);
  const navigateToUnderwritingDeclinedResellPage = useCallback(() => {
    setDisplayProgress(false);
    navigateToPage(PAGES.UNDERWRITING_DECLINED_RESELL);
  }, [navigateToPage, setDisplayProgress]);
  const navigateToUnsupportedMarketPage = useCallback(() => { }, []);

  /* Load our BindProfile from context to initialize our profile params */
  const bindProfile = useProfile();
  const [profileParams, setProfileParams] = useBindProfileParams({ bindProfile });

  const underwritingCompanyContext = usePersistedUnderwritingCompanyContext();

  const profileRules = usePersistedProfileRules();

  /**
   * TODO:
   * The classic market check query is currently stubbed but we'll need to implement it in our prefill page

  const {
    data: isClassicMarket,
    isLoading: isLoadingClassicMarketCheck,
  } = useIsClassicMarketQuery({ market });
   */

  const handlePrefillRequestTriggered = useCallback(() => {
    navigateToLoadingPage({ shouldWaitForPrefill: true });
  }, [navigateToLoadingPage]);

  const {
    bindParams,
    createCustomQuote,
    createQuote,
    didCreateQuote,
    isCreatingCustomQuote,
    isCreatingQuote,
    isQuoteCurrent,
    quotesContext,
    quoteCoveragesContext,
    quoteCoveragesContextsForBackendOptions,
    quotingRules,
    readyToCustomizeQuote,
    selectedQuote,
    updateBindParams,
    updateSelectedQuote,
  } = useQuote({
    defaultBillingCycle,
    defaultStartDate: bindProfile.policyEffectiveDate,
    initialRatingRequestId,
    profileParams,
  });

  const {
    successUrl,
  } = useConfig();

  const {
    data: supportedMarkets,
    isLoading: isLoadingSupportedMarkets,
  } = useSupportedMarketsMarketsQuery();

  const handleQuoteReady = useCallback(() => {
    navigateToCoverageDetailsPage();
  }, [navigateToCoverageDetailsPage]);

  const handleAcceptDisclaimers = useCallback((accepted) => {
    updateBindParams((prevBindParams) => prevBindParams.set('quoteDisclaimerAffirmed', accepted));

    if (accepted) {
      navigateToCheckoutPage();
    }
  }, [navigateToCheckoutPage, updateBindParams]);

  const handlePolicyCreated = useCallback(() => {
    navigateToCoveredPage();
  }, [navigateToCoveredPage]);

  const navigateToDeepLinkPage = useCallback(() => {
    const deepLinkTargetVin = profileParams.vehicles.find((v) => v.cid === deepLink?.actionTarget)?.vin;

    navigateToPage(deepLink.navigateTo(), {
      universalDriverId: deepLink.actionTarget,
      vehicleCid: deepLink.actionTarget,
      vin: deepLinkTargetVin,
    });
  }, [deepLink, navigateToPage, profileParams]);

  const handleUpdateProfileParams = useCallback((updatedProfileParams, scrollToRideshareCoverage = false, returnToPartner = deepLink.wasDeepLinked()) => {
    setProfileParams(updatedProfileParams);
    setScrollToRideshareCoverage(scrollToRideshareCoverage);
    navigateToLoadingPage({ shouldCallSuccessUrl: returnToPartner && deepLink.wasDeepLinked() });
  }, [navigateToLoadingPage, setProfileParams, deepLink]);

  const navigateToDriverVehicleAssignment = useCallback((updatedProfileParams) => {
    const nextRoute = DriverVehicleAssignmentFlowService.getRoute({
      baseRoute: '/bind',
      unassignedDriversCount: updatedProfileParams.unassignedDrivers().length,
      unassignedVehiclesCount: updatedProfileParams.unassignedVehicles().length,
      submitRoute: PAGES.LOADING,
    });

    if (nextRoute.includes(RELATIVE_PATHS.DRIVER_VEHICLE_ASSIGNMENT)) {
      navigateToPage(PAGES.DRIVER_VEHICLE_ASSIGNMENT, { profileParams: updatedProfileParams });
    } else if (nextRoute.includes(RELATIVE_PATHS.VEHICLE_DRIVER_ASSIGNMENT)) {
      navigateToPage(PAGES.VEHICLE_DRIVER_ASSIGNMENT, { profileParams: updatedProfileParams });
    } else {
      handleUpdateProfileParams(updatedProfileParams);
    }
  }, [handleUpdateProfileParams, navigateToPage]);

  const conditionallyNavigateToDriverVehicleAssignment = useCallback((updatedProfileParams) => {
    if (profileRules?.driverVehicleAssignments && updatedProfileParams.vehicles.length > 1) {
      navigateToDriverVehicleAssignment(ProfileDriverVehicleAssignmentService.clearAssignments(updatedProfileParams));
    } else {
      handleUpdateProfileParams(updatedProfileParams);
    }
  }, [handleUpdateProfileParams, navigateToDriverVehicleAssignment, profileRules?.driverVehicleAssignments]);

  const {
    completeFlow,
    exitFlow,
  } = useDeepLinkFlow();

  const handleCancel = useCallback(() => {
    if (deepLink.wasDeepLinked()) {
      exitFlow();
    } else {
      navigateToCoverageDetailsPage();
    }
  }, [deepLink, exitFlow, navigateToCoverageDetailsPage]);

  const quoteCustomizationOnContinue = useCallback(() => {
    if (deepLink.wasDeepLinked()) {
      completeFlow();
    } else {
      navigateToDisclaimersPage();
    }
  }, [completeFlow, deepLink, navigateToDisclaimersPage]);

  const updateProfileParamsRideshare = useCallback(() => {
    const updatedProfileParams = profileParams.set('rideshare', !profileParams.rideshare);
    const scrollToRideshareCoverage = true;
    const returnToPartner = false;
    handleUpdateProfileParams(updatedProfileParams, scrollToRideshareCoverage, returnToPartner);
  }, [handleUpdateProfileParams, profileParams]);

  const [scrollToRideshareCoverage, setScrollToRideshareCoverage] = useState(false);

  return (
    <>
      <Header>
        <>
          {
            displayProgress &&
            <Progress
              currentProgressItem={progressState}
            />
          }
          <Button
            onClickSpeakToAgent={toggleSpeakToAgent}
          />
        </>
      </Header>

      <Modal
        isShowing={showSpeakToAgent}
        onCancel={toggleSpeakToAgent}
      />

      <Switch>
        <Route
          funnelStage={funnelStage}
          path={PATHS.COVERED}
          render={() => (
            <BindCoveredEntry
              profileParams={profileParams}
              softLaunchExperience={!environment.bindAppCoveredV1ExperienceEnabled}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.CHECKOUT}
          render={() => (
            <BindCheckout
              bindParams={bindParams}
              maxPolicyStartDate={dayjs(bindProfile.policyEffectiveDate).toDate()}
              onEditPlan={navigateToCoverageDetailsPage}
              onPolicyCreated={handlePolicyCreated}
              profileParams={profileParams}
              quoteCoveragesContext={quoteCoveragesContext}
              quotesContext={quotesContext}
              selectedQuote={selectedQuote}
              updateBindParams={updateBindParams}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.DISCLAIMERS}
          render={() => (
            <BindDisclaimers
              bindParams={bindParams}
              onAcceptDisclaimers={handleAcceptDisclaimers}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.LOADING}
          render={({ location }) => (
            <BindLoadingEntry
              createQuote={createQuote}
              didCreateQuote={didCreateQuote && isQuoteCurrent}
              isCreatingQuote={isCreatingQuote}
              isQuoteCurrent={isQuoteCurrent}
              onQuoteReady={handleQuoteReady}
              onQuoteUnderwritingStatusReady={navigateToUnderwritingPage}
              profileParams={profileParams}
              quotesContext={quotesContext}
              readyToCustomizeQuote={readyToCustomizeQuote}
              shouldCallSuccessUrl={location.state?.shouldCallSuccessUrl ?? false}
              shouldWaitForPrefill={location.state?.shouldWaitForPrefill ?? false}
              successUrl={successUrl}
              wasDeeplinked={deepLink.wasDeepLinked()}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.ADD_VEHICLE}
          render={() => (
            <BindAddVehicle
              onCancel={handleCancel}
              onUpdateProfileParams={conditionallyNavigateToDriverVehicleAssignment}
              profileParams={profileParams}
              profileRules={profileRules}
              shouldReturnToPartnerOnBack={deepLink.wasDeepLinked()}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.EDIT_VEHICLE}
          render={({ location }) => {
            if (location.state?.vin !== undefined && location.state?.vehicleCid !== undefined) {
              return (
                <BindEditVehicle
                  onCancel={handleCancel}
                  onUpdateProfileParams={conditionallyNavigateToDriverVehicleAssignment}
                  profileParams={profileParams}
                  profileRules={profileRules}
                  shouldReturnToPartnerOnBack={deepLink.wasDeepLinked()}
                  vehicleCid={location.state.vehicleCid}
                  vin={location.state.vin}
                />
              );
            }
            return <Redirect to={PATHS.CUSTOMIZE_COVERAGES} />;
          }}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.ADD_DRIVER}
          render={() => (
            <BindAddDriver
              onCancel={handleCancel}
              onUpdateProfileParams={conditionallyNavigateToDriverVehicleAssignment}
              profileParams={profileParams}
              profileRules={profileRules}
              shouldReturnToPartnerOnBack={deepLink.wasDeepLinked()}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.EDIT_DRIVER}
          render={({ location }) => {
            if (location.state?.universalDriverId !== undefined) {
              return (
                <BindEditDriver
                  onCancel={handleCancel}
                  onUpdateProfileParams={conditionallyNavigateToDriverVehicleAssignment}
                  profileParams={profileParams}
                  profileRules={profileRules}
                  universalDriverId={location.state.universalDriverId}
                />
              );
            }
            return <Redirect to={PATHS.CUSTOMIZE_COVERAGES} />;
          }}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.CUSTOMIZE_COVERAGES}
          render={() => (
            <UnderwritingCompanyProvider value={underwritingCompanyContext}>
              <BindQuoteCustomization
                bindParams={bindParams}
                createCustomQuote={createCustomQuote}
                isCreatingCustomQuote={isCreatingCustomQuote}
                onClickAddDriver={navigateToAddDriverPage}
                onClickAddVehicle={navigateToAddVehiclePage}
                onClickEditDriver={navigateToEditDriverPage}
                onClickEditVehicle={navigateToEditVehiclePage}
                onContinue={quoteCustomizationOnContinue}
                onUpdateRideshare={updateProfileParamsRideshare}
                profile={Profile.buildFromData(profileParams)}
                profileParams={profileParams}
                quoteCoveragesContext={quoteCoveragesContext}
                quoteCoveragesContextsForBackendOptions={quoteCoveragesContextsForBackendOptions}
                quotesContext={quotesContext}
                quotingRules={quotingRules}
                scrollToRideshareCoverage={scrollToRideshareCoverage}
                selectedQuote={selectedQuote}
                updateBindParams={updateBindParams}
                updateSelectedQuote={updateSelectedQuote}
              />
            </UnderwritingCompanyProvider>
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.UNDERWRITING_DECLINED_RESELL}
          render={() => (
            <BindUnderwritingDeclinedResell
              profileParams={profileParams}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.UNDERWRITING_DECLINED}
          render={() => (
            <BindUnderwritingDeclined
              onClickContinue={navigateToUnderwritingDeclinedResellPage}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.UNDERWRITING_PENDING}
          render={() => (
            <BindUnderwritingPending
              quotesContext={quotesContext}
            />
          )}
        />
        <Route
          path={PATHS.BASE_DRIVER_VEHICLE_ASSIGNMENT}
          render={({ location }) => (
            <BindDriverVehicleAssignment
              location={location}
              onCancel={handleCancel}
              onVehicleUsageChange={navigateToDriverVehicleAssignment}
              shouldReturnToPartnerOnBack={deepLink.wasDeepLinked()}
            />
          )}
        />
        <Route
          funnelStage={funnelStage}
          path={PATHS.START}
          render={() => (
            <BindStartEntry
              isLoadingProfileRules={!profileRules}
              isLoadingSupportedMarkets={isLoadingSupportedMarkets}
              navigateToDeepLinkPage={navigateToDeepLinkPage}
              onPrefillRequestTriggered={handlePrefillRequestTriggered}
              onUnsupportedMarket={navigateToUnsupportedMarketPage}
              profileParams={profileParams}
              profileRules={profileRules}
              supportedMarkets={supportedMarkets}
              updateProfileParams={setProfileParams}
            />
          )}
        />
      </Switch>
    </>
  );
};

BindRoutes.propTypes = {
  defaultBillingCycle: PropTypes.oneOf([
    BillingCycle.FULL_TERM,
    BillingCycle.MONTHLY,
  ]),
  funnelStage: PropTypes.string.isRequired,
  initialRatingRequestId: PropTypes.string,
};

export default BindRoutes;
