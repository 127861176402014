import PrefillRequest from '@root/prefill/src/models/prefill-request';

class BindPrefillRequest extends PrefillRequest {
  isReadyForSubmission() {
    const validations = [
      this.isNameValid(),
      this.isMailingAddressValid(),
      this.isDobValid(),
      this.isEmailValid(),
      this.isHomeownerValid(),
      this.isMaritalStatusValid(),
    ];

    return validations.every((requirement) => requirement);
  }
}

export default BindPrefillRequest;
