import AnalyticsService from '@root/core/src/services/analytics-service';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileDisclaimer from '@root/profile/src/components/profile-disclaimer';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileModalButton from '@root/profile/src/components/profile-modal-button';
import PropTypes from '@root/vendor/prop-types';
import RadioOption from '@root/core/src/components/radio-option';
import React, { Component } from '@root/vendor/react';
import SubHeading from '@root/profile/src/components/sub-heading';
import VehicleCreationParams from '@root/auto-pricing/src/models/vehicle-creation-params';

export default class ProfileAddVehicleVinEtching extends Component {
  static propTypes = {
    brandedColor: PropTypes.string,
    cssButtonOverrides: PropTypes.object,
    disableCancel: PropTypes.bool,
    linkStyles: PropTypes.object,
    module: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    vehicleCreationParams: PropTypes.instanceOf(VehicleCreationParams).isRequired,
    vin: PropTypes.string,

  };

  static analyticsContext = 'PROFILE_ADD_VEHICLE_VIN_ETCHING'

  state = {
    vinEtching: null,
  };

  componentDidMount() {
    AnalyticsService.trackViewEvent(this._getAnalyticsContext());
  }

  _getAnalyticsContext = () => [...this.props.module, ProfileAddVehicleVinEtching.analyticsContext];

  _handleSelectYes = () => {
    this.setState({
      vinEtching: true,
    });
  };

  _handleSelectNo = () => {
    this.setState({
      vinEtching: false,
    });
  };

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(this._getAnalyticsContext(), 'CANCEL');
    this.props.onCancel();
  };

  _handleSubmit = () => {
    AnalyticsService.trackClickEvent(this._getAnalyticsContext(), 'CONTINUE');
    if (this.state.vinEtching !== null) {
      const vehicleCreationParams = this.props.vehicleCreationParams.set('vinEtching', this.state.vinEtching);
      this.props.onDone(vehicleCreationParams);
    }
  };

  render() {
    return (
      <div>
        <ProfileHeading>
          Does this vehicle have its VIN etched into the windows?
        </ProfileHeading>
        {this.props.vin && (
          <SubHeading>
            VIN: {this.props.vin}
          </SubHeading>
        )}
        <ProfileForm onSubmit={this._handleSubmit}>
          <RadioOption
            circleColor={this.props.brandedColor}
            id={'vin-etching-yes'}
            isSelected={!!this.state.vinEtching}
            label={'Yes'}
            name={'vin-etching'}
            onChange={this._handleSelectYes}
            optionValue={'Y'}
          />
          <RadioOption
            circleColor={this.props.brandedColor}
            id={'vin-etching-no'}
            isSelected={this.state.vinEtching === false}
            label={'No'}
            name={'vin-etching'}
            onChange={this._handleSelectNo}
            optionValue={'N'}
          />
          <ProfileModalButton
            cssButtonOverrides={this.props.cssButtonOverrides}
            disabled={this.state.vinEtching === null}
          >
            Continue
          </ProfileModalButton>
          {!this.props.disableCancel && (
            <NevermindLink
              linkStyles={this.props.linkStyles}
              onCancel={this._handleCancel}
            />
          )}
        </ProfileForm>
        <ProfileDisclaimer
          text={'If the VIN is etched into the vehicle windows (this helps prevent theft), you earn an extra 5% discount.'}
        />
      </div>
    );
  }
}
