import Address from '@root/core/src/models/address';
import AttributionService from '@root/attribution/src/services/attribution-service';
import Driver from '@root/auto-pricing/src/models/driver';
import DriverVehicleAssignment from '@root/auto-pricing/src/models/driver-vehicle-assignment';
import ExcludedDriver from '@root/auto-pricing/src/models/excluded-driver';
import Homeowner from '@root/core/src/models/homeowner';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import Vehicle from '@root/core/src/models/vehicle';
import filter from '@root/vendor/lodash/filter';
import flow from '@root/vendor/lodash/flow';
import uuid from '@root/vendor/uuid/v4';

export default class ProfileParams {
  static marketSpecificFixups = {
    VA: [this._applyAutomaticDriverVehicleAssignments],
  }

  static _applyMarketSpecificFixups(profile) {
    const marketSpecificFixups = this.marketSpecificFixups[profile.market()] || [];
    const fixup = flow(...marketSpecificFixups);

    return fixup(profile);
  }

  static _applyAutomaticDriverVehicleAssignments(profileParams) {
    const drivers = profileParams.getSelectedDrivers();
    const vehicles = profileParams.getAllSelectedVehicles();

    let driverVehicleAssignments = profileParams.driverVehicleAssignments;
    if (vehicles.length === 1) {
      driverVehicleAssignments = drivers.map((driver) =>
        new DriverVehicleAssignment({
          driver,
          vehicle: vehicles[0],
          primaryVehicle: true,
        })
      );
      profileParams.hasAutomaticDriverVehicleAssignment = true;
    } else if (profileParams.hasAutomaticDriverVehicleAssignment) {
      driverVehicleAssignments = [];
      profileParams.hasAutomaticDriverVehicleAssignment = false;
    }
    return ProfileParams.buildWithNoFixup({
      ...profileParams,
      driverVehicleAssignments,
    });
  }

  static build(options) {
    return this._applyMarketSpecificFixups(Object.assign(
      new ProfileParams(),
      options,
    ));
  }

  static buildWithNoFixup(options) {
    return Object.assign(
      new ProfileParams(),
      options,
    );
  }

  static buildFromAnswerSet(profileAnswerSet) {
    const drivers = profileAnswerSet.drivers.map((driverFromAnswerSet) => {
      const driver = {
        ...driverFromAnswerSet,
      };

      if (!driver.universalDriverId) {
        driver.universalDriverId = uuid();
      }
      const driverObject = Driver.buildFromData(driver);

      if (driverObject.valid()) {
        return driverObject;
      }
      return null;
    }).filter((driver) => driver);

    if (drivers.length > 0 && !drivers.find((d) => d.pni)) {
      drivers[0].pni = true;
    }

    return this.build(
      {
        ...profileAnswerSet,
        drivers,
        vehicles: profileAnswerSet.vehicles.map((vehicle) => Vehicle.buildFromData(vehicle)),
        mailingAddress: Address.buildFromData(profileAnswerSet.address),
      }
    );
  }

  hasDuplicate(vehicle, type) {
    switch (type) {
    case 'MakeModelYear':
      return filter(this.vehicles, {
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
      }).length > 1;

    case 'MakeModel':
      return filter(this.vehicles, {
        make: vehicle.make,
        model: vehicle.model,
      }).length > 1;

    default:
      return filter(this.vehicles, {
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        selected: vehicle.selected,
      }).length > 1;
    }
  }

  static buildFromProfile(profile) {
    const drivers = profile.drivers.map((driver) => Driver.buildFromData({
      ...driver,
      selected: true,
    }));

    const excludedDrivers = (profile.excludedDrivers || []).map((excludedDriver) => ExcludedDriver.buildFromData(
      excludedDriver,
    ));

    const vehicles = profile.vehicles.map((vehicle) => Vehicle.buildFromData({
      ...vehicle,
      testDriveSelected: profile.testDriveSelected,
      selected: true,
    }));
    const driverVehicleAssignments = (profile.driverVehicleAssignments ?? [])
      .map((dva) => DriverVehicleAssignment.buildFromData({
        ...dva,
        drivers,
        vehicles,
      }));
    const mailingAddress = Address.buildFromData({
      city: profile.city,
      line1: profile.address1,
      line2: profile.address2,
      state: profile.state,
      zip: profile.zip,
    });
    const primaryNamedInsured = Driver.buildFromData({
      ...profile.primaryNamedInsured,
      selected: true,
    });

    let homeowner = null;

    if (profile.homeowner !== null) {
      homeowner = profile.homeowner ? Homeowner.OWN : Homeowner.RENT;
    }

    const profileParams = {
      drivers,
      driverVehicleAssignments,
      excludedDrivers,
      homeowner,
      mailingAddress,
      originalUniversalDriverId: profile.originalUniversalDriverId,
      primaryNamedInsured,
      ratingMunicipality: profile.ratingMunicipality,
      vehicles,
      rideshare: profile.rideshare,
      selfReportedMovingViolations: profile.selfReportedMovingViolations,
    };

    return this.build(profileParams);
  }

  constructor({
    drivers,
    driverVehicleAssignments = [],
    excludedDrivers = [],
    hasAutomaticDriverVehicleAssignment = false,
    homeowner,
    address = new Address(),
    originalUniversalDriverId,
    ratingMunicipality = null,
    vehicles,
    wasDriversSelectionMade = false,
    wasVehiclesSelectionMade = false,
    rightQuoteComplete = false,
    rootEnterpriseClientId = null,
    rootEnterpriseDriverId = null,
    priorInsuranceLastSixMonths = null,
    durationWithoutInsuranceLastSixMonths = null,
    previousInsurerDurationInsured = null,
    rideshare = null,
    selfReportedMovingViolations = null,
  } = {}) {
    this.drivers = drivers;
    this.driverVehicleAssignments = driverVehicleAssignments;
    this.excludedDrivers = excludedDrivers;
    this.hasAutomaticDriverVehicleAssignment = hasAutomaticDriverVehicleAssignment;
    this.homeowner = homeowner;
    this.mailingAddress = address;
    this.originalUniversalDriverId = originalUniversalDriverId;
    this.ratingMunicipality = ratingMunicipality;
    this.vehicles = vehicles;
    this.wasDriversSelectionMade = wasDriversSelectionMade;
    this.wasVehiclesSelectionMade = wasVehiclesSelectionMade;
    this.rightQuoteComplete = rightQuoteComplete;
    this.rootEnterpriseClientId = rootEnterpriseClientId;
    this.rootEnterpriseDriverId = rootEnterpriseDriverId;
    this.priorInsuranceLastSixMonths = priorInsuranceLastSixMonths;
    this.durationWithoutInsuranceLastSixMonths = durationWithoutInsuranceLastSixMonths;
    this.previousInsurerDurationInsured = previousInsurerDurationInsured;
    this.rideshare = rideshare;
    this.selfReportedMovingViolations = selfReportedMovingViolations;
  }

  set(key, value) {
    return ProfileParams.build({
      ...this,
      [key]: value,
    });
  }

  getOrderedDrivers() {
    let pniDrivers = this.drivers.filter((driver) => driver.pni);
    let nonPniDrivers = this.drivers.filter((driver) => !driver.pni);

    if (!pniDrivers.length) {
      return this.drivers.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    pniDrivers = pniDrivers.sort((a, b) => a.firstName.localeCompare(b.firstName));
    nonPniDrivers = nonPniDrivers.sort((a, b) => a.firstName.localeCompare(b.firstName));

    return [...pniDrivers, ...nonPniDrivers];
  }

  getPniDriver() {
    return this.drivers.find((driver) => driver.selected && !driver.removed && driver.pni);
  }

  getOriginalDriver() {
    return this.drivers.find((driver) => driver.universalDriverId === this.originalUniversalDriverId);
  }

  getSelectedDrivers() {
    return this.drivers.filter((driver) => driver.selected && !driver.removed);
  }

  hasPniDriverAndSelectedVehiclesWithVin() {
    const hasPniDriver = !!this.getPniDriver();
    const selectedVehicles = this.getSelectedVehiclesWithVin();
    return hasPniDriver && selectedVehicles.length > 0;
  }

  getAllSelectedVehicles() {
    return (this.vehicles || []).filter((vehicle) => vehicle.selected && !vehicle.removed);
  }

  getSelectedVehiclesWithVin() {
    return this.vehicles.filter((vehicle) => vehicle.selected && !vehicle.removed && vehicle.vin);
  }

  findSelectedVehicleWithVinMakeAndModel() {
    const selectedVehicles = this.getSelectedVehiclesWithVin();
    if (selectedVehicles.length === 1 &&
        selectedVehicles[0].make &&
        selectedVehicles[0].model) {
      return selectedVehicles[0];
    }
    return null;
  }

  getOrderedVehicles() {
    return this.vehicles.sort((a, b) => a.key().localeCompare(b.key()));
  }

  getOrderedVinlessVehicles() {
    return this.getOrderedVehicles().filter((vehicle) => !vehicle.vin && vehicle.selected);
  }

  allVehiclesMapped(workingAssignments) {
    const currentlyAssignedVehicles = workingAssignments.map((dva) => dva.vehicle.cid);

    return this.getAllSelectedVehicles()
      .map((v) => v.cid)
      .every((cid) => currentlyAssignedVehicles.includes(cid));
  }

  unassignedDrivers() {
    const assignedUniversalDriverIds = this.driverVehicleAssignments
      .map((dva) => dva?.driver?.universalDriverId);

    return this.getSelectedDrivers()
      .filter((driver) => !assignedUniversalDriverIds.includes(driver.universalDriverId));
  }

  unassignedVehicles() {
    const assignedVehicleCids = this.driverVehicleAssignments
      .map((dva) => dva?.vehicle?.cid);

    return this.getAllSelectedVehicles()
      .filter((vehicle) => !assignedVehicleCids.includes(vehicle.cid));
  }

  hasVinlessVehicles() {
    return this.vehicles.some((v) => !v.vin);
  }

  hasExcludedDrivers() {
    return this.excludedDrivers.length > 0;
  }

  isHomeownerValid() {
    return Homeowner.Options.some(({ value }) => this.homeowner === value);
  }

  isVehiclesValid() {
    return !!this.vehicles?.some((vehicle) => vehicle.selected);
  }

  isDriversValid() {
    return this.drivers.some((driver) => driver.selected);
  }

  market() {
    return this.mailingAddress.state;
  }

  isValid() {
    return [
      this.isHomeownerValid(),
      this.isVehiclesValid(),
      this.isDriversValid(),
    ].every((requirement) => requirement);
  }

  hasDuplicateNameDrivers() {
    return this.drivers.some((driver) => {
      const cleanedFirstName = driver.firstName.trim().toLowerCase();
      const cleanedLastName = driver.lastName.trim().toLowerCase();

      return this.drivers.filter((d) =>
        d.firstName.trim().toLowerCase() === cleanedFirstName && d.lastName.trim().toLowerCase() === cleanedLastName
      ).length > 1;
    });
  }

  unSelectNonPniDriversAndVehicles() {
    const updatedDrivers = this.drivers.map((driver) => driver.pni ? driver : driver.set('selected', false));
    const updatedVehicles = this.vehicles.map((vehicle) => vehicle.set('selected', false));

    return this.set('drivers', updatedDrivers).set('vehicles', updatedVehicles);
  }

  serializeForSubmission() {
    const drivers = this._serializeSelectedOptions(this.drivers);
    const driverVehicleAssignments = this._serializeArrayOptions(this.driverVehicleAssignments);
    const excludedDrivers = this._serializeArrayOptions(this.excludedDrivers);
    const vehicles = this._serializeSelectedOptions(this.vehicles);
    const homeowner = Homeowner.isHomeowner(this.homeowner);
    const result = AttributionService.getPartnerDriverIdentifiers();
    if (result) {
      this.rootEnterpriseClientId = result.rootEnterpriseClientId || null;
      this.rootEnterpriseDriverId = result.rootEnterpriseDriverId || null;
    }
    const rideshare = this.rideshare === null || this.rideshare === undefined ? false : this.rideshare;
    return {
      address: this.mailingAddress.serializeForSubmission(),
      drivers,
      driverVehicleAssignments,
      excludedDrivers,
      homeowner,
      ratingMunicipality: this.ratingMunicipality,
      vehicles,
      rootEnterpriseClientId: this.rootEnterpriseClientId,
      rootEnterpriseDriverId: this.rootEnterpriseDriverId,
      priorInsuranceLastSixMonths: this.priorInsuranceLastSixMonths,
      durationWithoutInsuranceLastSixMonths: this.durationWithoutInsuranceLastSixMonths,
      previousInsurerDurationInsured: this.previousInsurerDurationInsured,
      selfReportedMovingViolations: this.selfReportedMovingViolations,
      rideshare,
    };
  }

  serializeForSaving() {
    return {
      ...this,
      address: this.mailingAddress,
      drivers: this.drivers.map((d) => d.serializeForSaving()),
      driverVehicleAssignments: this._serializeArrayOptions(this.driverVehicleAssignments),
      excludedDrivers: this.excludedDrivers.map((d) => d.serializeForSaving()),
      vehicles: this.vehicles.map((v) => v.serializeForSaving()),
    };
  }

  unselectVehicle(vehicle) {
    const newVehicles = this.vehicles.map((v) => {
      if (v.cid === vehicle.cid) {
        return v.set('selected', false);
      }
      return v;
    });

    return this.set('vehicles', newVehicles);
  }

  setMaritalStatusFromPrefill() {
    if (this.maritalStatus) {
      return ProfileDriverService.setDriverValue(this, this.originalUniversalDriverId, 'maritalStatus', this.maritalStatus);
    }
    return this;
  }

  selectAll() {
    this.drivers.forEach((driver) => driver.selected = true);
    this.vehicles.forEach((vehicle) => vehicle.selected = true);

    if (this.primaryNamedInsured) {
      this.primaryNamedInsured.selected = true;
    }
  }

  _serializeSelectedOptions(options) {
    return options
      .filter((option) => option.selected && !option.removed)
      .map((option) => option.serializeForSubmission());
  }

  _serializeArrayOptions(options) {
    return options
      .map((option) => option.serializeForSubmission());
  }
}
