import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { RootError } from '@root-common/root-errors';
import { Shadows, StyleSheet, createEmotionClassName } from '@root/core/src/utils/styles';
import { clickOnSpace } from '@root/core/src/utils/forms';
import { opacityOverlayOnState } from '@root/core/src/utils/opacity-overlay';

export function FormAccessibleWrapper({
  children,
  handleSpacebar = true,
  includeHoverStyles = true,
  includeActiveStyles = true,
  includeFocusStyles = true,
  tabIndex = 0,
}) {
  return React.Children.map(children, (child) => {
    const { props: { onClick, css: childCss } = {} } = child;

    if (handleSpacebar && onClick === undefined) {
      throw new RootError({
        message: 'Children of FormAccessibleWrapper must have an onClick handler. You can turn this off by setting handleSpacebar to false.',
        name: 'FormAccessibleWrapperError',
      });
    }

    return React.cloneElement(child, {
      onKeyUp: handleSpacebar ? clickOnSpace : undefined,
      tabIndex: tabIndex ? tabIndex : undefined,
      className: createEmotionClassName([
        styles.base,
        includeHoverStyles && styles.hover,
        includeFocusStyles && styles.focus,
        includeActiveStyles && styles.active,
        childCss,
      ]),
    });
  });
}

FormAccessibleWrapper.propTypes = {
  handleSpacebar: PropTypes.bool,
  includeActiveStyles: PropTypes.bool,
  includeFocusStyles: PropTypes.bool,
  includeHoverStyles: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
};

const styles = StyleSheet.create({
  base: {
    position: 'relative',
  },
  hover: {
    ...Shadows.onHoverStateShadow(),
  },
  focus: {
    ...Shadows.onFocusStateShadow(),
  },
  active: {
    ...opacityOverlayOnState(),
  },
});
