import { Colors } from '@root/core/src/utils/styles';

export function BlackVehicleWifiIcon() {
  return (
    <svg
      fill={'none'}
      height={'41'}
      viewBox={'0 0 29 41'}
      width={'29'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        clipRule={'evenodd'}
        d={'M3.25098 24.3588V32.9063C3.25098 33.1824 3.47483 33.4062 3.75098 33.4062H6.0237C6.29985 33.4062 6.5237 33.1824 6.5237 32.9062V30.7764H21.9782V32.9063C21.9782 33.1824 22.2021 33.4062 22.4782 33.4062H24.751C25.0271 33.4062 25.251 33.1824 25.251 32.9062L25.251 24.3588C25.251 23.9136 25.0532 23.4915 24.7113 23.2065L23.751 22.4062H24.7511C25.0272 22.4062 25.2511 22.1824 25.2511 21.9062V20.9062C25.2511 20.6301 25.0272 20.4062 24.7511 20.4062H22.551L21.0423 17.8918C20.8616 17.5905 20.536 17.4062 20.1848 17.4062H8.31717C7.9659 17.4062 7.6404 17.5905 7.45967 17.8918L5.95098 20.4062H3.75098C3.47483 20.4062 3.25098 20.6301 3.25098 20.9062V21.9062C3.25098 22.1824 3.47483 22.4062 3.75098 22.4062H4.75098L3.7907 23.2065C3.44871 23.4915 3.25098 23.9136 3.25098 24.3588ZM18.751 25.9062C18.751 25.6301 18.9748 25.4062 19.251 25.4062H22.751C23.0271 25.4062 23.251 25.6301 23.251 25.9062V27.4062C23.251 27.6824 23.0271 27.9062 22.751 27.9062H19.251C18.9748 27.9062 18.751 27.6824 18.751 27.4062V25.9062ZM5.75098 25.4062C5.47483 25.4062 5.25098 25.6301 5.25098 25.9062V27.4062C5.25098 27.6824 5.47483 27.9062 5.75098 27.9062H9.25098C9.52712 27.9062 9.75098 27.6824 9.75098 27.4062V25.9062C9.75098 25.6301 9.52712 25.4062 9.25098 25.4062H5.75098ZM21.751 22.9062H6.75098L8.66245 19.425C8.83809 19.1051 9.17406 18.9062 9.53901 18.9062H18.9629C19.3279 18.9062 19.6638 19.1051 19.8395 19.425L21.751 22.9062Z'}
        fill={Colors.black()}
        fillRule={'evenodd'}
      />
      <path
        d={'M9.80611 6.85503C12.5824 3.97405 17.0836 3.97405 19.8599 6.85503L20.333 6.36408C17.2954 3.21197 12.3706 3.21197 9.33301 6.36408L9.80611 6.85503Z'}
        fill={Colors.black()}
      />
      <path
        d={'M18.1424 8.63731C16.3147 6.74066 13.3513 6.74066 11.5236 8.63731L11.0505 8.14637C13.1395 5.97858 16.5265 5.97858 18.6155 8.14637L18.1424 8.63731Z'}
        fill={Colors.black()}
      />
      <path
        d={'M13.2405 10.4189C14.12 9.50628 15.546 9.50628 16.4255 10.4189L16.8986 9.92801C15.7578 8.7442 13.9082 8.7442 12.7674 9.92801L13.2405 10.4189Z'}
        fill={Colors.black()}
      />
      <path
        d={'M15.8104 12.0417C15.8104 12.5709 15.3969 13 14.8869 13C14.3768 13 13.9634 12.5709 13.9634 12.0417C13.9634 11.5124 14.3768 11.0834 14.8869 11.0834C15.3969 11.0834 15.8104 11.5124 15.8104 12.0417Z'}
        fill={Colors.black()}
      />
    </svg>

  );
}
