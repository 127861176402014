import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { ListCardItem } from '@root/core/src/components/list-card';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function CoverageGroupItem({
  additionalDescription, children, description, heading, className,
}) {
  return (
    <ListCardItem className={className}>
      <div css={styles.headingContainer}>
        {heading && <h2 css={styles.heading}>{heading}</h2>}
      </div>
      {description && <p css={styles.description}>{description}</p>}
      {additionalDescription && <p css={[styles.description, styles.additionalDescription]}>{additionalDescription}</p>}
      {children}
    </ListCardItem>
  );
}

CoverageGroupItem.propTypes = {
  additionalDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading2(),
    marginBottom: 10,
    flexGrow: 1,
  },
  description: {
    marginBottom: 0,
  },
  additionalDescription: {
    marginTop: 20,
  },
  headingContainer: {
    display: 'flex',
  },
});
