import ArrayToText from '@root/core/src/components/array-to-text';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default class QuotesMobileSelectOption extends Component {
  static propTypes = {
    mobileSelectedOptionStyles: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    option: PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
      value: PropTypes.string.isRequired,
    }).isRequired,
    selectedValue: PropTypes.string.isRequired,
  }

  _getOptionStyle(isSelected) {
    if (isSelected) {
      return [styles.option, styles.selectedOption, this.props.mobileSelectedOptionStyles];
    }

    return styles.option;
  }

  _getSubLabelStyle(isSelected) {
    if (isSelected) {
      return [styles.subLabel, styles.selectedSubLabel];
    }

    return styles.subLabel;
  }

  _handleClick = (e) => {
    e.stopPropagation();
    this.props.onClick(this.props.option);
  }

  render() {
    const { option, selectedValue } = this.props;
    const isSelected = option.value === selectedValue;

    const subLabelContent = isSelected ? <>&#8226;</> : option.subLabel;

    return (
      <div
        css={this._getOptionStyle(isSelected)}
        onClick={this._handleClick}
      >
        <div><ArrayToText>{option.label.split(' / ')}</ArrayToText></div>
        <div css={this._getSubLabelStyle(isSelected)}>{subLabelContent}</div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  option: {
    height: 75,
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':not(:last-child)': {
      borderBottom: `1px solid ${Colors.gray30()}`,
    },
  },
  label: {
    whiteSpace: 'pre-line',
  },
  subLabel: {
    color: Colors.gray50(),
  },
  selectedOption: {
    color: Colors.white(),
    background: Colors.rootOrange(),
  },
  selectedSubLabel: {
    color: Colors.white(),
    fontSize: 24,
  },
});
