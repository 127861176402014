import PaymentMethodIconService from '@root/payments/src/services/payment-method-icon-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function BraintreeCardIcon({ cardType = '' }) {
  const altText = cardType ? cardType : 'Credit card';

  return (
    <img
      alt={altText}
      src={PaymentMethodIconService.getPaymentMethodIcon(cardType)}
    />
  );
}

BraintreeCardIcon.propTypes = {
  cardType: PropTypes.string,
};
