import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const FRAUD_DISCLAIMER_URL = `${environment.websiteBaseUrl}/fraud-disclaimer/`;

export function FraudDisclaimerLink({ styles }) {
  return (
    <a
      css={styles}
      href={FRAUD_DISCLAIMER_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Fraud Disclaimer
    </a>
  );
}

FraudDisclaimerLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
