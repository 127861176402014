import BindParams from '@root/quotes/src/models/bind-params';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuoteCustomizationService from '@root/bind.joinroot.com/src/services/quote-customization-service';
import dayjs from '@root/vendor/dayjs';
import isEqual from '@root/vendor/lodash/isEqual';
import useCreateCustomQuoteMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-create-custom-quote-mutation';
import useCreateQuoteMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-create-quote-mutation';
import useRerateWithBackendOptionsMutation from '@root/bind.joinroot.com/src/hooks/api/mutations/use-rerate-with-backend-options-mutation';
import uuidv4 from '@root/vendor/uuid/v4';
import { RootError } from '@root-common/root-errors';
import { useCallback, useEffect } from '@root/vendor/react';
import { usePersistedQuoteCoveragesContext } from '@root/bind.joinroot.com/src/hooks/api/use-persisted-quote-coverages-context';
import { usePersistedQuoteCoveragesContextsForBackendOptions } from '@root/bind.joinroot.com/src/hooks/api/use-persisted-quote-coverages-contexts-for-backend-options';
import { usePersistedQuotesContext } from '@root/bind.joinroot.com/src/hooks/api/use-persisted-quotes-context';
import { usePersistedQuotingRules } from '@root/bind.joinroot.com/src/hooks/api/use-persisted-quoting-rules';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEYS = {
  BIND_PARAMS: 'bind_params',
  QUOTE_ID: 'quote_id',
  RATING_REQUEST_ID: 'rating_request_id',
  SELECTED_QUOTE: 'selected_quote',
  SERIALIZED_LAST_QUOTED_PROFILE_PARAMS: 'serialized_last_quoted_profile_params',
};

const parseStartDateFromPolicyEffectiveDate = (policyEffectiveDate) => {
  const today = dayjs().utc().endOf('day');

  // If not provided, policyEffectiveDate is null, which dayjs doesn't like, but it does deal with undefined
  const parsedDate = dayjs(policyEffectiveDate || undefined).utc().endOf('day');

  return parsedDate.isAfter(today) ? parsedDate.toDate() : today.toDate();
};

function useQuote({
  defaultBillingCycle,
  defaultStartDate,
  initialRatingRequestId,
  profileParams,
}) {
  if (!profileParams) {
    throw new RootError({
      additionalData: {
        profileParams,
      },
      message: 'Argument profileParams for the useQuote hook is required to be present',
      name: 'MissingProfileParamsError',
    });
  } else if (!(profileParams instanceof ProfileParams)) {
    throw new RootError({
      additionalData: {
        profileParams,
      },
      fingerprint: ['Invalid profileParams type for the useQuote hook'],
      message: `Argument profileParams of type ${profileParams.constructor.name} for the useQuote hook is required to be of type ProfileParams`,
      name: 'InvalidProfileParamsError',
    });
  }

  const [bindParams, setBindParams] = usePersistedState({
    deserialize: (persistedItem) => BindParams.build({
      ...persistedItem,
      paymentNonce: undefined,
      startDate: new Date(persistedItem.startDate),
    }),
    initialValue: BindParams.build({
      billingCycle: defaultBillingCycle,
      startDate: parseStartDateFromPolicyEffectiveDate(defaultStartDate),
    }),
    storageKey: LOCAL_STORAGE_KEYS.BIND_PARAMS,
  });

  const [serializedLastQuotedProfileParams, setSerializedLastQuotedProfileParams] = usePersistedState({
    storageKey: LOCAL_STORAGE_KEYS.SERIALIZED_LAST_QUOTED_PROFILE_PARAMS,
  });

  const [ratingRequestId, setRatingRequestId] = usePersistedState({
    initialValue: initialRatingRequestId,
    storageKey: LOCAL_STORAGE_KEYS.RATING_REQUEST_ID,
  });

  const [quoteId, setQuoteId] = usePersistedState({ storageKey: LOCAL_STORAGE_KEYS.QUOTE_ID });

  const [selectedQuote, setSelectedQuote] = usePersistedState({
    deserialize: (persistedItem) => CustomQuote.build({
      ...persistedItem,
      quoteCoveragesContext: QuoteCoveragesContext.build(persistedItem.quoteCoveragesContext),
    }),
    storageKey: LOCAL_STORAGE_KEYS.SELECTED_QUOTE,
  });

  const {
    isQuotesContextCurrent,
    quotesContext,
    setQuotesContext,
  } = usePersistedQuotesContext({ ratingRequestId });

  const {
    isQuoteCoveragesContextCurrent,
    quoteCoveragesContext,
    setQuoteCoveragesContext,
  } = usePersistedQuoteCoveragesContext({ ratingRequestId });

  const {
    isQuoteCoveragesContextsForBackendOptionsCurrent,
    quoteCoveragesContextsForBackendOptions,
    refetchQuotesCoveragesForAllBackendOptions,
    setQuoteCoveragesContextsForBackendOptions,
  } = usePersistedQuoteCoveragesContextsForBackendOptions({
    manualTortMarket: quotesContext?.manualTortMarket,
    quoteId,
    ratingRequestId,
  });

  const { isQuotingRulesCurrent, quotingRules } = usePersistedQuotingRules({
    market: profileParams.market(),
    ratingRequestId,
  });

  const {
    mutate: createQuoteMutation,
    isLoading: isCreatingQuote,
    isSuccess: didCreateQuote,
  } = useCreateQuoteMutation({
    onSuccess: (createdQuote) => {
      setSerializedLastQuotedProfileParams(profileParams.serializeForSubmission());
      setRatingRequestId(createdQuote?.details?.ratingRequestId);
    },
  });

  const { mutate: rerateWithBackendOptionsMutation } = useRerateWithBackendOptionsMutation();

  const createQuote = useCallback((config = {}) => {
    setSelectedQuote(undefined);
    setQuotesContext(undefined);
    setQuoteCoveragesContext(undefined);
    setRatingRequestId(undefined);

    if (!isCreatingQuote) {
      createQuoteMutation({
        profile: profileParams.serializeForSubmission(),
      }, config);
    }
  }, [setSelectedQuote, setQuotesContext, setQuoteCoveragesContext, setRatingRequestId, isCreatingQuote, createQuoteMutation, profileParams]);

  const {
    mutate: createCustomQuote,
    isLoading: isCreatingCustomQuote,
    isSuccess: didCreateCustomQuote,
  } = useCreateCustomQuoteMutation({
    onSuccess: (createdCustomQuote) => {
      const createdQuote = CustomQuote.build(createdCustomQuote);
      const updatedQuotesContext = quotesContext.updateCustomQuote(createdQuote);
      setQuotesContext(updatedQuotesContext);
      setBindParams((prevBindParams) => prevBindParams.setAttributes({
        quoteId: createdQuote.id,
      }));
    },
  });

  const handleUpdateBindParams = useCallback((newBindParamsObjectOrFunction) => {
    let newBindParams = newBindParamsObjectOrFunction;

    // since the prop passed was directly using react's setState before, some calls were relying on the fact that setState accepts an anonymous function or the data itself
    if (typeof newBindParamsObjectOrFunction === 'function') {
      newBindParams = newBindParamsObjectOrFunction(bindParams);
    }

    if (newBindParams.tortSelection === bindParams.tortSelection) {
      return setBindParams(newBindParams);
    }

    rerateWithBackendOptionsMutation({
      backendOptions: { tortSelection: newBindParams.tortSelection },
      rateId: selectedQuote.rateId,
    }, {
      onSuccess: () => {
        setQuoteCoveragesContextsForBackendOptions(undefined);
        refetchQuotesCoveragesForAllBackendOptions();

        const backendOptions = quoteCoveragesContextsForBackendOptions[0].options.flatMap((o) => Object.keys(o));

        const context = quoteCoveragesContextsForBackendOptions.find((c) => {
          return backendOptions.every((option) => {
            const choice = c.options.find((o) => o[option])[option];
            return newBindParams[option] === choice;
          });
        });

        const newQuoteCoveragesContext = QuoteCoveragesContext.build(context.quoteCoveragesContext);
        setQuoteCoveragesContext(newQuoteCoveragesContext);

        let newSelectedQuote = selectedQuote.updateQuoteCoveragesContext(newQuoteCoveragesContext);
        newSelectedQuote = newSelectedQuote.set('originQuoteId', selectedQuote.originQuoteId ?? selectedQuote.id).set('id', uuidv4());
        newBindParams = newBindParams.set('quoteId', newSelectedQuote.id);

        const profileVins = profileParams.getAllSelectedVehicles().map((v) => v.getAvailableVin());

        setBindParams(newBindParams);
        setQuoteCoveragesContext(newQuoteCoveragesContext);
        setSelectedQuote(newSelectedQuote.updatePrices(profileVins).addTaxAndFees(quotesContext));
      },
    });
  }, [
    bindParams,
    profileParams,
    quoteCoveragesContextsForBackendOptions,
    quotesContext,
    refetchQuotesCoveragesForAllBackendOptions,
    rerateWithBackendOptionsMutation,
    selectedQuote,
    setBindParams,
    setQuoteCoveragesContext,
    setQuoteCoveragesContextsForBackendOptions,
    setSelectedQuote,
  ]);

  useEffect(() => {
    if (quotesContext && quoteCoveragesContext && !selectedQuote) {
      const initialSelectedQuote = QuoteCustomizationService.createCustomQuote(
        quotesContext,
        quoteCoveragesContext,
      );

      setSelectedQuote(initialSelectedQuote);
      setQuoteId(initialSelectedQuote.id);
      setBindParams((prevBindParams) => prevBindParams.setAttributes({
        quoteId: initialSelectedQuote.id,
        tortSelection: quotesContext.tortSelection,
      }));
    }
  }, [quotesContext, quoteCoveragesContext, selectedQuote, setBindParams, setSelectedQuote, setQuoteId]);

  const isQuoteCurrent = !!serializedLastQuotedProfileParams && isEqual(profileParams.serializeForSubmission(), serializedLastQuotedProfileParams);

  return {
    bindParams,
    createCustomQuote,
    createQuote,
    didCreateCustomQuote,
    didCreateQuote,
    isCreatingCustomQuote,
    isCreatingQuote,
    isQuoteCurrent,
    quotesContext,
    quoteCoveragesContext,
    quoteCoveragesContextsForBackendOptions,
    quotingRules,
    readyToCustomizeQuote: isQuoteCurrent
    && !!ratingRequestId
    && isQuotesContextCurrent
    && isQuoteCoveragesContextCurrent
    && isQuoteCoveragesContextsForBackendOptionsCurrent
    && isQuotingRulesCurrent
    && !!selectedQuote,
    selectedQuote,
    updateBindParams: handleUpdateBindParams,
    updateSelectedQuote: setSelectedQuote,
  };
}

export default useQuote;
