import BaseInput from '@root/core/src/components/input';
import React, { useMemo } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { StyleSheet } from '@root/core/src/utils/styles';

const Input = (props) => {
  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  return (
    <BaseInput
      inputStyle={styles.inputStyle}
      {...props}
    />
  );
};

Input.propTypes = BaseInput.propTypes;

export const stylesGenerator = ({ primaryColor }, restStyles = {}) => StyleSheet.create({
  inputStyle: {
    caretColor: primaryColor,
  },
  ...restStyles,
});

export default Input;
