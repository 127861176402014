import ProtectedRoute from '@root/bind.joinroot.com/src/components/routers/protected-route';
import React from '@root/vendor/react';
import useProfile from '@root/bind.joinroot.com/src/context/profile';

const { authorized, ...AuthProtectedRoutePropTypes } = ProtectedRoute.propTypes; // eslint-disable-line no-unused-vars

const ProfileProtectedRoute = ({
  children, path, redirectUnauthorizedToPath, ...restProps
}) => {
  const profile = useProfile();
  return (
    <ProtectedRoute
      authorized={!!profile}
      path={path}
      redirectUnauthorizedToPath={redirectUnauthorizedToPath}
      {...restProps}
    >
      {children}
    </ProtectedRoute>
  );
};
ProfileProtectedRoute.propTypes = AuthProtectedRoutePropTypes;

export default ProfileProtectedRoute;
