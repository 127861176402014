import DateInput from '@root/core/src/components/date-input';
import DobDate from '@root/core/src/models/dob-date';
import InputError from '@root/core/src/components/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import noop from '@root/vendor/lodash/noop';
import useInputFocusOnMount from '@root/core/src/hooks/use-input-focus-on-mount';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const MONTHS = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
];

function onlyPossibleDatePart(datePart, allParts) {
  return allParts.filter((part) => part.startsWith(datePart.toString())).length === 1;
}

export default function DateInputGroup({
  date, errorLabel, onBlur = () => noop, onChange, onValidYear = noop, focusOnMount = false, inputStyle,
}) {
  const [prevDate, setPrevDate] = useState(date);
  const [touched, setTouched] = useState({
    month: false,
    day: false,
    year: false,
  });

  const yearInput = useRef();

  const firstInputRef = useInputFocusOnMount(focusOnMount);

  useEffect(() => {
    if (date.isSameDate(prevDate)) { return; }

    const finishedEntryHandlers = {
      month: () => date.isValidMonth() && onlyPossibleDatePart(date.month, MONTHS) && yearInput.current.focus(),
      year: () => date.isValidYear() && onValidYear(),
    };

    ['month', 'year'].find((datePartName) => date[datePartName] !== prevDate[datePartName] && finishedEntryHandlers[datePartName]());
    setPrevDate(date);
  }, [date, onValidYear, prevDate]);

  const handleChange = (datePartName) => (value) => {
    if (!date.day) { onChange('day')('1'); }
    onChange(datePartName)(value);
  };

  const handleBlur = (datePartName) => (value) => {
    setTouched({
      ...touched,
      [datePartName]: true,
    });
    onBlur?.(datePartName)?.(value);
  };

  return (
    <>
      <div css={styles.dateInputGroupWrapper}>
        <DateInput
          inputStyle={[styles.leftInput, inputStyle]}
          isError={!!errorLabel || !!(date.month && !date.isValidMonth()) && touched.month}
          label={'MM'}
          maxLength={'2'}
          onBlur={handleBlur('month')}
          onChange={handleChange('month')}
          ref={firstInputRef}
          value={date['month']}
        />
        <DateInput
          inputStyle={[styles.rightInput, inputStyle]}
          isError={!!errorLabel || !!(date.year && !date.isValidYear()) && touched.year}
          label={'YYYY'}
          maxLength={'4'}
          onBlur={handleBlur('year')}
          onChange={handleChange('year')}
          ref={yearInput}
          value={date['year']}
        />
      </div>
      <InputError message={errorLabel} />
    </>
  );
}

DateInputGroup.propTypes = {
  date: PropTypes.instanceOf(DobDate).isRequired,
  errorLabel: PropTypes.string,
  focusOnMount: PropTypes.bool,
  inputStyle: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onValidYear: PropTypes.func,
};

const styles = StyleSheet.create({
  dateInputGroupWrapper: {
    display: 'flex',
  },
  leftInput: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  rightInput: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  errorLabel: {
    color: Colors.darkPink(),
    paddingTop: 10,
  },
});
