import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import noop from '@root/vendor/lodash/noop';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { TextTypes } from '@root/core/src/components/input';

export function DateInput({
  disabled = false,
  inputRef,
  inputStyle,
  inputType = TextTypes.TEXT,
  isUseNumberKeyboard = true,
  isError,
  label,
  maxLength,
  onBlur = noop,
  onChange,
  onFocus = noop,
  value,
  ...props
}) {
  const inputId = props.inputId || label.toLowerCase().replace(' ', '-') + '-input';
  const labelId = `${inputId}-label`;

  const labelStyles = [styles.label];
  const inputStyles = [styles.input, inputStyle];

  if (value !== '') { labelStyles.push(styles.labelSmall); }
  if (isError) {
    labelStyles.push(styles.errorLabel);
    inputStyles.push(styles.errorInput);
  }

  const numberKeyboardProps = {
    inputMode: 'decimal',
    pattern: '[0-9]*',
  };

  return (
    <div css={styles.dateInputWrapper}>
      <input
        aria-labelledby={labelId}
        css={inputStyles}
        disabled={disabled}
        id={inputId}
        maxLength={maxLength}
        onBlur={(e) => onBlur(e.target.value)}
        onChange={(e) => onChange(e.target.value)}
        onFocus={(e) => onFocus(e.target.value)}
        ref={inputRef}
        type={inputType}
        value={value}
        {...(isUseNumberKeyboard && numberKeyboardProps)}
      />
      <label
        css={labelStyles}
        htmlFor={inputId}
        id={labelId}
      >
        {label}
      </label>
    </div>
  );
}

DateInput.propTypes = {
  disabled: PropTypes.bool,
  inputId: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  inputStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  inputType: PropTypes.oneOf(Object.values(TextTypes)),
  isError: PropTypes.bool,
  isUseNumberKeyboard: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  value: PropTypes.string.isRequired,
};

export default React.forwardRef((props, ref) => (
  <DateInput
    {...props}
    inputRef={ref}
  />
));

const labelSmallStyle = {
  ...Theme.inputLabel(),
  top: 0,
  transform: 'translateY(10px) translateX(-50%)',
};

const styles = StyleSheet.create({
  dateInputWrapper: {
    height: 65,
    width: '100%',
    position: 'relative',
  },
  input: {
    ...Theme.input(),
    caretColor: Colors.rootOrange(),
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    border: `1px solid ${Colors.gray30()}`,
    paddingTop: '20px',
    ':focus + label': labelSmallStyle,
    ':focus': {
      outline: 'none',
      borderColor: Colors.black(),
    },
    ':disabled': {
      border: 'none',
      background: Colors.gray10(),
    },
  },
  label: {
    ...Theme.input({
      placeholder: true,
    }),
    pointerEvents: 'none',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    transition: 'all 150ms ease-out',
  },
  labelSmall: labelSmallStyle,
  errorInput: {
    borderColor: Colors.error(),
    ':focus': {
      borderColor: Colors.error(),
    },
    color: Colors.error(),
  },
});
