import AddDriverInexperiencedOperatorComponent from '@root/age-licensed/src/components/age-licensed-inexperienced-operator';
import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Driver from '@root/auto-pricing/src/models/driver';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import dayjs from '@root/vendor/dayjs';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class ProfileAddDriverInexperiencedOperator extends Component {
  static propTypes = {
    driver: PropTypes.instanceOf(Driver).isRequired,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      currentAssignment: '',
      availableOptions: [],
    };
  }

  static analyticsContext = 'PROFILE_ADD_DRIVER_INEXPERIENCED_OPERATOR';

  static contextType = AnalyticsContext;

  componentDidMount() {
    const drivers = [this.props.driver];

    if (!ProfileDriverService.requiresInexperiencedOperator(drivers, this.props.profileRulesContext)) {
      AnalyticsService.trackEvent(ProfileAddDriverInexperiencedOperator.analyticsContext, 'NOT_REQUIRED', { eventPrefix: this.context.eventPrefix });
      return this.props.onDone(this.props.driver);
    }

    AnalyticsService.trackViewEvent(ProfileAddDriverInexperiencedOperator.analyticsContext, { eventPrefix: this.context.eventPrefix });

    const dobDate = dayjs(this.props.driver.dob.toString(), 'YYYY-MM-DD');
    const driverAge = dayjs().diff(dobDate, 'years');
    const yearDifference = driverAge - this.props.driver.ageWhenLicensed;
    const minIndex = Math.max(0, yearDifference - 1);
    const maxIndex = yearDifference + 1;
    const availableOptions = this.props.profileRulesContext.userSpecifiedYearsLicensedSelectionChoices.slice(minIndex, maxIndex);

    this.setState({
      availableOptions,
    });
  }

  _handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.currentAssignment) {
      AnalyticsService.trackClickEvent(ProfileAddDriverInexperiencedOperator.analyticsContext, 'SUBMIT', { eventPrefix: this.context.eventPrefix });
      const newDriver = this.props.driver.set('yearsLicensedSelection', this.state.currentAssignment);
      this.props.onDone(newDriver);
    }
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverInexperiencedOperator.analyticsContext, 'CANCEL', { eventPrefix: this.context.eventPrefix });
    this.props.onCancel();
  }

  _renderTitle() {
    return `How many years has ${this.props.driver.firstName} had their license?`;
  }

  _updateCurrentAssignment = (e) => {
    this.setState({
      currentAssignment: e.target.value,
    });
  }

  render() {
    return (
      <div>
        <div css={styles.wrapper}>
          <AddDriverInexperiencedOperatorComponent
            availableOptions={this.state.availableOptions}
            currentAssignment={this.state.currentAssignment}
            handleSubmit={this._handleSubmit}
            onChange={this._updateCurrentAssignment}
            title={this._renderTitle()}
          />
        </div>
        <div>
          <NevermindLink onCancel={this._handleCancel} />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    marginBottom: 30,
  },
});
