import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getUndewritingCompanyContextConfiguration({ market, profileId }) {
  const query = {
    market,
    ...profileId ? { profileId } : {},
  };

  return new NetworkRequestConfiguration({
    endpoint: 'underwriting_companies',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: query,
  });
}
