import BaseCard from '@root/core/src/components/card';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors } from '@root/core/src/utils/styles';

const styles = {
  card: {
    backgroundColor: Colors.white(),
    display: 'flex',
    flex: 0,
    justifyContent: 'center',
    width: '100%',
    zIndex: 2,

    ...Responsive.md({
      width: Responsive.BREAKPOINTS.md,
    }),
  },
};

const Card = ({
  children,
  cssOverrides = {},
  ...restProps
}) => {
  return (
    <BaseCard
      cssOverrides={{
        ...styles.card,
        ...cssOverrides,
      }}
      {...restProps}
    >
      {children}
    </BaseCard>
  );
};

Card.propTypes = BaseCard.propTypes;

export default Card;
