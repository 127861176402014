import Coverage from '@root/auto-pricing/src/models/coverage';
import Money from '@root/core/src/models/money';
import lowerCase from '@root/vendor/lodash/lowerCase';

export default class CoverageAmountLabel {
  static COVERAGE_TYPES = {
    deductible: 'deductible',
    perDay: 'per day',
    perPerson: 'per person',
    perOccurrence: 'per accident',
  };

  static CUSTOM_COVERAGE_TYPES_FOR_SYMBOL = {
    [Coverage.Symbols.ROADSIDE]: 'per incident',
  }

  static DECLINED_LABEL = 'Declined';

  static _customCoverageOptionsLabel = {
    FL: {
      [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: {
        [Coverage.CoverageOptions.STACKED]: {
          [Coverage.CoverageOptionChoice.NO]: 'unstacked coverage',
          [Coverage.CoverageOptionChoice.YES]: 'stacked coverage',
        },
      },
      [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: {
        ignoreLabelConcatenation: true,
        [Coverage.CoverageOptions.RESIDENT_RELATIVES_INCLUDED]: {
          [Coverage.CoverageOptionChoice.NO]: 'applies to named insured only',
          [Coverage.CoverageOptionChoice.YES]: 'applies to resident relatives',
        },
        [Coverage.CoverageOptions.WORK_LOSS_EXCLUDED]: {
          [Coverage.CoverageOptionChoice.NO]: 'wage loss included',
          [Coverage.CoverageOptionChoice.YES]: 'wage loss rejected',
        },
      },
    },
    PA: {
      [Coverage.Symbols.UNDERINSURED_MOTORIST]: {
        [Coverage.CoverageOptions.STACKED]: {
          [Coverage.CoverageOptionChoice.NO]: 'unstacked coverage',
          [Coverage.CoverageOptionChoice.YES]: 'stacked coverage',
        },
      },
      [Coverage.Symbols.UNINSURED_MOTORIST]: {
        [Coverage.CoverageOptions.STACKED]: {
          [Coverage.CoverageOptionChoice.NO]: 'unstacked coverage',
          [Coverage.CoverageOptionChoice.YES]: 'stacked coverage',
        },
      },
    },
  };

  static getCoverageAmountLabelForCoverage(coverage, market) {
    if (coverage.declined) {
      return CoverageAmountLabel.DECLINED_LABEL;
    }

    const coveragePrices = CoverageAmountLabel._getCoveragePrices(coverage);

    const base = Object.keys(CoverageAmountLabel.COVERAGE_TYPES)
      .filter((coverageType) => coveragePrices[coverageType] !== null && coveragePrices[coverageType] !== undefined)
      .reduce((coverageLabel, coverageType, idx) => {
        return coverageLabel +
       CoverageAmountLabel._formatCoverageLabel(coverageType, coverage.symbol, coveragePrices, idx);
      }, '');

    return base ? base + CoverageAmountLabel.buildCoverageOptionsLabel(coverage.coverageOptions, coverage.symbol, market) : '';
  }

  static _formatCoverageLabel(coverageType, coverageSymbol, coveragePrices, idx) {
    const divider = idx > 0 ? ' / ' : '';
    const dollarString = Money.fromDollars(coveragePrices[coverageType]).formattedDollars();
    const standardCoverageTypeString = CoverageAmountLabel.COVERAGE_TYPES[coverageType];
    const customCoverageTypeString = CoverageAmountLabel.CUSTOM_COVERAGE_TYPES_FOR_SYMBOL[coverageSymbol];

    const coverageTypeString = customCoverageTypeString || standardCoverageTypeString;

    return `${divider}${dollarString} ${coverageTypeString}`;
  }

  static buildCoverageOptionsLabel(coverageOptions, symbol, market) {
    const coverageOptionsLabels = [];

    for (const [key, value] of Object.entries(coverageOptions)) {
      const labels = CoverageAmountLabel._customCoverageOptionsLabel[market]?.[symbol]?.[key] || null;

      if (labels) {
        coverageOptionsLabels.push(labels[value]);
      } else if (value === Coverage.CoverageOptionChoice.YES) {
        coverageOptionsLabels.push(lowerCase(key.toString()));
      }
    }

    const ignoreLabelConcatenation = CoverageAmountLabel._customCoverageOptionsLabel[market]?.[symbol]?.['ignoreLabelConcatenation'];
    const labelPrefix = ignoreLabelConcatenation ? ' ' : ' with ';
    const labelJoin = ignoreLabelConcatenation ? ', ' : ', with ';

    return coverageOptionsLabels.length ? labelPrefix + coverageOptionsLabels.sort().join(labelJoin) : '';
  }

  static _getCoveragePrices(coverage) {
    return Object.keys(coverage)
      .filter((key) => Object.keys(CoverageAmountLabel.COVERAGE_TYPES).includes(key))
      .reduce((prices, key) => ({
        ...prices,
        [key]: coverage[key],
      }), {});
  }
}

