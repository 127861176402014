import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const VEHICLE_IMAGE_ALT_TEXT = 'vehicle-image';

const CoverageSummary = ({
  drivers,
  vehicles,
}) => {
  const strings = useI18nNext('components.bindStartEntry.coverageSummary');

  const primaryDriver = drivers[0];
  const primaryVehicle = vehicles[0];
  const vehicleImageUrl = vehicles[0]?.vehicleImageUrl;

  return (
    <div css={styles.container} >
      <div css={[styles.summary, styles.vehicleSummary]}>
        <h3>
          {strings.covered.vehicle}
        </h3>

        <div css={styles.vehicleSummaryWrap}>
          {!!vehicleImageUrl && (
            <div css={styles.imageContainer}>
              <img
                alt={VEHICLE_IMAGE_ALT_TEXT}
                css={styles.coveredImage}
                src={vehicleImageUrl}
              />
            </div>
          )}
          <div css={styles.vehicleText}>
            <p css={styles.text}>
              {
                [
                  primaryVehicle.year,
                  primaryVehicle.make,
                  primaryVehicle.model,
                ].filter((e) => !!e).join(' ')
              }
            </p>

            {
              !!primaryVehicle.trim &&
              <p css={styles.subtext}>{primaryVehicle.trim}</p>
            }
          </div>
        </div>
      </div>

      <div css={[styles.summary, styles.driverSummary]}>
        <h3>
          {strings.covered.driver}
        </h3>

        <p css={styles.text}>
          {`${primaryDriver.firstName} ${primaryDriver.lastName}`}
        </p>
      </div>
    </div>
  );
};

CoverageSummary.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CoverageSummary;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    ...Responsive.md({
      flexDirection: 'row',
      marginLeft: 0,
    }),
  },
  summary: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem',
    minWidth: 225,
  },
  driverSummary: {
    flex: '40%',
  },
  vehicleSummary: {
    flex: '60%',
  },
  vehicleText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '130%',
  },
  vehicleSummaryWrap: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    borderRadius: '50%',
    display: 'flex',
    flexShrink: 1,
    justifyContent: 'center',
    height: 51,
    marginRight: 10,
    overflow: 'hidden',
    width: 51,
  },
  coveredImage: {
    height: '100%',
    width: 'auto',
  },
  text: {
    fontSize: '1.125rem',
    fontWeight: 'normal',
    marginBottom: 0,
  },
  subtext: {
    fontSize: '0.875rem',
    marginBottom: 0,
  },
});
