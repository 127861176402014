import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import TriageGetAppButton from '@root/triage/src/components/triage-get-app-button';
import TriageSendTextForm from '@root/triage/src/components/triage-send-text-form';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function TriageGetApp({
  inline = false,
  trackClick,
  formButtonText,
  onSubmit,
}) {
  return (
    <div css={styles.wrapper}>
      <div css={styles.textMessageForm}>
        <TriageSendTextForm
          buttonText={formButtonText}
          inline={inline}
          onSubmit={onSubmit}
          trackClick={trackClick}
        />
      </div>
      <div css={styles.appLink}>
        <TriageGetAppButton trackClick={trackClick} />
      </div>
    </div>
  );
}

TriageGetApp.propTypes = {
  formButtonText: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  onSubmit: PropTypes.func,
  trackClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: 70,
  },
  appLink: {
    marginTop: 50,
    marginBottom: 20,
    ...Responsive.md({
      display: 'none',
    }),
  },
  textMessageForm: {
    display: 'none',
    ...Responsive.md({
      display: 'block',
    }),
  },
});
