import CoBrandedLogo from '@root/bind.joinroot.com/src/components/bind-view/header/co-branded-logo';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const styles = StyleSheet.create({
  headerWrapper: {
    backgroundColor: Colors.white(),
    display: 'flex',
    flexDirection: 'row',
    flex: 0,
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
    justifyContent: 'space-between',
    width: '100vw',
    padding: '5px 80px',
    borderBottom: '1.5px rgba(0, 0, 0, 0.1) solid',
    zIndex: 2,

    ...Responsive.lessThanMd({
      padding: '10px 1rem',
    }),
  },
  logoContainer: {
    marginBottom: '0.5em',
    marginTop: '0.5em',
    ...Responsive.lessThanMd({
      maxWidth: '50%',
    }),

    img: {
      height: 30,
      width: 'auto',
      maxWidth: '175px',
      objectFit: 'contain',
    },

    svg: {
      height: 30,
      maxWidth: '175px',
      objectFit: 'contain',
    },
  },
  contentContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
});

export default function Header({
  children,
  cssOverrides = {},
}) {
  const [branding] = useBranding();

  return (
    <div
      css={[
        styles.headerWrapper,
        cssOverrides,
      ]}
      data-testid={'header'}
    >
      <CoBrandedLogo
        cssOverrides={styles.logoContainer}
        forceDefaultLogo={branding?.hideHeaderPrimaryLogo ?? false}
      />
      <div css={styles.contentContainer}>
        {children}
      </div>
    </div>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  cssOverrides: PropTypes.object,
};
