import Button from '@root/core/src/components/button';
import ExplainerCard from '@root/core/src/components/explainer-card';
import Link from '@root/core/src/components/link';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RightQuoteDivider from '@root/right-quote/src/components/right-quote-divider';
import RightQuoteExplainerHeader from '@root/right-quote/src/components/right-quote-explainer-header';
import RightQuoteExplainerLearnMore from '@root/right-quote/src/components/right-quote-explainer-learn-more';
import money from '@root/right-quote/src/assets/money.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RightQuoteExplainerNoComp({
  buttonText = 'Continue', isModal, onContinue,
}) {
  const { trackClick } = useAnalytics('RIGHT_QUOTE_EXPLAINER_NO_COMP');
  const [showLearnMore, setShowLearnMore] = useState(false);

  const handleContinue = () => {
    trackClick('CONTINUE');
    onContinue();
  };

  const handleLearnMore = () => {
    trackClick('LEARN_MORE');
    setShowLearnMore(true);
  };

  return (
    <ExplainerCard
      onModalClose={isModal ? onContinue : null}
    >
      <RightQuoteExplainerHeader
        icon={money}
      >
        <div>It sounds like your vehicles are fully paid off.</div>
      </RightQuoteExplainerHeader>
      <div css={styles.container}>
        <div css={styles.content}>
          <p css={styles.text}>
            Paying more for extra coverage may not be worth the cost.
            {!showLearnMore &&
              <>
                {' '}
                <Link
                  css={styles.link}
                  onClick={handleLearnMore}
                >
                  Learn More
                </Link>
              </>
            }
          </p>
          {!showLearnMore &&
          <RightQuoteDivider />
          }
        </div>
        <RightQuoteExplainerLearnMore
          displayExplainer={showLearnMore}
        >
          <div css={[styles.content, styles.text, styles.explainerText]}>
            If you ever file a claim to cover the cost of vehicle repairs, your insurance company will only pay up to the amount your vehicle is worth.
          </div>
        </RightQuoteExplainerLearnMore>
        <div css={styles.content}>
          <p css={[styles.text, styles.bottomText]}>
            People with vehicles like yours often skip Collision and Comprehensive coverage.
          </p>
          <Button
            onClick={handleContinue}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </ExplainerCard>
  );
}

RightQuoteExplainerNoComp.propTypes = {
  buttonText: PropTypes.string,
  isModal: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    padding: '30px 0',
    ...Responsive.sm({
      paddingBottom: 50,
    }),
  },
  content: {
    padding: '0 20px',
    ...Responsive.sm({
      padding: '0 80px',
    }),
  },
  text: {
    ...Theme.paragraph1(),
  },
  bottomText: {
    marginBottom: 20,
  },
  explainerText: {
    color: Colors.white(),
  },
  link: {
    ...Theme.link(),
  },
});
