import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import CoBrandedLogo from '@root/bind.joinroot.com/src/components/bind-view/header/co-branded-logo';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const MissingAuthEntry = () => {
  useAnalytics('MISSING_AUTH_ENTRY');

  const strings = useI18nNext('pages.missingAuthEntry');
  return (
    <BindView
      cssOverrides={{
        container: {
          '&:before': {
            display: 'none',
          },
        },
        content: {
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    >
      <BindView.Card
        cssOverrides={styles.card}
      >
        <h1 css={styles.heading}>
          {strings.heading}
        </h1>
        <p css={styles.info}>
          {strings.info}
        </p>
        <p css={styles.instruction}>
          {`${strings.instruction} ${strings.source}`}
        </p>
        <div css={styles.logoContainer}>
          <CoBrandedLogo
            cssOverrides={styles.logo}
          />
        </div>
      </BindView.Card>
    </BindView>
  );
};

const styles = StyleSheet.create({
  card: {
    ...Responsive.lessThanMd({
      backgroundColor: Colors.white(),
      margin: '0.5rem',
      padding: '1.5rem',
      ...Shadows.cardShadow(),
    }),
  },
  heading: {
    ...Theme.heading1({
    }),
    fontSize: 40,
    ...Responsive.md({
      fontSize: 46,
    }),
  },
  info: {
    ...Theme.heading2(),
  },
  instruction: {
    ...Theme.heading3(),
  },
  logo: {
    display: 'flex',
    flex: 0,
    marginLeft: -10,
    ...Responsive.lessThanMd({
      marginLeft: '-3rem',
    }),
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0,
    marginTop: '1em',
  },
});

MissingAuthEntry.propTypes = {};

export default MissingAuthEntry;
