import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Toggle from '@root/core/src/components/toggle';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
export default class ProfileReviewVinEtching extends Component {
  static propTypes = {
    circleSelectedStyles: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    toggleSelectedStyles: PropTypes.object,
    vinEtching: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <div
          css={styles.leftBox}
        >
          <h3 css={styles.heading}>
            VIN is etched into the windows
          </h3>
          <p css={styles.content}>
            If the VIN is etched into the vehicle windows (this helps prevent theft), you earn an extra 5% discount.
          </p>
        </div>
        <div
          css={styles.rightBox}
          onClick={this.props.onClick}
        >
          <Toggle
            circleSelectedStyles={this.props.circleSelectedStyles}
            isSelected={this.props.vinEtching}
            toggleSelectedStyles={this.props.toggleSelectedStyles}
          />
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  heading: {
    ...Theme.heading3(),
  },
  content: {
    ...Theme.paragraph2(),
  },
  wrapper: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftBox: {
    width: '75%',
  },
  rightBox: {
    width: '25%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
