import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import groupDotsImage from '@root/bind.joinroot.com/src/assets/group-dots.png';
import lifeStyleImage from '@root/bind.joinroot.com/src/assets/life-style-image.png';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const WelcomeBlock = () => {
  const strings = useI18nNext('components.bindStartEntry.welcomeBlock');

  return (
    <div
      css={styles.container}
    >
      <div css={styles.leftContainer}>
        <img
          css={styles.image}
          src={lifeStyleImage}
        />
        <img
          css={styles.imageOverlay}
          src={groupDotsImage}
        />
      </div>
      <div css={styles.rightContainer}>
        <div css={styles.title}>
          <h1>{strings.title.line1}</h1>
          <h1>{strings.title.line2}</h1>
        </div>
        <p css={styles.subTitle}>
          {strings.subTitle}
        </p>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    zIndex: 1,
    marginLeft: 20,
    marginRight: 20,
    ...Responsive.md({
      marginLeft: 0,
      padding: 40,
      flexDirection: 'row',
      width: Responsive.BREAKPOINTS.md,
    }),
  },
  leftContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    ...Responsive.md({
      marginBottom: 0,
    }),
  },
  rightContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginTop: 40,
    marginBottom: 20,
    ...Responsive.md({
      marginBottom: 0,
    }),
  },
  subTitle: {
    color: Colors.gray50(),
    fontSize: '1.125rem',
    lineHeight: '130%',
  },
  title: {
    marginBottom: 15,
    '> h1': {
      lineHeight: '110%',
      marginBottom: 0,
    },
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: '100%',
    marginLeft: -20,
    ...Responsive.md({
      marginRight: 50,
    }),
  },
  imageOverlay: {
    height: 75,
    width: 185,
    marginTop: -40,
    marginLeft: -20,
    ...Responsive.md({
      marginRight: 50,
    }),
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default WelcomeBlock;
