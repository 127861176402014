import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Shadows from '@root/brand/src/utils/shadows';
import closeModal from '@root/core/src/assets/closeModal.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { opacityKeyframes } from '@root/brand/src/utils/animation';

export default class ProfileModal extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    closeImage: PropTypes.node,
    disableFixedWidths: PropTypes.bool,
    isOverlay: PropTypes.bool,
    onCloseClick: PropTypes.func,
    styles: PropTypes.object,
  }

  _getWrapperStyles() {
    return this.props.isOverlay ? styles.wrapperOverlay : styles.wrapperRegular;
  }

  _getModalStyles() {
    return this.props.isOverlay ? styles.modalOverlay : styles.modalRegular;
  }

  _renderCloseButton() {
    if (!this.props.onCloseClick) { return null; }

    return (
      <button
        aria-label={'close'}
        css={styles.closeButton}
        onClick={this.props.onCloseClick}
      >
        <img src={this.props.closeImage || closeModal} />
      </button>
    );
  }

  render() {
    const modalStyles = [
      this._getModalStyles(),
      styles.modal,
      this.props.styles,
    ];

    if (this.props.disableFixedWidths) {
      modalStyles.push(styles.disableFixedWidths);
    }

    return (
      <div
        css={[
          styles.wrapper,
          this._getWrapperStyles(),
        ]}
      >
        <div css={modalStyles}>
          {this._renderCloseButton()}
          {this.props.children}
        </div>
      </div>
    );
  }
}

const sharedStyles = {
  modal: {
    padding: '50px 110px',
    borderRadius: 10,
    maxWidth: 'calc(41.66666667% + 220px)',
    ...Shadows.modal(),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const styles = StyleSheet.create({
  closeButton: {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    height: 24,
    padding: 0,
    position: 'absolute',
    right: 20,
    top: 20,
    width: 24,
  },
  modal: {
    position: 'relative',
    backgroundColor: Colors.white(),
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    overflowY: 'visible',
  },
  disableFixedWidths: {
    ...Responsive.md({
      maxWidth: 'inherit',
    }),
  },
  modalRegular: {
    ...Responsive.md({
      ...sharedStyles.modal,
    }),
    padding: 20,
  },
  modalOverlay: {
    ...sharedStyles.modal,
    ...Responsive.lessThanSm({
      paddingLeft: 20,
      paddingRight: 20,
    }),
  },
  wrapper: {
    width: '100%',
    animationName: opacityKeyframes,
    animationDuration: '300ms',
  },
  wrapperOverlay: {
    ...sharedStyles.wrapper,
    height: '100%',
  },
  wrapperRegular: {
    ...Responsive.md({
      ...sharedStyles.wrapper,
    }),
  },
});
