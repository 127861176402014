import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Subtext({ children, className }) {
  return (
    <p
      className={className}
      css={styles.subtext}
    >
      {children}
    </p>
  );
}

Subtext.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

const styles = StyleSheet.create({
  subtext: {
    ...Theme.paragraph1(),
    marginBottom: 30,
  },
});
