import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

export function BlackVehicleIcon({
  height = '24',
  width = '24',
}) {
  return (
    <svg
      fill={'none'}
      height={height}
      viewBox={'0 0 24 24'}
      width={width}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        clipRule={'evenodd'}
        d={'M1 11.4526V16V16.1362V17.8701V19.9999C1 20.276 1.22386 20.4999 1.5 20.4999H3.77273C4.04887 20.4999 4.27273 20.276 4.27273 19.9999V17.8701H19.7273V19.9999C19.7273 20.276 19.9511 20.4999 20.2273 20.4999H22.5C22.7761 20.4999 23 20.276 23 19.9999V17.8701V16.1362L23 16V11.4526C23 11.0074 22.8023 10.5852 22.4603 10.3002L21.5 9.5H22.5001C22.7762 9.5 23.0001 9.27614 23.0001 9V8C23.0001 7.72386 22.7762 7.5 22.5001 7.5H20.3L18.7913 4.9855C18.6106 4.6843 18.2851 4.5 17.9338 4.5H6.06619C5.71493 4.5 5.38942 4.6843 5.2087 4.9855L3.7 7.5H1.5C1.22386 7.5 1 7.72386 1 8V9C1 9.27614 1.22386 9.5 1.5 9.5H2.5L1.53972 10.3002C1.19773 10.5852 1 11.0074 1 11.4526ZM16.5 13C16.5 12.7239 16.7239 12.5 17 12.5H20.5C20.7761 12.5 21 12.7239 21 13V14.5C21 14.7761 20.7761 15 20.5 15H17C16.7239 15 16.5 14.7761 16.5 14.5V13ZM3.5 12.5C3.22386 12.5 3 12.7239 3 13V14.5C3 14.7761 3.22386 15 3.5 15H7C7.27614 15 7.5 14.7761 7.5 14.5V13C7.5 12.7239 7.27614 12.5 7 12.5H3.5ZM19.5 10H4.5L6.41147 6.51871C6.58712 6.19881 6.92308 6 7.28803 6H16.712C17.0769 6 17.4129 6.19881 17.5885 6.51871L19.5 10Z'}
        fill={Colors.black()}
        fillRule={'evenodd'}
      />
    </svg>

  );
}

BlackVehicleIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
