import isEmpty from '@root/vendor/lodash/isEmpty';
import { isRequired } from '@root/core/src/utils/validators';

export default class QuotesCheckoutForm {
  static validations = [
    isRequired('paymentFieldsValid', ''), // string is blank as it will never be seen on the screen.
    isRequired('billingCycle', 'Please select a Payment frequency'),
    isRequired('startDate', 'Please choose a Start date'),
  ]

  constructor() {
    this.billingCycle = null;
    this.startDate = null;
    this.paymentFieldsValid = false;
  }

  setAttributes(attributes) {
    return Object.assign(
      new QuotesCheckoutForm(),
      this,
      attributes
    );
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  isValid() {
    return isEmpty(this.errors());
  }

  errors() {
    const errs = {};
    QuotesCheckoutForm.validations.forEach((validation) => validation(this, errs));
    return errs;
  }

  errorsFor(fieldName) {
    return this.errors()[fieldName];
  }
}
