import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import wrap from '@root/bind.joinroot.com/src/utils/wrap';
import { AnimatePresence, motion } from '@root/vendor/framer-motion';

const variants = {
  enter: (direction) => {
    return {
      display: 'flex',
      flex: 1,
      y: direction > 0 ? -35 : 35,
      opacity: 0,
      width: '100%',
    };
  },
  center: {
    display: 'flex',
    flex: 1,
    zIndex: 1,
    y: 0,
    opacity: 1,
    width: '100%',
  },
  exit: (direction) => {
    return {
      display: 'flex',
      flex: 1,
      zIndex: 0,
      y: direction < 0 ? -35 : 35,
      opacity: 0,
      width: '100%',
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const defaultCarouselTransition = {
  y: {
    type: 'spring',
    stiffness: 300,
    damping: 30,
  },
  opacity: {
    duration: 0.3,
  },
};

const Carousel = ({
  autoPaginateDuration,
  draggable = false,
  items,
  motionContainerCssOverrides,
  renderItem,
  transition = defaultCarouselTransition,
}) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const itemIndex = wrap(0, items.length, page);

  const paginate = useCallback((newDirection) => {
    setPage([page + newDirection, newDirection]);
  }, [page, setPage]);

  useEffect(() => {
    if (autoPaginateDuration) {
      const autoDirection = autoPaginateDuration instanceof Array && autoPaginateDuration[0] ? autoPaginateDuration[0] : 1;
      const autoDuration = autoPaginateDuration instanceof Array && autoPaginateDuration[1] ? autoPaginateDuration[1] : autoPaginateDuration;

      const timeout = setTimeout(() => paginate(autoDirection), autoDuration);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [autoPaginateDuration, paginate]);

  const handleDragEnd = useCallback((e, { offset, velocity }) => {
    if (!draggable) {
      return;
    }
    const swipe = swipePower(offset.x, velocity.x);

    if (swipe < -swipeConfidenceThreshold) {
      paginate(1);
    } else if (swipe > swipeConfidenceThreshold) {
      paginate(-1);
    }
  }, [draggable, paginate]);

  return (
    <AnimatePresence
      custom={direction}
      initial={false}
    >
      <motion.div
        animate={'center'}
        custom={direction}
        drag={draggable ? 'x' : undefined}
        dragConstraints={draggable ? {
          left: 0,
          right: 0,
        } : undefined}
        dragElastic={draggable ? 1 : undefined}
        exit={'exit'}
        initial={'enter'}
        key={page}
        onDragEnd={handleDragEnd}
        style={{
          display: 'inline-block',
          ...motionContainerCssOverrides,
        }}
        transition={transition}
        variants={variants}
      >
        {
          renderItem(items[itemIndex], {
            index: itemIndex,
            paginate,
          })
        }
      </motion.div>
    </AnimatePresence>
  );
};

Carousel.propTypes = {
  autoPaginateDuration: PropTypes.oneOfType([ // This prop should be passed as a ms duration, or a tuple of [direction, duration]
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  draggable: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  motionContainerCssOverrides: PropTypes.object,
  renderItem: PropTypes.func.isRequired,
  transition: PropTypes.object,
};

export default Carousel;
