/* eslint react/jsx-no-bind: 0 */

import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import BindParams from '@root/quotes/src/models/bind-params';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageCopy from '@root/quotes/src/components/coverage/coverage-copy';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import CoverageTitle from '@root/quotes/src/components/coverage/coverage-title';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuotesContext from '@root/quotes/src/models/quotes-context';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React, { Component, Fragment } from '@root/vendor/react';

export default class CoverageSectionLiability extends Component {
  static propTypes = {
    bindParams: PropTypes.instanceOf(BindParams).isRequired,
    cardHeaderBackgroundColor: PropTypes.string,
    coverageSelections: PropTypes.object,
    customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
    dropdownIndicator: PropTypes.string,
    IconComponent: PropTypes.elementType,
    mobileSelectedOptionStyles: PropTypes.object,
    onAcceptCoverages: PropTypes.func.isRequired,
    onCoverageChange: PropTypes.func.isRequired,
    onCoverageSelectClose: PropTypes.func.isRequired,
    onCoverageSelectOpen: PropTypes.func.isRequired,
    onDeclineCoverages: PropTypes.func.isRequired,
    primaryBrandColor: PropTypes.string,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    quoteCoveragesContext: PropTypes.instanceOf(QuoteCoveragesContext).isRequired,
    quotesContext: PropTypes.instanceOf(QuotesContext).isRequired,
    styleOverrides: PropTypes.object,
  };

  _handleAcceptCoverage = (coverage) => {
    const allSelectedVehicles = this.props.profileParams.getAllSelectedVehicles();
    const allSelectedAvailableVins = allSelectedVehicles.map((v) => v.getAvailableVin());
    const coverageWithVins = coverage.set('coveredVins', allSelectedAvailableVins);

    this.props.onAcceptCoverages([coverageWithVins]);
  };

  _renderBiAddCoverageCard(biDeclinable, market, coverage) {
    if (biDeclinable) {
      return (
        <AddCoverageCard
          description={
            <CoverageCopy
              coverage={coverage}
              market={market}
            />}
          heading={
            <CoverageTitle
              coverage={coverage}
              market={market}
            />}
          IconComponent={this.props.IconComponent}
          isCoverageAdded={!coverage.declined}
          onAddCoverage={() => this._handleAcceptCoverage(coverage)}
          primaryBrandColor={this.props.primaryBrandColor}
        />
      );
    }

    return null;
  }

  render() {
    const {
      cardHeaderBackgroundColor,
      coverageSelections,
      customQuote,
      dropdownIndicator,
      mobileSelectedOptionStyles,
      onCoverageChange,
      onCoverageSelectClose,
      onCoverageSelectOpen,
      profileParams,
      primaryBrandColor,
      styleOverrides,
    } = this.props;

    const market = profileParams.market();
    const bodilyInjuryHeading = Coverage.getLocaleName({
      market,
      symbol: Coverage.Symbols.BODILY_INJURY,
    });
    const propertyDamageHeading = Coverage.getLocaleName({
      market,
      symbol: Coverage.Symbols.PROPERTY_DAMAGE,
    });
    const coverage = customQuote.getCoverage(Coverage.Symbols.BODILY_INJURY);
    const biDeclinable = customQuote.coverageIsDeclinable(coverage);

    let onRemoveCoverage = null;

    if (this.props.customQuote.coverageIsDeclinable(coverage)) {
      onRemoveCoverage = () => this.props.onDeclineCoverages([coverage]);
    }

    return (
      <CoverageGroup
        description={biDeclinable ? null : 'Required by law'}
        heading={'Liability coverage'}
        styleOverrides={styleOverrides}
      >
        <Fragment key={Coverage.Symbols.BODILY_INJURY}>
          {this._renderBiAddCoverageCard(biDeclinable, market, coverage)}
          <CoverageSelectionCard
            description={'Covers medical expenses for others injured when you cause an accident.'}
            headerBackgroundColor={cardHeaderBackgroundColor}
            heading={`${bodilyInjuryHeading}`}
            isCoverageAdded={!coverage.declined}
            onRemoveCoverage={onRemoveCoverage}
            vehiclesSelected={true}
          >
            <QuotesSelect
              dropdownIndicator={dropdownIndicator}
              dropdownIndicatorColor={primaryBrandColor}
              inputId={'bi-coverage-select'}
              inputName={'bi-coverage-select'}
              mobileSelectedOptionStyles={mobileSelectedOptionStyles}
              onChange={(id) => onCoverageChange(id, Coverage.Symbols.BODILY_INJURY)}
              onClose={onCoverageSelectClose}
              onOpen={onCoverageSelectOpen}
              options={coverageSelections.bi}
              optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
              placeholder={'Select option'}
              selectedValue={customQuote.getCoverage(Coverage.Symbols.BODILY_INJURY).id}
            />
          </CoverageSelectionCard>
        </Fragment>

        <CoverageSelectionCard
          description={'Covers damage to other vehicles or property when you cause an accident.'}
          headerBackgroundColor={this.props.cardHeaderBackgroundColor}
          heading={`${propertyDamageHeading}`}
          isCoverageAdded={true}
          vehiclesSelected={true}
        >
          <QuotesSelect
            dropdownIndicator={dropdownIndicator}
            dropdownIndicatorColor={primaryBrandColor}
            inputId={'pd-coverage-select'}
            inputName={'pd-coverage-select'}
            mobileSelectedOptionStyles={mobileSelectedOptionStyles}
            onChange={(id) => this.props.onCoverageChange(id, Coverage.Symbols.PROPERTY_DAMAGE)}
            onClose={this.props.onCoverageSelectClose}
            onOpen={this.props.onCoverageSelectOpen}
            options={this.props.coverageSelections.pd}
            optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
            placeholder={'Select option'}
            selectedValue={this.props.customQuote.getCoverage(Coverage.Symbols.PROPERTY_DAMAGE).id}
          />
        </CoverageSelectionCard>
      </CoverageGroup>
    );
  }
}
