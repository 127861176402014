import IconText from '@root/core/src/components/icon-text';
import PropTypes from '@root/vendor/prop-types';
import lock from '@root/core/src/assets/lock.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SecurityFooter({ styles: propStyles, text = 'Root will not sell your personalized data to third parties.' }) {
  return (
    <IconText
      containerStyles={[propStyles]}
      icon={lock}
      iconAltText={'Security lock'}
      iconStyles={styles.icon}
      text={text}
    />
  );
}

SecurityFooter.propTypes = {
  styles: PropTypes.object,
  text: PropTypes.string,
};

const styles = StyleSheet.create({
  icon: {
    paddingLeft: 0,
  },
});
