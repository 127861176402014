export const NATIVE_MESSAGE = {
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
};

// This handler name is initialized by partners within their native apps, and should not be changed
export const WEBKIT_MESSAGE_HANDLER_NAME = 'rootMessageHandler';

const postMessage = (message) => {
  // iOS
  window.webkit?.messageHandlers?.[WEBKIT_MESSAGE_HANDLER_NAME]?.postMessage?.({ message });

  // Android
  window.JSBridge?.onEmbeddedEvent?.(message);

  return true;
};

class NativeMessagingService {
  static broadcastExitedFlow() {
    return postMessage(NATIVE_MESSAGE.CANCELLED);
  }

  static broadcastCompletedFlow() {
    return postMessage(NATIVE_MESSAGE.COMPLETED);
  }
}

export default NativeMessagingService;
