import Input from '@root/bind.joinroot.com/src/components/input';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileReviewService from '@root/profile-review/src/services/profile-review-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_EVENTS = {
  FOCUSED_ON_MUNICIPALITY_INPUT_FIELD: 'FOCUSED_ON_MUNICIPALITY_INPUT_FIELD',
  MUNICIPALITY_ERROR_DISPLAYED: 'MUNICIPALITY_ERROR_DISPLAYED',
};

export const sanitizeMunicipality = (municipality) => {
  return municipality.toLowerCase().split(' ').map((word) => {
    return word[0].toUpperCase() + word.substr(1);
  }).join(' ');
};

const RatingMunicipalityInput = ({
  onValidatedTerritory,
  onUpdateProfileParams,
  profileParams,
  territoryContext,
  trackEvent,
  ...restProps
}) => {
  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  const [city, setCity] = useState('');
  const [predictedTerritories, setPredictedTerritories] = useState([]);
  const [cityNeedsValidation, setCityNeedsValidation] = useState(true);
  const [cityError, setCityError] = useState(false);

  const strings = useI18nNext('components.bindStartEntry.ratingMunicipalityInput');

  const validateCity = useCallback(async () => {
    const isValid = await ProfileReviewService.isValidTerritory(city, territoryContext.territories);

    if (isValid) {
      setPredictedTerritories([]);
      onUpdateProfileParams(profileParams.set('ratingMunicipality', sanitizeMunicipality(city)));
      onValidatedTerritory(isValid);
      setCityNeedsValidation(false);
    } else {
      setCityError(predictedTerritories.length === 0 && city.length !== 0 && !isValid);
    }
  }, [city, onUpdateProfileParams, onValidatedTerritory, predictedTerritories.length, profileParams, territoryContext.territories]);

  useEffect(() => {
    if (cityNeedsValidation) {
      validateCity();
    }
  }, [cityNeedsValidation, validateCity]);

  const handleRatingMunicipalityFocus = () => {
    trackEvent(ANALYTICS_EVENTS.FOCUSED_ON_MUNICIPALITY_INPUT_FIELD);
  };

  const handleCityChange = useCallback((currentCity) => {
    setCity(currentCity);
    const inputLength = currentCity.length;
    if (inputLength > 0) {
      setPredictedTerritories(territoryContext.territories.filter((t) => {
        return t.toLowerCase().slice(0, inputLength) === currentCity.toLowerCase();
      }));
      validateCity();
    } else {
      setPredictedTerritories([]);
    }
  }, [territoryContext.territories, validateCity]);

  const getValidTerritoryError = useMemo(() => {
    if (cityError) {
      trackEvent(ANALYTICS_EVENTS.MUNICIPALITY_ERROR_DISPLAYED);
      return strings.errors.invalid;
    }
  }, [cityError, strings.errors.invalid, trackEvent]);

  const handlePredictionClick = (territory) => {
    setCity(territory);
    setPredictedTerritories([]);
  };

  const listItems = predictedTerritories.map((territory) => {
    return (
      <li
        css={styles.li}
        key={territory}
        onClick={() => handlePredictionClick(territory)}
      >
        {territory}
      </li>
    );
  }).slice(0, 5);

  return (
    <div {...restProps}>
      <h5 css={styles.title}>{strings.title}</h5>
      <Input
        errorLabel={getValidTerritoryError}
        label={strings.label}
        onChange={handleCityChange}
        onFocus={handleRatingMunicipalityFocus}
        value={city}
      />
      <div css={styles.listContainer}>
        <ul
          css={styles.list}
          data-testid={'predictions'}
        >
          {listItems}
        </ul>
      </div>
      <p css={styles.content}>
        {strings.info}
      </p>
    </div>
  );
};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  button: {
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  title: {
    color: Colors.nearBlack(),
    ...Theme.heading3(),
  },
  content: {
    ...Theme.paragraph2(),
    marginBottom: 0,
    paddingTop: 10,
    paddingLeft: 10,
  },
  list: {
    ...Shadows.softShadow(),
    display: 'inline-block',
    listStyle: 'none',
    paddingLeft: 0,
    position: 'absolute',
    width: '100%',
    zIndex: ZIndex.DROPDOWN_MENU,
  },
  listContainer: {
    width: '100%',
    position: 'relative',
  },
  li: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 60,
    backgroundColor: Colors.nearWhite(),
    border: `1px solid ${Colors.gray30()}`,
    color: Colors.gray50(),
    borderTopWidth: 0,
    padding: '15px',
    ':hover': {
      backgroundColor: primaryColor,
      color: Colors.white(),
      cursor: 'pointer',
    },
  },
});

RatingMunicipalityInput.propTypes = {
  onUpdateProfileParams: PropTypes.func.isRequired,
  onValidatedTerritory: PropTypes.func,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  territoryContext: PropTypes.shape({
    territories: PropTypes.arrayOf(PropTypes.string).isRequired,
    isCity: PropTypes.bool.isRequired,
  }).isRequired,
  trackEvent: PropTypes.func.isRequired,
};

export default RatingMunicipalityInput;
