import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import useLottieAnimation from '@root/core/src/hooks/use-lottie-animation';

const splashLoaderAnimation = require('@root/core/src/assets/animations/splash-loader.json');

export const TITLE = 'Welcome to Root Insurance animation';

const SplashLoader = ({
  className,
  onComplete,
}) => {
  const [animationContainer, player] = useLottieAnimation(splashLoaderAnimation, {
    autoplay: true,
    loop: false,
    useFull: true,
  });

  useEffect(() => {
    if (player && !!onComplete) {
      const animationCompletedListener = player.addEventListener('complete', onComplete);

      return () => player.removeEventListener('complete', animationCompletedListener);
    }
  }, [onComplete, player]);

  useEffect(() => {
    if (player) {
      return () => player.destroy();
    }
  }, [player]);

  return (
    <div
      className={className}
      ref={animationContainer}
      title={TITLE}
    />
  );
};

SplashLoader.propTypes = {
  className: PropTypes.oneOf([
    PropTypes.object,
    PropTypes.string,
  ]),
  onComplete: PropTypes.func,
};

export default SplashLoader;
