import Button from '@root/core/src/components/button';
import Coverage from '@root/auto-pricing/src/models/coverage';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RideshareInfoGraphic from '@root/profile/src/components/rideshare-info-graphic';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const BindRideshareInfo = ({
  market,
  onCloseClick,
  primaryBrandColor,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageRideshare.infoModal');
  const styles = stylesGenerator(primaryBrandColor);

  const biDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.BODILY_INJURY,
  });
  const collDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COLLISION,
  });
  const compDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COMPREHENSIVE,
  });
  const pdDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.PROPERTY_DAMAGE,
  });

  return (
    <>
      <ProfileHeading>
        {strings.header}
      </ProfileHeading>
      <div css={styles.headerSection}>
        <div css={styles.description}>
          {strings.requirement_normal} <span css={styles.descriptionBold}>{strings.requirement_bold}</span>
        </div>
      </div>
      <RideshareInfoGraphic primaryBrandColor={primaryBrandColor} />
      <div css={styles.infoListSection}>
        <div css={styles.sectionHeader}>{strings.phase_1.header}</div>
        <ul css={styles.description}>
          <li>{strings.phase_1.bi({
            biDisplayName,
            pdDisplayName,
          })}
          </li>
          <li>{strings.phase_1.compColl({
            collDisplayName,
            compDisplayName,
          })}
          </li>
        </ul>
      </div>
      <div>
        <div css={styles.sectionHeader}>{strings.phase_2.header}</div>
        <ul css={styles.description}>
          <li>{strings.phase_2.compColl({
            collDisplayName,
            compDisplayName,
          })}
          </li>
        </ul>
      </div>
      <div>
        <div css={styles.sectionHeader}>{strings.good_to_know.header}</div>
        <ul css={styles.description}>
          <li>{strings.good_to_know.compColl({
            biDisplayName,
            pdDisplayName,
          })}
          </li>
          <li>{strings.good_to_know.bi({
            collDisplayName,
            compDisplayName,
          })}
          </li>
          <li>{strings.good_to_know.bullet_3}.</li>
          <li>{strings.good_to_know.bullet_4}.</li>
        </ul>
      </div>
      <div css={styles.disclaimerText}>
        {strings.disclaimer}
      </div>
      <Button
        css={[
          styles.button,
        ]}
        onClick={onCloseClick}
      >
        {strings.closeButton}
      </Button>
    </>
  );
};

const stylesGenerator = (primaryColor) => StyleSheet.create({
  headerSection: {
    paddingBottom: '36px',
  },
  description: {
    ...Theme.paragraph1(),
    color: Colors.nearBlack,
    marginBottom: '12px',
  },
  descriptionBold: {
    ...Theme.paragraph1({
      bold: true,
    }),
    color: Colors.nearBlack,
  },
  infoListSection: {
    marginTop: '36px',
  },
  sectionHeader: {
    ...Theme.paragraph1({
      bold: true,
    }),
    color: Colors.nearBlack,
    paddingBottom: '10px',
  },
  disclaimerText: {
    ...Theme.paragraph2(),
  },
  editLink: {
    ...Theme.textLink(),
  },
  modal: {
    padding: 0,
  },
  button: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
});

BindRideshareInfo.propTypes = {
  market: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  primaryBrandColor: PropTypes.string,
};

export default BindRideshareInfo;
