import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ProgressBar({
  bottomText = '',
  fillPercent = 0,
  leftLabel = '',
  rightLabel = '',
  reverseFillDirection = false,
  reverseBottomTextPosition = false,
  styleOverrides = {},
}) {
  const progressBarFill = (percent) => ({
    width: `${percent}%`,
  });

  return (
    <div css={styles.outerContainer}>
      <div css={styles.timeline}>
        <div css={styles.labelContainer}>
          <div css={styles.label}>
            {leftLabel}
          </div>
          <div css={styles.label}>
            {rightLabel}
          </div>
        </div>
        <div css={styles.dividersContainer}>
          <div />
          <div css={styles.divider} />
          <div css={styles.divider} />
          <div css={styles.divider} />
          <div css={styles.divider} />
          <div css={styles.divider} />
          <div />
        </div>
      </div>

      <div
        css={[
          styles.barContainer,
          reverseFillDirection ? styles.startRight : styles.startLeft,
        ]}
        data-testid={'progress-bar-fill-container'}
      >
        <div
          css={[
            styles.barFill,
            progressBarFill(fillPercent),
            styleOverrides.modalBarChartBarFill,
          ]}
          data-testid={'progress-bar-fill'}
        />
      </div>
      <div
        css={[
          styles.bottomLabel,
          reverseBottomTextPosition ? styles.startRight : styles.startLeft,
          styleOverrides.modalBarChartBottomLabel,
        ]}
      >
        {bottomText}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  bottomText: PropTypes.string,
  fillPercent: PropTypes.number,
  leftLabel: PropTypes.string,
  reverseBottomTextPosition: PropTypes.bool,
  reverseFillDirection: PropTypes.bool,
  rightLabel: PropTypes.string,
  styleOverrides: PropTypes.shape({
    modalBarChartBarFill: PropTypes.object,
    modalBarChartBottomLabel: PropTypes.object,
  }),
};

const styles = StyleSheet.create({
  outerContainer: {
    width: '100%',
  },
  timeline: {
    position: 'relative',
    width: '100%',
    marginBottom: 8,
    marginTop: 15,
  },
  labelContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
  },
  label: {
    ...Theme.inputLabel(),
  },
  dividersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  divider: {
    height: 16,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.gray10(),
  },
  barContainer: {
    backgroundColor: Colors.gray10(),
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 6,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.gray20(),
    justifyContent: 'flex-start',
    height: 24,
    width: '100%',
  },
  barFill: {
    height: 22,
    backgroundColor: Colors.rootOrange(),
    borderRadius: 6,
  },
  startLeft: {
    justifyContent: 'flex-start',
  },
  startRight: {
    justifyContent: 'flex-end',
  },
  bottomLabel: {
    paddingTop: 6,
    display: 'flex',
    ...Theme.button(),
    color: Colors.rootOrange(),
  },
});

