import React from '@root/vendor/react';
import { BlackVehicleLocationIcon } from '@root/profile/src/components/icons/black-vehicle-location-icon';
import { BlackVehicleWifiIcon } from '@root/profile/src/components/icons/black-vehicle-wifi-icon';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function RideshareInfoGraphic() {
  return (
    <div css={styles.container}>
      <div css={styles.line} />
      <div css={[styles.rowDescriptionOne, styles.row]}>Damage you cause</div>
      <div css={[styles.rowDescriptionTwo, styles.row]}>Damage to your vehicle</div>
      <div css={[styles.columnDescriptionOne, styles.iconDescription]}>
        <BlackVehicleWifiIcon />
        <span>App on, waiting for trip</span>
      </div>
      <div css={[styles.columnDescriptionTwo, styles.iconDescription]}>
        <BlackVehicleLocationIcon />
        <span>Trip accepted</span>
      </div>
      <div css={[styles.waitingCoveredBlock, styles.box, styles.orangeBlock]}>Root Rideshare & Delivery coverage</div>
      <div css={[styles.acceptedVariesBlock, styles.box, styles.clearBlock]}>Coverage varies by company *</div>
      <div css={[styles.acceptedCoveredBlock, styles.box, styles.orangeBlock]}>Root Rideshare & Delivery coverage</div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Theme.paragraph2(),
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 4fr',
    gridTemplateRows: '1fr 1fr [line] 1px 1fr',
    columnGap: '18px',
    rowGap: '12px',
  },
  line: {
    backgroundColor: Colors.gray50(),
    gridColumnStart: 1,
    gridColumnEnd: -1,
    gridRowStart: 3,
    gridRowEnd: 3,
  },
  rowDescriptionOne: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
  },
  rowDescriptionTwo: {
    gridColumn: '1 / 2',
    gridRow: '4 / 5',
  },
  columnDescriptionOne: {
    gridColumn: '2 / 3',
    gridRow: '1 / 2',
  },
  columnDescriptionTwo: {
    gridColumn: '3 / 4',
    gridRow: '1 / 2',
  },
  waitingCoveredBlock: {
    gridColumn: '2 / 3',
    gridRow: '2 / 5',
  },
  acceptedVariesBlock: {
    gridColumn: '3 / 4',
    gridRow: '2 / 3',
  },
  acceptedCoveredBlock: {
    gridColumn: '3 / 4',
    gridRow: '4 / 5',
  },
  iconDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  orangeBlock: {
    backgroundColor: Colors.rootOrange(),
    color: Colors.white(),
  },
  clearBlock: {
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: Colors.gray50(),
  },
});
