import CaretLoader from '@root/core/src/components/caret-loader';
import MediaAlphaScriptService from '@root/core/src/services/media-alpha-script-service';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useEffect, useRef } from '@root/vendor/react';

export default function MediaAlphaResell({
  analyticsContext,
  explainerText,
  heading = 'We want to help you find the best option for your needs.',
  placementId,
  request,
}) {
  const { trackEvent } = useAnalytics(analyticsContext);

  const isScriptAdded = useRef(false);

  useEffect(() => {
    if (isScriptAdded.current) { return; }

    MediaAlphaScriptService.createScript(placementId, 'media_alpha_element', request);

    trackEvent('QUOTES_LOADED');
    isScriptAdded.current = true;

    return () => {
      MediaAlphaScriptService.cleanupScript();
    };
  }, [placementId, request, trackEvent]);

  return (
    <div css={styles.container}>
      <h2 css={styles.heading}>
        {heading}
      </h2>
      {explainerText &&
        <p css={styles.subtext}>
          {explainerText}
        </p>
      }
      <div
        css={styles.quotesContainer}
        data-testid={'media-alpha-container'}
        id={'media_alpha_element'}
      >
        <CaretLoader />
      </div>
      <p css={styles.disclaimer}>
        {'Root is partnered with MediaAlpha, a shopping center for insurance, to recommend carrier options. By clicking on an option above, you’ll be leaving Root and will be directed to MediaAlpha. We will pass along your information to MediaAlpha, who can help generate insurance recommendations for you.'}
      </p>
    </div>
  );
}

MediaAlphaResell.propTypes = {
  analyticsContext: PropTypes.string.isRequired,
  explainerText: PropTypes.string,
  heading: PropTypes.string,
  placementId: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 770,
    padding: '0 5px',
    ...Responsive.sm({
      width: '95%',
      margin: '0 auto',
      padding: 0,
    }),
  },
  heading: {
    ...Theme.heading2(),
    ...Responsive.sm({
      padding: '0 20px',
    }),
  },
  subtext: {
    ...Theme.paragraph1(),
    marginTop: 20,
    ...Responsive.sm({
      padding: '0 20px',
    }),
  },
  quotesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
  },
  disclaimer: {
    ...Theme.paragraph2(),
    ...Responsive.sm({
      padding: '0 20px',
    }),
  },
});
