import Button from '@root/core/src/components/button';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import errorSign from '@root/core/src/assets/error-sign.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function OutOfMarketModal({
  onClose, isModalOpen = false, descriptionText, ctaText,
}) {
  const { trackClick } = useAnalytics('OUT_OF_MARKET_MODAL');

  const cta = ctaText ?? 'Edit address';
  const description = descriptionText ?? 'Please enter an in-state address or remove this vehicle.';

  const handleXButtonClick = () => {
    trackClick('X');
    onClose();
  };

  const handleEditAddressButtonClick = () => {
    trackClick('EDIT_ADDRESS');
    onClose();
  };

  return (
    <Modal
      isShowing={isModalOpen}
      name={'out-of-market-modal'}
      onCancel={handleXButtonClick}
      responsiveCloseButton={true}
      styleOverrides={styles}
    >
      <div css={styles.centerAlign}>
        <img
          alt={'invalid market'}
          css={styles.image}
          src={errorSign}
        />
      </div>
      <h1 css={styles.title}>All vehicles on the policy must be in your home state.</h1>
      <p css={styles.subHeading}>{description}</p>
      <Button onClick={handleEditAddressButtonClick}>
        {cta}
      </Button>
    </Modal>
  );
}

OutOfMarketModal.propTypes = {
  ctaText: PropTypes.string,
  descriptionText: PropTypes.string,
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  outerContent: {
    padding: 20,
    borderRadius: 6,
    ...Responsive.sm({
      padding: 20,
      borderRadius: 6,
    }),
  },
  innerContent: {
    ...Responsive.sm({
      padding: 40,
      paddingTop: 0,
    }),
  },
  subHeading: {
    ...Theme.cardContent(),
    fontSize: 16,
  },
  title: {
    ...Theme.cardHeading(),
    fontSize: 32,
    marginBottom: 24,
    ...Responsive.sm({
      ...Theme.cardHeading(),
      fontSize: 32,
      marginBottom: 24,
    }),
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
  },
});
