import { rerateWithBackendOptions } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useRerateWithBackendOptionsMutation = (options = {}) => {
  return useMutation({
    mutationFn: rerateWithBackendOptions,
    mutationKey: ['quote', 'rerateWithBackendOptions'],
    ...options,
  });
};

export default useRerateWithBackendOptionsMutation;
