import { markQuoteViewed } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useMarkQuoteViewedMutation = (options = {}) => {
  return useMutation({
    mutationFn: markQuoteViewed,
    mutationKey: ['quote', 'markViewed'],
    ...options,
  });
};

export default useMarkQuoteViewedMutation;
