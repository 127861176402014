import PropTypes from '@root/vendor/prop-types';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function TriageContent({ children }) {
  return (
    <div css={styles.container}>
      {children}
    </div>
  );
}

TriageContent.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
  container: {
    marginTop: 45,
    marginBottom: 24,
    ...Theme.paragraph1(),
  },
});
