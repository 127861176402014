import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function FieldHeading({ children }) {
  return (
    <h2 css={styles.fieldHeading}>
      {children}
    </h2>
  );
}

FieldHeading.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  fieldHeading: {
    ...Theme.paragraph1(),
    color: Colors.black(),
    marginBottom: 0,
  },
});
