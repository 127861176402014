import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import addIcon from '@root/quotes/src/assets/add-icon.svg';
import addIconBlack from '@root/quotes/src/assets/add-icon-black.svg';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class AddButton extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    IconComponent: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    primaryBrandColor: PropTypes.string,
    useRightQuoteIcon: PropTypes.bool,
  }

  render() {
    const {
      children, IconComponent, onClick, primaryBrandColor, useRightQuoteIcon,
    } = this.props;

    const styles = stylesGenerator(primaryBrandColor);

    const buttonIconStyle = IconComponent ? styles.buttonWithIconComponent : styles.button;
    const buttonStyles = useRightQuoteIcon ? [buttonIconStyle, styles.rightQuoteIcon] : buttonIconStyle;

    return (
      <button
        css={buttonStyles}
        onClick={onClick}
        type={'button'}
      >
        {IconComponent &&
        <div css={styles.icon}>
          <IconComponent />
        </div>
        }
        {children}
      </button>
    );
  }
}

const stylesGenerator = (primaryColor) => StyleSheet.create({
  button: {
    ...Theme.link(),
    backgroundColor: 'transparent',
    backgroundImage: `url(${addIcon})`,
    backgroundPosition: '0 50%',
    backgroundRepeat: 'no-repeat',
    border: 0,
    color: Colors.black(),
    minHeight: 30,
    paddingLeft: 40,
    textAlign: 'left',
  },
  buttonWithIconComponent: {
    ...Theme.link(),
    backgroundColor: 'transparent',
    backgroundPosition: '0 50%',
    backgroundRepeat: 'no-repeat',
    border: 0,
    color: Colors.black(),
    minHeight: 30,
    paddingLeft: 40,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-around',
    ':hover': {
      color: primaryColor,
    },

  },
  icon: {
    paddingRight: 10,
  },
  rightQuoteIcon: {
    backgroundImage: `url(${addIconBlack})`,
  },
});
