import AddCoverageCard from '@root/quotes/src/components/coverage-options/add-coverage-card';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageSelectionCard from '@root/quotes/src/components/coverage-options/coverage-selection-card';
import Link from '@root/core/src/components/link';
import Profile from '@root/auto-pricing/src/models/profile';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import RideshareInfo from '@root/profile/src/components/rideshare-info';
import checkmarkFilledGreen from '@root/quotes/src/assets/checkmark-filled-green.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { ListCardItem } from '@root/core/src/components/list-card';
import { RIDESHARE_SUBROUTE } from '@root/profile-review/src/services/profile-review-flow-service';
import { Theme } from '@root/core/src/utils/styles';
import { useUnderwritingCompanyContext } from '@root/profile/src/contexts/underwriting-company-context';

export default function CoverageSectionRideshare({
  cardHeaderBackgroundColor,
  skipProfileReviewFlow = false,
  IconComponent,
  profile,
  goToProfileReview,
  primaryBrandColor,
  styleOverrides,
  onLearnMoreClick,
  onRemoveCoverage,
  rideshareRef,
}) {
  const underwritingCompanyContext = useUnderwritingCompanyContext();
  const { trackClick, trackEvent } = useAnalytics('COVERAGE_SECTION_RIDESHARE', false);
  const styles = stylesGenerator(primaryBrandColor);

  useEffect(() => {
    if (underwritingCompanyContext.isRideshareMarket) {
      trackEvent('VIEWED');
    }
  }, [trackEvent, underwritingCompanyContext.isRideshareMarket]);

  const [isShowingRideshareInfoModal, setIsShowingRideshareInfoModal] = useState(false);

  const handleCloseRideshareModal = () => {
    trackEvent('CLOSE_RIDESHARE_MODAL');

    setIsShowingRideshareInfoModal(false);
  };

  const renderPortal = usePortal('rideshare-information');
  const portalRef = useRef();
  useModal(handleCloseRideshareModal, portalRef);

  if (!underwritingCompanyContext.isRideshareMarket) {
    return null;
  }

  const hasRideshareCoverage = !!profile.rideshare;

  const handleLearnMoreClick = () => {
    trackClick('LEARN_MORE');

    setIsShowingRideshareInfoModal(true);
  };

  const handleAddCoverage = () => {
    trackClick('ADD_COVERAGE');

    setIsShowingRideshareInfoModal(false);

    goToProfileReview('RIDESHARE', RIDESHARE_SUBROUTE);
  };

  const coverageDescription = 'Root requires this coverage if you drive for rideshare or delivery companies like Uber or DoorDash.';
  const coverageHeading = 'Rideshare & Delivery';

  const learnMoreLink = (
    <Link
      cssOverrides={styles.linkStyles}
      onClick={skipProfileReviewFlow ? onLearnMoreClick : handleLearnMoreClick}
    >
      {'Learn more'}
    </Link>
  );

  const market = profile.market;

  const collisionDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COLLISION,
  });

  const comprehensiveDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.COMPREHENSIVE,
  });

  const bodilyInjuryDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.BODILY_INJURY,
  });

  const propertyDamageDisplayName = Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.PROPERTY_DAMAGE,
  });

  return (
    <>
      <CoverageGroup
        heading={coverageHeading}
        hideDivider={true}
        styleOverrides={styleOverrides}
      >
        <div ref={rideshareRef} />
        <AddCoverageCard
          description={coverageDescription}
          headerBackgroundColor={cardHeaderBackgroundColor}
          heading={coverageHeading}
          IconComponent={IconComponent}
          isCoverageAdded={hasRideshareCoverage}
          onAddCoverage={handleAddCoverage}
          primaryBrandColor={primaryBrandColor}
        >
          {learnMoreLink}
          {skipProfileReviewFlow &&
            <p css={Theme.paragraph1}>
              <br />
              {`Rideshare & Delivery coverage includes ${collisionDisplayName} and ${comprehensiveDisplayName} and state minimums for ${bodilyInjuryDisplayName} and ${propertyDamageDisplayName}.`}
            </p>
          }
        </AddCoverageCard>
        <CoverageSelectionCard
          description={coverageDescription}
          headerBackgroundColor={cardHeaderBackgroundColor}
          heading={coverageHeading}
          isCoverageAdded={hasRideshareCoverage}
          onRemoveCoverage={onRemoveCoverage}
          renderLearnMore={() => learnMoreLink}
          vehiclesSelected={hasRideshareCoverage}
        >
          <ListCardItem css={styles.listItemBottom}>
            <div css={styles.includedContainer}>
              <img
                alt={'coverage included'}
                css={styles.checkmark}
                src={checkmarkFilledGreen}
              />
              <p css={styles.includedHeader}>Coverage included</p>
            </div>
            <p css={styles.includedBody}>{`Includes ${collisionDisplayName} and ${comprehensiveDisplayName} and state minimums for ${bodilyInjuryDisplayName} and ${propertyDamageDisplayName}`}.</p>
          </ListCardItem>
        </CoverageSelectionCard>
      </CoverageGroup>
      {renderPortal(
        <>
          {isShowingRideshareInfoModal && (
            <div
              css={styles.modalWrapper}
              ref={portalRef}
            >
              <RideshareInfo
                market={profile.market}
                onCloseClick={handleCloseRideshareModal}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

CoverageSectionRideshare.propTypes = {
  cardHeaderBackgroundColor: PropTypes.string,
  goToProfileReview: PropTypes.func.isRequired,
  IconComponent: PropTypes.func,
  onLearnMoreClick: PropTypes.func,
  onRemoveCoverage: PropTypes.func,
  primaryBrandColor: PropTypes.string,
  profile: PropTypes.instanceOf(Profile).isRequired,
  rideshareRef: PropTypes.object,
  skipProfileReviewFlow: PropTypes.bool,
  styleOverrides: PropTypes.object,
};

const stylesGenerator = (primaryColor) => StyleSheet.create({
  listItemBottom: {
    borderRadius: '0 0 10px 10px',
    border: `1px solid ${Colors.gray30()}`,
    background: Colors.nearWhite(),
  },
  includedHeader: Theme.heading5(),
  includedBody: Theme.paragraph2(),
  includedContainer: {
    display: 'flex',
  },
  checkmark: {
    height: 22,
    width: 22,
    marginRight: 12,
  },
  modalWrapper: {
    position: 'fixed',
    top: 63,
    height: 'calc(100vh - 63px)',
    overflow: 'auto',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
    ...Responsive.sm({
      paddingTop: 10,
      paddingBottom: 10,
    }),
  },
  linkStyles: {
    color: primaryColor || Colors.rootOrange(),
    ':hover': {
      color: primaryColor || Colors.rootOrange(),
    },
  },
});
