import CoverageLimit from '@root/auto-pricing/src/models/coverage-limit';
import camelCase from '@root/vendor/lodash/camelCase';
import coverageIconBI from '@root/auto-pricing/src/assets/coverageIconBI.svg';
import coverageIconColl from '@root/auto-pricing/src/assets/coverageIconColl.svg';
import coverageIconComp from '@root/auto-pricing/src/assets/coverageIconComp.svg';
import coverageIconFnrl from '@root/auto-pricing/src/assets/coverageIconFnrl.svg';
import coverageIconMedExp from '@root/auto-pricing/src/assets/coverageIconMedExp.svg';
import coverageIconMedPay from '@root/auto-pricing/src/assets/coverageIconMedPay.svg';
import coverageIconPD from '@root/auto-pricing/src/assets/coverageIconPD.svg';
import coverageIconPIP from '@root/auto-pricing/src/assets/coverageIconPIP.svg';
import coverageIconRental from '@root/auto-pricing/src/assets/coverageIconRental.svg';
import coverageIconRoadside from '@root/auto-pricing/src/assets/coverageIconRoadside.svg';
import coverageIconUMUIM from '@root/auto-pricing/src/assets/coverageIconUMUIM.svg';
import coverageIconWkLs from '@root/auto-pricing/src/assets/coverageIconWkLs.svg';
import { RootError } from '@root-common/root-errors';

export default class Coverage {
  static Symbols = {
    BODILY_INJURY: 'bi',
    COLLISION: 'coll',
    COLLISION_DEDUCTIBLE_WAIVER: 'cdw',
    COMPREHENSIVE: 'comp',
    CUSTOMIZED_EQUIPMENT: 'custe',
    PROPERTY_DAMAGE: 'pd',
    UNINSURED_UNDERINSURED_MOTORIST: 'umuim',
    UNINSURED_MOTORIST: 'um',
    UNDERINSURED_MOTORIST: 'uim',
    MEDICAL_PAY: 'medPay',
    RENTAL: 'rental',
    ROADSIDE: 'roadside',
    PERSONAL_INJURY_PROTECTION: 'pip',
    UNINSURED_MOTORIST_PROPERTY_DAMAGE: 'umpd',
    UNDERINSURED_MOTORIST_PROPERTY_DAMAGE: 'uimpd',
    MEDICAL_EXPENSES: 'medExp',
    WORK_LOSS: 'wkLs',
    FUNERAL_BENEFITS: 'fnrl',
  };

  static CoverageOptions = {
    CONVERSION: 'conversion',
    ENHANCED: 'enhanced',
    FULL_GLASS: 'fullGlass',
    RESIDENT_RELATIVES_INCLUDED: 'residentRelativesIncluded',
    STACKED: 'stacked',
    WORK_LOSS_EXCLUDED: 'workLossExcluded',
    EXTRA_ORDINARY_MEDICAL_BENEFITS: 'extraOrdinaryMedicalBenefits',
  };

  static CoverageOptionChoice = {
    YES: 'Y',
    NO: 'N',
  };

  static isCoverageOptionAccepted(coverageOptions, coverageOption) {
    return coverageOptions[coverageOption] !== Coverage.DeclinedCoverageOptions[coverageOption];
  }

  static DeclinedCoverageOptions = {
    [Coverage.CoverageOptions.CONVERSION]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.ENHANCED]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.FULL_GLASS]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.RESIDENT_RELATIVES_INCLUDED]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.STACKED]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.WORK_LOSS_EXCLUDED]: Coverage.CoverageOptionChoice.NO,
    [Coverage.CoverageOptions.EXTRA_ORDINARY_MEDICAL_BENEFITS]: Coverage.CoverageOptionChoice.NO,
  };

  static DefaultCoverageOptions = {
    ...Coverage.DeclinedCoverageOptions,
  };

  static Types = {
    VEHICLE: [
      Coverage.Symbols.BODILY_INJURY,
      Coverage.Symbols.COLLISION,
      Coverage.Symbols.COMPREHENSIVE,
      Coverage.Symbols.CUSTOMIZED_EQUIPMENT,
      Coverage.Symbols.FUNERAL_BENEFITS,
      Coverage.Symbols.MEDICAL_EXPENSES,
      Coverage.Symbols.MEDICAL_PAY,
      Coverage.Symbols.PERSONAL_INJURY_PROTECTION,
      Coverage.Symbols.PROPERTY_DAMAGE,
      Coverage.Symbols.RENTAL,
      Coverage.Symbols.ROADSIDE,
      Coverage.Symbols.WORK_LOSS,
    ],
    NON_VEHICLE: [
      Coverage.Symbols.UNDERINSURED_MOTORIST,
      Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
      Coverage.Symbols.UNINSURED_MOTORIST,
      Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
      Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST,
    ],
  }

  static IconsBySymbol = {
    [Coverage.Symbols.BODILY_INJURY]: coverageIconBI,
    [Coverage.Symbols.COLLISION]: coverageIconColl,
    [Coverage.Symbols.COMPREHENSIVE]: coverageIconComp,
    [Coverage.Symbols.CUSTOMIZED_EQUIPMENT]: coverageIconPD,
    [Coverage.Symbols.FUNERAL_BENEFITS]: coverageIconFnrl,
    [Coverage.Symbols.MEDICAL_EXPENSES]: coverageIconMedExp,
    [Coverage.Symbols.MEDICAL_PAY]: coverageIconMedPay,
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: coverageIconPIP,
    [Coverage.Symbols.PROPERTY_DAMAGE]: coverageIconPD,
    [Coverage.Symbols.RENTAL]: coverageIconRental,
    [Coverage.Symbols.ROADSIDE]: coverageIconRoadside,
    [Coverage.Symbols.UNDERINSURED_MOTORIST]: coverageIconUMUIM,
    [Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: coverageIconPD,
    [Coverage.Symbols.UNINSURED_MOTORIST]: coverageIconUMUIM,
    [Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: coverageIconPD,
    [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: coverageIconUMUIM,
    [Coverage.Symbols.WORK_LOSS]: coverageIconWkLs,
  };

  static LimitTypesBySymbol = {
    [Coverage.Symbols.BODILY_INJURY]: CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    [Coverage.Symbols.COLLISION]: CoverageLimit.Types.DEDUCTIBLE,
    [Coverage.Symbols.COMPREHENSIVE]: CoverageLimit.Types.DEDUCTIBLE,
    [Coverage.Symbols.CUSTOMIZED_EQUIPMENT]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.FUNERAL_BENEFITS]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.MEDICAL_EXPENSES]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.MEDICAL_PAY]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    [Coverage.Symbols.PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.RENTAL]: CoverageLimit.Types.PER_DAY_PER_OCCURRENCE,
    [Coverage.Symbols.ROADSIDE]: CoverageLimit.Types.PER_DAY_PER_OCCURRENCE,
    [Coverage.Symbols.UNDERINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    [Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.UNINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    [Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    [Coverage.Symbols.WORK_LOSS]: CoverageLimit.Types.PER_PERSON,
  };

  static LimitTypesBySymbolForSort = {
    [Coverage.Symbols.BODILY_INJURY]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.COLLISION]: CoverageLimit.Types.DEDUCTIBLE,
    [Coverage.Symbols.COMPREHENSIVE]: CoverageLimit.Types.DEDUCTIBLE,
    [Coverage.Symbols.CUSTOMIZED_EQUIPMENT]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.FUNERAL_BENEFITS]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.MEDICAL_EXPENSES]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.MEDICAL_PAY]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.RENTAL]: CoverageLimit.Types.PER_DAY,
    [Coverage.Symbols.ROADSIDE]: CoverageLimit.Types.PER_DAY_PER_OCCURRENCE,
    [Coverage.Symbols.UNDERINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.UNINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimit.Types.PER_OCCURRENCE,
    [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: CoverageLimit.Types.PER_PERSON,
    [Coverage.Symbols.WORK_LOSS]: CoverageLimit.Types.PER_PERSON,
  };

  static NonLiabilityCoverageTypes = [
    Coverage.Symbols.UNINSURED_MOTORIST,
    Coverage.Symbols.UNDERINSURED_MOTORIST,
    Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST,
    Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
    Coverage.Symbols.PERSONAL_INJURY_PROTECTION,
    Coverage.Symbols.MEDICAL_PAY,
    Coverage.Symbols.MEDICAL_EXPENSES,
    Coverage.Symbols.WORK_LOSS,
    Coverage.Symbols.FUNERAL_BENEFITS,
  ]

  static DeductiblesCoverageTypes = [
    Coverage.Symbols.COLLISION,
    Coverage.Symbols.COMPREHENSIVE,
  ]

  static LiabilityCoverageTypes = [
    Coverage.Symbols.BODILY_INJURY,
    Coverage.Symbols.PROPERTY_DAMAGE,
  ]

  static FullCoverageIndicator = {
    ALL: 'A',
    SOME: 'S',
    NONE: 'N',
  }

  static NamesBySymbol = {
    default: {
      [Coverage.Symbols.BODILY_INJURY]: 'Bodily Injury',
      [Coverage.Symbols.COLLISION]: 'Collision',
      [Coverage.Symbols.COLLISION_DEDUCTIBLE_WAIVER]: 'Collision Deductible Waiver',
      [Coverage.Symbols.COMPREHENSIVE]: 'Comprehensive',
      [Coverage.Symbols.CUSTOMIZED_EQUIPMENT]: 'Customized Equipment',
      [Coverage.Symbols.FUNERAL_BENEFITS]: 'Funeral Benefits',
      [Coverage.Symbols.MEDICAL_EXPENSES]: 'Medical and Rehabilitation Expenses',
      [Coverage.Symbols.MEDICAL_PAY]: 'Medical Payments',
      [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Personal Injury Protection',
      [Coverage.Symbols.PROPERTY_DAMAGE]: 'Property Damage',
      [Coverage.Symbols.RENTAL]: 'Rental',
      [Coverage.Symbols.ROADSIDE]: 'Roadside Assistance',
      [Coverage.Symbols.UNDERINSURED_MOTORIST]: 'Underinsured Motorist Bodily Injury',
      [Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: 'Underinsured Motorist Property Damage',
      [Coverage.Symbols.UNINSURED_MOTORIST]: 'Uninsured Motorist Bodily Injury',
      [Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: 'Uninsured Motorist Property Damage',
      [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: 'Uninsured and Underinsured Motorist Bodily Injury',
      [Coverage.Symbols.WORK_LOSS]: 'Work Loss',
    },
    VA: {
      [Coverage.Symbols.MEDICAL_PAY]: 'Medical Expense Benefits',
      [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Income Loss',
      [Coverage.Symbols.RENTAL]: 'Transportation Expense',
      [Coverage.Symbols.ROADSIDE]: 'Towing and Labor Costs',
    },
  }

  static build({
    id,
    declined,
    deductible,
    coverageOptions,
    perOccurrence,
    perPerson,
    perDay,
    symbol,
    coveredVins = [],
    approximatePremiumCents,
    approximateMonthlyPremiumCents,
    rawApproximatePremiumCents,
    vin,
    group,
  } = {}) {
    const buildParams = {
      id,
      declined,
      deductible,
      perOccurrence,
      perPerson,
      perDay,
      coveredVins,
      symbol: camelCase(symbol),
    };

    if (group) {
      buildParams.group = group;
    }

    if (approximatePremiumCents) {
      buildParams.approximatePremiumCents = approximatePremiumCents;
    }

    if (approximateMonthlyPremiumCents) {
      buildParams.approximateMonthlyPremiumCents = approximateMonthlyPremiumCents;
    }

    if (rawApproximatePremiumCents) {
      buildParams.rawApproximatePremiumCents = rawApproximatePremiumCents;
    }

    if (vin) {
      buildParams.vin = vin;
    }

    if (coverageOptions) {
      buildParams.coverageOptions = Object.values(Coverage.CoverageOptions).reduce((mappedCoverageOptions, coverageOptionName) => {
        mappedCoverageOptions[coverageOptionName] = coverageOptions[coverageOptionName];
        return mappedCoverageOptions;
      }, {});
    }

    return Object.assign(
      new Coverage(),
      buildParams,
    );
  }

  getLimitType() {
    const limitType = Coverage.LimitTypesBySymbol[this.symbol];
    if (limitType) {
      return limitType;
    } else {
      throw `unknown coverage for ${this.symbol}`;
    }
  }

  static getLimitTypesForSymbolForSort(symbol, coverages) {
    if (symbol === Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE && coverages.some((c) => c.deductible)) {
      return CoverageLimit.Types.DEDUCTIBLE;
    }

    return Coverage.LimitTypesBySymbolForSort[symbol];
  }

  static getLocaleName({ market, symbol }) {
    const localeName = Coverage.NamesBySymbol?.[market]?.[symbol] || Coverage.NamesBySymbol?.['default']?.[symbol];

    if (localeName) {
      return localeName;
    }

    throw new RootError({
      message: `Unexpected coverage symbol: ${symbol}`,
      name: 'CoverageError',
      fingerprint: ['CoverageUnexpectedCoverageSymbol'],
    });
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes) {
    return Object.assign(
      new Coverage(),
      this,
      attributes
    );
  }
}
