import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import closeModal from '@root/core/src/assets/closeModal.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

const CloseModalButton = ({ onClick }) => {
  return (
    <div css={styles.container}>
      <button
        css={styles.closeButton}
        onClick={onClick}
      >
        <img
          alt={'close-modal-icon'}
          src={closeModal}
        />
      </button>
    </div>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    background: 'transparent',
    border: 0,
    cursor: 'pointer',
    marginRight: -20,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
});

CloseModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseModalButton;
