import PropTypes from '@root/vendor/prop-types';

const NumberOrStringType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

const SvgProps = {
  height: NumberOrStringType,
  width: NumberOrStringType,
};

const IconProps = {
  ...SvgProps,
  color: PropTypes.string,
};

const AutoscalingSvg = ({
  children, height, title, viewBox, width, ...restProps
}) => (
  <svg
    height={height}
    preserveAspectRatio={'xMidYMid meet'}
    viewBox={viewBox}
    width={width}
    xmlns={'http://www.w3.org/2000/svg'}
    {...restProps}
  >
    {!!title && <title>{title}</title>}
    {children}
  </svg>
);
AutoscalingSvg.propTypes = {
  ...SvgProps,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
};

export {
  AutoscalingSvg,
  IconProps,
  SvgProps,
};
