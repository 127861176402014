import QuotingRules from '@root/quotes/src/models/quoting-rules';
import useQuotingRulesQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-quoting-rules-query';
import { useCallback } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEYS = {
  LAST_FETCHED_QUOTING_RULES_MARKET: 'last_fetched_quoting_rules_market',
  QUOTING_RULES: 'quoting_rules',
};

const POLLING_INTERVAL = 1000;

export function usePersistedQuotingRules({ market, ratingRequestId }) {
  const [quotingRules, setQuotingRules] = usePersistedState({
    deserialize: (persistedItem) => new QuotingRules(persistedItem.rules),
    storageKey: LOCAL_STORAGE_KEYS.QUOTING_RULES,
  });

  const [lastFetchedQuotingRulesMarket, setLastFetchedQuotingRulesMarket] = usePersistedState({
    storageKey: LOCAL_STORAGE_KEYS.LAST_FETCHED_QUOTING_RULES_MARKET,
  });

  const handleFetchedQuotingRules = useCallback((quotingRulesContext) => {
    if (quotingRulesContext) {
      setLastFetchedQuotingRulesMarket(market);

      setQuotingRules(
        new QuotingRules(quotingRulesContext)
      );
    }
  }, [market, setLastFetchedQuotingRulesMarket, setQuotingRules]);

  const isQuotingRulesCurrent = !!quotingRules && lastFetchedQuotingRulesMarket === market;

  useQuotingRulesQuery({ market }, {
    enabled: !!market && !!ratingRequestId && !isQuotingRulesCurrent,
    onSuccess: handleFetchedQuotingRules,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    isQuotingRulesCurrent,
    quotingRules,
  };
}
