import * as Sentry from '@root/vendor/@sentry/react';
import AppEntry from '@root/bind.joinroot.com/src/app-entry';
import ErrorReportService from '@root/core/src/services/error-report-service';
import React from '@root/vendor/react';
import ReactDOM from '@root/vendor/react-dom';
import environment from '@root/core/src/utils/environment';
import { Integrations } from '@root/vendor/@sentry/tracing';
import { Router } from '@root/vendor/react-router-dom';
import { createBrowserHistory } from '@root/vendor/history';

const unsupportedBrowsers = [
  'Edge/17',
  'Edge/18',
];

const isUnsupportedBrowser = () => {
  unsupportedBrowsers.forEach((browser) => {
    if (window.navigator.userAgent && window.navigator.userAgent.indexOf(browser) >= 0) {
      return true;
    }
  });

  return false;
};

if (Object.entries && window.URLSearchParams && !isUnsupportedBrowser()) {
  const history = createBrowserHistory();
  let tracesSampleRate = 1.0;

  if (environment.environment === 'production') {
    tracesSampleRate = 0.05;
  }

  const options = {
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate,
  };

  ErrorReportService.init({
    environment,
    options,
  });

  ReactDOM.render(
    <AppEntry
      history={history}
      routerComponent={Router}
    />,
    document.getElementById('root')
  );
}
