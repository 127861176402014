import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export function CookieManagementLink({ styles }) {
  const toggleOsanoSidebar = () => {
    if (window.Osano.cm.drawerOpen) {
      window.Osano.cm.hideDrawer('osano-cm-dom-info-dialog-open');
    } else {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  return (
    <a
      css={[localStyles.link, styles]}
      onClick={toggleOsanoSidebar}
    >
      Manage Cookies
    </a>
  );
}

CookieManagementLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const localStyles = StyleSheet.create({
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
});
