import Address from '@root/core/src/models/address';
import Driver from '@root/auto-pricing/src/models/driver';
import DriverVehicleAssignment from '@root/auto-pricing/src/models/driver-vehicle-assignment';
import ExcludedDriver from '@root/auto-pricing/src/models/excluded-driver';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import Vehicle from '@root/core/src/models/vehicle';
import { RootError } from '@root-common/root-errors';

class BindProfileParams extends ProfileParams {
  static build(options) {
    return this._applyMarketSpecificFixups(Object.assign(
      new BindProfileParams(),
      options,
    ));
  }

  static buildFromBindProfile(bindProfile) {
    const drivers = (bindProfile.drivers ?? []).map((driver) => Driver.buildFromData({
      ...driver,
      selected: true,
      pni: driver.pni,
    }));

    const excludedDrivers = (bindProfile.excludedDrivers || []).map((excludedDriver) => ExcludedDriver.buildFromData(
      excludedDriver,
    ));

    const vehicles = bindProfile.vehicles.map((vehicle) => Vehicle.buildFromData({
      ...vehicle,
      testDriveSelected: bindProfile.testDriveSelected,
      selected: true,
    }));
    const driverVehicleAssignments = (bindProfile.driverVehicleAssignments ?? [])
      .map((dva) => DriverVehicleAssignment.buildFromData({
        ...dva,
        drivers,
        vehicles,
      }));
    const mailingAddress = Address.buildFromData(bindProfile.mailingAddress);

    const profileParams = {
      drivers,
      driverVehicleAssignments,
      excludedDrivers,
      homeowner: bindProfile.homeowner,
      mailingAddress,
      originalUniversalDriverId: drivers[0].universalDriverId,
      originalVehicleVin: vehicles[0].vin,
      primaryNamedInsured: drivers.find((driver) => driver.selected && !driver.removed && driver.pni),
      ratingMunicipality: bindProfile.ratingMunicipality,
      vehicles,
      rideshare: bindProfile.rideshare,
    };

    return this.build(profileParams);
  }

  static buildFromData(bindProfileParamsData) {
    const drivers = bindProfileParamsData.drivers.map(Driver.buildFromData);

    const numPrimaryNamedInsureds = drivers.filter((driver) => driver.selected && !driver.removed && driver.pni).length;
    if (numPrimaryNamedInsureds !== 1) {
      throw new RootError({
        message: 'The bind profile params must contain exactly one primary named insured',
        name: 'PrimaryNamedInsuredError',
      });
    }

    const vehicles = bindProfileParamsData.vehicles.map((vehicle) => Vehicle.buildFromData({
      ...vehicle,
      id: vehicle.cid,
    }));

    const driverVehicleAssignments = (bindProfileParamsData.driverVehicleAssignments ?? []).map((dva) =>
      DriverVehicleAssignment.buildFromData({
        ...dva,
        drivers,
        vehicleId: dva.vehicleCid,
        vehicles,
      })
    );

    const excludedDrivers = (bindProfileParamsData.excludedDrivers ?? []).map((excludedDriver) =>
      ExcludedDriver.buildFromData(excludedDriver)
    );

    return this.build(
      {
        ...bindProfileParamsData,
        drivers,
        driverVehicleAssignments,
        excludedDrivers,
        vehicles,
        mailingAddress: Address.buildFromData(bindProfileParamsData.address),
      }
    );
  }

  constructor({
    drivers,
    driverVehicleAssignments = [],
    excludedDrivers = [],
    hasAutomaticDriverVehicleAssignment = false,
    homeowner,
    address = new Address(),
    originalUniversalDriverId,
    originalVehicleId,
    ratingMunicipality = null,
    vehicles,
    wasDriversSelectionMade = false,
    wasVehiclesSelectionMade = false,
    rightQuoteComplete = false,
    rootEnterpriseClientId = null,
    rootEnterpriseDriverId = null,
    priorInsuranceLastSixMonths = null,
    durationWithoutInsuranceLastSixMonths = null,
    previousInsurerDurationInsured = null,
    rideshare = null,
  } = {}) {
    super();
    this.drivers = drivers;
    this.driverVehicleAssignments = driverVehicleAssignments;
    this.excludedDrivers = excludedDrivers;
    this.hasAutomaticDriverVehicleAssignment = hasAutomaticDriverVehicleAssignment;
    this.homeowner = homeowner;
    this.mailingAddress = address;
    this.originalUniversalDriverId = originalUniversalDriverId;
    this.originalVehicleId = originalVehicleId;
    this.ratingMunicipality = ratingMunicipality;
    this.vehicles = vehicles;
    this.wasDriversSelectionMade = wasDriversSelectionMade;
    this.wasVehiclesSelectionMade = wasVehiclesSelectionMade;
    this.rightQuoteComplete = rightQuoteComplete;
    this.rootEnterpriseClientId = rootEnterpriseClientId;
    this.rootEnterpriseDriverId = rootEnterpriseDriverId;
    this.priorInsuranceLastSixMonths = priorInsuranceLastSixMonths;
    this.durationWithoutInsuranceLastSixMonths = durationWithoutInsuranceLastSixMonths;
    this.previousInsurerDurationInsured = previousInsurerDurationInsured;
    this.rideshare = rideshare;
  }

  set(key, value) {
    return BindProfileParams.build({
      ...this,
      [key]: value,
    });
  }
}

export default BindProfileParams;
