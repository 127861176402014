import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import DriverVehicleAssignmentEntry from '@root/driver-vehicle-assignment/src/driver-vehicle-assignment-entry';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Shadows, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_CONTEXT = 'BIND_DRIVER_VEHICLE_ASSIGNMENT';

const BindDriverVehicleAssignment = ({
  onCancel,
  location,
  onVehicleUsageChange,
  shouldReturnToPartnerOnBack,
}) => {
  useAnalytics(ANALYTICS_CONTEXT);
  const [useBrandingState] = useBranding();
  const brandStyles = useMemo(() => stylesGenerator(useBrandingState), [useBrandingState]);

  return (
    <>
      {shouldReturnToPartnerOnBack ?
        <BindView.ExitFlowButton exitToQuote />
        :
        <BindView.BackToProfileButton onClick={onCancel} />
      }
      <BindView>
        <BindView.Card
          cssOverrides={{
            marginTop: 50,
          }}
        >
          <DriverVehicleAssignmentEntry
            brandedColor={useBrandingState.primaryColor}
            cssButtonOverrides={brandStyles.buttonStyle}
            location={location}
            module={'bind'}
            onVehicleUsageChange={onVehicleUsageChange}
            profileParams={location.state?.profileParams}
          />
        </BindView.Card>
      </BindView>
    </>
  );
};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  buttonStyle: {
    background: primaryColor,
    marginTop: 30,
    marginBottom: 30,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
});

BindDriverVehicleAssignment.propTypes = {
  location: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onVehicleUsageChange: PropTypes.func.isRequired,
  shouldReturnToPartnerOnBack: PropTypes.bool,
};

export default BindDriverVehicleAssignment;
