import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function SubHeading({ children, cssOverrides = {} }) {
  return (
    <h2
      css={[styles.subHeading, cssOverrides]}
    >
      {children}
    </h2>
  );
}

const styles = StyleSheet.create({
  subHeading: {
    ...Theme.paragraph1(),
    color: Colors.black(),
    marginTop: 30,
    marginBottom: 20,
  },
});

SubHeading.propTypes = {
  children: PropTypes.node,
  cssOverrides: PropTypes.object,
};
