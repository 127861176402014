import environment from '@root/core/src/utils/environment';
import getI18n from '@root/bind.joinroot.com/src/utils/get-i18n';
import useLocalization from '@root/bind.joinroot.com/src/context/localization';
import { useMemo } from '@root/vendor/react';

const useI18n = (propertyToGet = '', lang = 'en') => useMemo(() => getI18n(propertyToGet, { lang }), [lang, propertyToGet]);

export function useI18nNext(
  propertyToGet = '',
  config = {
    legacyLang: 'en',
    legacyProperty: undefined,
  }
) {
  const [overwriteI18nValues] = useLocalization();

  if (environment.bindAppServerDrivenLocalizationEnabled) {
    const result = getI18n(
      propertyToGet,
      {
        // TODO: Update to be dynamic, when we can support multiple languages
        lang: 'en',
        overwriteI18nValues,
      }
    );
    return result;
  } else {
    return getI18n(
      propertyToGet,
      {
        lang: config?.legacyLang,
      }
    );
  }
}

export default useI18n;
