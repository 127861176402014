import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import FieldCell from '@root/profile/src/components/edit-driver/field-cell';
import FieldGroup from '@root/profile/src/components/edit-driver/field-group';
import FieldHeading from '@root/profile/src/components/edit-driver/field-heading';
import GenderSelect from '@root/core/src/components/gender-select';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import StateSelect from '@root/core/src/components/state-select';
import { obfuscate } from '@root/core/src/utils/strings';

export default class LicenseInfoFields extends Component {
  static propTypes = {
    ageWhenLicensed: PropTypes.instanceOf(AgeWhenLicensed),
    chevronStyle: PropTypes.string,
    displayAgeWhenLicensed: PropTypes.bool,
    errors: PropTypes.object,
    gender: PropTypes.string,
    genderOptions: PropTypes.array.isRequired,
    inputStyle: PropTypes.object,
    isObfuscated: PropTypes.bool,
    licenseNumber: PropTypes.string,
    licenseState: PropTypes.string,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onLicenseChange: PropTypes.func.isRequired,
    optionStyles: PropTypes.object,
    persistLabel: PropTypes.bool,
    useGender: PropTypes.bool.isRequired,
  };

  state = {
    licenseNumber: undefined,
    ageWhenLicensed: this.props.ageWhenLicensed,
  };

  static defaultProps = {
    ageWhenLicensed: new AgeWhenLicensed(),
    persistLabel: false,
  }

  getLicenseNumber() {
    if (this.props.isObfuscated) {
      if (this.state.licenseNumber !== undefined) {
        return this.state.licenseNumber;
      }
      return obfuscate(this.props.licenseNumber, {
        numVisible: 3,
      });
    }
    return this.props.licenseNumber;
  }

  _handleFocusLicenseNumber = () => {
    if (this.props.isObfuscated && !this.state.licenseNumber) {
      this.setState({
        licenseNumber: '',
      });
    }
  };

  _handleChangeLicenseNumber = (value) => {
    if (this.props.isObfuscated) {
      this.setState({
        licenseNumber: value,
      }, this.props.onLicenseChange(value));
      return;
    }
    this.props.onLicenseChange(value);
  };

  _handleBlurLicenseNumber = (value) => {
    if (this.props.isObfuscated) {
      this.setState({
        licenseNumber: value || undefined,
      }, this.props.onBlur('licenseNumber')(value));
      return;
    }
    this.props.onBlur('licenseNumber')(value);
  };

  _handleChangeAgeWhenLicensed = (value) => {
    value = this._sanitizeAgeWhenLicensed(value);
    this.props.onChange('ageWhenLicensed')(this.props.ageWhenLicensed.set(value));
  }

  _handleBlurAgeWhenLicensed = (value) => {
    this.props.onBlur('ageWhenLicensed')(value);
  }

  _sanitizeAgeWhenLicensed = (value) => {
    if (!!value && isNaN(value)) {
      const checkedValue = value.replace(/[^\d]/g, '');

      if (!isNaN(checkedValue)) {
        return checkedValue;
      }
    }

    return value;
  }

  _renderAgeWhenLicensed() {
    if (this.props.displayAgeWhenLicensed) {
      return (
        <FieldCell>
          <Input
            errorLabel={this.props.errors.ageWhenLicensed}
            inputType={TextTypes.TEXT}
            label={AgeWhenLicensed.LABEL}
            onBlur={this._handleBlurAgeWhenLicensed}
            onChange={this._handleChangeAgeWhenLicensed}
            pattern={'[0-9]*'}
            value={`${this.props.ageWhenLicensed.age}`}
          />
        </FieldCell>
      );
    }
  }

  _renderGenderSelect() {
    if (this.props.useGender) {
      return (
        <FieldCell>
          <GenderSelect
            dropdownIndicator={this.props.chevronStyle}
            onChange={this.props.onChange('gender')}
            options={this.props.genderOptions}
            optionStyles={this.props.optionStyles}
            persistLabel={this.props.persistLabel}
            selectedValue={this.props.gender || ''}
          />
        </FieldCell>
      );
    }
  }

  render() {
    return (
      <FieldGroup>
        <FieldHeading>
          License info
        </FieldHeading>
        <FieldCell>
          <Input
            errorLabel={this.props.errors.licenseNumber}
            inputStyle={this.props.inputStyle}
            label={'License number'}
            onBlur={this._handleBlurLicenseNumber}
            onChange={this._handleChangeLicenseNumber}
            onFocus={this._handleFocusLicenseNumber}
            value={this.getLicenseNumber()}
          />
        </FieldCell>
        <FieldCell>
          <StateSelect
            dropdownIndicator={this.props.chevronStyle}
            onChange={this.props.onChange('licenseState')}
            optionStyles={this.props.optionStyles}
            persistLabel={this.props.persistLabel}
            placeholder={'License state'}
            selectedValue={this.props.licenseState || ''}
          />
        </FieldCell>
        {this._renderGenderSelect()}
        {this._renderAgeWhenLicensed()}
      </FieldGroup>
    );
  }
}
