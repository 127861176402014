import PropTypes from '@root/vendor/prop-types';
import { styled } from '@root/core/src/utils/styles';

export default function ListItemBase({
  isDisabled = false, className, children, onClick,
}) {
  return (
    <ListItemBaseStyled
      className={className}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </ListItemBaseStyled>
  );
}

ListItemBase.opacityLevels = {
  DISABLED: '.35',
  ENABLED: '1',
};

const ListItemBaseStyled = styled.li`
  opacity: ${(props) => props.isDisabled ? ListItemBase.opacityLevels.DISABLED : ListItemBase.opacityLevels.ENABLED};
  display: flex;
  align-items: center;
  padding: 12px;
  list-style: none;
  padding: 12px 0;
  @media (min-width: 576px) {
    padding: 12px;
  }
  cursor: ${(props) => props.onClick ? 'pointer' : 'auto'};
`;

ListItemBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};
