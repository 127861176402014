import Colors from '@root/brand/src/utils/colors';
import Container from '@root/core/src/components/container';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';
import { CaliforniaFaqLink } from '@root/core/src/components/disclaimer-links/california-faq-link';
import { CookieManagementLink } from '@root/core/src/components/disclaimer-links/cookie-management-link';
import { DoNotSellMyInformationLink } from '@root/core/src/components/disclaimer-links/do-not-sell-my-information-link';
import { FcraDisclaimerLink } from '@root/core/src/components/disclaimer-links/fcra-disclaimer-link';
import { FraudDisclaimerLink } from '@root/core/src/components/disclaimer-links/fraud-disclaimer-link';
import { MobileAppEulaLink } from '@root/core/src/components/disclaimer-links/mobile-app-eula-link';
import { PrivacyPolicyLink } from '@root/core/src/components/disclaimer-links/privacy-policy-link';
import { RecaptchaPrivacyPolicyLink } from '@root/core/src/components/disclaimer-links/recaptcha-privacy-policy-link';
import { RecaptchaTermsOfServiceLink } from '@root/core/src/components/disclaimer-links/recaptcha-terms-of-service-link';
import { StyleSheet } from '@root/core/src/utils/styles';
import { TermsAndConditionsLink } from '@root/core/src/components/disclaimer-links/terms-and-conditions-link';

export default function GlobalFooterDisclaimer({ className, shouldRenderAdditionalDisclaimers = false }) {
  return (
    <Container
      className={className}
      css={styles.container}
    >
      <small css={[styles.globalFooterText]}>
        Copyright ROOT {new Date().getFullYear()}. ROOT is a registered servicemark of Root Insurance Company, Columbus, OH. Disclaimer for quotes:
        WE RESERVE THE RIGHT TO REFUSE TO QUOTE ANY INDIVIDUAL A PREMIUM RATE FOR THE INSURANCE ADVERTISED HEREIN. Disclaimer
        for coverage: Coverage is available in the event of a covered loss. Exclusions may apply. Not available in all states.
        Disclaimer for savings: Based on savings reported by actual customers who purchased a new Root policy between October
        2019 - July 2020; changes in coverage levels not evaluated. For California residents: Telematics is not used and
        resulting represented savings are not applicable. Referral program not applicable. Roadside Assistance purchased as
        separate coverage. Visit <CaliforniaFaqLink /> for more information. This site is protected by reCAPTCHA and the
        Google <RecaptchaPrivacyPolicyLink /> and <RecaptchaTermsOfServiceLink /> apply.
        {
          environment.osanoEnabled === 'true' &&
          <span>&nbsp;Click here to <CookieManagementLink />.</span>
        }
        {
          shouldRenderAdditionalDisclaimers &&
          <span>
            &nbsp;By continuing, you agree to our <PrivacyPolicyLink />, <TermsAndConditionsLink />, <FcraDisclaimerLink />,
            and <MobileAppEulaLink />. Please see our <FraudDisclaimerLink />.
          </span>
        }
        {
          <span>&nbsp;<DoNotSellMyInformationLink />.</span>
        }
      </small>
    </Container>
  );
}

GlobalFooterDisclaimer.propTypes = {
  className: PropTypes.string,
  shouldRenderAdditionalDisclaimers: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    paddingTop: '12px',
    paddingBottom: '12px',

    '& a': {
      color: Colors.nearBlack(),
      cursor: 'pointer',
      textDecoration: 'underline',
      transition: 'color 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
      ':hover': {
        color: Colors.rootOrange(),
      },
    },
  },

  globalFooterText: {
    fontSize: '12px',
    color: Colors.gray50(),
  },
});
