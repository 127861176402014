import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import Button from '@root/bind.joinroot.com/src/components/button';
import CaretLoader from '@root/core/src/components/caret-loader';
import CoverageSummary from '@root/bind.joinroot.com/src/components/bind-start-entry/coverage-summary';
import DobInputGroup from '@root/bind.joinroot.com/src/components/bind-start-entry/dob-input-group';
import DriverLicenseInput from '@root/bind.joinroot.com/src/components/bind-start-entry/driver-license-input';
import FooterDisclaimers from '@root/bind.joinroot.com/src/components/bind-start-entry/footer-disclaimers';
import HomeownerSelect from '@root/bind.joinroot.com/src/components/bind-start-entry/homeowner-select';
import LicenseValidationService from '@root/core/src/services/license-number-validation-service';
import MaritalStatusSelect from '@root/bind.joinroot.com/src/components/bind-start-entry/marital-status-select';
import PhoneNumberInput from '@root/bind.joinroot.com/src/components/bind-start-entry/phone-number-input';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileReviewService from '@root/profile-review/src/services/profile-review-service';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import ProfileRulesContextFactory from '@root/auto-pricing/test/support/factories/profile-rules-context-factory';
import PropTypes from '@root/vendor/prop-types';
import RatingMunicipalityInput, { sanitizeMunicipality } from '@root/bind.joinroot.com/src/components/bind-start-entry/rating-municipality-input';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import SceneLoader from '@root/core/src/components/scene-loader';
import WelcomeBlock from '@root/bind.joinroot.com/src/components/bind-start-entry/welcome-block';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useDeepLink from '@root/bind.joinroot.com/src/context/deep-link';
import usePrefillRequest from '@root/bind.joinroot.com/src/hooks/api/use-prefill-request';
import useProfile from '@root/bind.joinroot.com/src/context/profile';
import useTerritories from '@root/bind.joinroot.com/src/hooks/api/queries/use-territories-query';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const ANALYTICS_CONTEXT = 'BIND_START_ENTRY';
export const ANALYTICS_EVENTS = {
  CONTINUE: 'CONTINUE',
  NAVIGATE_TO_DEEP_LINK_PAGE: 'NAVIGATE_TO_DEEP_LINK_PAGE',
  SKIPPING: 'SKIPPING',
};

const BindStartEntry = ({
  isLoadingProfileRules,
  isLoadingSupportedMarkets,
  navigateToDeepLinkPage,
  onPrefillRequestTriggered,
  profileParams,
  profileRules,
  updateProfileParams,
}) => {
  const [hasPrefillBeenSubmitted, setHasPrefillBeenSubmitted] = useState(false);

  const { trackEvent, trackClick } = useAnalytics(ANALYTICS_CONTEXT);

  const cardStrings = useI18nNext('pages.bindStartEntry.card');

  const profile = useProfile();
  const deepLink = useDeepLink();

  const onSuccess = useCallback(() => {
    setHasPrefillBeenSubmitted(true);
    onPrefillRequestTriggered();
  }, [onPrefillRequestTriggered]);

  const {
    createPrefill,
    currentPrefillRequest,
    initialPrefillRequest,
    isCreatingPrefill,
    setCurrentPrefillRequest,
  } = usePrefillRequest({
    onSuccess,
    profile,
  });

  const [initiallyValid, setInitallyValid] = useState({
    dob: initialPrefillRequest.isDobValid(),
    homeowner: initialPrefillRequest.isHomeownerValid(),
    license: initialPrefillRequest.isLicenseValid(),
    mailingAddress: initialPrefillRequest.isMailingAddressValid(),
    maritalStatus: initialPrefillRequest.isMaritalStatusValid(),
    name: initialPrefillRequest.isNameValid(),
    phoneNumber: initialPrefillRequest.isPhoneNumberValid(),
    territory: false,
  });

  const [displayRatingMunicipalityInput, setDisplayRatingMunicipalityInput] = useState(false);
  const [hasFullyValidatedInitialLicense, setHasFullyValidatedInitialLicense] = useState(false);
  const [isLicenseValid, setIsLicenseValid] = useState(initiallyValid.license);
  const [isTerritoryValid, setIsTerritoryValid] = useState(false);
  const [isValidatingLicense, setIsValidatingLicense] = useState(false);

  const updateLicenseValid = useCallback((isValid) => {
    setInitallyValid((currentState) => ({
      ...currentState,
      license: isValid,
    }));
    setHasFullyValidatedInitialLicense(true);
  }, [setHasFullyValidatedInitialLicense, setInitallyValid]);

  const city = profile.mailingAddress?.city;
  const isRatingMunicipalityRequired = profileRules?.ratingMunicipality;

  const {
    data: territoryContext,
    isLoading: isLoadingTerritories,
  } = useTerritories({ market: profile.mailingAddress?.state }, {
    enabled: profileRules?.ratingMunicipality && city !== undefined,
    onSuccess: (data) => {
      const isValid = ProfileReviewService.isValidTerritory(city, data.territoryContext.territories);
      setInitallyValid((currentState) => ({
        ...currentState,
        territory: isValid,
      }));
      setIsTerritoryValid(isValid);
      if (isValid) {
        updateProfileParams(profileParams.set('ratingMunicipality', sanitizeMunicipality(city)));
      } else {
        setDisplayRatingMunicipalityInput(true);
      }
    },
  });

  useEffect(() => {
    if (!hasFullyValidatedInitialLicense && initiallyValid.license) {
      LicenseValidationService.isValidForMarket({
        licenseState: currentPrefillRequest.licenseState,
        licenseNumber: currentPrefillRequest.licenseNumber,
      }).then(updateLicenseValid);
    }
  }, [currentPrefillRequest.licenseNumber, currentPrefillRequest.licenseState, hasFullyValidatedInitialLicense, initiallyValid.license, updateLicenseValid]);

  const handleClickContinue = useCallback(async () => {
    const isFormValid = currentPrefillRequest.isReadyForEarlySubmission();

    trackClick(ANALYTICS_EVENTS.CONTINUE, { isFormValid });
    if (!hasPrefillBeenSubmitted && isFormValid) {
      createPrefill();
    }
  }, [currentPrefillRequest, createPrefill, hasPrefillBeenSubmitted, trackClick]);

  const disableSubmitForTerritory = isRatingMunicipalityRequired && !(isTerritoryValid || initiallyValid.territory);
  const disableSubmit = isValidatingLicense || !currentPrefillRequest.isReadyForSubmission() || !isLicenseValid || disableSubmitForTerritory;

  const wasDeepLinked = deepLink.wasDeepLinked();

  useEffect(() => {
    if (wasDeepLinked && profileRules && !isLoadingSupportedMarkets) {
      trackEvent(ANALYTICS_EVENTS.NAVIGATE_TO_DEEP_LINK_PAGE);
      navigateToDeepLinkPage();
    }
  }, [isLoadingSupportedMarkets, navigateToDeepLinkPage, profileRules, trackEvent, wasDeepLinked]);

  const [checkedSkip, setCheckedSkip] = useState(false);

  useEffect(() => {
    if (checkedSkip) { return; }

    // Only skip the page if the user was not deep linked
    if (!disableSubmit && !wasDeepLinked) {
      trackEvent(ANALYTICS_EVENTS.SKIPPING);
      createPrefill();
    }

    setCheckedSkip(true);
  }, [checkedSkip, createPrefill, disableSubmit, setCheckedSkip, trackEvent, wasDeepLinked]);

  if (!checkedSkip || isCreatingPrefill || wasDeepLinked) {
    return <SceneLoader hideHeader />;
  }

  return (
    <>
      <BindView.ExitFlowButton />
      <BindView
        cssOverrides={{
          card: {
            ...Responsive.md({
              flexDirection: 'row',
              marginLeft: 0,
              marginRight: 0,
            }),
            marginLeft: 10,
            marginRight: 10,
          },
          container: {
            '&:before': {
              height: '80vh',
              ...Responsive.md({
                height: '60vh',
              }),
            },
          },
        }}
        data-testid={'bind-start-entry'}
      >
        <WelcomeBlock />
        <BindView.Card>
          {
            isLoadingSupportedMarkets || isLoadingProfileRules || isLoadingTerritories ?
              <CaretLoader /> :
              <div css={styles.wrapper}>
                <h1 css={styles.header}>
                  {cardStrings.header}
                </h1>
                <p css={styles.paragraph}>
                  {cardStrings.paragraph}
                </p>
                <CoverageSummary
                  drivers={profile.drivers}
                  vehicles={profile.vehicles}
                />
                <DriverLicenseInput
                  css={styles.prefillInput}
                  hidden={initiallyValid.license}
                  onUpdatePrefillRequest={setCurrentPrefillRequest}
                  onUpdateProfileParams={updateProfileParams}
                  onValidatedLicense={setIsLicenseValid}
                  onValidatingLicense={setIsValidatingLicense}
                  prefillRequest={currentPrefillRequest}
                  profileParams={profileParams}
                  trackEvent={trackEvent}
                />
                <DobInputGroup
                  css={styles.prefillInput}
                  hidden={initiallyValid.dob}
                  onUpdatePrefillRequest={setCurrentPrefillRequest}
                  onUpdateProfileParams={updateProfileParams}
                  prefillRequest={currentPrefillRequest}
                  profileParams={profileParams}
                  trackEvent={trackEvent}
                />
                <PhoneNumberInput
                  css={styles.prefillInput}
                  hidden={initiallyValid.phoneNumber}
                  onUpdatePrefillRequest={setCurrentPrefillRequest}
                  prefillRequest={currentPrefillRequest}
                  trackEvent={trackEvent}
                />
                <HomeownerSelect
                  css={styles.prefillInput}
                  hidden={initiallyValid.homeowner}
                  onUpdatePrefillRequest={setCurrentPrefillRequest}
                  onUpdateProfileParams={updateProfileParams}
                  prefillRequest={currentPrefillRequest}
                  profileParams={profileParams}
                  trackEvent={trackEvent}
                />
                <MaritalStatusSelect
                  css={styles.prefillInput}
                  hidden={initiallyValid.maritalStatus}
                  maritalStatusChoices={[...profileRules?.maritalStatusChoices || ProfileRulesContextFactory.createModelForMarketWithCivilUnion().maritalStatusChoices]}
                  onUpdatePrefillRequest={setCurrentPrefillRequest}
                  onUpdateProfileParams={updateProfileParams}
                  prefillRequest={currentPrefillRequest}
                  profileParams={profileParams}
                  trackEvent={trackEvent}
                />
                {isRatingMunicipalityRequired && displayRatingMunicipalityInput &&
                  <RatingMunicipalityInput
                    css={styles.prefillInput}
                    onUpdateProfileParams={updateProfileParams}
                    onValidatedTerritory={setIsTerritoryValid}
                    profileParams={profileParams}
                    territoryContext={territoryContext.territoryContext}
                    trackEvent={trackEvent}
                  />
                }
                <Button
                  disabled={disableSubmit}
                  onClick={handleClickContinue}
                >
                  {cardStrings.button}
                </Button>
              </div>
          }

        </BindView.Card>
        <FooterDisclaimers />
      </BindView>
    </>
  );
};

BindStartEntry.propTypes = {
  isLoadingProfileRules: PropTypes.bool,
  isLoadingSupportedMarkets: PropTypes.bool,
  navigateToDeepLinkPage: PropTypes.func.isRequired,
  onPrefillRequestTriggered: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  profileRules: PropTypes.instanceOf(ProfileRulesContext),
  updateProfileParams: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  header: {
    ...Theme.heading1(),
  },
  paragraph: {
    ...Theme.paragraph1(),
    color: Colors.gray50(),
    marginBottom: 0,
  },
  prefillInput: {
    paddingTop: '1.5em',
  },
});

export default BindStartEntry;
