export const DECLINED = 'declined';

export const CoverageSymbols = {
  BODILY_INJURY: 'bi',
  CDW: 'cdw',
  COLLISION: 'coll',
  COMPREHENSIVE: 'comp',
  BI_PD_COMBINED: 'biPdCombined',
  FUNERAL_BENEFITS: 'fnrl',
  MEDICAL_EXPENSES: 'medExp',
  MEDICAL_PAY: 'medPay',
  PERSONAL_INJURY_PROTECTION: 'pip',
  PROPERTY_DAMAGE: 'pd',
  RENTAL: 'rental',
  ROADSIDE: 'roadside',
  UNDERINSURED_MOTORIST: 'uim',
  UNDERINSURED_MOTORIST_PROPERTY_DAMAGE: 'uimpd',
  UNINSURED_MOTORIST: 'um',
  UNINSURED_MOTORIST_PROPERTY_DAMAGE: 'umpd',
  UNINSURED_MOTORIST_PROPERTY_DAMAGE_DEDUCTIBLE: 'umpd_deductible',
  UNINSURED_UNDERINSURED_MOTORIST: 'umuim',
  WORK_LOSS: 'wkLs',
  UNKNOWN: 'unknown',
};

export const CoverageOptions = {
  CONVERSION: 'conversion',
  ENHANCED: 'enhanced',
  FULL_GLASS: 'fullGlass',
  STACKED: 'stacked',
};

export const CoverageOptionChoice = {
  NO: 'N',
  YES: 'Y',
};

export const DefaultCoverageOptions = {
  [CoverageOptions.CONVERSION]: CoverageOptionChoice.NO,
  [CoverageOptions.ENHANCED]: CoverageOptionChoice.NO,
  [CoverageOptions.FULL_GLASS]: CoverageOptionChoice.NO,
  [CoverageOptions.STACKED]: CoverageOptionChoice.NO,
};

export const CoverageLimitTypes = {
  PER_PERSON_PER_OCCURRENCE: 'perPersonPerOccurrence',
  PER_DAY_PER_OCCURRENCE: 'perDayPerOccurrence',
  PER_OCCURRENCE: 'perOccurrence',
  PER_PERSON: 'perPerson',
  DEDUCTIBLE: 'deductible',
  PER_DAY: 'perDay',
};

export const ComparisonOrder = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export const FullCoverageIndicator = {
  ALL: 'A',
  SOME: 'S',
  NONE: 'N',
};

export const BackendOptions = {
  FULL: 'full',
  LIMITED: 'limited',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  NO_USE: 'no_use',
};

export const QuotingRulesKeys = {
  ALTERNATE_VALIDATE: 'alternateValidate',
  COVERAGES: 'coverages',
  DEPENDENT_SYMBOL: 'dependentSymbol',
  FILTERING_VALIDATE: 'filteringValidate',
  FIXUP_TYPE: 'fixupType',
  PARENT_SYMBOLS: 'parentSymbols',
  SORT_ORDER_FACTORY: 'sortOrderFactory',
  VALIDATE: 'validate',
};

export const CoverageTypes = {
  PHYSICAL_DAMAGE: [
    CoverageSymbols.COLLISION,
    CoverageSymbols.COMPREHENSIVE,
    CoverageSymbols.RENTAL,
    CoverageSymbols.ROADSIDE,
  ],
};

export const SortOrder = {
  [CoverageSymbols.BODILY_INJURY]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.CDW]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.COLLISION]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.COMPREHENSIVE]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.PROPERTY_DAMAGE]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.UNINSURED_MOTORIST]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.UNDERINSURED_MOTORIST]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.MEDICAL_PAY]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.PERSONAL_INJURY_PROTECTION]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.FUNERAL_BENEFITS]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.MEDICAL_EXPENSES]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.WORK_LOSS]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.RENTAL]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.ROADSIDE]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.UNINSURED_MOTORIST]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: ComparisonOrder.DESCENDING,
  [CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE_DEDUCTIBLE]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.UNDERINSURED_MOTORIST]: ComparisonOrder.ASCENDING,
  [CoverageSymbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: ComparisonOrder.DESCENDING,
};

export const CoverageLimitTypesBySymbolForSort = {
  [CoverageSymbols.BODILY_INJURY]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.COLLISION]: CoverageLimitTypes.DEDUCTIBLE,
  [CoverageSymbols.COMPREHENSIVE]: CoverageLimitTypes.DEDUCTIBLE,
  [CoverageSymbols.FUNERAL_BENEFITS]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.MEDICAL_EXPENSES]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.MEDICAL_PAY]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.PERSONAL_INJURY_PROTECTION]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.PROPERTY_DAMAGE]: CoverageLimitTypes.PER_OCCURRENCE,
  [CoverageSymbols.RENTAL]: CoverageLimitTypes.PER_DAY,
  [CoverageSymbols.ROADSIDE]: CoverageLimitTypes.PER_DAY_PER_OCCURRENCE,
  [CoverageSymbols.UNDERINSURED_MOTORIST]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimitTypes.PER_OCCURRENCE,
  [CoverageSymbols.UNINSURED_MOTORIST]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: CoverageLimitTypes.PER_OCCURRENCE,
  [CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST]: CoverageLimitTypes.PER_PERSON,
  [CoverageSymbols.WORK_LOSS]: CoverageLimitTypes.PER_PERSON,
};

export const FixupTypes = {
  FIXUP_BOTH: 'fixupBothCoverages',
  FIXUP_DEPENDENT: 'fixupDependentCoverages',
  FIXUP_UMPD_WITH_COLL: 'fixupUmpdWithColl',
  FIXUP_RENTAL_WITH_COLL: 'fixupRentalWithColl',
  FIXUP_ANY_COLL_COMP_REQUIRES_FULL: 'fixupAnyCollCompRequiresFull',
  FIXUP_PHYSICAL_DAMAGE_TO_DECLINED: 'fixupPhysicalDamageToDeclined',
};

export const RuleTypes = {
  ANY_COLL_OR_COMP_REQUIRES_FULL: 'anyCollOrCompRequiresFull',
  CDW_MUST_EQUAL_COLL_OR_BE_DECLINED: 'cdwMustEqualCollOrBeDeclined',
  COLL_EXCLUDES_UMPD: 'collExcludesUmpd',
  DECLINE_EXTERNAL_PHYSICAL_DAMAGE: 'declineExternalPhysicalDamage',
  FULL_TORT_REQUIRES_ONLY_GUEST_PIP: 'fullTortRequiresOnlyGuestPip',
  HIGHEST_UMPD_REQUIRES_HIGHEST_PD: 'highestUmpdRequiresHighestPd',
  HIGHEST_UM_REQUIRES_HIGHEST_BI: 'highestUmRequiresHighestBi',
  PIP_AND_MED_MUTUALLY_EXCLUSIVE: 'pipAndMedMutuallyExclusive',
  RENTAL_REQUIRES_COLL_AND_COMP: 'rentalRequiresCollAndComp',
  ROADSIDE_MUST_EQUAL_COLL_OR_BE_DECLINED: 'roadsideMustEqualCollOrBeDeclined',
  UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD: 'uimpdMustBeLessThanOrEqualToPd',
  UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_UMPD: 'uimpdMustBeLessThanOrEqualToUmpd',
  UIM_AND_UIMPD_DEPENDENT: 'uimAndUimpdDependent',
  UIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_UM: 'uimMustBeGreaterThanOrEqualToUm',
  UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI: 'uimMustBeLessThanOrEqualToBi',
  UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI_UNLESS_LOWEST: 'uimMustBeLessThanOrEqualToBiUnlessLowest',
  UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_UM: 'uimMustBeLessThanOrEqualToUm',
  UIM_MUST_EQUAL_UM: 'uimMustEqualUm',
  UIM_REQUIRES_UM: 'uimRequiresUm',
  UMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD: 'umpdMustBeLessThanOrEqualToPd',
  UMPD_REQUIRES_UM: 'umpdRequiresUm',
  UMPD_REQUIRES_UMUIM: 'umpdRequiresUmuim',
  UMPD_REQUIRES_UM_OR_UIM: 'umpdRequiresUmOrUim',
  UMUIME_MUST_EQUAL_BI: 'umuimeMustEqualBi',
  UMUIM_AND_UMPD_DEPENDENT: 'umuimAndUmpdDependent',
  UMUIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_BI: 'umuimMustBeGreaterThanOrEqualToBi',
  UMUIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI: 'umuimMustBeLessThanOrEqualToBi',
  UM_MUST_BE_GREATER_THAN_BI: 'umMustBeGreaterThanBi',
  UM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI: 'umMustBeLessThanOrEqualToBi',
};

export const FixupSequence = [
  RuleTypes.UMUIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_BI,
  RuleTypes.UMUIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI,
  RuleTypes.UMUIME_MUST_EQUAL_BI,
  RuleTypes.UMPD_REQUIRES_UMUIM,
  RuleTypes.UMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD,
  RuleTypes.HIGHEST_UMPD_REQUIRES_HIGHEST_PD,
  RuleTypes.UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD,
  RuleTypes.UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_UMPD,
  RuleTypes.UM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI,
  RuleTypes.UM_MUST_BE_GREATER_THAN_BI,
  RuleTypes.HIGHEST_UM_REQUIRES_HIGHEST_BI,
  RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI,
  RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI_UNLESS_LOWEST,
  RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_UM,
  RuleTypes.UIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_UM,
  RuleTypes.UMPD_REQUIRES_UM_OR_UIM,
  RuleTypes.UMPD_REQUIRES_UM,
  RuleTypes.UIM_REQUIRES_UM,
  RuleTypes.UIM_MUST_EQUAL_UM,
  RuleTypes.UMUIM_AND_UMPD_DEPENDENT,
  RuleTypes.UIM_AND_UIMPD_DEPENDENT,
  RuleTypes.COLL_EXCLUDES_UMPD,
  RuleTypes.CDW_MUST_EQUAL_COLL_OR_BE_DECLINED,
  RuleTypes.ROADSIDE_MUST_EQUAL_COLL_OR_BE_DECLINED,
  RuleTypes.FULL_TORT_REQUIRES_ONLY_GUEST_PIP,
  RuleTypes.ANY_COLL_OR_COMP_REQUIRES_FULL,
  RuleTypes.RENTAL_REQUIRES_COLL_AND_COMP,
  RuleTypes.PIP_AND_MED_MUTUALLY_EXCLUSIVE,
  RuleTypes.DECLINE_EXTERNAL_PHYSICAL_DAMAGE,
];
