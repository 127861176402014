import BillingCycle from '@root/quotes/src/models/billing-cycle';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Select from '@root/bind.joinroot.com/src/components/select';

const options = BillingCycle.optionsForSelect();

const BillingCycleSelect = ({ billingCycle, updateBillingCycle }) => {
  return (
    <Select
      inputId={'billingCycle'}
      inputName={'billingCycle'}
      onChange={updateBillingCycle}
      options={options}
      persistLabel={true}
      placeholder={'Payment frequency'}
      selectedValue={billingCycle}
    />
  );
};

BillingCycleSelect.propTypes = {
  billingCycle: PropTypes.oneOf([
    BillingCycle.ANNUALLY,
    BillingCycle.FULL_TERM,
    BillingCycle.MONTHLY,
  ]),
  updateBillingCycle: PropTypes.func.isRequired,
};

export default BillingCycleSelect;
