import { getPrefillRequest } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const usePrefillRequestQuery = (overrides = {}) => useQuery({
  queryFn: async () => await getPrefillRequest(),
  queryKey: ['prefillRequest'],
  ...overrides,
});

export default usePrefillRequestQuery;
