import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import trashIcon from '@root/quotes/src/assets/trash-icon.svg';
import trashIconRed from '@root/quotes/src/assets/trash-icon-red.svg';
import { LegacyStyleSheet as StyleSheet, css } from '@root/core/src/utils/styles';

export default class RemoveButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const { onClick } = this.props;

    return (
      <button
        aria-label={'Remove coverage'}
        className={css(styles.button)}
        onClick={onClick}
        title={'Remove coverage'}
        type={'button'}
      />
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    backgroundImage: `url(${trashIcon})`,
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    border: 0,
    height: 26,
    width: 26,
    cursor: 'pointer',
    ':hover': {
      backgroundImage: `url(${trashIconRed})`,
    },
  },
});
