import Address from '@root/core/src/models/address';
import List, { ListItem } from '@root/bind.joinroot.com/src/components/list';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import navPinIcon from '@root/core/src/assets/navPin.svg';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const MailingAddressListItem = ({
  mailingAddress,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard.mailingAddressListItem');

  if (!mailingAddress) {
    return null;
  }

  return (
    <List header={strings.title}>
      <ListItem
        icon={navPinIcon}
        subtext={(
          <>
            <span>{mailingAddress.streetAddress()}</span>
            <br />
            <span>{mailingAddress.cityStateZip()}</span>
          </>
        )}
      />
    </List>
  );
};

MailingAddressListItem.propTypes = {
  mailingAddress: PropTypes.instanceOf(Address),
};

export default MailingAddressListItem;
