import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ProfileIcon({
  alt = 'Profile Icon', src, className,
}) {
  return (
    <img
      alt={alt}
      className={className}
      css={styles.icon}
      src={src}
    />
  );
}

ProfileIcon.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.node,
  src: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  icon: {
    width: 48,
    ...Responsive.md({
      width: 79,
    }),
  },
});
