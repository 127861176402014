import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Col, Row } from '@root/core/src/components/grid';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function IconTextRow({
  heading, icon, subtext,
}) {
  return (
    <Row
      css={styles.row}
    >
      <Col
        css={styles.iconContainer}
        md={3}
        xs={4}
      >
        <img src={icon} />
      </Col>
      <Col
        md={9}
        xs={8}
      >
        <h1 css={styles.heading}>
          {heading}
        </h1>
        {subtext && (
          <p css={styles.subtext}>
            {subtext}
          </p>
        )}
      </Col>
    </Row>
  );
}

IconTextRow.propTypes = {
  heading: PropTypes.string,
  icon: PropTypes.any,
  subtext: PropTypes.string,
};

const styles = StyleSheet.create({
  row: {
    marginTop: 30,
  },
  iconContainer: {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    ...Theme.paragraph1(),
    color: Colors.black(),
    marginBottom: 5,
  },
  subtext: {
    ...Theme.paragraph2(),
    marginBottom: 0,
  },
});
