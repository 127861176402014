import AutoQuoteDisclaimer from '@root/quotes/src/models/auto-quote-disclaimer';
import BindParams from '@root/quotes/src/models/bind-params';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import BodySmallText from '@root/core/src/components/body-small-text';
import Button from '@root/core/src/components/button';
import ChevronRight from '@root/bind.joinroot.com/src/components/icons/chevron-right';
import Disclaimer from '@root/quotes/src/components/disclaimer';
import HeadingSmall from '@root/core/src/components/heading-small';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import Subtext from '@root/core/src/components/subtext';
import affirmAutoQuoteDisclaimersConfiguration from '@root/quotes/src/api/affirm-auto-quote-disclaimers/affirm-auto-quote-disclaimers-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import useGetAutoQuoteDisclaimers from '@root/quotes/src/api/get-auto-quote-disclaimers/use-get-auto-quote-disclaimers';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { Shadows, StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const BindDisclaimers = ({ bindParams, onAcceptDisclaimers }) => {
  const [branding] = useBranding();
  const brandedStyles = stylesGenerator(branding);

  const { trackClick, trackEvent } = useAnalytics('BIND_DISCLAIMERS');
  const strings = useI18nNext('pages.bindDisclaimers');

  const [disclaimers, setDisclaimers] = useState(null);

  useGetAutoQuoteDisclaimers(bindParams.quoteId, bindParams.billingCycle, useCallback((result) => {
    setDisclaimers(result.data.autoQuoteDisclaimers.map((disclaimer) => AutoQuoteDisclaimer.build(disclaimer)));
  }, []));

  const [affirmAutoQuoteDisclaimers, isAcknowledging] = useImperativeNetworkRequest(affirmAutoQuoteDisclaimersConfiguration);

  const [shouldDisplayAgreements, setShouldDisplayAgreements] = useState(false);

  const handleDisclaimerClick = useCallback((disclaimer) => {
    trackClick('DISCLAIMER', {
      id: disclaimer.id,
      name: disclaimer.name,
    });
  }, [trackClick]);

  const displayAgreements = useCallback(() => {
    trackClick('CONTINUE');

    setShouldDisplayAgreements(true);
    trackEvent('AGREEMENTS_VIEWED');
  }, [setShouldDisplayAgreements, trackClick, trackEvent]);

  const handleDecline = useCallback(() => {
    trackClick('DECLINE');
    setShouldDisplayAgreements(false);
    onAcceptDisclaimers(false);
  }, [onAcceptDisclaimers, setShouldDisplayAgreements, trackClick]);

  const handleClickProceed = useCallback(async () => {
    if (isAcknowledging) { return; }
    trackClick('AGREE');

    await affirmAutoQuoteDisclaimers({
      disclaimerIds: disclaimers.map((disclaimer) => disclaimer.id),
      billingCycle: bindParams.billingCycle,
      quoteId: bindParams.quoteId,
    });

    onAcceptDisclaimers(true);
  }, [affirmAutoQuoteDisclaimers, bindParams, isAcknowledging, disclaimers, onAcceptDisclaimers, trackClick]);

  if (!disclaimers) {
    return <SceneLoader hideHeader />;
  }

  return (
    <>
      <BindView.ExitFlowButton />
      <BindView>
        <BindView.Card
          cssOverrides={{
            marginTop: 50,
          }}
        >
          <HeadingSmall>
            {strings.title}
          </HeadingSmall>
          {!shouldDisplayAgreements &&
          <div>
            <Subtext>
              {strings.subtitle}
            </Subtext>
            {disclaimers.map((disclaimer) => (
              <Disclaimer
                chevron={<ChevronRight color={branding.primaryColor} />}
                disclaimer={disclaimer}
                key={disclaimer.id}
                onClick={handleDisclaimerClick}
              />
            ))}
            <Button
              css={brandedStyles.button}
              onClick={displayAgreements}
            >
              {strings.buttonConfirm}
            </Button>
          </div>
          }
          {shouldDisplayAgreements &&
          <div>
            <div css={styles.statement}>
              {strings.affirmTitle}
            </div>
            <BodySmallText>
              {strings.affirmReadDocuments}
            </BodySmallText>
            <BodySmallText>
              {strings.affirmLegalSignature}
            </BodySmallText>
            <div css={styles.ctaContainer}>
              <Button
                css={brandedStyles.declineButton}
                onClick={handleDecline}
                variant={'secondary'}
              >
                {strings.buttonDecline}
              </Button>
              <Button
                css={brandedStyles.agreeButton}
                disabled={isAcknowledging}
                onClick={handleClickProceed}
              >
                {strings.buttonAgree}
              </Button>
            </div>
          </div>
          }
        </BindView.Card>
      </BindView>
    </>
  );
};

BindDisclaimers.propTypes = {
  bindParams: PropTypes.instanceOf(BindParams),
  onAcceptDisclaimers: PropTypes.func.isRequired,
};

export default BindDisclaimers;

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  button: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  declineButton: {
    border: `2px solid ${primaryColor}`,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  agreeButton: {
    marginLeft: 16,
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
});

const styles = StyleSheet.create({
  ctaContainer: {
    display: 'flex',
  },
  statement: {
    marginTop: 20,
    marginBottom: 8,
  },
});
