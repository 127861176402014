import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import useMarketRulesQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-market-rules-query';
import useProfile from '@root/bind.joinroot.com/src/context/profile';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'profile_rules';

export function usePersistedProfileRules() {
  const [profileRules, setProfileRules] = usePersistedState({
    deserialize: (persistedItem) => persistedItem && new ProfileRulesContext(persistedItem),
    storageKey: LOCAL_STORAGE_KEY,
  });

  const bindProfile = useProfile();
  const market = bindProfile?.mailingAddress?.state;

  useMarketRulesQuery({ market }, {
    enabled: !profileRules && !!market,
    onSuccess: (rules) => {
      setProfileRules(
        new ProfileRulesContext(rules)
      );
    },
  });

  return profileRules;
}
