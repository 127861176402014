import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Toggle from '@root/core/src/components/toggle';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function CoverageOptionToggle({
  circleSelectedStylesOverrides,
  containerStyleOverrides,
  description,
  isSelected,
  onToggleChange,
  toggleSelectedStylesOverrides,
  toggleText,
}) {
  return (
    <div css={[styles.container, containerStyleOverrides]}>
      <span css={styles.descriptionContainer}>{description}</span>
      <div css={styles.toggleContainer}>
        <span css={styles.toggleTextContainer}>{toggleText}</span>
        <Toggle
          circleSelectedStyles={circleSelectedStylesOverrides}
          isSelected={isSelected}
          onClick={onToggleChange}
          size={Toggle.Sizes.NORMAL}
          toggleSelectedStyles={toggleSelectedStylesOverrides}
          toggleStyles={styles.toggleStyles}
        />
      </div>
    </div>
  );
}

CoverageOptionToggle.propTypes = {
  circleSelectedStylesOverrides: PropTypes.object,
  containerStyleOverrides: PropTypes.object,
  description: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  toggleSelectedStylesOverrides: PropTypes.object,
  toggleText: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  descriptionContainer: {
    maxWidth: '75%',
  },
  toggleContainer: {
    display: 'inline-flex',
  },
  toggleStyles: {
    alignSelf: 'start',
  },
  toggleTextContainer: {
    marginRight: 3,
  },
});
