import Button from '@root/bind.joinroot.com/src/components/button';
import Driver from '@root/auto-pricing/src/models/driver';
import List, { ListItem } from '@root/bind.joinroot.com/src/components/list';
import PlusIcon from '@root/bind.joinroot.com/src/components/icons/plus-icon';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { PersonIcon } from '@root/profile/src/components/icons/person-icon';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const CoveredDriversList = ({
  drivers,
  onClickAddDriver,
  onClickEditDriver,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageDetailsCard.coveredDriversList');

  if (!drivers) {
    return null;
  }

  return (
    <>
      <List header={strings.title}>
        {drivers.map((driver) => {
          return (
            <ListItem
              editable
              icon={<PersonIcon />}
              key={driver.universalDriverId}
              onClick={() => onClickEditDriver(driver.universalDriverId)}
              subtext={driver.fullName()}
            />
          );
        })}
      </List>
      <Button
        cssOverrides={styles.addItemButton}
        iconComponent={PlusIcon}
        onClick={onClickAddDriver}
      >
        {strings.addItem}
      </Button>
    </>
  );
};

const styles = StyleSheet.create({
  addItemButton: {
    border: `2px solid ${Colors.gray40()}`,
    backgroundColor: 'transparent',
    color: Colors.black(),
    marginTop: -10,
    marginBottom: 15,
  },
});

CoveredDriversList.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.instanceOf(Driver)),
  onClickAddDriver: PropTypes.func.isRequired,
  onClickEditDriver: PropTypes.func.isRequired,
};

export default CoveredDriversList;
