import React from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import {
  AutoscalingSvg,
  SvgProps,
} from '@root/bind.joinroot.com/src/components/icons';

const PlusIcon = ({
  height = 24,
  width = 24,
  iconStyles,
}) => {
  const [{ primaryColor }] = useBranding();

  return (
    <AutoscalingSvg
      css={iconStyles}
      fill={'none'}
      height={height}
      viewBox={'0 0 24 24'}
      width={width}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle
        cx={'12'}
        cy={'12'}
        fill={primaryColor}
        r={'11'}
      />
      <path
        d={'M12 7V17'}
        stroke={'white'}
        strokeWidth={'2'}
      />
      <path
        d={'M17 12L7 12'}
        stroke={'white'}
        strokeWidth={'2'}
      />
    </AutoscalingSvg>
  );
};

PlusIcon.propTypes = SvgProps;

export default PlusIcon;
