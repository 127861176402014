import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import CloseModalButton from '@root/bind.joinroot.com/src/components/close-modal-button';
import EditVehicleManager from '@root/bind.joinroot.com/src/components/bind-edit-vehicle/edit-vehicle-manager';
import ProfileVehicleService from '@root/auto-pricing/src/services/profile-vehicle-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_CONTEXT = 'BIND_EDIT_VEHICLE';
export const ANALYTICS_EVENTS = {
  CLOSE_MODAL_BUTTON: 'CLOSE_MODAL_BUTTON',
};

const BindEditVehicle = ({
  onCancel,
  onUpdateProfileParams,
  profileParams,
  profileRules,
  shouldReturnToPartnerOnBack,
  vehicleCid,
}) => {
  const { trackClick, trackEvent } = useAnalytics(ANALYTICS_CONTEXT);
  const [useBrandingState] = useBranding();
  const brandStyles = useMemo(() => stylesGenerator(useBrandingState), [useBrandingState]);

  const handleModalClose = () => {
    trackClick(ANALYTICS_EVENTS.CLOSE_MODAL_BUTTON);
    onCancel();
  };

  const onProfileChange = useCallback((updatedProfileParams) => {
    onUpdateProfileParams(updatedProfileParams);
  }, [onUpdateProfileParams]);

  const vehicle = useMemo(() => {
    return ProfileVehicleService.getVehicle(profileParams, vehicleCid);
  }, [profileParams, vehicleCid]);

  useEffect(() => {
    if (!vehicle) {
      onCancel();
    }
  }, [onCancel, vehicle]);

  if (!vehicle) {
    return null;
  }

  return (
    <>
      {shouldReturnToPartnerOnBack ?
        <BindView.ExitFlowButton exitToQuote />
        :
        <BindView.BackToProfileButton onClick={onCancel} />
      }
      <BindView>
        <BindView.Card>
          <CloseModalButton onClick={handleModalClose} />
          <EditVehicleManager
            circleSelectedStyles={brandStyles.toggleSelectedCircleStyle}
            cssButtonOverrides={brandStyles.buttonStyle}
            inputStyle={brandStyles.inputStyle}
            linkStyles={brandStyles.linkStyles}
            onProfileChange={onProfileChange}
            profileParams={profileParams}
            profileRulesContext={profileRules}
            toggleSelectedStyles={brandStyles.toggleSelectedStyle}
            trackClick={trackClick}
            trackEvent={trackEvent}
            vehicle={vehicle}
          />
        </BindView.Card>
      </BindView>
    </>
  );
};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  buttonStyle: {
    background: primaryColor,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  toggleSelectedStyle: {
    background: primaryColor,
  },
  toggleSelectedCircleStyle: {
    right: 0,
    borderColor: primaryColor,
  },
  inputStyle: {
    caretColor: primaryColor,
  },
  linkStyles: {
    color: Colors.nearBlack(),
    ':hover': {
      color: primaryColor,
    },
  },
});

BindEditVehicle.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  profileParams: PropTypes.object.isRequired,
  profileRules: PropTypes.object.isRequired,
  shouldReturnToPartnerOnBack: PropTypes.bool,
  vehicleCid: PropTypes.string.isRequired,
};

export default BindEditVehicle;
