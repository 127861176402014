import NetworkService from '@root/core/src/services/network-service';
import QuotesCheckoutError from '@root/quotes/src/models/quotes-checkout-error';
import { NetworkResult } from '@root/core/src/services/network-service';
import { createPolicy, createSupportTicket } from '@root/quotes/src/api/root-server';

export default class QuotesService {
  static SupportTicketParams = {
    BOOMERANG_STUCK: {
      message: 'Automatic Requote - Quote Delayed',
      subject: 'Automatic Requote - Quote Delayed',
      reasons: ['automatic_requote_stuck'],
    },
  }

  static async createPolicy(bindParams) {
    const result = await NetworkService.request(createPolicy, bindParams);
    const { data } = result;

    if (data?.rawResponse?.status === 422) {
      return new NetworkResult(data, QuotesCheckoutError.parseResponseError(data));
    }

    return result;
  }

  static async createSupportTicket(contactSupport) {
    const result = await NetworkService.request(createSupportTicket, contactSupport);
    return result;
  }

  static async createSupportTicketForBoomerangStuck(email) {
    return await QuotesService.createSupportTicket({
      email,
      ...QuotesService.SupportTicketParams.BOOMERANG_STUCK,
    });
  }
}
