import { getQuoteCoverages } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useQuoteCoveragesQuery = ({ ratingRequestId }, overrides = {}) => useQuery({
  enabled: !!ratingRequestId,
  queryFn: async () => await getQuoteCoverages({
    ratingRequestId,
  }),
  queryKey: ['user', 'quote', ratingRequestId, 'coverages'],
  ...overrides,
});

export default useQuoteCoveragesQuery;
