import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

export default function QuotesStickyFooter({ children, footerStyles }) {
  const containerStyles = [styles.container, footerStyles];

  return (
    <div css={containerStyles}>
      {children}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    color: Colors.black(),
    position: 'fixed',
    left: 0,
    height: 90,
    width: '100%',
    bottom: 0,
    background: Colors.white(),
    ...Shadows.top(),
    padding: '16px 20px 25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...Responsive.short({
      paddingBottom: 0,
      height: 70,
    }),
  },
});

QuotesStickyFooter.propTypes = {
  children: PropTypes.node,
  footerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
