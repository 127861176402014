const ANALYTICS_SCOPE = 'SPEAK_TO_AGENT';
const ANALYTICS_EVENT = {
  CLICK_CALL_AGENT: 'CLICK_PHONE_NUMBER',
  CLOSE_MODAL: 'MODAL_CLOSED',
  OPEN_MODAL: 'MODAL_OPENED',
};

export {
  ANALYTICS_EVENT,
  ANALYTICS_SCOPE,
};
