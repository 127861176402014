import ErrorReportService from '@root/core/src/services/error-report-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BraintreeCardIcon, BraintreeHostedField } from '@root/payments/src/components/braintree';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { PAYMENT_FORM_ELEMENTS } from '@root/payments/src/components/en';

const hostedInputStyles = {
  input: {
    'font-size': '16px',
    'line-height': '22px',
    color: Colors.gray50(),
    padding: '27px 0 7px 15px',
  },
};

// The values of the keys are the strings that Braintree uses to identify fields.
const BRAINTREE_FORM_FIELD_IDS = {
  CARD_NUMBER: 'number',
  CVV: 'cvv',
  NAME_ON_CARD: 'cardholderName',
  EXPIRATION_DATE: 'expirationDate',
  ZIP_CODE: 'postalCode',
};

const FIELD_CONTENT_STATE = {
  EMPTY: 'empty',
  INITIAL: 'initial',
  VALUED: 'valued',
  VALID_VALUED: 'valid',
};

const propTypes = {
  /** @type {string} Used to track clicks.  If none is provided, a default will be used. **/
  analyticsContext: PropTypes.string,
  /** @type {object} The wrapper around braintree's API.  At minimum, fieldRegistry and createHostedFields functions are expected. **/
  braintreeService: PropTypes.object.isRequired,
  /** @type {bool} Mask the credit card number when the field does not have focus.  Default false. **/
  maskCardNumberInput: PropTypes.bool,
  /**
   * @type {func} called when there is an error creating the fields. The error object will be the first parameter.
   * The error will already have been logged to the error reporting service.
   **/
  onInitializationError: PropTypes.func.isRequired,
  /**
   * @type {func} called when any field validity event has occurred.
   *    The parameter will be true if all fields are valid, false otherwise.
   *    Note: an empty field does not count as valid as all fields are considered required.
   */
  onValidityChange: PropTypes.func.isRequired,
};

/**
 * A component to render the fields for credit card entry.  This is NOT a whole form.
 * This expects the consumer:
 *  1. to place the fields in a form
 *  2. renders the Submit/Pay button
 * This will notify the consumer via callbacks:
 *  1. Initialization errors that occurred when creating the form.
 *  2. Changes in validity for all fields (i.e. when all fields are valid or not).
 * Note: Validity is decided by Braintree.
 */
export default function PaymentFormElements({
  analyticsContext = 'PAYMENT_FORM_ELEMENTS',
  braintreeService,
  maskCardNumberInput = false,
  onInitializationError,
  onValidityChange,
}) {
  const isMounted = useRef(false);
  const { trackClick } = useAnalytics(analyticsContext);

  const [card, setCard] = useState({
    cardType: null,
    cvvLabel: 'CVV',
  });

  const [fieldValidityState, setFieldValidityState] = useState({
    [BRAINTREE_FORM_FIELD_IDS.CVV]: FIELD_CONTENT_STATE.INITIAL,
    [BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER]: FIELD_CONTENT_STATE.INITIAL,
    [BRAINTREE_FORM_FIELD_IDS.EXPIRATION_DATE]: FIELD_CONTENT_STATE.INITIAL,
    [BRAINTREE_FORM_FIELD_IDS.NAME_ON_CARD]: FIELD_CONTENT_STATE.INITIAL,
    [BRAINTREE_FORM_FIELD_IDS.ZIP_CODE]: FIELD_CONTENT_STATE.INITIAL,
  });
  // Tracking the field with focus, since a field should not have error styling if in focus.
  const [fieldWithFocus, setFieldWithFocus] = useState(null);

  useEffect(() => {
    isMounted.current = true;

    const createHostedFields = async () => {
      if (isMounted.current) {
        const hostedFieldsResult = await braintreeService.createHostedFields(hostedInputStyles);

        if (hostedFieldsResult.isError()) {
          ErrorReportService.reportError({
            caughtAt: 'payment-form-elements',
            error: hostedFieldsResult.error,
            additionalData: hostedFieldsResult.flattenedDetails(),
          });
          onInitializationError(hostedFieldsResult.error);
        }
      }
    };

    createHostedFields();

    return () => {
      isMounted.current = false;
      braintreeService.teardown();
    };
  }, [braintreeService, onInitializationError]);

  const handleCardTypeChange = ({ cardType, cvvLabel }) => {
    setCard({
      cvvLabel,
      cardType,
    });
  };

  const handleGainFocus = (fieldName) => {
    trackClick(fieldName);
    setFieldWithFocus(fieldName);
  };

  const handleFieldBlur = (fieldName, isValid, isEmpty) => {
    let newFieldState;
    if (isEmpty) {
      newFieldState = FIELD_CONTENT_STATE.EMPTY;
    } else if (isValid) {
      newFieldState = FIELD_CONTENT_STATE.VALID_VALUED;
    } else {
      newFieldState = FIELD_CONTENT_STATE.VALUED;
    }
    setFieldWithFocus(null);
    checkSubmissionReadiness(fieldName, newFieldState);
  };

  const handleEmpty = (fieldName) => {
    checkSubmissionReadiness(fieldName, FIELD_CONTENT_STATE.EMPTY);
  };

  const handleNotEmpty = (fieldName, isValid) => {
    handleFieldChange(fieldName)(isValid);
  };

  const handleFieldChange = (fieldName) => {
    return (isValid) => {
      const newFieldState = isValid ? FIELD_CONTENT_STATE.VALID_VALUED : FIELD_CONTENT_STATE.VALUED;
      checkSubmissionReadiness(fieldName, newFieldState);
    };
  };

  /**
   * Determine if all the fields have valid data or not.  Notify consumer if it changed.
   * @param fieldName unique identifier of field changed.  (Expected to be a BRAINTREE_FORM_FIELD_IDS value.)
   * @param newFieldState Value of FIELD_CONTENT_STATE indicating the new state of the given field.
   */
  const checkSubmissionReadiness = (fieldName, newFieldState) => {
    const newFieldValidityState = {
      ...fieldValidityState,
      [fieldName]: newFieldState,
    };
    setFieldValidityState(newFieldValidityState);

    // If the most recently updated field is VALID_VALUED, then we need to check the rest of the fields
    // to determine if all fields now have valid values.
    if (newFieldState === FIELD_CONTENT_STATE.VALID_VALUED) {
      const formSubmissionReady =
        newFieldValidityState[BRAINTREE_FORM_FIELD_IDS.CVV] === FIELD_CONTENT_STATE.VALID_VALUED &&
        newFieldValidityState[BRAINTREE_FORM_FIELD_IDS.EXPIRATION_DATE] === FIELD_CONTENT_STATE.VALID_VALUED &&
        newFieldValidityState[BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER] === FIELD_CONTENT_STATE.VALID_VALUED &&
        newFieldValidityState[BRAINTREE_FORM_FIELD_IDS.ZIP_CODE] === FIELD_CONTENT_STATE.VALID_VALUED &&
        newFieldValidityState[BRAINTREE_FORM_FIELD_IDS.NAME_ON_CARD] === FIELD_CONTENT_STATE.VALID_VALUED;

      onValidityChange(formSubmissionReady);
    } else {
      // The most recently updated field is not valid, inform the consumer.
      onValidityChange(false);
    }
  };

  /**
   * Determine if the field should be stylized for an error.
   * A field that currently has focus will not have an error style, regardless of the content of the field.
   * @param fieldName unique identifier of field to check for errors.  (Expected to be a BRAINTREE_FORM_FIELD_IDS value.)
   * @returns true if the field should show error styling, false if otherwise.
   */
  const displayErrorStyle = (fieldName) => {
    if (fieldName === fieldWithFocus) {
      return false;
    }
    const fieldState = fieldValidityState[fieldName];
    if (fieldState === FIELD_CONTENT_STATE.INITIAL) {
      return false;
    }

    return fieldState !== FIELD_CONTENT_STATE.VALID_VALUED;
  };

  /**
   * Create a Braintree hosted field with the given info.  All events and icons will be defined by this function.
   * @param braintreeFieldId one of the BRAINTREE_FORM_FIELD_IDS constants.
   * @param label user visible text
   * @param additionalProps any additional props to pass to the new field (ex. wrapperStyles)
   * @returns {JSX.Element} the new field
   */
  const createBraintreeField = (braintreeFieldId, label, additionalProps) => {
    const moreProps = { ...additionalProps } || {};
    if (braintreeFieldId === BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER) {
      moreProps.maskInput = maskCardNumberInput;
      moreProps.iconSlot = <BraintreeCardIcon cardType={card.cardType} />;
      moreProps.onCardTypeChange = handleCardTypeChange;
    } else if (braintreeFieldId === BRAINTREE_FORM_FIELD_IDS.CVV) {
      moreProps.iconSlot = <BraintreeCardIcon cardType={'cvv'} />;
    }

    return (
      <BraintreeHostedField
        errorMessage={determineErrorMessage(braintreeFieldId)}
        fieldRegistry={braintreeService.fieldRegistry}
        id={braintreeFieldId}
        isFormError={false}
        label={label}
        onBlur={handleFieldBlur}
        onEmpty={handleEmpty}
        onFocus={handleGainFocus}
        onNotEmpty={handleNotEmpty}
        onValidityChange={handleFieldChange(braintreeFieldId)}
        overrideError={displayErrorStyle(braintreeFieldId)}
        type={braintreeFieldId}
        {...moreProps}
      />
    );
  };

  const determineErrorMessage = (fieldName) => {
    const fieldState = fieldValidityState[fieldName];
    if (fieldState === FIELD_CONTENT_STATE.INITIAL || fieldState === FIELD_CONTENT_STATE.VALID_VALUED) {
      return null;
    } else if (fieldState === FIELD_CONTENT_STATE.EMPTY) {
      switch (fieldName) {
      case BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.CARD_NUMBER_REQUIRED;
      case BRAINTREE_FORM_FIELD_IDS.CVV:
        return `Enter a 3-4 digit ${card.cvvLabel}`;
      case BRAINTREE_FORM_FIELD_IDS.EXPIRATION_DATE:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.EXPIRATION_DATE_REQUIRED;
      case BRAINTREE_FORM_FIELD_IDS.NAME_ON_CARD:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.NAME_ON_CARD_REQUIRED;
      case BRAINTREE_FORM_FIELD_IDS.ZIP_CODE:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.ZIP_CODE_REQUIRED;
      }
    } else {
      switch (fieldName) {
      case BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.CARD_NUMBER_INVALID;
      case BRAINTREE_FORM_FIELD_IDS.CVV:
        return `Enter a 3-4 digit ${card.cvvLabel}`;
      case BRAINTREE_FORM_FIELD_IDS.EXPIRATION_DATE:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.EXPIRATION_DATE_INVALID;
      case BRAINTREE_FORM_FIELD_IDS.ZIP_CODE:
        return PAYMENT_FORM_ELEMENTS.ERROR_MESSAGES.ZIP_CODE_INVALID;
      }
    }
  };

  return (
    <>

      <div css={styles.rowContainer}>
        {createBraintreeField(BRAINTREE_FORM_FIELD_IDS.NAME_ON_CARD, 'Name on card')}
      </div>
      <div css={styles.rowContainer}>
        {createBraintreeField(BRAINTREE_FORM_FIELD_IDS.CARD_NUMBER, 'Card number')}
      </div>
      <div css={styles.rowContainer}>
        <span css={styles.halfWidthLeft}>
          {createBraintreeField(BRAINTREE_FORM_FIELD_IDS.EXPIRATION_DATE, 'Expiration date', { wrapperStyles: styles.dateInput })}
        </span>
        <span css={styles.halfWidthRight}>
          {createBraintreeField(BRAINTREE_FORM_FIELD_IDS.CVV, card.cvvLabel || 'CVV', { wrapperStyles: styles.cvvInput })}
        </span>
      </div>
      <div css={styles.rowContainer}>
        {createBraintreeField(BRAINTREE_FORM_FIELD_IDS.ZIP_CODE, 'Zip code')}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  halfWidthLeft: {
    width: '50%',
    paddingRight: '5px',
  },
  halfWidthRight: {
    width: '50%',
    paddingLeft: '5px',
  },
  dateInput: {
    ...Theme.roundedCorners(),
    marginRight: -1,
  },
  cvvInput: {
    ...Theme.roundedCorners(),
  },
});

PaymentFormElements.propTypes = propTypes;
