//The purpose of this file is to house all string constants relating to the payments module.
export const PAYMENT_FORM_ELEMENTS = {
  ERROR_MESSAGES: {
    CARD_NUMBER_REQUIRED: 'Card number is required',
    CARD_NUMBER_INVALID: 'Enter a valid card number',
    EXPIRATION_DATE_REQUIRED: 'Expiration is required',
    EXPIRATION_DATE_INVALID: 'Enter a valid date',
    NAME_ON_CARD_REQUIRED: 'Cardholder name is required',
    ZIP_CODE_REQUIRED: 'Zip code is required',
    ZIP_CODE_INVALID: 'Enter a valid zip code',
  },
};
