import Button from '@root/core/src/components/button';
import Modal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function WhatIsGaragingAddressModal({ onClose, isModalOpen = false }) {
  const { trackClick } = useAnalytics('WHAT_IS_GARAGING_ADDRESS_MODAL');

  const handleXButtonClick = () => {
    trackClick('X');
    onClose();
  };

  const handleGotItButtonClick = () => {
    trackClick('GOT_IT');
    onClose();
  };

  return (
    <Modal
      isShowing={isModalOpen}
      name={'out-of-market-modal'}
      onCancel={handleXButtonClick}
      responsiveCloseButton={true}
      styleOverrides={styles}
    >
      <h1 css={styles.title}>What’s garaging address?</h1>
      <p css={styles.subHeading}>The garaging address is the physical location where your vehicle is parked most of the time. For most people this matches your home or mailing address. If you primarily park elsewhere, please add that address for your vehicle.</p>
      <Button onClick={handleGotItButtonClick}>
        OK, got it!
      </Button>
    </Modal>
  );
}

WhatIsGaragingAddressModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  outerContent: {
    padding: 20,
    borderRadius: 6,
    ...Responsive.sm({
      padding: 20,
      borderRadius: 6,
    }),
  },
  innerContent: {
    ...Responsive.sm({
      padding: 40,
    }),
  },
  subHeading: {
    ...Theme.cardContent(),
    fontSize: 16,
  },
  title: {
    ...Theme.cardHeading(),
    fontSize: 32,
    marginBottom: 24,
    ...Responsive.sm({
      ...Theme.cardHeading(),
      fontSize: 32,
      marginBottom: 24,
    }),
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
  },
});
