import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import { Redirect, Route } from '@root/core/src/components/route';

const ProtectedRoute = ({
  authorized,
  children,
  redirectUnauthorizedToPath,
  ...restProps
}) => {
  const isAuthorized = useMemo(() => authorized instanceof Function ? authorized() : authorized, [authorized]);

  const rendered = useMemo(() => {
    if (isAuthorized) {
      return <Route {...restProps}>{children}</Route>;
    }
    return <Redirect to={redirectUnauthorizedToPath} />;
  }, [children, isAuthorized, redirectUnauthorizedToPath, restProps]);

  return rendered;
};

ProtectedRoute.propTypes = {
  ...Route.propTypes,
  authorized: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]).isRequired,
  redirectUnauthorizedToPath: PropTypes.string.isRequired,
};

export default ProtectedRoute;
