import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const TERMS_AND_CONDITIONS_URL = `${environment.websiteBaseUrl}/terms-and-conditions/`;

export function TermsAndConditionsLink({ styles }) {
  return (
    <a
      css={styles}
      href={TERMS_AND_CONDITIONS_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Terms & Conditions
    </a>
  );
}

TermsAndConditionsLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
