import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { RootError } from '@root-common/root-errors';

const UnderwritingCompanyContext = React.createContext();

function UnderwritingCompanyProvider({ children, value }) {
  return (
    <UnderwritingCompanyContext.Provider value={value}>
      {children}
    </UnderwritingCompanyContext.Provider>
  );
}

UnderwritingCompanyProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object.isRequired,
};

function useUnderwritingCompanyContext() {
  const context = React.useContext(UnderwritingCompanyContext);
  if (context === undefined) {
    throw new RootError({
      message: 'useUnderwritingCompany must be used within a UnderwritingCompanyProvider',
      name: 'UnderwritingCompanyProviderError',
    });
  }
  return context;
}

export {
  UnderwritingCompanyProvider,
  useUnderwritingCompanyContext,
};

