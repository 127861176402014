import AutoQuoteDisclaimer from '@root/quotes/src/models/auto-quote-disclaimer';
import CaretLoader from '@root/core/src/components/caret-loader';
import IconCell from '@root/core/src/components/icon-cell';
import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useRef, useState } from '@root/vendor/react';
import disclaimerIcon from '@root/quotes/src/assets/disclaimer.svg';
import { RootError } from '@root-common/root-errors';
import { StyleSheet } from '@root/core/src/utils/styles';
import { getAutoQuoteDisclaimer } from '@root/quotes/src/api/root-server';

export default function Disclaimer({
  chevron,
  chevronStyle,
  disclaimer,
  onClick,
}) {
  const [isLoading, setIsLoading] = useState(!disclaimer?.url);
  const [disclaimerState, setDisclaimerState] = useState(disclaimer);
  const [disclaimerIsFetching, setDisclaimerIsFetching] = useState(false);
  const [pollAttempts, setPollAttempts] = useState(0);
  const timeout = useRef();
  const isMounted = useRef(false);
  const sleep = async (ms) => new Promise((resolve) => timeout.current = setTimeout(resolve, ms));

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  useEffect(() => {
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  });

  const fetchDisclaimer = async () => {
    if (isMounted.current) {
      setDisclaimerIsFetching(true);

      const fetchedDisclaimer = await poll();
      setDisclaimerIsFetching(false);

      setDisclaimerState(fetchedDisclaimer);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!disclaimerIsFetching && !disclaimerState?.url) {
      fetchDisclaimer();
    }
  });

  const poll = useCallback(async () => {
    if (isMounted.current) {
      if (pollAttempts > 30) {
        throw new RootError({
          message: 'Disclaimer polling timed out.',
          name: 'DisclaimerError',
        });
      }

      setPollAttempts(pollAttempts + 1);
      const result = await NetworkService.safeRequest(getAutoQuoteDisclaimer, disclaimer.id);

      if (result.data?.autoQuoteDisclaimer?.url) {
        return result.data.autoQuoteDisclaimer;
      } else {
        await sleep(1000);
        return poll();
      }
    }
  }, [disclaimer.id, pollAttempts]);

  const handleDisclaimerClick = async () => {
    if (isLoading) { return; }

    onClick(disclaimerState);

    if (disclaimerState.url) {
      window.open(disclaimerState.url);
    }
  };

  const renderChevron = () => {
    if (isLoading) {
      return (
        <div css={styles.icon}>
          <CaretLoader />
        </div>
      );
    }

    if (chevron) {
      return (
        <div css={styles.chevron}>
          {chevron}
        </div>
      );
    }
  };

  const iconCellStyles = isLoading ? styles.loadingCell : {};

  return (
    <IconCell
      chevron={renderChevron()}
      chevronStyle={chevronStyle}
      cssOverrides={iconCellStyles}
      icon={disclaimerIcon}
      key={disclaimerState.id}
      onClick={handleDisclaimerClick}
      primaryText={disclaimerState.name}
    />
  );
}

Disclaimer.propTypes = {
  chevron: PropTypes.node,
  chevronStyle: PropTypes.string,
  disclaimer: PropTypes.instanceOf(AutoQuoteDisclaimer).isRequired,
  onClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  chevron: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 25,
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(0.5)',
    marginRight: 5,
  },
  loadingCell: {
    cursor: 'default',
  },
});
