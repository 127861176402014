import Carousel from '@root/bind.joinroot.com/src/components/carousel';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

const {
  items: _items, renderItem: _renderItem, ...LoadingCarouselPropTypes // eslint-disable-line no-unused-vars
} = Carousel.propTypes;

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  title: {
    display: 'flex',
    ...Theme.paragraph1({ bold: true }),
    color: primaryColor,
    textTransform: 'uppercase',
  },
  item: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
  },
  header: {
    ...Theme.paragraph1({ bold: true }),
    color: Colors.nearBlack(),
    marginBottom: 0,
  },
  detail: {
    ...Theme.paragraph2(),
    color: Colors.gray50(),
    marginTop: 0,
  },
});

const LoadingCarousel = ({
  autoPaginateDuration = 5500,
  items,
}) => {
  const [branding] = useBranding();
  const brandedStyles = stylesGenerator(branding);

  const renderItem = useCallback((item) => {
    return (
      <div css={brandedStyles.item}>
        <p
          css={brandedStyles.header}
        >
          {item.title}
        </p>
        <p
          css={brandedStyles.detail}
        >
          {item.detail}
        </p>
      </div>
    );
  }, [brandedStyles]);

  return (
    <>
      <p
        css={brandedStyles.title}
      >
        {'Good to know'}
      </p>
      <Carousel
        autoPaginateDuration={autoPaginateDuration}
        draggable={false}
        items={items}
        motionContainerCssOverrides={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
        }}
        renderItem={renderItem}
      />
    </>
  );
};

LoadingCarousel.propTypes = {
  ...LoadingCarouselPropTypes,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
  })),
};

export default LoadingCarousel;
