import Link from '@root/core/src/components/link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function NevermindLink({
  onCancel, className, linkStyles,
}) {
  return (
    <div css={styles.linkContainer}>
      <Link
        className={className}
        cssOverrides={linkStyles}
        onClick={onCancel}
      >
        Never mind
      </Link>
    </div>
  );
}

NevermindLink.propTypes = {
  className: PropTypes.string,
  linkStyles: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  linkContainer: {
    textAlign: 'center',
  },
});
