import { createQuote } from '@root/bind.joinroot.com/src/services/api';
import { useMutation } from '@root/vendor/react-query';

const useCreateQuoteMutation = (options = {}) => {
  return useMutation({
    mutationFn: createQuote,
    mutationKey: ['quote', 'create'],
    ...options,
  });
};

export default useCreateQuoteMutation;
