import NativeMessagingService from '@root/bind.joinroot.com/src/services/native-messaging-service';

function redirect(to) {
  // eslint-disable-next-line root/prevent-use-of-window-location
  window.location.assign(to);
}

function completeFlow(redirectTo) {
  if (NativeMessagingService.broadcastCompletedFlow()) {
    redirect(redirectTo);
  }
}

function exitFlow(redirectTo) {
  if (NativeMessagingService.broadcastExitedFlow()) {
    redirect(redirectTo);
  }
}

const DeepLinkService = {
  completeFlow,
  exitFlow,
  redirect,
};

export default DeepLinkService;
