import Button from '@root/core/src/components/button';
import Modal from '@root/core/src/components/modal';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function PaymentBreakdownModal({
  onClose, isModalOpen = false, paymentBreakdown, closeButtonOverrides = {},
}) {
  const { trackClick } = useAnalytics('FEE_BREAKDOWN_MODAL');

  const handleXButtonClick = () => {
    trackClick('X');
    onClose();
  };

  const handleClose = () => {
    trackClick('CLOSE');
    onClose();
  };

  const lineItemTotalInCents = () => {
    return paymentBreakdown?.reduce((total, lineItem) => {
      return total + (lineItem.amountInCents ?? 0);
    }, 0);
  };

  const renderPaymentBreakdown = () => {
    return paymentBreakdown?.map((item) => {
      if (item && item?.amountInCents > 0) {
        return (
          <div
            css={styles.lineItemSection}
            key={item.name}
          >
            <div css={styles.lineItem}>
              <span css={styles.lineItemTitle}>{item.name}</span>
              <span css={styles.lineItemAmount}>{Money.fromCents(item.amountInCents).formattedDollarsAndCents()}</span>
            </div>
            {item.description &&
              <div css={styles.lineItemDescription}>
                {item.description}
              </div>
            }
          </div>
        );
      }

      return null;
    });
  };

  return (
    <Modal
      isShowing={isModalOpen}
      name={'out-of-market-modal'}
      onCancel={handleXButtonClick}
      responsiveCloseButton={true}
      styleOverrides={styles}
    >
      <h1 css={styles.title}>Auto payment breakdown</h1>
      {renderPaymentBreakdown()}
      <div css={styles.totalSection}>
        <span css={styles.lineItemTitle}>{'Total'}</span>
        <span css={styles.totalAmount}>{Money.fromCents(lineItemTotalInCents()).formattedDollarsAndCents()}</span>
      </div>
      <Button
        css={closeButtonOverrides}
        onClick={handleClose}
      >
        {'Close'}
      </Button>
    </Modal>
  );
}

PaymentBreakdownModal.propTypes = {
  closeButtonOverrides: PropTypes.object,
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  paymentBreakdown: PropTypes.array,
};

const styles = StyleSheet.create({
  outerContent: {
    padding: 20,
    borderRadius: '30px 30px 0 0',
    ...Responsive.sm({
      padding: 20,
      borderRadius: 6,
    }),
  },
  innerContent: {
    ...Responsive.sm({
      padding: 4,
      paddingTop: 0,
    }),
  },
  title: {
    ...Theme.heading1(),
    fontSize: 32,
    marginTop: 26,
    marginBottom: 18,
    ...Responsive.sm({
      ...Theme.heading1(),
      fontSize: 32,
      marginTop: 52,
      marginBottom: 24,
    }),
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  lineItemAmount: {
    ...Theme.paragraph1(),
  },
  lineItemDescription: {
    ...Theme.paragraph2(),
  },
  lineItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineItemSection: {
    marginBottom: 18,
  },
  lineItemTitle: {
    ...Theme.button(),
  },
  totalAmount: {
    ...Theme.heading1(),
  },
  totalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 48,
    ...Responsive.sm({
      marginBottom: 24,
    }),
  },
});
