import ListCardItem from '@root/core/src/components/list-card-item';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ListCard({ children, headerBackgroundColor }) {
  const additionalStyles = headerBackgroundColor ? { backgroundColor: headerBackgroundColor } : {};

  return (
    <ul css={[styles.card, additionalStyles]}>
      {children}
    </ul>
  );
}

export { ListCardItem };

ListCard.propTypes = {
  children: PropTypes.node,
  headerBackgroundColor: PropTypes.string,
};

const styles = StyleSheet.create({
  card: {
    marginBottom: 30,
    borderRadius: 10,
    listStyleType: 'none',
    maxWidth: 550,
    width: '100%',
    padding: 0,
  },
});
