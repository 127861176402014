import AnalyticsContext from '@root/core/src/contexts/analytics/analytics-context';
import AnalyticsService from '@root/core/src/services/analytics-service';
import Driver from '@root/auto-pricing/src/models/driver';
import Input from '@root/core/src/components/input';
import LicenseValidationService from '@root/core/src/services/license-number-validation-service';
import LoaderButton from '@root/core/src/components/loader-button';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import StateSelect from '@root/core/src/components/state-select';
import licenseStroke from '@root/core/src/assets/licenseStroke.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class ProfileAddDriverLicense extends Component {
  static propTypes = {
    chevronStyle: PropTypes.string,
    cssButtonOverrides: PropTypes.object,
    driver: PropTypes.instanceOf(Driver).isRequired,
    inputStyle: PropTypes.object,
    linkStyles: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    optionStyles: PropTypes.object,
    profileParams: PropTypes.instanceOf(ProfileParams),
  }

  static analyticsContext = 'PROFILE_ADD_DRIVER_LICENSE';

  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      driver: props.driver,
      licenseNumberError: false,
      duplicateDriverError: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(ProfileAddDriverLicense.analyticsContext, { eventPrefix: this.context.eventPrefix });
  }

  _handleLicenseNumberChange = (licenseNumber) => {
    const driver = this.state.driver.set('licenseNumber', licenseNumber);
    this.setState({
      driver,
      licenseNumberError: !licenseNumber,
    });
  };

  _handleLicenseStateSelection = (state) => {
    const driver = this.state.driver.set('licenseState', state);
    this.setState({
      driver,
    });
  }

  async _validateLicenseNumber() {
    if (!(this.state.driver.licenseState && this.state.driver.licenseNumber)) {
      return false;
    }

    return await LicenseValidationService.isValidForMarket({
      licenseState: this.state.driver.licenseState,
      licenseNumber: this.state.driver.licenseNumber,
    });
  }

  _isDuplicateDriver() {
    return this.props.profileParams.drivers.some((d) => d.selected && d.matches(this.state.driver));
  }

  _handleSubmit = async () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverLicense.analyticsContext, 'SUBMIT', { eventPrefix: this.context.eventPrefix });
    this.setState({
      isLoading: true,
    });

    const isValidLicenseNumber = await this._validateLicenseNumber();
    const isDuplicateDriver = this._isDuplicateDriver();

    this.setState({
      licenseNumberError: !isValidLicenseNumber,
      duplicateDriverError: isDuplicateDriver,
      isLoading: false,
    }, () => {
      if (!this.state.licenseNumberError && !this.state.duplicateDriverError) {
        this.props.onDone(this.state.driver);
      }
    });
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(ProfileAddDriverLicense.analyticsContext, 'CANCEL', { eventPrefix: this.context.eventPrefix });
    this.props.onCancel();
  }

  _getLicenseNumberError() {
    if (this.state.licenseNumberError) {
      if (!this.state.driver.licenseState) {
        return 'Please enter a state.';
      }
      return 'Please enter a valid license number.';
    }

    if (this.state.duplicateDriverError) {
      return 'You already added that driver.';
    }
  }

  render() {
    return (
      <div css={styles.wrapper}>
        <ProfileHeading icon={licenseStroke}>
          What’s their driver’s license info?
        </ProfileHeading>
        <ProfileForm onSubmit={this._handleSubmit}>
          <StateSelect
            dropdownIndicator={this.props.chevronStyle}
            onChange={this._handleLicenseStateSelection}
            optionStyles={this.props.optionStyles}
            selectedValue={this.state.driver.licenseState || ''}
          />
          <div css={styles.licenseNumber}>
            <Input
              errorLabel={this._getLicenseNumberError()}
              inputStyle={this.props.inputStyle}
              label={'License number'}
              onChange={this._handleLicenseNumberChange}
              value={this.state.driver.licenseNumber || ''}
            />
          </div>
          <LoaderButton
            isLoading={this.state.isLoading}
            loadingText={'Validating license...'}
            styles={[styles.button, this.props.cssButtonOverrides]}
          >
            Continue
          </LoaderButton>
          <NevermindLink
            linkStyles={this.props.linkStyles}
            onCancel={this._handleCancel}
          />
        </ProfileForm>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  licenseNumber: {
    marginTop: 10,
  },
  wrapper: {
    width: '100%',
  },
});
