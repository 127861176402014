import DriverVehicleAssignment from '@root/driver-vehicle-assignment/src/scenes/driver-vehicle-assignment';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import VehicleDriverAssignment from '@root/driver-vehicle-assignment/src/scenes/vehicle-driver-assignment';
import { Route, Switch, useHistory } from '@root/core/src/components/route';

const routeDefinitions = [
  {
    component: DriverVehicleAssignment,
    routes: [
      '/:module/driver-vehicle-assignment/driver-vehicle',
      '/:module/:subModule/driver-vehicle-assignment/driver-vehicle',
    ],
  },
  {
    component: VehicleDriverAssignment,
    routes: [
      '/:module/driver-vehicle-assignment/vehicle-driver',
      '/:module/:subModule/driver-vehicle-assignment/vehicle-driver',
    ],
  },
];

export default function DriverVehicleAssignmentRouter({ location, passProps }) {
  const history = useHistory();
  const onBack = () => {
    history.goBack();
  };

  const sceneRenderer = (Scene) => ({ match }) => (
    <Scene
      {...match.params}
      {...passProps}
      onBack={onBack}
    />
  );

  return (
    <>
      <Switch location={location}>
        {routeDefinitions.map(({ component, routes }) => (
          <Route
            exact={true}
            key={routes[0]}
            path={routes}
            render={sceneRenderer(component)}
          />
        ))}
      </Switch>
    </>
  );
}

DriverVehicleAssignmentRouter.propTypes = {
  location: PropTypes.object.isRequired,
  passProps: PropTypes.object.isRequired,
};

