import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import chevronOrange from '@root/core/src/assets/chevron-orange.svg';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function IconCell({
  chevron,
  chevronStyle = chevronOrange,
  cssOverrides,
  error,
  icon,
  leftIconContainerStyles,
  onClick,
  primaryText,
  primaryTextStyles,
  rightContainerStyles,
  secondaryText,
  secondaryTextStyles,
  textContainerStyles,
}) {
  const containerStyle = [styles.container, onClick ? styles.clickableContainer : null];
  const secondaryTextComponent = secondaryText && (
    <span
      css={[styles.cellText, secondaryTextStyles]}
      data-testid={'icon-cell-secondary'}
    >
      {secondaryText}
    </span>
  );

  const renderChevron = () => {
    if (!onClick) {
      return null;
    }

    if (chevron) {
      return chevron;
    }

    return (
      <div css={styles.chevron}>
        <img
          alt={'icon cell chevron'}
          src={chevronStyle}
        />
      </div>
    );
  };

  return (
    <>
      <div
        css={[containerStyle, cssOverrides]}
        onClick={onClick}
      >
        {icon &&
        <div css={styles.leftIconContainer}>
          <div
            css={[styles.leftIconGradientContainer, leftIconContainerStyles]}
            data-testid={'icon-cell-left-container'}
          >
            <img src={icon} />
          </div>
        </div>
        }
        <div css={[styles.rightContainer, rightContainerStyles]}>
          <div css={[styles.textContainer, textContainerStyles]}>
            <span
              css={[styles.cellText, primaryTextStyles]}
              data-testid={'icon-cell-primary'}
            >
              {primaryText}
            </span>
            {secondaryTextComponent}
          </div>
          {renderChevron()}
        </div>
      </div>
      {error}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    userSelect: 'none',
    marginBottom: 10,
    alignItems: 'stretch',
    minHeight: 70,
    background: Colors.white(),
  },
  clickableContainer: {
    cursor: 'pointer',
  },
  leftIconContainer: {
    width: 50,
  },
  leftIconGradientContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    background: Colors.white(),
    borderTop: `1px solid ${Colors.gray20()}`,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 300ms ease-out',
  },
  rightContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    borderTop: `1px solid ${Colors.gray20()}`,
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 15,
    marginTop: 13,
    marginBottom: 13,
    width: '80%',
  },
  chevron: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 25,
    flexWrap: 'wrap',
  },
  cellText: {
    ...Theme.input(),
    color: Colors.black(),
  },
});

IconCell.propTypes = {
  chevron: PropTypes.node,
  chevronStyle: PropTypes.string,
  cssOverrides: PropTypes.object,
  error: PropTypes.node,
  icon: PropTypes.string,
  leftIconContainerStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onClick: PropTypes.func,
  primaryText: PropTypes.string.isRequired,
  primaryTextStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  rightContainerStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  secondaryText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  secondaryTextStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  textContainerStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
