import BrandingContextFactory from '@root/bind.joinroot.com/test/support/factories/branding-context-factory';
import PropTypes from '@root/vendor/prop-types';
import { RootError } from '@root-common/root-errors';
import { createContext, useContext } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'branding';

const BrandingContext = createContext();
const SetBrandingContext = createContext();

const BrandingProvider = ({ children, value: overrideValue = null }) => {
  const [branding, setBranding] = usePersistedState({
    initialValue: overrideValue || BrandingContextFactory.carvanaValues(),
    shouldSkipLoadingFromLocalStorage: !!overrideValue,
    storageKey: LOCAL_STORAGE_KEY,
  });

  return (
    <BrandingContext.Provider value={branding}>
      <SetBrandingContext.Provider value={setBranding}>
        {children}
      </SetBrandingContext.Provider>
    </BrandingContext.Provider>
  );
};

BrandingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

const useBrandingState = () => {
  const context = useContext(BrandingContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useBranding must be used within BrandingProvider',
      name: 'BrandingError',
    });
  }

  return context;
};

const BrandingConsumer = ({ children }) => {
  const branding = useBrandingState();
  return children(branding);
};

BrandingConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

const useSetBrandingState = () => {
  const context = useContext(SetBrandingContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useBranding must be used within BrandingProvider',
      name: 'BrandingError',
    });
  }

  return context;
};

const useBranding = () => {
  return [useBrandingState(), useSetBrandingState()];
};

export {
  BrandingConsumer,
  BrandingProvider,
};

export default useBranding;
