import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Toggle from '@root/core/src/components/toggle';
import Vehicle from '@root/core/src/models/vehicle';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default class CoverageVehicleToggle extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onToggle: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    toggleSelectedColor: PropTypes.string,
    vehicle: PropTypes.instanceOf(Vehicle).isRequired,
  }

  static defaultProps = {
    selected: false,
    disabled: false,
  }

  _handleToggle = () => {
    const {
      onToggle, vehicle, selected,
    } = this.props;

    onToggle(vehicle, !selected);
  }

  render() {
    const {
      vehicle, selected, disabled, toggleSelectedColor,
    } = this.props;
    const selectedText = selected ? 'Included' : 'Not included';

    const brandedStyle = styles(toggleSelectedColor || Colors.rootOrange());

    return (
      <div css={brandedStyle.wrapper}>
        <div css={brandedStyle.description}>{vehicle.yearMakeAndModelOrVin()}</div>
        <div css={brandedStyle.toggleWrapper}>
          <div css={brandedStyle.selectedText}>{selectedText}</div>
          <Toggle
            circleSelectedStyles={brandedStyle.circleSelected}
            disabled={disabled}
            isSelected={selected}
            onClick={this._handleToggle}
            size={Toggle.Sizes.SMALL}
            toggleSelectedStyles={brandedStyle.toggleSelected}
          />
        </div>
      </div>
    );
  }
}

const styles = (toggleSelectedColor) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    color: Colors.black(),
  },
  toggleWrapper: {
    display: 'flex',
  },
  selectedText: {
    marginRight: 10,
  },
  toggleSelected: {
    background: toggleSelectedColor,
  },
  circleSelected: {
    border: `1px solid ${toggleSelectedColor}`,
  },
});
