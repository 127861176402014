import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageAmountLabel from '@root/auto-pricing/src/utils/coverage-amount-label';
import Money from '@root/core/src/models/money';
import camelCase from '@root/vendor/lodash/camelCase';
import { filterCoverages, sortCoverageBySymbol } from '@root/vendor/quoting-rules';
import { fromQuotingData, toQuotingData } from '@root/quotes/src/models/quoting-data-adapter';

export const ComparisonOrder = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export const SublabelStrings = {
  ADDS: 'Adds',
  SAVES: 'Saves',
  SELECTED: 'Selected',
};

export default class QuoteCoveragesContext {
  static build({
    coverages = {},
    defaultCoverages = {},
    vehicleCoverageSymbols = [],
    nonVehicleCoverageSymbols = [],
  } = {}) {
    const builtCoverages = QuoteCoveragesContext.buildCoverages(coverages);
    const builtVehicleCoverageSymbols = QuoteCoveragesContext.buildCoverageSymbols(vehicleCoverageSymbols);
    const builtNonVehicleCoverageSymbols = QuoteCoveragesContext.buildCoverageSymbols(nonVehicleCoverageSymbols);

    return Object.assign(
      new QuoteCoveragesContext(),
      {
        coverages: builtCoverages,
        vehicleCoverageSymbols: builtVehicleCoverageSymbols,
        nonVehicleCoverageSymbols: builtNonVehicleCoverageSymbols,
        defaultCoverages,
      }
    );
  }

  static buildCoverages(coverages) {
    const builtCoverages = {};

    if (!coverages) {
      return builtCoverages;
    }

    Object.entries(coverages).forEach(([symbol, coverageArray]) => {
      builtCoverages[symbol] = coverageArray.map((coverage) => {
        return Coverage.build(coverage);
      });
    });

    return builtCoverages;
  }

  static buildCoverageSymbols(coverageSymbols) {
    return coverageSymbols.map((symbol) => camelCase(symbol));
  }

  getSortedCoverages() {
    const sortedCoverages = {};

    Object.entries(this.coverages).forEach(([symbol, coverages]) => {
      sortedCoverages[symbol] = sortCoverageBySymbol(symbol, coverages);
    });

    return sortedCoverages;
  }

  getCoverageSelections({
    billingCycle,
    customQuote,
    profileVins,
    quotingRules,
    market,
    monthlyPremiumRatio = 1,
  }) {
    const quotingData = toQuotingData({
      ...customQuote,
      availableVins: profileVins,
    });

    // Errors are returned but currently ignored. These will be reported
    // to sentry once all the known issues have been resolved
    const [result] = filterCoverages({
      quotingRules: quotingRules.rules,
      quotingData,
    });

    const { rateCoverages: sortedCoverages } = fromQuotingData(result);

    const coverageSelections = {};
    Object.keys(sortedCoverages).forEach((key) => {
      const coverageArray = sortedCoverages[key];
      const builtCoverages = [];

      coverageArray.forEach((coverage) => {
        if (builtCoverages.some((c) => c.value === coverage.id)) {
          return;
        }

        const option = this._buildCoverageSelection(coverage, customQuote, profileVins, billingCycle, market, monthlyPremiumRatio);

        if (!option.label || option.label === CoverageAmountLabel.DECLINED_LABEL) {
          return;
        }

        builtCoverages.push(option);
      });

      coverageSelections[key] = builtCoverages;
    });

    return coverageSelections;
  }

  getCoverageByIdAndSymbol(id, symbol) {
    return this.coverages[symbol]?.find((coverage) => coverage.id === id);
  }

  getCoverageByIdVinSymbol(id, vin, symbol) {
    return this.coverages[symbol]?.find((coverage) => coverage.id === id && coverage.vin === vin);
  }

  _buildCoverageSelection(coverage, customQuote, profileVins, billingCycle, market, monthlyPremiumRatio) {
    return {
      label: CoverageAmountLabel.getCoverageAmountLabelForCoverage(coverage, market),
      subLabel: this._getCoverageSublabel(coverage, customQuote, profileVins, billingCycle, monthlyPremiumRatio),
      value: coverage.id,
      coverageOptions: coverage.coverageOptions,
      deductible: coverage.deductible,
      perPerson: coverage.perPerson,
      perOccurrence: coverage.perOccurrence,
      perDay: coverage.perDay,
    };
  }

  _getCoverageSublabel(coverage, customQuote, profileVins, billingCycle, monthlyPremiumRatio) {
    const fullCoverageIndicator = customQuote.getFullCoverageIndicator(profileVins);
    const selectedCoverage = customQuote.getCoverage(coverage.symbol);

    if (selectedCoverage?.id === coverage.id) {
      return SublabelStrings.SELECTED;
    }

    let premiumDifference;

    if (this.vehicleCoverageSymbols.includes(coverage.symbol)) {
      premiumDifference = this._getVehicleCoveragePremiumDifference(coverage, selectedCoverage, billingCycle, fullCoverageIndicator, profileVins, monthlyPremiumRatio);
    } else {
      premiumDifference = this._getCoveragePremiumDifference(coverage, selectedCoverage, billingCycle, fullCoverageIndicator, monthlyPremiumRatio);
    }

    const prefix = premiumDifference > 0 ? SublabelStrings.SAVES : SublabelStrings.ADDS;
    const dollarString = new Money(Math.abs(premiumDifference)).formattedDollarsCeil();

    return `${prefix} ${dollarString}`;
  }

  _getCoveragePremiumDifference(coverage, selectedCoverage, billingCycle, fullCoverageIndicator, monthlyPremiumRatio) {
    if (billingCycle === BillingCycle.MONTHLY) {
      const coverageMonthlyPremiumCents = Math.floor(monthlyPremiumRatio * Math.round(coverage.rawApproximatePremiumCents[fullCoverageIndicator] / 6.0));

      const rawApproximatePremiumsCents = selectedCoverage?.rawApproximatePremiumCents;

      const selectedMonthlyPremiumCents = rawApproximatePremiumsCents ? Math.floor(monthlyPremiumRatio * Math.round(rawApproximatePremiumsCents[fullCoverageIndicator] / 6.0)) : 0;
      return parseInt(selectedMonthlyPremiumCents - coverageMonthlyPremiumCents);
    }

    const coveragePremiumCents = coverage.approximatePremiumCents[fullCoverageIndicator];

    const approximatePremiumCents = selectedCoverage?.approximatePremiumCents;

    const selectedPremiumCents = approximatePremiumCents ? approximatePremiumCents[fullCoverageIndicator] : 0;
    return parseInt(selectedPremiumCents - coveragePremiumCents);
  }

  _getVehicleCoveragePremiumDifference(coverage, selectedCoverage, billingCycle, fullCoverageIndicator, profileVins, monthlyPremiumRatio) {
    let totalFullTermPremiumCents = 0;
    let rawTotalFullTermPremiumCents = 0;
    let totalSelectedFullTermPremiumCents = 0;
    let rawTotalSelectedFullTermPremiumCents = 0;

    if (selectedCoverage) {
      selectedCoverage.coveredVins.forEach((vin) => {
        const vinCoverage = this.getCoverageByIdVinSymbol(coverage.id, vin, coverage.symbol);
        const vinSelectedCoverage = this.getCoverageByIdVinSymbol(selectedCoverage?.id, vin, coverage.symbol);

        totalFullTermPremiumCents += vinCoverage ? vinCoverage.approximatePremiumCents[fullCoverageIndicator] : 0;
        rawTotalFullTermPremiumCents += vinCoverage ? vinCoverage.rawApproximatePremiumCents[fullCoverageIndicator] : 0;
        totalSelectedFullTermPremiumCents += vinSelectedCoverage ? vinSelectedCoverage.approximatePremiumCents[fullCoverageIndicator] : 0;
        rawTotalSelectedFullTermPremiumCents += vinSelectedCoverage ? vinSelectedCoverage.rawApproximatePremiumCents[fullCoverageIndicator] : 0;
      });
    } else {
      profileVins.forEach((vin) => {
        const vinCoverage = this.getCoverageByIdVinSymbol(coverage.id, vin, coverage.symbol);
        totalFullTermPremiumCents += vinCoverage ? vinCoverage.approximatePremiumCents[fullCoverageIndicator] : 0;
        rawTotalFullTermPremiumCents += vinCoverage ? vinCoverage.rawApproximatePremiumCents[fullCoverageIndicator] : 0;
      });
    }

    if (billingCycle === BillingCycle.MONTHLY) {
      const coverageMonthlyPremiumCents = Math.floor(monthlyPremiumRatio * Math.round(rawTotalFullTermPremiumCents / 6.0));
      const coverageSelectedMonthlyPremiumCents = Math.floor(monthlyPremiumRatio * Math.round(rawTotalSelectedFullTermPremiumCents / 6.0));

      return parseInt(coverageSelectedMonthlyPremiumCents - coverageMonthlyPremiumCents);
    }
    return parseInt(totalSelectedFullTermPremiumCents - totalFullTermPremiumCents);
  }
}
