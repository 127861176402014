import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const PRIVACY_POLICY_URL = `${environment.websiteBaseUrl}/privacy-policy/`;

export function PrivacyPolicyLink({ styles }) {
  return (
    <a
      css={styles}
      href={PRIVACY_POLICY_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Privacy Policy
    </a>
  );
}

PrivacyPolicyLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
