export default class Homeowner {
  static RENT = 'rent';
  static OWN = 'own';

  static Options = [
    {
      label: 'Rent',
      value: Homeowner.RENT,
    },
    {
      label: 'Own',
      value: Homeowner.OWN,
    },
  ];

  static isHomeowner(value) {
    return value === Homeowner.OWN || value === true;
  }
}
