import AuthService from '@root/core/src/services/auth-service';
import Homeowner from '@root/core/src/models/homeowner';
import environment from '@root/core/src/utils/environment';

export default class MediaAlphaScriptService {
  static DESKTOP_DNQ = 'ou12uf1QKlCemdHaw5-TwZCRlpA60g';
  static DESKTOP_NON_SERVED = 'o_CE3sAyO95OCUq13BC2acYa7VGTqQ';
  static MOBILE_DNQ = 'BRrlzOmtCS-7ddh2whEna0O6lsValg';
  static MOBILE_NON_SERVED = 'eU5p4tWeTBFXwx4mB64ZoKV5j98iGw';
  static THE_GENERAL = 'DqFQUYlZA_oknzs098qKzYKs28hEjA';
  static ROOT_APP_DNQ = 'g6nmWIurmzb053ant-BPn40tCAwV5g';
  static ROOT_APP_NON_SERVED = 'VPe3X1Eg2XZdHeQxEcgZBJc82JHDCQ';

  static createScript(
    placement_id,
    elementId,
    data = {
      zip: 'auto',
    },
  ) {
    const existingScript = document.getElementById('media-alpha-script');

    if (existingScript) { return; }

    const script = document.createElement('script');
    script.id = 'media-alpha-script';
    script.src = `${environment.mediaAlphaIntegrationSrc}`;
    script.onload = () => {
      window.MediaAlphaExchange = {
        data,
        placement_id,
        type: 'ad_unit',
        sub_1: AuthService.getCurrentUserContext().userId,
        ua_class: 'auto',
        version: 17,
      };

      window.MediaAlphaExchange__load(elementId);
    };

    document.head.appendChild(script);
  }

  static getNonServedPlacementId(isMobileBrowser) {
    return isMobileBrowser ? this.MOBILE_NON_SERVED : this.DESKTOP_NON_SERVED;
  }

  static getDNQPlacementId(isMobileBrowser) {
    return isMobileBrowser ? this.MOBILE_DNQ : this.DESKTOP_DNQ;
  }

  static cleanupScript() {
    const script = document.getElementById('media-alpha-script');

    if (script) {
      document.head.removeChild(script);
    }
  }

  static buildRequestFromProfile(profile) {
    if (!profile) {
      return {
        data: {
          zip: 'auto',
        },
      };
    }

    const pniDriver = profile.drivers.find((d) => d.pni);

    return {
      contact: `${pniDriver.firstName} ${pniDriver.lastName}`,
      zip: profile.zip ?? profile.mailingAddress?.zip,
      home_ownership: Homeowner.isHomeowner(profile.homeowner) ? 1 : 0,
      vehicles: this.buildVehicles(profile.vehicles),
      drivers: this.buildDrivers(profile.drivers),
    };
  }

  static buildVehicles(vehicles = []) {
    return vehicles.map((v) => {
      return {
        year: v.year,
        make: v.make,
        model: v.model,
        primary_purpose: v.primaryUsage,
        annual_mileage: v.annualizedMileage,
        commute_days_per_week: v.weeklyCommuteDays,
      };
    });
  }

  static buildDrivers(drivers = []) {
    return drivers.map((d) => {
      return {
        driver: `${d.firstName} ${d.lastName}`,
        birth_date: d.dob.replace(/\*\*/g, '01'),
        first_licensed: d.ageWhenLicensed,
        license_state: d.licenseState,
      };
    });
  }
}
