import Driver from '@root/auto-pricing/src/models/driver';
import ProfileAddDriverAgeWhenLicensed from '@root/profile/src/scenes/profile-add-driver-age-licensed';
import ProfileAddDriverBirthday from '@root/profile/src/scenes/profile-add-driver-birthday';
import ProfileAddDriverInexperiencedOperator from '@root/profile/src/scenes/profile-add-driver-inexperienced-operator';
import ProfileAddDriverLicense from '@root/profile/src/scenes/profile-add-driver-license';
import ProfileAddDriverMaritalStatus from '@root/profile/src/scenes/profile-add-driver-marital-status';
import ProfileAddDriverName from '@root/profile/src/scenes/profile-add-driver-name';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileModal from '@root/profile/src/components/profile-modal';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import SubflowAnimator from '@root/profile/src/components/subflow-animator';
import { StyleSheet } from '@root/core/src/utils/styles';

export default class AddDriverManager extends Component {
  static propTypes = {
    disableFixedWidths: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
  }

  static addDriverSteps = [
    ProfileAddDriverName,
    ProfileAddDriverLicense,
    ProfileAddDriverBirthday,
    ProfileAddDriverMaritalStatus,
    ProfileAddDriverAgeWhenLicensed,
    ProfileAddDriverInexperiencedOperator,
  ];

  constructor(props) {
    super(props);

    this.state = {
      driver: Driver.buildFromData({
        selected: true,
      }),
      currentStep: AddDriverManager.addDriverSteps[0],
    };
  }

  _getNextScene() {
    const nextStepIndex = AddDriverManager.addDriverSteps.findIndex((step) => step === this.state.currentStep) + 1;
    if (nextStepIndex >= AddDriverManager.addDriverSteps.length) {
      const profileParams = ProfileDriverService.addDriver(this.props.profileParams, this.state.driver);
      this.props.onComplete(profileParams);
    } else {
      this.setState({
        currentStep: AddDriverManager.addDriverSteps[nextStepIndex],
      });
    }
  }

  _handleStepFinished = (driver) => {
    this.setState({
      driver,
    }, () => this._getNextScene());
  }

  render() {
    const CurrentStep = this.state.currentStep;
    return (
      <ProfileModal disableFixedWidths={this.props.disableFixedWidths}>
        <div css={styles.content}>
          <SubflowAnimator
            animatorKey={CurrentStep}
          >
            <CurrentStep
              driver={this.state.driver}
              onCancel={this.props.onCancel}
              onDone={this._handleStepFinished}
              profileParams={this.props.profileParams}
              profileRulesContext={this.props.profileRulesContext}
            />
          </SubflowAnimator>
        </div>
      </ProfileModal>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    minHeight: 510,
  },
});
