import {
  BackendOptions,
  CoverageLimitTypes,
  CoverageOptionChoice,
  CoverageOptions,
  CoverageSymbols,
  FixupSequence,
  FixupTypes,
  QuotingRulesKeys,
  RuleTypes,
} from './constants';

const intersection = (values1, values2) => values1.reduce((shared, vin) => {
  if (values2.includes(vin)) {
    shared.push(vin);
  }

  return shared;
}, []);

const any = () => true;

const equals = (limitType) => (parentCoverage, dependentCoverage) =>
  dependentCoverage.declined || (dependentCoverage[limitType] || 0) === (parentCoverage[limitType] || 0);

const greaterThanOrEqual = (limitType) => (parentCoverage, dependentCoverage) =>
  (dependentCoverage[limitType] || 0) >= (parentCoverage[limitType] || 0);

const greaterThan = (limitType) => (parentCoverage, dependentCoverage) =>
  (dependentCoverage[limitType] || 0) > (parentCoverage[limitType] || 0);

const lessThanOrEqual = (limitType) => (parentCoverage, dependentCoverage) =>
  (dependentCoverage[limitType] || 0) <= (parentCoverage[limitType] || 0);

const requires = (parentCoverage, dependentCoverage) =>
  dependentCoverage.declined || !dependentCoverage.declined && !parentCoverage.declined;

const interdependent = (firstCoverage, secondCoverage) =>
  firstCoverage.declined === secondCoverage.declined;

const excludes = (firstCoverage, secondCoverage) =>
  firstCoverage.declined !== secondCoverage.declined || firstCoverage.declined && secondCoverage.declined;

const partialCollCompFull = (coll, comp, availableVins) => {
  if (coll.declined && comp.declined || !coll.coveredVins.length && !comp.coveredVins.length) {
    return true;
  }

  const fullCoverageVins = intersection(coll.coveredVins, comp.coveredVins);
  return intersection(fullCoverageVins, availableVins).length === availableVins.length
    && !coll.declined && !comp.declined;
};

const umpdColl = (coll, umpd) => {
  if (coll.declined || !coll.coveredVins.length) {
    return true;
  }

  return umpd.declined;
};

const umpdCollPerVehicle = (coll, availableVins, umpd) => {
  const allFullCoverage = intersection(coll.coveredVins, availableVins).length === availableVins.length;
  if (allFullCoverage) {
    return umpd.declined;
  }

  return !intersection(coll.coveredVins, umpd.coveredVins).length;
};

const tortSelectionPip = (limitType) => (dependentCoverage, { tortSelection, sortedCoverages }) => {
  if (tortSelection !== BackendOptions.FULL) {
    return true;
  }

  if (dependentCoverage.declined) {
    return false;
  }

  return dependentCoverage[limitType] === Math.min(...sortedCoverages[dependentCoverage.symbol]
    .filter((c) => !c.declined)
    .map((c) => c[limitType])
  );
};

const dependentHighestCoverage = (limitType) => (parentCoverage, dependentCoverage, { sortedCoverages }) => {
  const selectedHighestDependentLimit = dependentCoverage[limitType] === Math.max(...sortedCoverages[dependentCoverage.symbol].map((sym) => sym[limitType]));
  const selectedHightestParentLimit = parentCoverage[limitType] === Math.max(...sortedCoverages[parentCoverage.symbol].map((sym) => sym[limitType]));

  return !selectedHighestDependentLimit || selectedHighestDependentLimit && selectedHightestParentLimit;
};

const lessThanOrEqualUnlessLowestCoverage = (limitType) => (parentCoverage, dependentCoverage, { sortedCoverages }) => {
  if (dependentCoverage.declined) { return true; }
  const isSelectedLowestDependentLimit = dependentCoverage[limitType] === Math.min(...sortedCoverages[dependentCoverage.symbol].filter((c) => !c.declined).map((sym) => sym[limitType]));
  const isSelectedLowestParentLimit = parentCoverage[limitType] === Math.min(...sortedCoverages[parentCoverage.symbol].filter((c) => !c.declined).map((sym) => sym[limitType]));

  return isSelectedLowestDependentLimit && isSelectedLowestParentLimit || lessThanOrEqual(limitType)(parentCoverage, dependentCoverage);
};

function ascending() {
  const limitTypes = arguments;

  return (firstCoverage, secondCoverage) => {
    for (const limitType of limitTypes) {
      const result = (firstCoverage[limitType] || 0) - (secondCoverage[limitType] || 0);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}

function descending() {
  const limitTypes = arguments;

  return (firstCoverage, secondCoverage) => {
    for (const limitType of limitTypes) {
      const result = (secondCoverage[limitType] || 0) - (firstCoverage[limitType] || 0);
      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}

const fixupDefinitions = {
  [RuleTypes.ANY_COLL_OR_COMP_REQUIRES_FULL]: {
    fixupType: FixupTypes.FIXUP_ANY_COLL_COMP_REQUIRES_FULL,
    coverages: [
      CoverageSymbols.COLLISION,
      CoverageSymbols.COMPREHENSIVE,
    ],
    validate: partialCollCompFull,
    filteringValidate: any,
    sortOrderFactory: descending(CoverageLimitTypes.DEDUCTIBLE),
  },
  [RuleTypes.CDW_MUST_EQUAL_COLL_OR_BE_DECLINED]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.COLLISION],
    dependentSymbol: CoverageSymbols.CDW,
    validate: (coll, cdw, options) => {
      const cdwAcceptedDeclined = options.cdwAcceptedDeclined;
      if (cdwAcceptedDeclined === BackendOptions.DECLINED) {
        return cdw.declined;
      }
      if (coll.declined || !coll.coveredVins.length) {
        return cdw.declined;
      }

      return (cdw.deductible || 0) === (coll.deductible || 0);
    },
    sortOrderFactory: descending(CoverageLimitTypes.DEDUCTIBLE),
  },
  [RuleTypes.COLL_EXCLUDES_UMPD]: {
    fixupType: FixupTypes.FIXUP_UMPD_WITH_COLL,
    parentSymbols: [CoverageSymbols.COLLISION],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: umpdColl,
    alternateValidate: umpdCollPerVehicle,
    sortOrderFactory: ascending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.DECLINE_EXTERNAL_PHYSICAL_DAMAGE]: {
    fixupType: FixupTypes.FIXUP_PHYSICAL_DAMAGE_TO_DECLINED,
  },
  [RuleTypes.FULL_TORT_REQUIRES_ONLY_GUEST_PIP]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [],
    dependentSymbol: CoverageSymbols.PERSONAL_INJURY_PROTECTION,
    validate: tortSelectionPip(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.HIGHEST_UMPD_REQUIRES_HIGHEST_PD]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.PROPERTY_DAMAGE],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: dependentHighestCoverage(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.HIGHEST_UM_REQUIRES_HIGHEST_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST,
    validate: dependentHighestCoverage(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.PIP_AND_MED_MUTUALLY_EXCLUSIVE]: {
    fixupType: FixupTypes.FIXUP_BOTH,
    coverages: [
      CoverageSymbols.PERSONAL_INJURY_PROTECTION,
      CoverageSymbols.MEDICAL_PAY,
    ],
    validate: excludes,
    filteringValidate: any,
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.RENTAL_REQUIRES_COLL_AND_COMP]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.COLLISION, CoverageSymbols.COMPREHENSIVE],
    dependentSymbol: CoverageSymbols.RENTAL,
    validate: (coll, comp, rental) => requires(coll, rental) && requires(comp, rental),
    sortOrderFactory: descending(CoverageLimitTypes.PER_DAY),
  },
  [RuleTypes.ROADSIDE_MUST_EQUAL_COLL_OR_BE_DECLINED]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.COLLISION],
    dependentSymbol: CoverageSymbols.ROADSIDE,
    validate: (coll, roadside, options) => {
      const roadsideAcceptedDeclined = options.roadsideAcceptedDeclined;
      if (roadsideAcceptedDeclined === BackendOptions.DECLINED) {
        return roadside.declined;
      }
      if (coll.declined || !coll.coveredVins.length) {
        return roadside.deductible === 0;
      }
      return (roadside.deductible || 0) === (coll.deductible || 0);
    },
    sortOrderFactory: descending(CoverageLimitTypes.DEDUCTIBLE),
  },
  [RuleTypes.UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.PROPERTY_DAMAGE],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UIMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_UMPD]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UIM_AND_UIMPD_DEPENDENT]: {
    fixupType: FixupTypes.FIXUP_BOTH,
    coverages: [
      CoverageSymbols.UNDERINSURED_MOTORIST,
      CoverageSymbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE,
    ],
    validate: interdependent,
    filteringValidate: any,
    sortOrderFactory: ascending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_UM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: greaterThanOrEqual(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI_UNLESS_LOWEST]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: lessThanOrEqualUnlessLowestCoverage(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_UM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UIM_MUST_EQUAL_UM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: equals(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UIM_REQUIRES_UM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNDERINSURED_MOTORIST,
    validate: requires,
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMPD_MUST_BE_LESS_THAN_OR_EQUAL_TO_PD]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.PROPERTY_DAMAGE],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UMPD_REQUIRES_UM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: requires,
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMPD_REQUIRES_UMUIM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: requires,
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMPD_REQUIRES_UM_OR_UIM]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [
      CoverageSymbols.UNINSURED_MOTORIST,
      CoverageSymbols.UNDERINSURED_MOTORIST,
    ],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    validate: (um, uim, umpd) => !um.declined || !uim.declined || umpd.declined,
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMUIME_MUST_EQUAL_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST,
    validate: (bi, umuim) =>
      umuim.coverageOptions[CoverageOptions.ENHANCED] === CoverageOptionChoice.NO || (umuim.perPerson || 0) === (bi.perPerson || 0),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMUIM_AND_UMPD_DEPENDENT]: {
    fixupType: FixupTypes.FIXUP_BOTH,
    coverages: [
      CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST,
      CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE,
    ],
    validate: interdependent,
    filteringValidate: any,
    sortOrderFactory: ascending(CoverageLimitTypes.PER_PERSON, CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UMUIM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: descending(CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UM_MUST_BE_GREATER_THAN_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST,
    validate: greaterThan(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: ascending(CoverageLimitTypes.PER_OCCURRENCE, CoverageLimitTypes.PER_PERSON),
  },
  [RuleTypes.UMUIM_MUST_BE_GREATER_THAN_OR_EQUAL_TO_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_UNDERINSURED_MOTORIST,
    validate: greaterThanOrEqual(CoverageLimitTypes.PER_PERSON),
    sortOrderFactory: ascending(CoverageLimitTypes.PER_PERSON, CoverageLimitTypes.PER_OCCURRENCE),
  },
  [RuleTypes.UM_MUST_BE_LESS_THAN_OR_EQUAL_TO_BI]: {
    fixupType: FixupTypes.FIXUP_DEPENDENT,
    parentSymbols: [CoverageSymbols.BODILY_INJURY],
    dependentSymbol: CoverageSymbols.UNINSURED_MOTORIST,
    validate: lessThanOrEqual(CoverageLimitTypes.PER_OCCURRENCE),
    sortOrderFactory: descending(CoverageLimitTypes.PER_OCCURRENCE),
  },
};

export function getFixupDefinitions(quotingRules) {
  return FixupSequence.reduce((prev, fixupType) => {
    if (quotingRules[fixupType]) {
      prev.push({
        ...fixupDefinitions[fixupType],
        name: fixupType,
      });
    }

    return prev;
  }, []);
}

export function getFilteringValidate(fixup, validateKey = QuotingRulesKeys.VALIDATE) {
  return fixup.filteringValidate || fixup[validateKey];
}
