export const ACTION_IDS = {
  ADD_DRIVER: 'ADD_DRIVER',
  ADD_VEHICLE: 'ADD_VEHICLE',
  CUSTOMIZE_COVERAGES: 'CUSTOMIZE_COVERAGES',
  EDIT_DRIVER: 'EDIT_DRIVER',
  EDIT_VEHICLE: 'EDIT_VEHICLE',
  REMOVE_DRIVER: 'REMOVE_DRIVER',
  REMOVE_VEHICLE: 'REMOVE_VEHICLE',
};

export const PAGES = {
  ADD_DRIVER: 'ADD_DRIVER',
  EDIT_DRIVER: 'EDIT_DRIVER',
  ADD_VEHICLE: 'ADD_VEHICLE',
  EDIT_VEHICLE: 'EDIT_VEHICLE',
  BASE_DRIVER_VEHICLE_ASSIGNMENT: 'BASE_DRIVER_VEHICLE_ASSIGNMENT',
  DRIVER_VEHICLE_ASSIGNMENT: 'DRIVER_VEHICLE_ASSIGNMENT',
  VEHICLE_DRIVER_ASSIGNMENT: 'VEHICLE_DRIVER_ASSIGNMENT',
  CUSTOMIZE_COVERAGES: 'CUSTOMIZE_COVERAGES',
  COVERED: 'COVERED',
  DISCLAIMERS: 'DISCLAIMERS',
  CHECKOUT: 'CHECKOUT',
  START: 'START',
  LOADING: 'LOADING',
  UNDERWRITING_DECLINED: 'UNDERWRITING_DECLINED',
  UNDERWRITING_PENDING: 'UNDERWRITING_PENDING',
  UNDERWRITING_DECLINED_RESELL: 'UNDERWRITING_DECLINED_RESELL',
};

export const RELATIVE_PATHS = {
  [PAGES.ADD_DRIVER]: '/add-driver',
  [PAGES.EDIT_DRIVER]: '/edit-driver',
  [PAGES.ADD_VEHICLE]: '/add-vehicle',
  [PAGES.EDIT_VEHICLE]: '/edit-vehicle',
  [PAGES.BASE_DRIVER_VEHICLE_ASSIGNMENT]: '/driver-vehicle-assignment',
  [PAGES.DRIVER_VEHICLE_ASSIGNMENT]: '/driver-vehicle-assignment/driver-vehicle',
  [PAGES.VEHICLE_DRIVER_ASSIGNMENT]: '/driver-vehicle-assignment/vehicle-driver',
  [PAGES.CUSTOMIZE_COVERAGES]: '/coverage',
  [PAGES.COVERED]: '/covered',
  [PAGES.DISCLAIMERS]: '/disclaimers',
  [PAGES.CHECKOUT]: '/checkout',
  [PAGES.START]: '/',
  [PAGES.UNDERWRITING_DECLINED]: '/declined',
  [PAGES.UNDERWRITING_DECLINED_RESELL]: '/declined-resell',
  [PAGES.UNDERWRITING_PENDING]: '/pending',
  [PAGES.LOADING]: '/loading',
};
