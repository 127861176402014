import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function FieldGroup({ children }) {
  return (
    <div css={styles.fieldGroup}>
      {children}
    </div>
  );
}

FieldGroup.propTypes = {
  children: PropTypes.node,
};

const styles = StyleSheet.create({
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
});
