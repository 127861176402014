import AnalyticsService from '@root/core/src/services/analytics-service';
import DobDate from '@root/core/src/models/dob-date';
import NevermindLink from '@root/core/src/components/nevermind-link';
import ProfileDisclaimer from '@root/profile/src/components/profile-disclaimer';
import ProfileForm from '@root/profile/src/components/profile-form';
import ProfileHeading from '@root/profile/src/components/profile-heading';
import ProfileModalButton from '@root/profile/src/components/profile-modal-button';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import ShortDateInputGroup from '@root/core/src/components/short-date-input-group';
import SubHeading from '@root/profile/src/components/sub-heading';
import VehicleCreationParams from '@root/auto-pricing/src/models/vehicle-creation-params';

export default class ProfileAddVehiclePurchaseDate extends Component {
  static propTypes = {
    cssButtonOverrides: PropTypes.object,
    disableCancel: PropTypes.bool,
    inputStyle: PropTypes.object,
    linkStyles: PropTypes.object,
    module: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    vehicleCreationParams: PropTypes.instanceOf(VehicleCreationParams).isRequired,
    vin: PropTypes.string,
  }

  static analyticsContext = 'PROFILE_ADD_VEHICLE_PURCHASE_DATE'

  state = {
    date: new DobDate(''),
    error: '',
  }

  componentDidMount() {
    AnalyticsService.trackViewEvent(this._getAnalyticsContext());
  }

  _getAnalyticsContext = () => [...this.props.module, ProfileAddVehiclePurchaseDate.analyticsContext];

  _handleChange = (field) => (value) => {
    this.setState((prevState) => ({
      date: prevState.date.set(field, value),
      error: '',
    }));
  }

  _handleCancel = () => {
    AnalyticsService.trackClickEvent(this._getAnalyticsContext(), 'CANCEL');
    this.props.onCancel();
  }

  _handleSubmit = () => {
    AnalyticsService.trackClickEvent(this._getAnalyticsContext(), 'CONTINUE');
    if (this.state.date.isValid()) {
      const vehicleCreationParams = this.props.vehicleCreationParams.set('purchaseDate', this.state.date);
      this.props.onDone(vehicleCreationParams);
    } else {
      this.setState({
        error: 'Please enter the vehicle’s purchase date.',
      });
    }
  }

  render() {
    return (
      <div>
        <ProfileHeading>
          When did you purchase or lease this vehicle?
        </ProfileHeading>
        {this.props.vin && (
          <SubHeading>
            VIN: {this.props.vin}
          </SubHeading>
        )}
        <ProfileForm onSubmit={this._handleSubmit}>
          <ShortDateInputGroup
            focusOnMount
            date={this.state.date}
            errorLabel={this.state.error}
            inputStyle={this.props.inputStyle}
            onChange={this._handleChange}
          />
          <ProfileModalButton
            cssButtonOverrides={this.props.cssButtonOverrides}
            disabled={!this.state.date.isValid()}
          >
            Continue
          </ProfileModalButton>
          {!this.props.disableCancel && (
            <NevermindLink
              linkStyles={this.props.linkStyles}
              onCancel={this._handleCancel}
            />
          )}
        </ProfileForm>
        <ProfileDisclaimer
          text={'You may be eligible for a better rate depending on how long you’ve owned or leased this vehicle. The closest month is OK.'}
        />
      </div>
    );
  }
}
