import AgeLicensedFieldCell from '@root/age-licensed/src/components/age-licensed-field-cell';
import AgeLicensedHeading from '@root/age-licensed/src/components/age-licensed-heading';
import AgeLicensedSubHeading from '@root/age-licensed/src/components/age-licensed-sub-heading';
import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import Button from '@root/core/src/components/button';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function AgeLicensedAgeWhenLicensed({
  ageWhenLicensed,
  cssButtonOverrides,
  error,
  onChange,
  onSubmit,
  title,
}) {
  const onSubmitWrapper = (event) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <div>
      <AgeLicensedHeading>
        {title}
      </AgeLicensedHeading>
      <AgeLicensedSubHeading>
        Learner’s permits and foreign driver’s licenses don’t apply.
      </AgeLicensedSubHeading>
      <form onSubmit={onSubmitWrapper}>
        <AgeLicensedFieldCell>
          <Input
            errorLabel={error}
            inputType={TextTypes.TEXT}
            label={AgeWhenLicensed.LABEL}
            onBlur={onChange}
            onChange={onChange}
            pattern={'[0-9]*'}
            value={`${ageWhenLicensed || ''}`}
          />
        </AgeLicensedFieldCell>
        <Button
          disabled={!!(error || !ageWhenLicensed || !`${ageWhenLicensed}`)}
          styles={cssButtonOverrides}
        >
          Continue
        </Button>
      </form>
    </div>
  );
}

AgeLicensedAgeWhenLicensed.propTypes = {
  ageWhenLicensed: PropTypes.instanceOf(AgeWhenLicensed),
  cssButtonOverrides: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

