import ErrorReportService from '@root/core/src/services/error-report-service';
import StorageService from '@root/core/src/services/storage-service';
import { useEffect, useState } from '@root/vendor/react';

export function usePersistedState({
  deserialize,
  initialValue,
  serialize,
  shouldSkipLoadingFromLocalStorage = false,
  storageKey,
}) {
  const [currentValue, setCurrentValue] = useState(() => {
    const getInitialValue = () => initialValue instanceof Function ? initialValue() : initialValue;

    if (typeof window === 'undefined' || shouldSkipLoadingFromLocalStorage) {
      return getInitialValue();
    }

    try {
      const storedItem = StorageService.getItem(storageKey);

      if (storedItem) {
        return deserialize ? deserialize(storedItem) : storedItem;
      }

      return getInitialValue();
    } catch (error) {
      ErrorReportService.reportError({
        error,
        caughtAt: 'load-state-from-local-storage',
        additionalData: {
          storageKey,
        },
      });

      return getInitialValue();
    }
  });

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        const itemToStore = serialize ? serialize(currentValue) : currentValue;
        StorageService.setItem(storageKey, itemToStore);
      }
    } catch (error) {
      ErrorReportService.reportError({
        error,
        caughtAt: 'save-state-to-local-storage',
        additionalData: {
          currentValue,
          storageKey,
        },
      });
    }
  }, [currentValue, serialize, storageKey]);

  return [currentValue, setCurrentValue];
}
