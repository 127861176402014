import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import useQuoteCoveragesQuery from '@root/bind.joinroot.com/src/hooks/api/queries/use-quote-coverages-query';
import { useCallback } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEYS = {
  LAST_FETCHED_QUOTE_COVERAGES_RATING_REQUEST_ID: 'last_fetched_quote_coverages_rating_request_id',
  QUOTE_COVERAGES_CONTEXT: 'quote_coverages_context',
};

const POLLING_INTERVAL = 1000;

export function usePersistedQuoteCoveragesContext({ ratingRequestId }) {
  const [quoteCoveragesContext, setQuoteCoveragesContext] = usePersistedState({
    deserialize: (persistedItem) => QuoteCoveragesContext.build(persistedItem),
    storageKey: LOCAL_STORAGE_KEYS.QUOTE_COVERAGES_CONTEXT,
  });

  const [lastFetchedQuoteCoveragesRatingRequestId, setLastFetchedQuoteCoveragesRatingRequestId] = usePersistedState({
    storageKey: LOCAL_STORAGE_KEYS.LAST_FETCHED_QUOTE_COVERAGES_RATING_REQUEST_ID,
  });

  const handleFetchedQuoteCoverages = useCallback((fetchedQuoteCoveragesData) => {
    if (fetchedQuoteCoveragesData.quoteCoveragesContext) {
      setLastFetchedQuoteCoveragesRatingRequestId(ratingRequestId);

      setQuoteCoveragesContext(
        QuoteCoveragesContext.build(fetchedQuoteCoveragesData.quoteCoveragesContext)
      );
    }
  }, [ratingRequestId, setLastFetchedQuoteCoveragesRatingRequestId, setQuoteCoveragesContext]);

  const isQuoteCoveragesContextCurrent = !!quoteCoveragesContext && lastFetchedQuoteCoveragesRatingRequestId === ratingRequestId;

  useQuoteCoveragesQuery({ ratingRequestId }, {
    enabled: !!ratingRequestId && !isQuoteCoveragesContextCurrent,
    onSuccess: handleFetchedQuoteCoverages,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    isQuoteCoveragesContextCurrent,
    quoteCoveragesContext,
    setQuoteCoveragesContext,
  };
}
