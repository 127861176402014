import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageLimit from '@root/auto-pricing/src/models/coverage-limit';
import CoverageOptionsToggle from '@root/quotes/src/components/coverage/coverage-option-toggle';
import PipCoverageOptionsExplanationLinkModal from '@root/quotes/src/components/modals/pip-coverage-options-explanation-link-modal';
import PipLostWagesExplanationLinkModal from '@root/quotes/src/components/modals/pip-lost-wages-explanation-link-modal';
import PropTypes from '@root/vendor/prop-types';
import QuotesSelect from '@root/quotes/src/components/form-elements/quotes-select';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

const SYMBOL = Coverage.Symbols.PERSONAL_INJURY_PROTECTION;

export default function PipResidentRelativesWorkLoss({
  coverageSelections,
  dropdownIndicator,
  mobileSelectedOptionStyles,
  pipCoverages,
  primaryBrandColor,
  onClose,
  onCoverageChange,
  onOpen,
  secondaryBrandColor,
  coverage,
}) {
  const { coverageOptions } = coverage;

  const shouldForceAcceptResidentRelatives = (deductible, workLossExcluded) => deductible === 0 && workLossExcluded === Coverage.CoverageOptionChoice.NO;

  const getSelectedCoverage = ({
    deductible: newDeductible, residentRelativesIncluded: newResidentRelativesValue, workLossExcluded: newWorkLossValue,
  }) => {
    return pipCoverages.find((selection) => {
      return selection.deductible === parseInt(newDeductible) &&
        selection.coverageOptions.residentRelativesIncluded === newResidentRelativesValue &&
        selection.coverageOptions.workLossExcluded === newWorkLossValue;
    });
  };

  const onDropDownChange = (deductible) => {
    onChange(CoverageLimit.Types.DEDUCTIBLE, parseInt(deductible));
  };

  const onToggleChange = (option) => {
    const newValue = coverageOptions[option] === Coverage.CoverageOptionChoice.YES ? Coverage.CoverageOptionChoice.NO : Coverage.CoverageOptionChoice.YES;
    onChange(option, newValue);
  };

  const onChange = (option, newValue) => {
    const dataToUpdate = {
      deductible: coverage.deductible,
      residentRelativesIncluded: coverageOptions.residentRelativesIncluded,
      workLossExcluded: coverageOptions.workLossExcluded,
      [option]: newValue,
    };
    if (shouldForceAcceptResidentRelatives(dataToUpdate.deductible, dataToUpdate.workLossExcluded)) {
      dataToUpdate.residentRelativesIncluded = Coverage.CoverageOptionChoice.YES;
    }
    const selectedCoverage = getSelectedCoverage(dataToUpdate);
    onCoverageChange(selectedCoverage.id, SYMBOL);
  };

  const dropDownSelections = coverageSelections.pip.filter((selection) => {
    return selection.coverageOptions.residentRelativesIncluded === coverage.coverageOptions.residentRelativesIncluded
      && selection.coverageOptions.workLossExcluded === coverage.coverageOptions.workLossExcluded;
  }).map((selection) => {
    return {
      ...selection,
      value: selection.deductible.toString(),
      label: selection.label.replace(/ applies.*/, ''),
    };
  }).sort((a, b) => b.deductible - a.deductible);

  return (
    <>
      <QuotesSelect
        dropdownIndicator={dropdownIndicator}
        dropdownIndicatorColor={primaryBrandColor}
        inputId={'pip-coverage-select'}
        inputName={'pip-coverage-select'}
        mobileSelectedOptionStyles={mobileSelectedOptionStyles}
        onChange={onDropDownChange}
        onClose={onClose}
        onOpen={onOpen}
        options={dropDownSelections}
        optionStyles={{ ':hover, :hover > span': { background: primaryBrandColor } }}
        placeholder={'Select option'}
        selectedValue={coverage.deductible.toString()}
      >
        <CoverageOptionsToggle
          circleSelectedStylesOverrides={primaryBrandColor && { borderColor: primaryBrandColor }}
          containerStyleOverrides={styles.containerCoverageOptionsTop}
          description={'Reject coverage for lost wages?'}
          isSelected={coverageOptions.workLossExcluded === Coverage.CoverageOptionChoice.YES}
          onToggleChange={() => onToggleChange(Coverage.CoverageOptions.WORK_LOSS_EXCLUDED)}
          toggleSelectedStylesOverrides={primaryBrandColor && { background: primaryBrandColor }}
          toggleText={coverageOptions.workLossExcluded === Coverage.CoverageOptionChoice.YES ? 'Yes' : 'No'}
        />
        <PipLostWagesExplanationLinkModal
          primaryBrandColor={primaryBrandColor}
          secondaryBrandColor={secondaryBrandColor}
        />
        {
          !shouldForceAcceptResidentRelatives(coverage.deductible, coverage.coverageOptions.workLossExcluded) &&
          <>
            <CoverageOptionsToggle
              circleSelectedStylesOverrides={primaryBrandColor && { borderColor: primaryBrandColor }}
              containerStyleOverrides={styles.containerCoverageOptionsBottom}
              description={'Apply these selections to dependent relatives living with you?'}
              isSelected={coverageOptions.residentRelativesIncluded === Coverage.CoverageOptionChoice.YES}
              onToggleChange={() => onToggleChange(Coverage.CoverageOptions.RESIDENT_RELATIVES_INCLUDED)}
              toggleSelectedStylesOverrides={primaryBrandColor && { background: primaryBrandColor }}
              toggleText={coverageOptions.residentRelativesIncluded === Coverage.CoverageOptionChoice.YES ? 'Yes' : 'No'}
            />
            <PipCoverageOptionsExplanationLinkModal
              primaryBrandColor={primaryBrandColor}
              secondaryBrandColor={secondaryBrandColor}
            />
          </>
        }
      </QuotesSelect>
    </>
  );
}

PipResidentRelativesWorkLoss.propTypes = {
  coverage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    deductible: PropTypes.number.isRequired,
    coverageOptions: PropTypes.shape({
      residentRelativesIncluded: PropTypes.string.isRequired,
      workLossExcluded: PropTypes.string.isRequired,
    }),
  }),
  coverageSelections: PropTypes.shape({
    pip: PropTypes.arrayOf(PropTypes.shape({
      coverageOptions: PropTypes.object,
    })),
  }),
  dropdownIndicator: PropTypes.string,
  mobileSelectedOptionStyles: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onCoverageChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  pipCoverages: PropTypes.arrayOf(PropTypes.shape({
    coverageOptions: PropTypes.shape({
      residentRelativesIncluded: PropTypes.string.isRequired,
      workLossExcluded: PropTypes.string.isRequired,
    }),
    deductible: PropTypes.number,
    id: PropTypes.string.isRequired,
  })),
  primaryBrandColor: PropTypes.string,
  secondaryBrandColor: PropTypes.string,
};

const styles = StyleSheet.create({
  containerCoverageOptionsTop: {
    marginTop: 32,
  },
  containerCoverageOptionsBottom: {
    marginTop: 24,
  },
});
