import AddVehicleManager from '@root/bind.joinroot.com/src/components/bind-add-vehicle/add-vehicle-manager';
import BindView from '@root/bind.joinroot.com/src/components/bind-view';
import CloseModalButton from '@root/bind.joinroot.com/src/components/close-modal-button';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Shadows, StyleSheet } from '@root/core/src/utils/styles';

export const ANALYTICS_CONTEXT = 'BIND_ADD_VEHICLE';
export const ANALYTICS_EVENTS = {
  CLOSE_MODAL_BUTTON: 'CLOSE_MODAL_BUTTON',
};

const BindAddVehicle = ({
  onCancel,
  onUpdateProfileParams,
  profileParams,
  profileRules,
  shouldReturnToPartnerOnBack,
}) => {
  const { trackClick } = useAnalytics(ANALYTICS_CONTEXT);
  const [useBrandingState] = useBranding();
  const brandStyles = useMemo(() => stylesGenerator(useBrandingState), [useBrandingState]);

  const handleModalClose = () => {
    trackClick(ANALYTICS_EVENTS.CLOSE_MODAL_BUTTON);
    onCancel();
  };

  return (
    <>
      {shouldReturnToPartnerOnBack ?
        <BindView.ExitFlowButton exitToQuote />
        :
        <BindView.BackToProfileButton onClick={onCancel} />
      }
      <BindView>
        <BindView.Card
          cssOverrides={{
            marginTop: 50,
          }}
        >
          <CloseModalButton onClick={handleModalClose} />
          <AddVehicleManager
            cssButtonOverrides={brandStyles.buttonStyle}
            inputStyle={brandStyles.inputStyle}
            linkStyles={brandStyles.linkStyles}
            module={['bind']}
            onCancel={onCancel}
            onUpdateProfileParams={onUpdateProfileParams}
            profileParams={profileParams}
            profileRulesContext={profileRules}
          />
        </BindView.Card>
      </BindView>
    </>
  );
};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  buttonStyle: {
    background: primaryColor,
    marginTop: 30,
    marginBottom: 30,
    ...Shadows.onFocusStateShadow({}, primaryColor),
  },
  inputStyle: {
    caretColor: primaryColor,
  },
  linkStyles: {
    ':hover': {
      color: primaryColor,
    },
  },
});

BindAddVehicle.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  profileRules: PropTypes.object.isRequired,
  shouldReturnToPartnerOnBack: PropTypes.bool,
};

export default BindAddVehicle;
