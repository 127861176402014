import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { FormAccessibleWrapper } from '@root/core/src/components/forms/form-accessible-wrapper';

function Toggle({
  circleSelectedStyles,
  disabled = false,
  formAccessible = true,
  isSelected,
  onClick = () => {},
  size = Toggle.Sizes.NORMAL,
  tabIndex = 0,
  testID,
  toggleSelectedStyles,
  toggleStyles,
}) {
  const buildStyles = (stylesheet, selectedStyleOverrides, extraStyles) => {
    return [
      stylesheet.base,
      stylesheet[size],
      extraStyles,
      ...isSelected && [stylesheet.selected, selectedStyleOverrides] || [],
      disabled && stylesheet.disabled,
    ];
  };

  const render = () => (
    <div
      aria-label={isSelected ? 'selected' : 'not selected'}
      css={buildStyles(styles, toggleSelectedStyles, toggleStyles)}
      data-testid={testID}
      onClick={!disabled ? onClick : () => {}}
    >
      <div
        css={buildStyles(circleStyles, circleSelectedStyles)}
      />
    </div>
  );

  return formAccessible && (
    <FormAccessibleWrapper
      includeHoverStyles={false}
      tabIndex={tabIndex}
    >
      {render()}
    </FormAccessibleWrapper>
  ) || render();
}

Toggle.Sizes = {
  SMALL: 'small',
  NORMAL: 'normal',
};

Toggle.propTypes = {
  circleSelectedStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Toggle.Sizes)),
  tabIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  testID: PropTypes.string,
  toggleSelectedStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  toggleStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default Toggle;

const styles = StyleSheet.create({
  base: {
    cursor: 'pointer',
    alignSelf: 'center',
    width: 50,
    height: 30,
    borderRadius: 100,
    position: 'relative',
    background: Colors.gray50(),
    transition: 'all 300ms ease-out',
  },
  selected: {
    background: Colors.rootOrange(),
  },
  small: {
    height: 20,
    width: 40,
  },
  disabled: {
    pointerEvents: 'none',
    background: Colors.gray40(),
  },
});

const circleStyles = StyleSheet.create({
  base: {
    width: 30,
    height: 30,
    background: Colors.white(),
    border: `1px solid ${Colors.gray50()}`,
    borderRadius: '50%',
    position: 'absolute',
    right: 20,
    transition: 'all 300ms ease-out',
  },
  selected: {
    right: 0,
    borderColor: Colors.rootOrange(),
  },
  small: {
    height: 20,
    width: 20,
  },
  disabled: {
    borderColor: Colors.gray40(),
  },
});
