import { CoverageOptions, CoverageSymbols, CoverageTypes, FixupTypes } from './constants';
import { getFixupDefinitions } from './quoting-rules';
import { isEqual, pick } from 'lodash';
import { sortCoverages } from './coverage-sort';
import { validQuotingData } from './validate';

export function fixupCoverages({
  quotingRules,
  updatedSymbol,
  quotingData,
}) {
  quotingData = {
    ...quotingData,
  };

  const errors = [];
  if (!validQuotingData(quotingData)) {
    errors.push(new Error('Quoting data is invalid check quoting data adaptor for issues.'));
  }

  const fixups = getFixupDefinitions(quotingRules);

  fixups.forEach((fixup) => {
    const fixupType = fixup.fixupType;

    try {
      if (fixupType === FixupTypes.FIXUP_DEPENDENT) {
        quotingData = fixupDependentCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_BOTH) {
        quotingData = fixupBothCoverages({
          ...fixup,
          quotingData,
          updatedSymbol,
        });
      } else if (fixupType === FixupTypes.FIXUP_ANY_COLL_COMP_REQUIRES_FULL) {
        quotingData = fixupAnyCollCompRequiresFullCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_UMPD_WITH_COLL) {
        quotingData = fixupUmpdWithCollCoverages({
          ...fixup,
          quotingData,
        });
      } else if (fixupType === FixupTypes.FIXUP_PHYSICAL_DAMAGE_TO_DECLINED) {
        quotingData = fixupPhysicalDamageToDeclined(quotingData);
      }
    } catch (err) {
      errors.push(err);
    }
  });

  return [
    quotingData,
    errors,
  ];
}

export function getSelectedCoverage(selectedCoverages, symbol) {
  return selectedCoverages.find((c) => c.symbol === symbol);
}

function fixupDependentCoverages({
  quotingData,
  name,
  parentSymbols,
  dependentSymbol,
  validate,
  sortOrderFactory,
}) {
  const sortedCoverages = sortCoverages(quotingData.rateCoverages);
  const parentCoverages = parentSymbols
    .map((sym) => getSelectedCoverage(quotingData.selectedCoverages, sym))
    .filter((coverage) => coverage);
  const dependentCoverage = getSelectedCoverage(quotingData.selectedCoverages, dependentSymbol);

  if (!dependentCoverage) {
    throw makeError(name, `Missing dependent coverage ${dependentSymbol}.`);
  }

  if (parentCoverages.length !== parentSymbols.length) {
    const required = parentSymbols.join(', ');
    const present = parentCoverages.map((coverage) => coverage.symbol).join(', ') || 'nothing';

    throw makeError(name, `Missing parent coverages. ${name} requires ${required} but only provided ${present}.`);
  }

  let newCoverage;
  if (validate(...parentCoverages, dependentCoverage, {
    sortedCoverages,
    ...quotingData.options,
  })) {
    newCoverage = dependentCoverage;
  } else {
    let validCoverages = sortedCoverages[dependentSymbol]
      .filter((c) => validate(...parentCoverages, c, {
        sortedCoverages,
        ...quotingData.options,
      }));

    validCoverages = filterCoveragesByCoverageOptions(validCoverages, dependentCoverage.coverageOptions);
    newCoverage = validCoverages.sort(sortOrderFactory)[0];
  }

  if (Array.isArray(newCoverage.coveredVins)) {
    newCoverage.coveredVins = newCoverage.coveredVins.filter((vin) => parentCoverages.every((c) => c.coveredVins.includes(vin)));
  }

  return mergeNewCoverage(quotingData, newCoverage);
}

function fixupBothCoverages({
  quotingData,
  name,
  coverages,
  updatedSymbol,
  validate,
  sortOrderFactory,
}) {
  const parentSymbol = coverages.find((symbol) => symbol === updatedSymbol);
  const dependentSymbol = coverages.find((symbol) => symbol !== updatedSymbol);

  if (!parentSymbol || !dependentSymbol) {
    return quotingData;
  }

  return fixupDependentCoverages({
    quotingData,
    name,
    validate,
    dependentSymbol,
    parentSymbols: [parentSymbol],
    sortOrderFactory,
  });
}

function fixupUmpdWithCollCoverages({
  quotingData,
  alternateValidate,
  ...rest
}) {
  if (quotingData.nonVehicleCoverageSymbols.includes(CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE)) {
    return fixupDependentCoverages({
      ...rest,
      quotingData,
    });
  } else {
    return fixupUmpdWithCollPerVehicle({
      ...rest,
      quotingData,
      validate: alternateValidate,
    });
  }
}

function fixupAnyCollCompRequiresFullCoverages({
  quotingData,
  name,
  validate,
  sortOrderFactory,
}) {
  const compSymbol = CoverageSymbols.COMPREHENSIVE;
  const collSymbol = CoverageSymbols.COLLISION;

  const sortedCoverages = sortCoverages(quotingData.rateCoverages);
  const collCoverage = getSelectedCoverage(quotingData.selectedCoverages, collSymbol);
  const compCoverage = getSelectedCoverage(quotingData.selectedCoverages, compSymbol);

  if (!collCoverage) {
    throw makeError(name, `Missing coverage ${collSymbol}.`);
  }

  if (!compCoverage) {
    throw makeError(name, `Missing coverage ${compSymbol}.`);
  }

  if (validate(collCoverage, compCoverage, quotingData.availableVins)) {
    return quotingData;
  }

  const newCollCoverage = replaceCollCompCoverage({
    selectedCoverage: collCoverage,
    coverages: sortedCoverages[collSymbol],
    availableVins: quotingData.availableVins,
    sortOrderFactory,
  });
  const newCompCoverage = replaceCollCompCoverage({
    selectedCoverage: compCoverage,
    coverages: sortedCoverages[compSymbol],
    availableVins: quotingData.availableVins,
    sortOrderFactory,
  });

  return mergeNewCoverage(mergeNewCoverage(quotingData, newCollCoverage), newCompCoverage);
}

function replaceCollCompCoverage({
  selectedCoverage,
  coverages,
  availableVins,
  sortOrderFactory,
}) {
  if (!selectedCoverage.declined) {
    return {
      ...selectedCoverage,
      coveredVins: [...availableVins],
    };
  }

  const validCoverages = coverages.filter((c) => !c.declined);
  const newCoverage = validCoverages.sort(sortOrderFactory)[0];
  return {
    ...newCoverage,
    coveredVins: [...availableVins],
  };
}

function fixupUmpdWithCollPerVehicle({
  quotingData,
  validate,
  name,
  sortOrderFactory,
}) {
  const umpdSymbol = CoverageSymbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE;
  const collSymbol = CoverageSymbols.COLLISION;

  const sortedCoverages = sortCoverages(quotingData.rateCoverages);
  const umpdCoverage = getSelectedCoverage(quotingData.selectedCoverages, umpdSymbol);
  const collCoverage = getSelectedCoverage(quotingData.selectedCoverages, collSymbol);

  if (!umpdCoverage) {
    throw makeError(name, `Missing coverage ${umpdSymbol}.`);
  }

  if (!collCoverage) {
    throw makeError(name, `Missing coverage ${collSymbol}.`);
  }

  const nonOverlappingVins = difference(quotingData.availableVins, collCoverage.coveredVins);
  let newCoveredVins = umpdCoverage.declined ? [] : nonOverlappingVins;
  let newCoverage = {
    ...umpdCoverage,
    coveredVins: newCoveredVins,
  };

  if (validate(collCoverage, quotingData.availableVins, newCoverage)) {
    return mergeNewCoverage(quotingData, newCoverage);
  }

  let validCoverages = sortedCoverages[umpdSymbol]
    .filter((coverage) => validate(collCoverage, quotingData.availableVins, coverage));

  validCoverages = filterCoveragesByCoverageOptions(validCoverages, umpdCoverage.coverageOptions);

  const validNewCoverage = validCoverages.sort(sortOrderFactory)[0];
  newCoveredVins = umpdCoverage.declined ? [] : nonOverlappingVins;
  newCoverage = {
    ...validNewCoverage,
    coveredVins: newCoveredVins,
  };

  return mergeNewCoverage(quotingData, newCoverage);
}

function fixupPhysicalDamageToDeclined(quotingData) {
  if (quotingData.options.physicalDamageEligible) {
    return quotingData;
  }

  const selectedPhysicalDamageCoverages = CoverageTypes.PHYSICAL_DAMAGE
    .map((symbol) => quotingData.selectedCoverages.find((coverage) => coverage.symbol === symbol));

  return selectedPhysicalDamageCoverages.reduce((updatedQuotingData, coverage) => {
    const declinedCoverage = quotingData.rateCoverages[coverage.symbol].find((c) => c.declined);

    return mergeNewCoverage(updatedQuotingData, declinedCoverage);
  }, Object.assign({}, quotingData));
}

function makeError(name, message) {
  return new Error(`Quoting rule ${name} cannot be executed. ${message}`);
}

function filterCoveragesByCoverageOptions(coverages, coverageOptions) {
  if (coverages.some((c) => _coverageOptionsEqual(c, coverageOptions))) {
    return coverages.filter((c) => c.declined || _coverageOptionsEqual(c, coverageOptions));
  }

  return coverages;
}

function difference(values1, values2) {
  return values1.reduce((shared, vin) => {
    if (!values2.includes(vin)) {
      shared.push(vin);
    }

    return shared;
  }, []);
}

function mergeNewCoverage(quotingData, newCoverage) {
  return {
    ...quotingData,
    selectedCoverages: quotingData.selectedCoverages.map((c) => c.symbol === newCoverage.symbol ? newCoverage : c),
  };
}

function _coverageOptionsEqual(coverage, coverageOptions) {
  return (
    isEqual(
      pick(coverage.coverageOptions, Object.values(CoverageOptions)),
      pick(coverageOptions, Object.values(CoverageOptions))
    )
  );
}
