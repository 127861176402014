import BillingCycle from '@root/quotes/src/models/billing-cycle';
import Button from '@root/bind.joinroot.com/src/components/button';
import ChevronUp from '@root/bind.joinroot.com/src/components/icons/chevron-up';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import Money from '@root/core/src/models/money';
import PropTypes from '@root/vendor/prop-types';
import QuotesPrice from '@root/quotes/src/components/quotes-price';
import React, { useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, Shadows, StyleSheet, createEmotionClassName } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const styles = StyleSheet.create({
  button: {
    minHeight: '2.5rem',
    width: 'auto',
    margin: 0,
  },
  buttonIcon: {
    marginRight: '1rem',
  },
  stickyPrice: {
    position: 'fixed',
    display: 'flex',
    bottom: '-100%',
    left: 0,
    right: 0,
    height: '5rem',
    padding: '0.5rem 20px',
    transitionProperty: 'bottom',
    transitionDuration: '0.3s',
    backgroundColor: Colors.white(),
    alignItems: 'center',
    justifyContent: 'space-between',
    ...Shadows.cardShadow(),
    ...Responsive.md({
      display: 'none',
    }),
  },
  stickyVisible: {
    bottom: 0,
  },
});

const stickyPriceClassName = createEmotionClassName(styles.stickyPrice);
const stickyVisibleClassName = createEmotionClassName(styles.stickyVisible);

const MobileStickyPriceFooter = ({
  billingCycle, onClickReview, selectedQuote, visible,
}) => {
  const strings = useI18nNext('components.bindQuoteCustomization.mobileStickyPriceFooter');
  const price = useMemo(() => {
    return Money.fromCents(
      selectedQuote.getMonthlyOrFullTermPaymentInCents(billingCycle)
    );
  }, [billingCycle, selectedQuote]);

  const containerClassName = useMemo(() => [stickyPriceClassName, visible && stickyVisibleClassName].join(' '), [visible]);

  return (
    <div className={containerClassName}>
      <QuotesPrice
        billingCycle={billingCycle}
        price={price}
        size={QuotesPrice.Size.MEDIUM}
      />
      <Button
        cssOverrides={styles.button}
        onClick={onClickReview}
      >
        <ChevronUp
          color={Colors.white()}
          css={styles.buttonIcon}
          height={'1rem'}
        />
        {strings.button}
      </Button>
    </div>
  );
};

MobileStickyPriceFooter.propTypes = {
  billingCycle: PropTypes.oneOf(BillingCycle.getOptions()).isRequired,
  onClickReview: PropTypes.func.isRequired,
  selectedQuote: PropTypes.instanceOf(CustomQuote).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default MobileStickyPriceFooter;
