export default class CoverageLimit {
  static Types = {
    PER_PERSON_PER_OCCURRENCE: 'perPersonPerOccurrence',
    PER_DAY_PER_OCCURRENCE: 'perDayPerOccurrence',
    PER_OCCURRENCE: 'perOccurrence',
    PER_PERSON: 'perPerson',
    DEDUCTIBLE: 'deductible',
    PER_DAY: 'perDay',
    PER_WEEK: 'perWeek',
  };

  static OrderedModifiers = [
    CoverageLimit.Types.PER_PERSON,
    CoverageLimit.Types.PER_DAY,
    CoverageLimit.Types.PER_WEEK,
    CoverageLimit.Types.PER_PERSON_PER_OCCURRENCE,
    CoverageLimit.Types.PER_DAY_PER_OCCURRENCE,
    CoverageLimit.Types.PER_OCCURRENCE,
  ];
}
