import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import carImage from '@root/bind.joinroot.com/src/assets/bind-underwriting-declined/car.png';
import carImage2x from '@root/bind.joinroot.com/src/assets/bind-underwriting-declined/car@2x.png';
import { AutoscalingSvg } from '@root/bind.joinroot.com/src/components/icons';
import { Colors } from '@root/core/src/utils/styles';

const altText = 'Car image';

const CarImage = ({
  classNames,
}) => {
  return (
    <div
      className={classNames?.container}
    >
      <img
        alt={altText}
        className={classNames?.image}
        src={carImage}
        srcSet={`${carImage2x} 2x`}
      />
      <AutoscalingSvg
        className={classNames?.circle}
        viewBox={'0 0 80 80'}
      >
        <circle
          cx={'40'}
          cy={'40'}
          fill={Colors.lightBlue()}
          r={'37'}
        />
      </AutoscalingSvg>
    </div>
  );
};

CarImage.propTypes = {
  classNames: PropTypes.shape({
    circle: PropTypes.string,
    container: PropTypes.string,
    image: PropTypes.string,
  }),
};

CarImage.altText = altText;

export default CarImage;
