import BillingCycle from '@root/quotes/src/models/billing-cycle';

export default class BindParams {
  static build({
    quoteId, billingCycle, startDate, paymentNonce, quoteDisclaimerAffirmed, tortSelection, quoteUnderwritingStatus,
  } = {}) {
    const bindParams = new BindParams();
    return bindParams.setAttributes({
      quoteId,
      billingCycle,
      startDate,
      paymentNonce,
      quoteDisclaimerAffirmed,
      tortSelection,
      quoteUnderwritingStatus,
    });
  }

  constructor(today = new Date()) {
    this.quoteId = null;
    this.billingCycle = BillingCycle.MONTHLY;
    this.startDate = today;
    this.paymentNonce = null;
    this.quoteDisclaimerAffirmed = false;
    this.tortSelection = 'no_use';
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new BindParams(),
      this,
      attributes,
    );
  }

  serializeForSubmission() {
    return {
      effectiveDate: this.startDate.toString(),
      quoteId: this.quoteId,
      paymentMethodNonce: this.paymentNonce,
      invoicePeriod: this.billingCycle,
      quoteDisclaimerAffirmed: this.quoteDisclaimerAffirmed,
    };
  }
}
