import ProfileAddVehicleAntiTheft from '@root/profile/src/scenes/add-vehicle/profile-add-vehicle-anti-theft';
import ProfileAddVehiclePurchaseDate from '@root/profile/src/scenes/add-vehicle/profile-add-vehicle-purchase-date';
import ProfileAddVehicleVin from '@root/profile/src/scenes/add-vehicle/profile-add-vehicle-vin';
import ProfileAddVehicleVinEtching from '@root/profile/src/scenes/add-vehicle/profile-add-vehicle-vin-etching';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import ProfileRulesContext from '@root/auto-pricing/src/models/profile-rules-context';
import ProfileVehicleService from '@root/auto-pricing/src/services/profile-vehicle-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useState } from '@root/vendor/react';
import SubflowAnimator from '@root/profile/src/components/subflow-animator';
import Vehicle from '@root/core/src/models/vehicle';
import VehicleCreationParams from '@root/auto-pricing/src/models/vehicle-creation-params';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { RootError } from '@root-common/root-errors';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function AddVehicleManager({
  cssButtonOverrides,
  inputStyle,
  linkStyles,
  module,
  onUpdateProfileParams,
  onCancel,
  profileParams,
  profileRulesContext,
}) {
  const [{ primaryColor }] = useBranding();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentVehicleCreationParams, setCurrentVehicleCreationParams] = useState(new VehicleCreationParams());
  const [currentPassProps, setCurrentPassProps] = useState({});

  const addVehicleSteps = useMemo(() => {
    const steps = [ProfileAddVehicleVin];

    if (profileRulesContext.antiTheftEquipment) {
      steps.push(ProfileAddVehicleAntiTheft);
    }

    if (profileRulesContext.vinEtching) {
      steps.push(ProfileAddVehicleVinEtching);
    }

    if (profileRulesContext.purchaseDate) {
      steps.push(ProfileAddVehiclePurchaseDate);
    }

    return steps;
  }, [profileRulesContext.antiTheftEquipment, profileRulesContext.purchaseDate, profileRulesContext.vinEtching]);

  const handleCancel = (useVin) => {
    if (useVin === true) {
      setCurrentStepIndex(0);
    } else {
      onCancel();
    }
  };

  const createVehicle = (vehicleCreationParams) => {
    const {
      antiTheftEquipment,
      vinEtching,
      purchaseDate,
    } = vehicleCreationParams;

    let vehicle = new Vehicle({
      selected: true,
      antiTheftEquipment,
      vinEtching,
      purchaseDate,
    });

    if (vehicleCreationParams.vin) {
      vehicle = vehicle.setAttributes({
        vin: vehicleCreationParams.vin,
      });
    } else {
      throw new RootError({
        message: 'Invalid VehicleCreationParams',
        name: 'AddVehicleManagerContentError',
      });
    }

    return vehicle;
  };

  const handleDone = (vehicleCreationParams, passProps, bail = false) => {
    const nextStepIndex = currentStepIndex + 1;

    if (nextStepIndex >= addVehicleSteps.length || bail) {
      const vehicle = createVehicle(vehicleCreationParams);

      return onUpdateProfileParams(ProfileVehicleService.addVehicle(profileParams, vehicle));
    }

    setCurrentPassProps(passProps);
    setCurrentVehicleCreationParams(vehicleCreationParams);
    setCurrentStepIndex(nextStepIndex);
  };

  const CurrentStep = addVehicleSteps[currentStepIndex];

  return (
    <div css={styles.content}>
      <SubflowAnimator animatorKey={CurrentStep}>
        <CurrentStep
          brandedColor={primaryColor}
          cssButtonOverrides={cssButtonOverrides}
          inputStyle={inputStyle}
          linkStyles={linkStyles}
          module={module}
          onCancel={handleCancel}
          onDone={handleDone}
          passProps={currentPassProps}
          profileParams={profileParams}
          vehicleCreationParams={currentVehicleCreationParams}
          vehicles={profileParams.vehicles}
        />
      </SubflowAnimator>
    </div>
  );
}

AddVehicleManager.propTypes = {
  cssButtonOverrides: PropTypes.object,
  inputStyle: PropTypes.object,
  linkStyles: PropTypes.object,
  module: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdateProfileParams: PropTypes.func.isRequired,
  profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
  profileRulesContext: PropTypes.instanceOf(ProfileRulesContext).isRequired,
};

const styles = StyleSheet.create({
  content: {
    minHeight: 510,
  },
});
