import FieldHeading from '@root/profile/src/components/edit-driver/field-heading';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Toggle from '@root/core/src/components/toggle';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function ToggleField({
  circleSelectedStyles, isSelected, onChange, secondaryText, testID, text, toggleSelectedStyles,
}) {
  return (
    <div
      css={styles.inputGroupWrapper}
    >
      <div css={styles.textContainer}>
        <FieldHeading>
          {text}
        </FieldHeading>
        {secondaryText && (
          <span css={styles.secondary}>
            {secondaryText}
          </span>
        )}
      </div>
      <Toggle
        circleSelectedStyles={circleSelectedStyles}
        isSelected={isSelected}
        onClick={onChange}
        testID={testID}
        toggleSelectedStyles={toggleSelectedStyles}
      />
    </div>
  );
}

ToggleField.propTypes = {
  circleSelectedStyles: PropTypes.object,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  secondaryText: PropTypes.string,
  testID: PropTypes.string,
  text: PropTypes.string.isRequired,
  toggleSelectedStyles: PropTypes.object,
};

const styles = StyleSheet.create({
  inputGroupWrapper: {
    display: 'flex',
    marginBottom: 30,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '70%',
    marginRight: 'auto',
  },
  secondary: {
    ...Theme.paragraph2(),
    marginTop: '0.5em',
  },
});
