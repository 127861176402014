import { getMarketRules } from '@root/bind.joinroot.com/src/services/api';
import { useQuery } from '@root/vendor/react-query';

const useMarketRulesQuery = ({ market }, overrides = {}) => useQuery({
  enabled: !!market,
  queryFn: async () => await getMarketRules({
    market,
  }),
  queryKey: ['market', market, 'rules'],
  ...overrides,
});

export default useMarketRulesQuery;
