import PropTypes from '@root/vendor/prop-types';
import { AnimationStyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, SwitchTransition } from '@root/vendor/react-transition-group';

export default function RightQuoteFadeTransition({ children, shouldTransition }) {
  return (
    <SwitchTransition
      mode={'out-in'}
    >
      <CSSTransition
        classNames={animationClassNames}
        enter={true}
        exit={false}
        in={shouldTransition}
        key={shouldTransition ? 'initial' : 'transitioned'}
        timeout={100}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
}

const animationClassNames = AnimationStyleSheet.createClassNames({
  enterActive: {
    opacity: 0,
  },
  enterDone: {
    transition: 'all 100ms ease-out',
    opacity: 1,
  },
});

RightQuoteFadeTransition.propTypes = {
  children: PropTypes.node.isRequired,
  shouldTransition: PropTypes.bool.isRequired,
};
