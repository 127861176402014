import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';

export default class ProfileForm extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  _handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit();
  }

  render() {
    return (
      <form onSubmit={this._handleSubmit}>
        {this.props.children}
      </form>
    );
  }
}
