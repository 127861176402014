import MaritalStatus from '@root/core/src/models/marital-status';

const MAXIMUM_COLLEGE_GRADUATE_AGE = 25;
const MAXIMUM_GOOD_STUDENT_AGE = 25;

export function showAccidentPreventionCourse(profileRulesContext, driver) {
  return profileRulesContext.accidentPreventionCourse &&
    driver.age >= profileRulesContext.accidentPreventionCourseMinimumAge ||
    profileRulesContext.activeDutyMilitary && driver.activeDutyMilitary ||
    profileRulesContext.nationalGuardMember && driver.nationalGuardMember;
}

export function showActiveDutyMilitary(profileRulesContext, driver) {
  if (profileRulesContext.accidentPreventionCourse && profileRulesContext.activeDutyMilitary) {
    return driver.age < profileRulesContext.accidentPreventionCourseMinimumAge;
  }

  return profileRulesContext.activeDutyMilitary;
}

export function showCollegeGraduateDiscount(profileRulesContext, driver) {
  return profileRulesContext.collegeGraduate &&
    driver.age < MAXIMUM_COLLEGE_GRADUATE_AGE &&
    driver.maritalStatus === MaritalStatus.SINGLE;
}

export function showGoodStudentDiscount(profileRulesContext, driver) {
  return profileRulesContext.goodStudent &&
    driver.age < MAXIMUM_GOOD_STUDENT_AGE &&
    driver.maritalStatus === MaritalStatus.SINGLE;
}

export function showNationalGuardMember(profileRulesContext, driver) {
  if (profileRulesContext.accidentPreventionCourse && profileRulesContext.nationalGuardMember) {
    return driver.age < profileRulesContext.accidentPreventionCourseMinimumAge;
  }

  return false;
}

export function showRefresherPreventionCourse(profileRulesContext) {
  if (profileRulesContext.refresherPreventionCourse) {
    return true;
  }

  return false;
}
