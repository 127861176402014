import get from '@root/vendor/lodash/get';
import i18n from '@root/bind.joinroot.com/src/services/i18n';
import merge from '@root/vendor/lodash/merge';

const getI18n = (propertyToGet, config = {
  lang: 'en',
  overwriteI18nValues: {},
}) => {
  const i18nLookup = merge(
    i18n,
    config?.overwriteI18nValues ?? {}
  );

  return get(i18nLookup, `${config.lang}.${propertyToGet}`);
};

export default getI18n;
