import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Disclaimer({ text, className }) {
  return (
    <div className={className}>
      <span css={styles.disclaimer}>
        {text}
      </span>
    </div>
  );
}

Disclaimer.propTypes = {
  className: PropTypes.node,
  text: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  disclaimer: Theme.paragraph2(),
});
