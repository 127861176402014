import PropTypes from '@root/vendor/prop-types';
import { RootError } from '@root-common/root-errors';
import { createContext, useContext } from '@root/vendor/react';
import { usePersistedState } from '@root/bind.joinroot.com/src/hooks/use-persisted-state';

export const LOCAL_STORAGE_KEY = 'localization';

const LocalizationContext = createContext();
const SetLocalizationContext = createContext();

const LocalizationProvider = ({ children, value: overrideValue = null }) => {
  const [localization, setLocalization] = usePersistedState({
    initialValue: overrideValue,
    shouldSkipLoadingFromLocalStorage: !!overrideValue,
    storageKey: LOCAL_STORAGE_KEY,
  });

  return (
    <LocalizationContext.Provider value={localization}>
      <SetLocalizationContext.Provider value={setLocalization}>
        {children}
      </SetLocalizationContext.Provider>
    </LocalizationContext.Provider>
  );
};

LocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

const useLocalizationState = () => {
  const context = useContext(LocalizationContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useLocalization must be used within LocalizationProvider',
      name: 'LocalizationError',
    });
  }

  return context;
};

const LocalizationConsumer = ({ children }) => {
  const localization = useLocalizationState();
  return children(localization);
};

LocalizationConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

const useSetLocalizationState = () => {
  const context = useContext(SetLocalizationContext);

  if (context === undefined) {
    throw new RootError({
      message: 'useLocalization must be used within LocalizationProvider',
      name: 'LocalizationError',
    });
  }

  return context;
};

const useLocalization = () => {
  return [useLocalizationState(), useSetLocalizationState()];
};

export {
  LocalizationConsumer,
  LocalizationProvider,
};

export default useLocalization;
