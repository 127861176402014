import Address from '@root/core/src/models/address';
import DobDate from '@root/core/src/models/dob-date';
import Homeowner from '@root/core/src/models/homeowner';
import RecentlyMoved from '@root/prefill/src/models/recently-moved';
import uuid from '@root/vendor/uuid/v4';

export default class PrefillRequest {
  static buildFromData({
    mailingAddress: mailingAddressFields = {},
    priorAddress: recentlyMovedMailingAddressFields = {},
    ...fields
  } = {}) {
    const mailingAddress = Address.buildFromData(mailingAddressFields);
    const recentlyMovedMailingAddress = Address.buildFromData(recentlyMovedMailingAddressFields);
    return Object.assign(
      new this(),
      {
        ...fields,
        mailingAddress,
        priorAddress: recentlyMovedMailingAddress,
      }
    );
  }

  constructor() {
    this.firstName = null;
    this.homeowner = null;
    this.maritalStatus = null;
    this.lastName = null;
    this.licenseNumber = null;
    this.licenseState = null;
    this.dob = null;
    this.mailingAddress = new Address();
    this.universalDriverId = uuid();
    this.previousResponse = null;
    this.phoneNumber = null;
    this.email = null;
    this.bundleHomeAndAuto = null;
    this.recentlyMoved = null;
    this.priorAddress = new Address();
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new PrefillRequest(),
      this,
      attributes,
    );
  }

  requiresDriversLicenseEntry() {
    if (!this.isLicenseValid() && this.previousResponse.requiresDriversLicenseEntry) { return true; }

    if (this.previousResponse && this.isReadyForSubmission()) {
      const drivers = this.previousResponse.drivers?.filter((d) => {
        return d.firstName.toLowerCase() === this.firstName.toLowerCase()
          && d.lastName.toLowerCase() === this.lastName.toLowerCase()
          && d.dob === this.dob
          && d.driversLicenseId === null;
      });
      return drivers?.length > 0;
    }
    return false;
  }

  isInSupportedMarket(supportedMarkets = []) {
    return supportedMarkets.includes(this.mailingAddress.state);
  }

  isInNewBusinessDisabledMarket(newBusinessDisabledMarkets = []) {
    return newBusinessDisabledMarkets.some((market) => market.abbreviation === this.mailingAddress.state);
  }

  isNameValid() {
    return !!(this.firstName && this.lastName);
  }

  isLicenseValid() {
    return !!(this.licenseNumber && this.licenseState);
  }

  isMailingAddressValid() {
    return this.mailingAddress.isValid;
  }

  isDobValid() {
    const dobDate = new DobDate(this.dob);
    return dobDate.isValid() && dobDate.isYoungEnough() && dobDate.isOldEnough();
  }

  isPhoneNumberValid() {
    return !!this.phoneNumber;
  }

  isEmailValid() {
    return !!this.email;
  }

  isMaritalStatusValid() {
    return !!this.maritalStatus;
  }

  isHomeownerValid() {
    return Homeowner.Options.some(({ value }) => this.homeowner === value);
  }

  isRecentlyMovedValid() {
    return RecentlyMoved.Options.some(({ value }) => this.recentlyMoved === value);
  }

  isRecentlyMovedMailingAddressValid() {
    return this.priorAddress.isValid;
  }

  isReadyForSubmission() {
    const validations = [
      this.isNameValid(),
      this.isMailingAddressValid(),
      this.isDobValid(),
      this.isPhoneNumberValid(),
      this.isEmailValid(),
      this.isHomeownerValid(),
      this.isMaritalStatusValid(),
    ];

    return validations.every((requirement) => requirement);
  }

  isReadyForEarlySubmission() {
    const validations = [
      this.isNameValid(),
      this.isMailingAddressValid(),
      this.isDobValid(),
    ];

    return validations.every((requirement) => requirement);
  }

  serializeForSubmission() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      dob: this.dob,
      homeowner: this.homeowner,
      maritalStatus: this.maritalStatus,
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
      mailingAddress: this.mailingAddress.serializeForSubmission(),
      universalDriverId: this.universalDriverId,
    };
  }
}
