import ServerUtils from '@root/core/src/api/server-utils';

export async function getPrefillAnswerSet() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/web_prefill_answer_set');
  return data;
}

export async function savePrefillAnswerSet(body) {
  const response = await ServerUtils.authenticatedPut('/web_api/web_prefill_answer_set', {
    body,
  });
  return response;
}

export async function getPrefill(params = {}) {
  const { data, rawResponse } = await ServerUtils.authenticatedGet('/web_api/prefill', {
    query: params,
    expectedResponse: [200, 204],
  });

  if (rawResponse.status === 204) {
    return {};
  }

  return data;
}

export async function getSupportedMarkets() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/supported_markets');
  return data;
}

export async function getTriageFlow() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/triage');
  return data;
}

export async function respondToCustomerSurvey({
  surveyName, questionName, answer,
}) {
  const response = await ServerUtils.authenticatedPatch('/web_api/customer_surveys/respond', {
    body: {
      customer_surveys: {
        survey_name: surveyName,
        question_name: questionName,
        response: JSON.stringify(answer),
      },
    },
    expectedResponse: 200,
  });
  return response;
}

export async function patchSkipPhoneNumberExperience() {
  const { data } = await ServerUtils.authenticatedPatch('/web_api/experiences/skip_prefill_phone_number_experience', {
    expectedResponse: [200],
  });
  return data;
}
