import AgentsPhoneNumber from '@root/agents/src/utils/agents-phone-number';
import Button from '@root/bind.joinroot.com/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { ANALYTICS_EVENT, ANALYTICS_SCOPE } from '@root/bind.joinroot.com/src/components/speak-to-agent';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export const formatAgentPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '1 (' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

const agentsFormattedNumber = formatAgentPhoneNumber(
  AgentsPhoneNumber.PHONE_NUMBERS.ISA_EMBEDDED
);

const agentsPhoneUrl = AgentsPhoneNumber.phoneFullURL(
  AgentsPhoneNumber.PHONE_NUMBERS.ISA_EMBEDDED
);

const ContactAgent = ({
  onClickCancel,
  styles = {},
}) => {
  const strings = useI18nNext('components.speakToAgent.modal');
  const heading = useMemo(() => strings.agentInfo.available.heading, [strings.agentInfo]);
  const detail = useMemo(() => strings.agentInfo.available.detail, [strings.agentInfo]);

  const [{ partnerAgentSupportAvailabilityHours, partnerAgentSupportPhoneNumber }] = useBranding();
  const partnerName = useI18nNext('partner') ?? '';
  const isPartnerBrandingAvailable = !!partnerAgentSupportPhoneNumber;

  const partnerHeading = useMemo(() => ({
    ...heading,
    speakTo: `to speak with your ${partnerName} agent.`,
  }), [heading, partnerName]);
  const partnerDetail = useMemo(() => partnerAgentSupportAvailabilityHours, [partnerAgentSupportAvailabilityHours]);
  const partnerFormattedNumber = useMemo(() => isPartnerBrandingAvailable && formatAgentPhoneNumber(partnerAgentSupportPhoneNumber), [isPartnerBrandingAvailable, partnerAgentSupportPhoneNumber]);
  const partnerPhoneUrl = useMemo(() => isPartnerBrandingAvailable && AgentsPhoneNumber.phoneFullURL(partnerAgentSupportPhoneNumber), [isPartnerBrandingAvailable, partnerAgentSupportPhoneNumber]);

  const { trackEvent } = useAnalytics(ANALYTICS_SCOPE, false);

  const handleClickCall = useCallback((e) => {
    e.preventDefault();
    trackEvent(ANALYTICS_EVENT.CLICK_CALL_AGENT);
    window.open(agentsPhoneUrl); // Open phone dialog in a new tab after recording analytics event
  }, [trackEvent]);

  if (isPartnerBrandingAvailable) {
    return (
      <>
        <p
          css={styles.heading}
        >
          <span>{partnerHeading.callFrom}</span>
          <span>{' '}</span>
          <a
            css={styles.phoneNumberLink}
            href={partnerPhoneUrl}
            onClick={handleClickCall}
          >
            {partnerFormattedNumber}
          </a>
          <span>{' '}</span>
          <span>{partnerHeading.speakTo}</span>
        </p>
        <p
          css={styles.detail}
          dangerouslySetInnerHTML={{ __html: partnerDetail }}
        />
        <Button
          onClick={onClickCancel}
        >
          {strings.close}
        </Button>
      </>
    );
  }

  return (
    <>
      <p
        css={styles.heading}
      >
        <span>{heading.callFrom}</span>
        <span>{' '}</span>
        <a
          css={styles.phoneNumberLink}
          href={agentsPhoneUrl}
          onClick={handleClickCall}
        >
          {agentsFormattedNumber}
        </a>
        <span>{' '}</span>
        <span>{heading.speakTo}</span>
      </p>
      <p
        css={styles.detail}
      >
        {detail}
      </p>
      <Button
        onClick={onClickCancel}
      >
        {strings.close}
      </Button>
    </>
  );
};

ContactAgent.propTypes = {
  onClickCancel: PropTypes.func,
  styles: PropTypes.object,
};

export default ContactAgent;
