import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import StateSelect from '@root/core/src/components/state-select';
import useInputFocusOnMount from '@root/core/src/hooks/use-input-focus-on-mount';
import { Col, Row } from '@root/core/src/components/grid';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function AddressFields({
  form,
  focusOnMount = false,
  disabled = false,
}) {
  const inputRef = useInputFocusOnMount(focusOnMount);

  return (
    <>
      <Row css={styles.rowline}>
        <Col sm={12}>
          <Input
            autoComplete={'shipping address-line1'}
            disabled={disabled}
            id={'address-line1'}
            label={'Address Line 1'}
            name={'address-line1'}
            ref={inputRef}
            {...form.getFieldProps('line1')}
          />
        </Col>
      </Row>
      <Row css={styles.rowline}>
        <Col sm={12}>
          <Input
            autoComplete={'shipping address-line2'}
            disabled={disabled}
            id={'address-line2'}
            label={'Apartment or suite (optional)'}
            name={'address-line2'}
            {...form.getFieldProps('line2')}
          />
        </Col>
      </Row>
      <Row css={styles.rowline}>
        <Col sm={12}>
          <Input
            autoComplete={'shipping address-level2'}
            disabled={disabled}
            id={'address-level2'}
            label={'City'}
            name={'address-level2'}
            {...form.getFieldProps('city')}
          />
        </Col>
      </Row>
      <Row css={styles.rowline}>
        <Col
          css={styles.collapsable}
          sm={6}
          xs={12}
        >
          {disabled ? (
            <Input
              disabled
              label={'State'}
              {...form.getFieldProps('state')}
            />
          ) : (
            <StateSelect
              selectedValue={form.values.state || ''}
              {...form.getFieldProps('state')}
            />
          )}
        </Col>
        <Col
          sm={6}
          xs={12}
        >
          <Input
            autoComplete={'shipping postal-code'}
            disabled={disabled}
            id={'postal-code'}
            inputType={TextTypes.NUMBER}
            label={'ZIP'}
            name={'postal-code'}
            {...form.getFieldProps('zip')}
          />
        </Col>
      </Row>
    </>
  );
}

AddressFields.propTypes = {
  disabled: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  rowline: {
    marginTop: 10,
  },
  collapsable: {
    marginBottom: 10,
  },
});
