export default class QuotesCheckoutError {
  static TYPE = {
    PROFILE_ERROR: 'profile_error',
    PAYMENT_ERROR: 'payment_error',
    QUOTES_ERROR: 'quotes_error',
  }

  static SERVER_ERROR_MESSAGES = {
    INVALID_PAYMENT: 'Do Not Honor',
    DENYLISTED_DRIVER: 'Oops! It looks like one or more of the drivers you added are not eligible for Root because they were previously declined.',
    INVALID_EFFECTIVE_DATE: 'Attempted to bind with an invalid effective date',
    MORATORIUM_IN_EFFECT: 'We are unable to process new policies at this time',
    PREPAID_CARDS_NOT_ACCEPTED: 'Root doesn\'t accept prepaid cards \n\n To purchase this policy, update your payment method to a bank-affiliated debit card or credit card.',
  }

  static CLIENT_ERROR_MESSAGES = {
    INVALID_PAYMENT: 'Your payment didn’t go through. Please try again.',
    DENYLISTED_DRIVER: 'You need to remove one of your drivers to continue.',
    INVALID_EFFECTIVE_DATE: 'You need to change your start date to continue.',
    MORATORIUM_IN_EFFECT: 'We can’t process your policy at this time.',
    PREPAID_CARDS_NOT_ACCEPTED: 'To purchase this policy, update your payment method to a bank-affiliated debit card or credit card.',
  }

  static ERRORS = {
    [QuotesCheckoutError.SERVER_ERROR_MESSAGES.DENYLISTED_DRIVER]: QuotesCheckoutError.build({
      heading: 'Hmm...',
      message: QuotesCheckoutError.CLIENT_ERROR_MESSAGES.DENYLISTED_DRIVER,
      type: QuotesCheckoutError.TYPE.PROFILE_ERROR,
    }),
    [QuotesCheckoutError.SERVER_ERROR_MESSAGES.INVALID_EFFECTIVE_DATE]: QuotesCheckoutError.build({
      heading: 'Hmm...',
      message: QuotesCheckoutError.CLIENT_ERROR_MESSAGES.INVALID_EFFECTIVE_DATE,
      type: QuotesCheckoutError.TYPE.QUOTES_ERROR,
    }),
    [QuotesCheckoutError.SERVER_ERROR_MESSAGES.MORATORIUM_IN_EFFECT]: QuotesCheckoutError.build({
      heading: 'Hmm...',
      message: QuotesCheckoutError.CLIENT_ERROR_MESSAGES.MORATORIUM_IN_EFFECT,
      type: QuotesCheckoutError.TYPE.QUOTES_ERROR,
    }),
    [QuotesCheckoutError.SERVER_ERROR_MESSAGES.PREPAID_CARDS_NOT_ACCEPTED]: QuotesCheckoutError.build({
      heading: 'Root doesn’t accept prepaid cards',
      message: QuotesCheckoutError.CLIENT_ERROR_MESSAGES.PREPAID_CARDS_NOT_ACCEPTED,
      type: QuotesCheckoutError.TYPE.PAYMENT_ERROR,
    }),
    default: QuotesCheckoutError.build({
      heading: 'Hmm...',
      message: QuotesCheckoutError.CLIENT_ERROR_MESSAGES.INVALID_PAYMENT,
      type: QuotesCheckoutError.TYPE.PAYMENT_ERROR,
    }),
  }

  static build({
    heading,
    message,
    type,
  } = {}) {
    const checkoutError = new QuotesCheckoutError();
    return checkoutError.setAttributes({
      heading,
      message,
      type,
    });
  }

  constructor() {
    this.heading = null;
    this.message = null;
    this.type = null;
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new QuotesCheckoutError(),
      this,
      attributes,
    );
  }

  static parseResponseError(data) {
    const message = data?.data?.message;

    if (data?.rawResponse?.status !== 422 || !message) {
      return null;
    }

    return QuotesCheckoutError.ERRORS[message] || QuotesCheckoutError.ERRORS.default;
  }
}
