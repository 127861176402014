import Button, { BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button';
import Link from '@root/core/src/components/link';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { AnimationStyleSheet, Colors, Gradients, StyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';

export default function Carousel({ children, onContinue }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAnimatingForward, setIsAnimatingForward] = useState(true);
  const [isNavDisabled, setIsNavDisabled] = useState(false);

  const handleContinue = () => {
    if (isNavDisabled) { return; }

    if (activeIndex < children.length - 1) {
      setIsNavDisabled(true);
      setIsAnimatingForward(true);
      setActiveIndex(activeIndex + 1);
    } else {
      onContinue();
    }
  };

  const handlePrevious = () => {
    if (isNavDisabled) { return; }

    if (activeIndex > 0) {
      setIsNavDisabled(true);
      setIsAnimatingForward(false);
      setActiveIndex(activeIndex - 1);
    }
  };

  const dots = [...Array(children.length)].map((_, index) => {
    const style = [styles.dot];
    if (index === activeIndex) {
      style.push(styles.dotSelected);
    }

    return (
      <div
        css={style}
        key={index}
      />
    );
  });

  const continueButton = (
    <Button
      css={styles.button}
      onClick={handleContinue}
    >
      {activeIndex < children.length - 1 ? 'Next' : 'Close'}
    </Button>
  );

  const animationClassNames = isAnimatingForward ? animationClassNamesForward : animationClassNamesBackward;

  return (
    <div css={styles.outerContainer}>
      <div css={styles.upperContainer}>
        {
          children.length > 1 &&
          <div css={styles.dotsContainer}>
            {dots}
          </div>
        }

        <div css={styles.body}>
          <TransitionGroup
            childFactory={childFactoryCreator(animationClassNames)}
            css={styles.transitionGroup}
          >
            <CSSTransition
              key={activeIndex}
              onExited={() => setIsNavDisabled(false)}
              timeout={300}
            >
              <div css={styles.childContent}>
                {children[activeIndex]}
              </div>
            </CSSTransition>
          </TransitionGroup>

          <div css={styles.overflowZone} />
        </div>
      </div>

      <div css={styles.lowerContainer}>
        <div css={styles.desktopButtons}>
          {continueButton}

          {
            activeIndex > 0 &&
            <Link
              cssOverrides={styles.linkButton}
              onClick={handlePrevious}
            >
              Previous
            </Link>
          }
        </div>

        <div css={styles.mobileButtonRow}>
          {
            activeIndex > 0 &&
            <Button
              css={styles.button}
              onClick={handlePrevious}
              variant={BUTTON_VARIANT_SECONDARY}
            >
              Previous
            </Button>
          }

          {continueButton}
        </div>
      </div>
    </div>
  );
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  onContinue: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  upperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 24,
    minHeight: 0,
    height: '100%',
    ...Responsive.sm({
      flexDirection: 'column-reverse',
    }),
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    height: 10,
    minWidth: 10,
    borderRadius: 50,
    border: `1px solid ${Colors.gray40()}`,
    margin: '0 5px',
  },
  dotSelected: {
    backgroundColor: Colors.black(),
    border: 'none',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    justifyContent: 'space-between',
    height: '100%',
  },
  transitionGroup: {
    position: 'relative',
    overflow: 'hidden',
  },
  childContent: {
    paddingBottom: 40,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  overflowZone: {
    background: Gradients.topToBottom([`${Colors.white()}00 0%`, `${Colors.white()} 100%`]),
    minHeight: 41,
    margin: '-40px 0 0',
    zIndex: 1,
    position: 'relative',
  },
  lowerContainer: {
    marginTop: 24,
  },
  desktopButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 6,
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  mobileButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    ...Responsive.sm({
      display: 'none',
    }),
  },
  button: {
    width: '100%',
    minWidth: 140,
    textAlign: 'center',
    marginTop: 0,
  },
  linkButton: {
    width: '100%',
    height: 60,
    ':focus': {
      outline: 'none',
    },
  },
});

function childFactoryCreator(classNames) {
  return (child) => React.cloneElement(child, {
    classNames,
  });
}

const animationClassNamesForward = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateX(100%)',
    overflow: 'hidden',
  },
  enterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-in-out',
    overflow: 'hidden',
  },
  enterDone: {
    transform: 'translateX(0%)',
  },
  exit: {
    transform: 'translate(0%, -100%)',
    position: 'absolute',
    overflow: 'hidden',
  },
  exitActive: {
    transform: 'translate(-100%, -100%)',
    transition: 'all 300ms ease-in-out',
    overflow: 'hidden',
  },
  exitDone: {
    transform: 'translate(-100%, -100%)',
    opacity: 0,
    overflow: 'hidden',
  },
});

const animationClassNamesBackward = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateX(-100%)',
    overflow: 'hidden',
  },
  enterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-in-out',
    overflow: 'hidden',
  },
  enterDone: {
    transform: 'translateX(0%)',
  },
  exit: {
    transform: 'translate(0%, -100%)',
    position: 'absolute',
    overflow: 'hidden',
  },
  exitActive: {
    transform: 'translate(100%, -100%)',
    transition: 'all 300ms ease-in-out',
    overflow: 'hidden',
  },
  exitDone: {
    transform: 'translate(100%, -100%)',
    opacity: 0,
    overflow: 'hidden',
  },
});
