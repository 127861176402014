import BindParams from '@root/quotes/src/models/bind-params';
import CoverageSectionAdditional from '@root/quotes/src/components/coverage/coverage-section-additional';
import CoverageSectionDeductibles from '@root/quotes/src/components/coverage/coverage-section-deductibles';
import CoverageSectionLiability from '@root/quotes/src/components/coverage/coverage-section-liability';
import CoverageSectionNonLiability from '@root/quotes/src/components/coverage/coverage-section-non-liability';
import CoverageSectionRentalCar from '@root/quotes/src/components/coverage/coverage-section-rental-car';
import CoverageSectionRideshare from '@root/quotes/src/components/coverage/coverage-section-rideshare';
import CoverageSectionRoadside from '@root/quotes/src/components/coverage/coverage-section-roadside';
import CustomQuote from '@root/quotes/src/models/custom-quote';
import Profile from '@root/auto-pricing/src/models/profile';
import ProfileParams from '@root/auto-pricing/src/models/profile-params';
import PropTypes from '@root/vendor/prop-types';
import QuoteCoveragesContext from '@root/quotes/src/models/quote-coverages-context';
import QuotingRules from '@root/quotes/src/models/quoting-rules';
import React, { Component } from '@root/vendor/react';

export default class CoverageCustomContainer extends Component {
  static propTypes = {
    bindParams: PropTypes.instanceOf(BindParams).isRequired,
    customQuote: PropTypes.instanceOf(CustomQuote).isRequired,
    monthlyPremiumRatio: PropTypes.number.isRequired,
    passProps: PropTypes.object.isRequired,
    profile: PropTypes.instanceOf(Profile).isRequired,
    profileParams: PropTypes.instanceOf(ProfileParams).isRequired,
    quoteCoveragesContext: PropTypes.instanceOf(QuoteCoveragesContext).isRequired,
    quotingRules: PropTypes.instanceOf(QuotingRules).isRequired,
  };

  render() {
    const {
      bindParams,
      customQuote,
      monthlyPremiumRatio,
      profileParams,
      quoteCoveragesContext,
      quotingRules,
    } = this.props;

    const profileVins = profileParams.vehicles.map((v) => v.getAvailableVin());
    const billingCycle = bindParams.billingCycle;
    const market = profileParams.mailingAddress.state;
    const coverageSelections = quoteCoveragesContext.getCoverageSelections({
      billingCycle,
      customQuote,
      monthlyPremiumRatio,
      profileVins,
      quotingRules,
      market,
    });

    const remainingProps = {
      quoteCoveragesContext,
      customQuote,
      profileParams,
      bindParams,
      coverageSelections,
      quotingRules,
    };

    return (
      <>
        <CoverageSectionLiability
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionDeductibles
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionNonLiability
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionRoadside
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionAdditional
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionRentalCar
          {...this.props.passProps}
          {...remainingProps}
        />
        <CoverageSectionRideshare
          cardHeaderBackgroundColor={this.props.passProps.cardHeaderBackgroundColor}
          goToProfileReview={this.props.passProps.goToProfileReview}
          IconComponent={this.props.passProps.IconComponent}
          onLearnMoreClick={this.props.passProps.onLearnMoreClick}
          onRemoveCoverage={this.props.passProps.onRemoveRideshareCoverage}
          primaryBrandColor={this.props.passProps.primaryBrandColor}
          profile={this.props.profile}
          rideshareRef={this.props.passProps.rideshareRef}
          skipProfileReviewFlow={this.props.passProps.skipProfileReviewFlow}
          styleOverrides={this.props.passProps.styleOverrides}
        />
      </>
    );
  }
}
