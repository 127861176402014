import BaseModal from '@root/core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { BUTTON_VARIANT_PRIMARY, BUTTON_VARIANT_STYLES } from '@root/core/src/components/button/styles/variant';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

import ContactAgent from '@root/bind.joinroot.com/src/components/speak-to-agent/modal/contact-agent';

import { ANALYTICS_EVENT, ANALYTICS_SCOPE } from '@root/bind.joinroot.com/src/components/speak-to-agent';

const styles = StyleSheet.create({
  button: {
    ...BUTTON_VARIANT_STYLES[BUTTON_VARIANT_PRIMARY],
    backgroundColor: 'transparent',
    color: Colors.black(),
    border: 0,
    svg: {
      fill: Colors.black(),
    },
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${Colors.gray50()}`,
    color: Colors.gray50(),
  },
  heading: {
    ...Theme.heading2(),
    fontWeight: 600,
  },
  detail: {
    ...Theme.paragraph1(),
    fontSize: 22,
  },
  innerContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    padding: '40px 0 0 0 !important',

    ...Responsive.sm({
      width: Responsive.BREAKPOINTS.xsm,
    }),
  },
  phoneNumberLink: {
    color: Colors.nearBlack(),
    textDecorationLine: 'underline',
    textDecorationStyle: 'dashed',
  },
});

const Modal = ({
  isShowing,
  name = 'speak-to-agent-modal',
  onCancel,
}) => {
  const { trackEvent } = useAnalytics(ANALYTICS_SCOPE, false);

  const handleClickCancel = useCallback(() => {
    trackEvent(ANALYTICS_EVENT.CLOSE_MODAL);
    onCancel();
  }, [onCancel, trackEvent]);

  return (
    <BaseModal
      isShowing={isShowing}
      name={name}
      onCancel={handleClickCancel}
      styleOverrides={styles}
    >
      <ContactAgent
        data-testid={'components--speak-to-agent--modal--contact-agent'}
        onClickCancel={handleClickCancel}
        styles={styles}
      />
    </BaseModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isShowing: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

export default Modal;
