import GlobalFooterDisclaimer from '@root/core/src/components/global-footer-disclaimer';
import IconText from '@root/core/src/components/icon-text';
import React, { useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import lock from '@root/core/src/assets/lock.svg';
import useBranding from '@root/bind.joinroot.com/src/context/branding';
import { Colors, StyleSheet, createEmotionClassName } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

const FooterDisclaimers = () => {
  const strings = useI18nNext('components.bindStartEntry.footerDisclaimers');

  const [branding] = useBranding();
  const styles = useMemo(() => stylesGenerator(branding), [branding]);

  return (
    <div css={styles.container}>
      <IconText
        containerStyles={styles.header}
        icon={lock}
        iconAltText={'Security lock'}
        iconStyles={styles.icon}
        text={strings.header}
      />
      <GlobalFooterDisclaimer
        shouldRenderAdditionalDisclaimers
        className={createEmotionClassName(styles.disclaimers)}
      />
    </div>
  );
};

FooterDisclaimers.propTypes = {};

const stylesGenerator = ({ primaryColor }) => StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    color: Colors.gray50(),
    fontWeight: 'normal',
  },
  disclaimers: {
    '> small': {
      fontSize: '0.875rem',
      '> a': {
        color: primaryColor,
      },
      '> span > a': {
        color: primaryColor,
      },
    },
  },
  header: {
    marginTop: 40,
    marginBottom: 20,
  },
  paragraph: {
    marginBottom: 100,
    ...Responsive.md({
      width: '60%',
    }),
  },
});

export default FooterDisclaimers;
