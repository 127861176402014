import ArrowBack from '@root/bind.joinroot.com/src/assets/icons/arrow-back';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

const BackButton = ({
  color, onClickButton, text,
}) => {
  return (
    <div css={styles.container}>
      <button
        css={[styles.button, { color }]}
        onClick={onClickButton}
        type={'button'}
      >
        <ArrowBack
          color={color}
          css={styles.icon}
        />
        <p css={styles.ctaText}>
          {text}
        </p>
      </button>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'left',
    paddingTop: 10,
    zIndex: 1,
    marginLeft: 10,
    marginBottom: 0,
    ...Responsive.md({
      marginLeft: 80,
      marginBottom: -30,
    }),
  },
  button: {
    cursor: 'pointer',
    background: 'none',
    paddingTop: 10,
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    zIndex: 5,
  },
  ctaText: {
    textDecoration: 'underline',
    paddingLeft: 5,
    fontSize: '1em',
  },
  icon: {
    height: '1rem',
    width: '1rem',
  },
});

BackButton.propTypes = {
  color: PropTypes.string,
  onClickButton: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default BackButton;
