import ChevronRight from '@root/bind.joinroot.com/src/components/icons/chevron-right';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import getI18n from '@root/bind.joinroot.com/src/utils/get-i18n';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const styles = StyleSheet.create({
  list: {
    height: '100%',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    paddingRight: '1.5rem',

    ...Responsive.lessThanMd({
      display: 'none',
    }),
  },
  listItem: {
    color: Colors.gray50(),
    fontSize: 14,
  },
  listItemActive: {
    color: Colors.nearBlack(),
    fontWeight: 700,
  },
  listItemSeparator: {
    fontSize: 10,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
});

const items = getI18n('components.bindView.header.progress.items');

export const PROGRESS_ITEMS = {
  FINALIZE_QUOTE: {
    title: items.finalizeQuote,
  },
  CUSTOMIZE_COVERAGES: {
    title: items.customizeCoverages,
  },
  CHECKOUT: {
    title: items.checkout,
  },
};

const Progress = ({
  currentProgressItem,
  progressItems = Object.values(PROGRESS_ITEMS),
  ...restProps
}) => {
  const renderProgressItem = useCallback((progressItem, linkIndex) => {
    const isActive = progressItem === currentProgressItem;
    const isNotLastItem = linkIndex + 1 !== progressItems.length;
    return (
      <React.Fragment key={progressItem.title}>
        <li
          css={[
            styles.listItem,
            ...isActive ? [styles.listItemActive] : [],
          ]}
          data-testid={`listItem${isActive ? '-active' : ''}`}
        >
          {progressItem.title}
        </li>
        {
          isNotLastItem &&
          <li css={styles.listItemSeparator}>
            <ChevronRight
              color={Colors.gray30()}
              data-testid={'listItemeparator'}
              height={'0.8rem'}
            />
          </li>
        }
      </React.Fragment>
    );
  }, [currentProgressItem, progressItems]);

  const renderedProgressItems = useMemo(() => [...progressItems].map(renderProgressItem), [progressItems, renderProgressItem]);

  return (
    <ul
      css={styles.list}
      data-testid={'progress'}
      {...restProps}
    >
      {renderedProgressItems}
    </ul>
  );
};

export const ProgressItemType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  activeRoutes: PropTypes.arrayOf(PropTypes.string),
  active: PropTypes.bool,
});

Progress.propTypes = {
  currentProgressItem: PropTypes.object,
  progressItems: PropTypes.arrayOf(ProgressItemType),
};

export default Progress;
