import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { AnimationStyleSheet, LegacyStyleSheet as StyleSheet, css } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';

export default class SubflowAnimator extends Component {
  static propTypes = {
    animatorKey: PropTypes.any.isRequired,
    children: PropTypes.node.isRequired,
  }

  render() {
    return (
      <TransitionGroup className={css(styles.transitionGroup)}>
        <CSSTransition
          classNames={animationClassNames}
          key={this.props.animatorKey}
          timeout={300}
        >
          {this.props.children}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

const styles = StyleSheet.create({
  transitionGroup: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

const animationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateX(25%)',
    opacity: '0',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  enterActive: {
    transform: 'translateX(0%)',
    transition: 'all 300ms ease-out',
    opacity: '1',
  },
  enterDone: {
    transform: 'translateX(0%)',
    opacity: '1',
  },
  exit: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: 'translateX(0%)',
    opacity: '1',
  },
  exitActive: {
    transform: 'translateX(-25%)',
    transition: 'all 300ms ease-out',
    opacity: '0',
  },
  exitDone: {
    transform: 'translateX(-25%)',
    opacity: '0',
  },
});
