import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function RightQuoteExplainerLearnMore({
  children,
  displayExplainer,
}) {
  const containerStyle = [styles.container];
  if (!displayExplainer) {
    containerStyle.push(styles.containerHidden);
  }

  return (
    <div
      css={containerStyle}
      role={'note'}
    >
      <div css={styles.content}>
        {children}
      </div>
    </div>
  );
}

RightQuoteExplainerLearnMore.propTypes = {
  children: PropTypes.node.isRequired,
  displayExplainer: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  container: {
    transition: 'max-height 0.2s ease-out',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: 250,
    margin: '30px 0',
  },
  content: {
    backgroundColor: Colors.gray50(),
    padding: '30px 0',
  },
  containerHidden: {
    maxHeight: 0,
    margin: 0,
    opacity: 0,
  },
});
